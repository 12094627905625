//We need this to make sure Uncaught ReferenceError: process is not defined doesn't show up
//this will have to do until or if we can upgrade our react-scripts to v5
//read this if you wanna know more https://github.com/facebook/create-react-app/issues/11771
window.process = {};

//And these are just the normal imports
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import Theme from "vardogyir-ui/Theme";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";

ReactDOM.render(
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={Theme}>
      <div>
        <App />
      </div>
    </ThemeProvider>
  </StyledEngineProvider>,
  document.getElementById("root")
);
