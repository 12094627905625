import React, { useRef } from "react";
import { Grid, Button, TextField, styled, InputAdornment, Typography } from "@mui/material";
import { Send, AttachFile } from "@mui/icons-material";
import ImageChip from "../../util/ImageChip";
import CircularProgress from "@mui/material/CircularProgress";

const StyledTextFieldContainer = styled(Grid)({
  paddingTop: "16px",
});

const StyledButton = styled(Button)({
  width: "36px!important",
  height: "28px",
  background: "#944BA8",
  "&:hover": {
    background: "#7B3D8A",
  },
  "&:active": {
    background: "#6A336F",
  },
  marginLeft: "8px",
  minWidth: "unset",
});

const AttachmentButton = styled(Button)({
  width: "36px!important",
  height: "28px",
  minWidth: "unset",
  padding: 0,
});

const CharacterCount = styled(Typography)({
  fontSize: '0.75rem',
  color: '#666',
  textAlign: 'left',  
  marginTop: '4px',
});

function PromptTextfield(props) {
  // Helper function to check if a question has been answered
  const checkIfQuestionAnswered = (prompt, ansList) => {
    if (prompt && prompt.length > 0) return true;
    for (let ans of ansList) {
      if (ans.length > 0) return true;
    }
    return false;
  };

  const getCorrectTextfield = () => {
    // Determine if the text field should be disabled
    const disabled = (props.isCompBuilder && !props.isInit) || props.paneSkeleton;

    // If loading or text field shouldn't be shown, return empty fragment
    if (props.loading || !props.showTextfield) return <></>;

    // Common props for both text fields
    const commonProps = {
      maxRows: 5,
      multiline: true,
      variant: "outlined",
      size: "small",
      fullWidth: true,
      inputProps: {
        maxLength: props.charLimit ? props.charLimit : 2000,
      },
    };
  

    const additionalPromptProps = {
      value: props.additionalMessagePrompt,
      placeholder: "Send an additional message to make changes or adjustments",
      onChange: (event) => {
        props.setAdditionalMessagePrompt(event.target.value);
      },
    };

    const userPromptProps = {
      value: props.userPrompt,
      placeholder: "Send a message",
      onChange: (event) => {
        props.updateUserPrompt(event.target.value);
      },
    };

    // Determine if the send button should be disabled for prebuild mode
    const prebuildDisabled = props.isPrebuild && props.prebuildQuestionAnswers?.length > 0
      ? !checkIfQuestionAnswered(props.userPrompt, props.prebuildQuestionAnswers)
      : props.userPrompt.length <= 0 && props.files.length <= 0;

    const isSendButtonDisabled = (
      (props.isAdditionalPrompt ? props.additionalMessagePrompt.length <= 0 && props.files.length <= 0 :
      prebuildDisabled) ||
      disabled ||
      props.isLoading
    );

    // Reference for the file input element
    const fileInputRef = useRef(null);

    // Handler for adding an image
    const handleAddImage = () => {
      fileInputRef.current.click();
    };

    // Handler for file selection
    const handleFileChange = (event) => {
      const file = event.target.files[0];

      if (file) {
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        });
        if (props.files.length > 0) {
          URL.revokeObjectURL(props.files[0]);
        }
        props.setFiles([file]);
        event.target.value = null;
      }
    };

    const endAdornment = (
      <InputAdornment position="end">
        <AttachmentButton onClick={handleAddImage}>
          <AttachFile style={{ color: 'black' }} />
        </AttachmentButton>
        <StyledButton
          variant={"contained"}
          onClick={props.isAdditionalPrompt ? props.generateWithAdditional : props.generate}
          disabled={isSendButtonDisabled}
        >
          {props.isLoading && (
            <CircularProgress size={24} className="buttonProgress" />
          )}
          <Send />
        </StyledButton>
      </InputAdornment>
    );

    const fileInput = (
      <input
        accept="image/*"
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={handleFileChange}
      />
    );

    const renderTextField = (textFieldProps) => (
      <>
        <TextField
          {...commonProps}
          {...textFieldProps}
          InputProps={{ endAdornment }}
        />
        <CharacterCount>
          {textFieldProps.value.length} / {props.charLimit || 2000}
        </CharacterCount>
      </>
    );

    // Render the appropriate text field based on whether it's an additional prompt or not
    if (props.isAdditionalPrompt) {
      return (
        <StyledTextFieldContainer container item xs={12}>
          <ImageChip
            files={props.files}
            item
            xs={12}
            setFiles={props.setFiles}
          />
          {renderTextField(additionalPromptProps)}
          {fileInput}
        </StyledTextFieldContainer>
      );
    } else {
      return (
        <Grid container direction={"column"}>
          <ImageChip
            files={props.files}
            item
            xs={12}
            setFiles={props.setFiles}
          />
          <StyledTextFieldContainer container item xs={12} direction="column">
            {renderTextField(userPromptProps)}
            {fileInput}
          </StyledTextFieldContainer>
        </Grid>
      );
    }
  };

  return getCorrectTextfield();
}

export default PromptTextfield;