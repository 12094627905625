import React from "react";
import { Chip } from "@mui/material";
import { PropTypes } from "mobx-react";
import {
  CheckBoxOutlineBlank,
  CheckBoxOutlined,
  CancelOutlined,
  ErrorOutline,
  MailOutline,
  PersonOutline,
  OutlinedFlag
} from "@mui/icons-material";

const ERROR_COLOUR = "rgb(225, 0, 0)";
const TODO_COLOUR = "rgb(255, 165, 0)";
const SUCCESS_COLOUR = "rgb(76, 175, 80)";
const TERMINATED_COLOUR = "rgb(158, 158, 158, 0.5)";
const DEFAULT_EMAIL_STATUS_COLOUR = "rgb(33, 150, 243)";
const DEFAULT_USERTASK_COLOUR = "rgb(156, 39, 176)";
const TaskChip = (props) => {
  const isEmail = (value) => {
    var reg = /\S+@\S+\.\S+/;
    return reg.test(value);
  };

  const getChipStyle = (color, fontWeight = 'normal') => ({
    backgroundColor: `${color}`,
    color: 'rgb(255, 255, 255)',
    fontWeight: fontWeight,
  });

  const whiteIconStyle = { color: 'white' };

  const getChipIcon = (status) => {
    switch (status) {
      case "TODO": return <CheckBoxOutlineBlank style={whiteIconStyle} />;
      case "DONE": return <CheckBoxOutlined style={whiteIconStyle} />;
      case "OVERDUE": return <OutlinedFlag style={whiteIconStyle} />;
      case "TERMINATED": return <CancelOutlined style={whiteIconStyle} />;
      case "ERROR": return <ErrorOutline style={whiteIconStyle} />;
      default: return isEmail(status) ? <MailOutline style={whiteIconStyle} /> : <PersonOutline style={whiteIconStyle} />;
    }
  };

  const overDue = props.overDue;
  switch (props.value) {
    case "TODO":
      if (props.extras.toDoColor != null)
        return (
          <Chip
            className={"taskChip warningTask"}
            style={getChipStyle(overDue ? ERROR_COLOUR : props.extras.toDoColor)}
            label={
              props.extras.toDoLabel != null
                ?
                overDue ? "(Overdue) "+ props.extras.toDoLabel : props.extras.toDoLabel
                :
                overDue ? "(Overdue) "+ "To do" : "To do"
            }
            icon={getChipIcon(overDue ? "OVERDUE" : "TODO")}
          />
        );
      else return <Chip className={"taskChip warningTask"} style={getChipStyle(TODO_COLOUR)} label="To do" icon={getChipIcon("TODO")} />;
    case "DONE":
      if (props.extras.completedColor)
        return (
          <Chip
            className={"taskChip warningTask"}
            style={getChipStyle(props.extras.completedColor)}
            label={props.extras.completedLabel != null ? props.extras.completedLabel : "Completed" }
            icon={getChipIcon("DONE")}
          />
        );
      else return <Chip className={"taskChip successTask"} style={getChipStyle(SUCCESS_COLOUR)} label="Done" icon={getChipIcon("DONE")} />;
    case "OVERDUE":
      return <Chip className={"taskChip errorTask"} style={getChipStyle(ERROR_COLOUR)} label="Overdue" icon={getChipIcon("OVERDUE")} />;
    case "TERMINATED":
      return (
        <Chip
          className={"taskChip defaultTask"}
          style={getChipStyle(TERMINATED_COLOUR, 'normal')}
          label="Terminated"
          icon={getChipIcon("TERMINATED")}
        />
      );
    case "ERROR":
      return <Chip className={"taskChip errorTask"} style={getChipStyle(ERROR_COLOUR)} label="Error" icon={getChipIcon("ERROR")} />;
    default:
      if (isEmail(props.value)) {
        return <Chip className={"taskChip"} style={getChipStyle(DEFAULT_EMAIL_STATUS_COLOUR)} label={props.value} icon={getChipIcon(props.value)} />;
      } else {
        return <Chip className={"taskChip userTask"} style={getChipStyle(DEFAULT_USERTASK_COLOUR)} label={props.value} icon={getChipIcon(props.value)} />;
      }
  }
};
TaskChip.propTypes = {
  value: PropTypes.string,
};

export default TaskChip;