import React from "react";
//mui
import { Grid, Fade } from "@mui/material";
//custom components
import Timer from "../../Timer";
//styles
import { withStyles } from "@mui/styles";
//components
import JSONRenderer from "../JSONRenderer";

const styles = () => ({
  gpt: {
    maxWidth: "100%!important",
    background: "#F5F5F5",
    borderRadius: "16px 16px 16px 0px",
    width: "fit-content",
    padding: "16px",
    fontSize: "14px",
    display: "flex",
    flexDirection: "column",
  },
});

const GPTResponse = ({
  classes,
  content,
  startTime,
  endTime,
  showTimer,
  isJson,
  showProposalText
}) => {
  return (
    <Fade timeout={1000} in={true}>
      {isJson ? (
        <Grid className={classes.gpt}>
          <b style={{ marginBottom: "16px" }}>Full plan</b>
          You can also view the full plan document which sets out the details of
          this workflow below.
          <JSONRenderer inputString={content} />
          {showTimer && <Timer startTime={startTime} endTime={endTime} />}
        </Grid>
      ) : (
        <Grid className={classes.gpt}>
          {(showProposalText || showTimer) && (
            <b style={{ marginBottom: "16px" }}>
              Proposal
            </b>
          )}
          {content}
          {showTimer && <Timer startTime={startTime} endTime={endTime} />}
        </Grid>
      )}
    </Fade>
  );
};

export default withStyles(styles)(GPTResponse);
