const config_dev = {
    USAGE_SERVICE_ADDRESS: "",
    REGION: process.env.REGION || "ap-southeast-2",
    USER_POOL_ID: process.env.USER_POOL_ID || "ap-southeast-2_Kp9DpRrdK",
    APP_CLIENT_ID: process.env.APP_CLIENT_ID || "5f93lfrqqh7nruhjiiin8qbeem",
    DOMAIN: process.env.DOMAIN || "w86dev.click",
    ISS: ""
};

const config_prod = {
    USAGE_SERVICE_ADDRESS: "",
    REGION: process.env.REGION || "ap-southeast-2",
    USER_POOL_ID: process.env.USER_POOL_ID || "ap-southeast-2_4pkuaVYZY",
    APP_CLIENT_ID: process.env.APP_CLIENT_ID || "7k5h7tnrnkdcg78d9ofpt1opij",
    DOMAIN: process.env.DOMAIN || "workflow86.com",
    ISS: ""
};
//const config = process.env.REACT_APP_ENV === "development" ? config_dev : config_prod;
const config = config_prod;
export default config;
