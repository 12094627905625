import React from "react";

// MUI
import { Close } from "@mui/icons-material";
import { Button, Grid } from "@mui/material";
import { styled } from "@mui/system";

// Util
import W86DraggableResizeablePane from "../../../../../W86Components/W86DraggableResizeablePane";
import Mermaid from "./Mermaid";

// Styled components
const TextButton = styled(Button)({
    textTransform: "unset !important",
    color: "black",
    fontSize: "11px",
});

const DraftText = styled("div")({
    color: "#5A5A5A",
    fontSize: "11px",
    fontWeight: 400,
    lineHeight: "12px",
});

const DragGrid = styled(Grid)({
    cursor: "grab", // Change cursor on hover
    "&:active": {
        cursor: "grabbing", // Change cursor when dragging
    },
});

function PreviewDiagramPopup({ mermaidJSContent, setOpenPrebuildPreview }) {
    return (
        <W86DraggableResizeablePane
            defaultPosition={{ x: 10, y: 100 }}
            topButtons={
                <DragGrid container display="flex" alignItems="center" justifyContent="space-between" className="drag-handle">
                    <Grid item xs>
                        <DraftText>Draft Diagram</DraftText>
                    </Grid>
                    <Grid item>
                        <Grid container display="flex" alignItems="center" justifyContent="flex-end">
                            <Grid item>
                                <TextButton size={"small"} startIcon={<Close style={{ height: "14px", width: "auto" }} />} onClick={() => setOpenPrebuildPreview(false)}>
                                    Close
                                </TextButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </DragGrid>
            }
            content={<Grid container>{mermaidJSContent && <Mermaid chart={mermaidJSContent} width="1000px" height="auto" />}</Grid>}
        />
    );
}

export default PreviewDiagramPopup;
