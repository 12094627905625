import React, { useState, useEffect, useContext } from "react";
import { Drawer, styled } from "@mui/material";
import config from "../../../../config";
import AIPanelToolbar from "./components/AIPanelToolbar";
import InitialChatState from "./components/InitialChatState";
import { send_request } from "../../../../utils/Request";
import WorkflowBuilderSidebarWithButtons from "../CanvasUtil/WorkflowBuilderSidebarWithButtons";
import ComponentBuilderSidebar from "./ComponentBuilderSidebar";
import AIPanelPropTypes from "./components/Chat/PropTypes/AIPanelPropTypes";
import SessionHistoryPanel from "./SessionHistoryPanel";
import { CanvasContext } from "../WorkflowCanvas";
import BuilderWarning from "./components/Chat/BuilderWarning";
import {useLocation, useNavigate} from "react-router-dom";

export const StyledAIPanelDrawer = styled(Drawer)(() => ({
  "& .MuiDrawer-paper": {
    width: `${config.AI_COMPONENT_BUILDER_PANE_WIDTH}!important`,
    height: "100%",
    maxHeight: "100%",
    zIndex: 100,
    backgroundColor: "#FFFFFF",
    border: "0px 0px 0px 1px solid #D9D9D9",
  },
}));

const AIPanel = (props) => {
  //refers to the state of the ai panel
  const [state, setState] = useState("INITIAL");
  //refers to the state of the worklfow builder that is contained inside the ai panel
  const [sidebarState, setSidebarState] = useState("INITIAL");
  //user display name
  const [username, setUsername] = useState("");
  //we need this to display history sessions
  const { isCanvasLocked } = useContext(CanvasContext);
  const [canvasLocked, setCanvasLocked] = isCanvasLocked
    ? isCanvasLocked
    : useState(false);
  const  [showBuilderWarning,setShowBuilderWarning] =useState(false);
  const search = useLocation().search;
  const queryParams = new URLSearchParams(search);
  const navigate = useNavigate();

  useEffect(()=>{
    if(queryParams.get("openBuilder"))
    {
      setState("WORKFLOW_BUILDER");
      setSidebarState("WORKFLOW");
      queryParams.delete("openBuilder");
      navigate({ search: queryParams.toString() });
    }
  },[queryParams.get("openBuilder")])
  //on mount
  useEffect(async () => {
    //fetch username on login
    const json = await send_request(`authz-service/user-info`, "", {});
    if (json && json.data) {
      setUsername(json.data.displayName);
    }
  }, []);

  // on change to edit component
  // this function takes care of closing components
  // when this is a workflow session or a notes session we do not switch state - just close the component.
  // for more information on this refer to the matrix
  // https://workflow86.atlassian.net/wiki/spaces/WF86/pages/1632010241/Copy+of+New+AI+panel+to+Component+panel+states
  useEffect(() => {
    const isWorkflowOrNotesSession = [
      "WORKFLOW_BUILDER",
      "WORKFLOW_EDITOR",
      "PROCESS_DISCOVERY",
      "BRAINSTORM_STRATEGY",
    ].includes(state);

    if (state !== "INITIAL") {
      props.setCallTriggerCloseInChild(true);
      if (
        props.editComponent === null &&
        !(props.selectedHistorySession && isWorkflowOrNotesSession)
      ) {
        setState((prevState) => {
          if (prevState != state) return "INITIAL";
          else return state;
        });
      }
    } else {
      props.setCallTriggerCloseInChild(false);
    }
  }, [props.editComponent, props.selectedHistorySession, state]);

  // when a session has been selected from history
  // close the sessions menu
  // set state of the panel to be able to display the correct chat
  useEffect(() => {
    if (props.selectedHistorySession) {
      if (props.selectedHistorySession.sessionId) {
        setState(determineSessionType());
        props.setOpenSessionHistory(false);
      }
    }
  }, [props.selectedHistorySession]);

  // if a component has been opened with an ai component session
  // and the user decides to close the component
  // we need to reset the state of the panel to the initial state
  useEffect(() => {
    //this must be a component session
    const isComponentSession = [
      "COMPONENT_BUILDER",
      "COMPONENT_EDITOR",
    ].includes(state);
    //the edit componet must be null - component pane closed
    if (
      props.editComponent === null &&
      props.resetAIStateToInitial === true &&
      isComponentSession
    ) {
      //restore initial state of the ai panel
      setState("INITIAL");
      //reset the flag
      props.setResetAIStateToInitial(false);
    } else if (props.resetAIStateToInitial === true) {
      //just reset the flag to prevent logical errors
      props.setResetAIStateToInitial(false);
    }
  }, [props.resetAIStateToInitial]);

  //handlers for toolbar
  const onWidth = () => {};
  const onCopy = () => {};
  let componentCount = 0;
  for (const key in props.nodes) {
    if (props.nodes[key].type === "component") {
      componentCount++;
    }
  }

  //switch chat state here
  const renderChat = () => {
    switch (state) {
      case "INITIAL":
        return (
          <InitialChatState
            username={username}
            setState={setState}
            setSidebarState={setSidebarState}
            editComponent={props.editComponent}
            isEmpty={componentCount === 0 ? true : false}
            setShowBuilderWarning=
                {setShowBuilderWarning}
          />
        );
      case "WORKFLOW_BUILDER":
      case "WORKFLOW_EDITOR":
      case "PROCESS_DISCOVERY":
      case "BRAINSTORM_STRATEGY":
        return (
          <WorkflowBuilderSidebarWithButtons
            classes={props.classes}
            sidebarState={sidebarState}
            setSidebarState={setSidebarState}
            showAIBuilder={props.showAIBuilder}
            setShowAIBuilder={props.setShowAIBuilder}
            selectedHistorySession={props.selectedHistorySession}
            setSelectedHistorySession={props.setSelectedHistorySession}
            setShowTray={props.setShowTray}
            setShowNotesTray={props.setShowNotesTray}
            showTourTray={props.showTourTray}
            draftVersion={props.draftVersion}
            setWorkflowName={props.setWorkflowName}
            handleSaveProjectName={props.handleSaveProjectName}
            setAiBuilderOpen={props.setAiBuilderOpen}
            projectId={props.projectId}
            nodes={props.nodes}
            edges={props.edges}
            CanvasStore={props.CanvasStore}
            getLatestVersion={props.getLatestVersion}
            refetchComponents={props.refetchComponents}
            isInAIDraft={props.isInAIDraft}
            triggerCloseInChild={props.triggerCloseInChild}
            setTriggerCloseInChild={props.setTriggerCloseInChild}
            editComponent={props.editComponent}
            setPaneSkeleton={props.setPaneSkeleton}
            setState={setState}
          />
        );
      case "COMPONENT_BUILDER":
      case "COMPONENT_EDITOR":
        return (
          <ComponentBuilderSidebar
            setState={setState}
            triggerCloseInChild={props.triggerCloseInChild}
            setTriggerCloseInChild={props.setTriggerCloseInChild}
            showBuildPrompt={props.showBuildPrompt}
            setShowBuildPrompt={props.setShowBuildPrompt}
            open={props.aiComponentBuilderOpen}
            isInit={props.isInit}
            close={() => {
              props.setAiComponentBuilderOpen(false);
              props.setAiComponentBuilderData("");
              props.setOpenSessionHistory(false);
              props.setResetHistory(null);
            }}
            projectId={props.projectId}
            nodes={props.nodes}
            handleSave={null}
            reactFlowInstance={props.reactFlowInstance}
            setSaving={async () => {
              props.setSaving(true);
              setTimeout(() => {
                props.setSaving(false), 3000;
              });
            }}
            draftVersion={props.draftVersion}
            setPaneSkeleton={props.setPaneSkeleton}
            instructions={
              props.editComponent ? props.editComponent.instructions : ""
            }
            instruction={
              props.editComponent ? props.editComponent.description : ""
            }
            componentId={
              props.editComponent ? props.editComponent.componentId : ""
            }
            componentType={props.editComponent ? props.editComponent.type : ""}
            setAiComponentBuilderData={props.setAiComponentBuilderData}
            aiComponentBuilderData={props.aiComponentBuilderData}
            setComponentInstruction={null}
            startCompTestRun={() => {
              props.setTestFromCompPanel(true);
              props.setOpenTestPanel(
                props.editComponent ? props.editComponent.componentId : ""
              );
            }}
            openSessionHistory={props.openSessionHistory}
            setOpenSessionHistory={props.setOpenSessionHistory}
            selectedHistorySession={props.selectedHistorySession}
            setSelectedHistorySession={props.setSelectedHistorySession}
            setResetHistory={props.setResetHistory}
            setIsInAIDraft={props.setIsInAIDraft}
            sidebarState={sidebarState}
            setSidebarState={setSidebarState}
            isExpanded={window.location.href.includes("fullScreen=true")}
            setCompSessionId={props.setCompSessionId}
            compSessionId={props.compSessionId}
          />

        );
      default: {
        return <div>INITIAL</div>;
      }
    }
  };

  // this is a utility function to assist controlling session history panel
  // we retired one session history panel from the inside of ai builder
  // so now you have to feed it an appropriate aiSource to get the correct sessions
  const determineSessionType = () => {
    //on the options page
    if (state === "INITIAL") {
      //if a component is open  - use that components build sessions
      if (props.editComponent) {
        return "COMPONENT_BUILDER";
        //if no component is present - load workflow build sessions
      } else return "WORKFLOW_BUILDER";
      //if openning a notes session
    } else if (
      state === "PROCESS_DISCOVERY" ||
      state === "BRAINSTORM_STRATEGY"
    ) {
      //this type should retrieve the notes sessions
      return "WORKFLOW_BUILDER";
      //in all other cases ai type should match the state of the panel
    } else return state;
  };

  return (
    <StyledAIPanelDrawer
      open={props.open}
      anchor={"right"}
      hideBackdrop={true}
      onClose={props.handleClose}
      variant="permanent"
    >
      <AIPanelToolbar
        setState={setState}
        state={state}
        onWidth={onWidth}
        onCopy={onCopy}
        onHistory={() => props.setOpenSessionHistory(!props.openSessionHistory)}
        onClose={props.handleClose}
      />
      {renderChat()}
      {props.openSessionHistory && (
        <SessionHistoryPanel
          projectId={props.projectId}
          componentId={
            props.editComponent ? props.editComponent.componentId : ""
          }
          handleClose={() => {
            props.setOpenSessionHistory(false);
            props.setResetHistory(null);
          }}
          selectedHistorySession={props.selectedHistorySession}
          setSelectedHistorySession={props.setSelectedHistorySession}
          resetHistory={props.resetHistory}
          setResetHistory={props.setResetHistory}
          aiEventSource={determineSessionType()}
          distanceFromRight={"0"}
          setCanvasLocked={setCanvasLocked}
        />

      )}
      <BuilderWarning open={showBuilderWarning} setState={setState} setSidebarState={setSidebarState} setShowBuilderWarning={setShowBuilderWarning} setAiBuilderOpen={props.setAiBuilderOpen}/>

    </StyledAIPanelDrawer>
  );
};

AIPanel.propTypes = AIPanelPropTypes;

export default AIPanel;
