import React from "react";
import { Grid, Card, CardContent, Typography, IconButton, Tooltip } from "@mui/material";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router";
import { Emoji } from "emoji-mart";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { format } from "../../utils/Date";
import ProjectStore from "../ProjectCanvas/ProjectStore";
import FadeIn from "react-fade-in";

const DashboardCard = ({ dashboard, classes, onMenuOpen }) => {
  const navigate = useNavigate();

  const renderEmoji = (image) => {
    return !image || image.charAt(0) !== ":" ? ":bar_chart:" : image;
  };

  return (
    <FadeIn>
      <Card
        className="cardFrameStyle cursor-pointer"
        style={{ position: "relative", padding: "24px 0" }}
      >
        <Link
          to={`/dashboard/${dashboard.id}`}
          style={{ textDecoration: "none" }}
        >
          <CardContent className="contentBackground">
            <Grid
              container
              item
              xs={12}
              onClick={() => {
                navigate(`/dashboard/${dashboard.id}`);
              }}
              className="noFeatured"
            >
              <Emoji
                set="twitter"
                emoji={renderEmoji(dashboard.image)}
                size={"2.5em"}
              />
            </Grid>

            <Grid
              container
              item
              className="textArea"
              direction={"row"}
              justifyContent={"flex-start"}
              xs={12}
            >
              <Grid container item xs={9} direction={"column"}>
                <Tooltip
                  title={dashboard.name}
                  placement="bottom-start"
                >
                  <Typography
                    variant="div"
                    className="textHeader"
                  >
                    {dashboard.name ? dashboard.name : "Untitled"}
                  </Typography>
                </Tooltip>
                <Tooltip
                  title={format(
                    dashboard.lastUpdated + "Z",
                    ProjectStore.state.timezone
                  )}
                >
                  <Typography
                    component="p"
                    className="textAuthor"
                  >
                    Last updated{" "}
                    {format(
                      dashboard.lastUpdated + "Z",
                      ProjectStore.state.timezone,
                      "relative"
                    )}
                  </Typography>
                </Tooltip>
              </Grid>
            </Grid>
          </CardContent>
        </Link>
        <Grid
          container
          item
          xs={3}
          direction={"column"}
          alignItems={"flex-end"}
          className={classes.moreIcon}
        >
          <IconButton
            onClick={(event) => onMenuOpen(event, dashboard)}
            className={"icon-btn"}
            size="large"
          >
            <MoreVertIcon
              className={"material-icons more-vert"}
            />
          </IconButton>
        </Grid>
      </Card>
    </FadeIn>
  );
};

export default DashboardCard;
