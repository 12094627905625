import React from "react";
import { Fade, Grid, MenuItem, Select, Typography } from "@mui/material";
import AIBuilderStyles from "../../../../AIBuilderStyles";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";

const EditComponentMessage = ({
  classes,
  sidebarState,
  setSidebarState,
  messageSent,
}) => {
  return (
    <Fade in timeout={1000}>
      <div className={classes.gpt}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Select
              onChange={(e) => {
                setSidebarState(e.target.value);
              }}
              variant={"outlined"}
              value={sidebarState}
              size="small"
              disabled={messageSent}
            >
              <MenuItem value="EDIT"> Edit this Component </MenuItem>
              <MenuItem value="BUILD"> Build new Component </MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12}>
            <Typography>
              <span className={"bold"}>
                <img
                  src="/svgs/edit_square.svg"
                  style={{
                    verticalAlign: "bottom",
                    height: "20px",
                    width: "20px",
                    marginRight: "8px",
                    filter: "brightness(0) saturate(100%)",
                  }}
                />
                Edit this Component
              </span>
            </Typography>
          </Grid>

          <Grid item xs={12}>
            I am currently operating in edit mode. This means I will only make
            specific changes according to your instructions or requirements, and
            leave everything else in the component unchanged.
          </Grid>
          <Grid item xs={12}>
            <b>💡 Tips</b>
          </Grid>
          <Grid item xs={12}>
            Be specific about what you want to change and what you do not want
            me to change. By default, I will only apply changes to what you have
            instructed and leave everything else the same.
          </Grid>
        </Grid>
      </div>
    </Fade>
  );
};

EditComponentMessage.propTypes = {
  classes: PropTypes.object.isRequired,
  setSidebarState: PropTypes.func.isRequired,
  sidebarState: PropTypes.string.isRequired,
  messageSent: PropTypes.bool.isRequired,
};

export default withStyles(AIBuilderStyles())(EditComponentMessage);
