import React from "react";
//mui
import { Grid, Fade } from "@mui/material";

//styles
import { withStyles } from "@mui/styles";

const styles = () => ({
  error: {
    maxWidth: "100%!important",
    background: "rgba(225, 0, 0, 0.15)",
    border: "2px solid #E10000",
    borderRadius: "16px 16px 16px 0px",
    width: "fit-content",
    padding: "16px",
    fontSize: "14px",
  },
});

const Error = ({ classes, content }) => {
  return (
    <Fade timeout={1000} in={true}>
      <Grid className={classes.error}>
        {content}
      </Grid>
    </Fade>
  );
};

export default withStyles(styles)(Error);
