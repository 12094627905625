import React, { useState, useEffect } from "react";
import { Grid, CircularProgress, Fade } from "@mui/material";
import { withStyles } from "@mui/styles";
import AIBuilderStyles from "../../../AIBuilderStyles";

//styles
import { styled } from "@mui/system";

const styles = AIBuilderStyles;

const TypingDots = styled("div")(({ theme }) => ({
  alignItems: "center",
  margin: "0px 8px 0px 8px",
  display: "flex",
  height: "17px",
  "& > div": {
    animation: "mercuryTypingAnimation 0.9s infinite ease-in-out",
    backgroundColor: "#757575",
    borderRadius: "50%",
    height: "10px",
    marginRight: "4px",
    verticalAlign: "middle",
    width: "10px",
    display: "inline-block",
    "&:nth-child(1)": {
      animationDelay: "200ms",
    },
    "&:nth-child(2)": {
      animationDelay: "300ms",
    },
    "&:nth-child(3)": {
      animationDelay: "400ms",
    },
    "&:last-child": {
      marginRight: "0",
    },
  },
  "@keyframes mercuryTypingAnimation": {
    "0%": {
      transform: "translateY(0px)",
      backgroundColor: "#757575",
    },
    "28%": {
      transform: "translateY(-7px)",
      backgroundColor: "#757575",
    },
    "44%": {
      transform: "translateY(0px)",
      backgroundColor: "#757575",
    },
  },
}));

const messages = [
  "Thinking...",
  "Planning...",
  "Executing instructions...",
  "Building...",
  "Processing information..."
];

const AnimatedLoadingMessage = props => {
  const [showTyping, setShowTyping] = useState(false);
  const [message, setMessage] = useState(messages[0]);

  useEffect(() => {
    setShowTyping(true);
    const typingInterval = setInterval(() => {
      setMessage(messages[Math.floor(Math.random() * messages.length)]);
    }, 5000);

    return () => {
      clearInterval(typingInterval);
    };
  }, []);

  return (
    <Fade in={showTyping} timeout={500}>
      <div className={props.classes.animatedLoadingMessage}>
        <Grid item>
          <TypingDots>
            <div></div>
            <div></div>
            <div></div>
          </TypingDots>
        </Grid>
        <Grid item className={props.classes.loadingAnimation}>
          {message}
        </Grid>
      </div>
    </Fade>
  );
};

export default withStyles(styles)(AnimatedLoadingMessage);
