

const aws_config_dev = {
  aws_cognito_region: "ap-southeast-2",
  aws_user_pools_id:  "ap-southeast-2_Kp9DpRrdK",
  aws_user_pools_web_client_id :  "5f93lfrqqh7nruhjiiin8qbeem",
  authenticationFlowType: 'USER_PASSWORD_AUTH',
  oauth: {
    domain: 'authenticate.app.w86dev.click',
    // scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
    redirectSignIn: 'https://dxjjoq4k8sgbx.cloudfront.net',
    redirectSignOut: 'https://dxjjoq4k8sgbx.cloudfront.net',
    redirectUri: "postmessage",
    responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
  },
  gg_client_id: "541992205730-ombu26ut0amuo794b59bku1kt08egofm.apps.googleusercontent.com"
};

const aws_config_prod = {
  aws_cognito_region: "ap-southeast-2",
  aws_user_pools_id:  "ap-southeast-2_4pkuaVYZY",
  aws_user_pools_web_client_id : "7k5h7tnrnkdcg78d9ofpt1opij",
  authenticationFlowType: 'USER_PASSWORD_AUTH',
  oauth: {
    domain: 'authenticateme.app.workflow86.com',
    // scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
    redirectSignIn: 'https://app.workflow86.com',
    redirectSignOut: 'https://app.workflow86.com',
    redirectUri: "postmessage",
    responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
  },
  gg_client_id: "541992205730-eaa8et59jir07ttls4vqgci5hpe5hdr5.apps.googleusercontent.com"
};

const aws_config = aws_config_prod;
export default aws_config;