import React, {Component} from 'react';
import Alert from "@mui/material/Alert";
import {Snackbar} from "@mui/material";

class SnackbarDialog extends Component {
  render() {
    const {open, message, status, colorProps} = this.props
    return (
      <Snackbar
        open={open}
        onClose={this.props.close}
        autoHideDuration={1000}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          icon={<></>}
          onClose={this.props.close}
          variant={"filled"}
          className={"saveSuccess"}
          severity={status}
          style={{background: `${colorProps}`}}
        >
          {message}
        </Alert>
      </Snackbar>
    );
  }
}

export default SnackbarDialog;