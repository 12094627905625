import React, { useState, useEffect, useContext, createContext } from "react"; //react
//material ui components
import { Drawer } from "@mui/material";
//prop types
import PropTypes from "prop-types";
//w86 ui components
import ComponentPaneHeader from "./ComponentPaneHeader";
//css
import styles from "./ComponentPaneStyle";
import { withStyles } from "@mui/styles";
//store to manage state
import ProjectStore from "../../ProjectCanvas/ProjectStore";
//sending requests
import {
  send_request,
  send_request_graphql_mutation,
} from "../../../utils/Request";
//gets component template by type
import getTemplate from "./TemplateDetector";
//mobx
import { inject, observer } from "mobx-react";
import { observable, decorate, toJS } from "mobx";
//placeholder for loading
import { IndividualComponentLoader } from "../../../utils/ComponentLoadingSkeleton";
import config from "../../../config";
import { useNavigate } from "react-router-dom";
import { CanvasContext } from "../Canvas/WorkflowCanvas";
import { createSaveEvent } from "../../../utils/createSaveEvent";
import { SAVE_EVENT } from "../Canvas/CanvasQueries";
import {
  DEFAULT_ZOOM_FOR_FOCUSING_VIEWPORT,
  setPositionInCanvasView,
} from "../Canvas/AIBuilder/util/TranslateUtil";
import { styled } from "@mui/material/styles";
import FormAdditionalButtons from "../../FormBuilder/FormComponents/FormAdditionalButtons";

//================================================================= Styled Components =========================================

const ReadOnlyBanner = styled('div')(({ isInAIDraft }) => ({
  backgroundColor: isInAIDraft ? 'rgba(148, 75, 168, 1)' : 'rgba(196, 196, 196, 1)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '8px 24px',
  fontSize: '14px',
  color: isInAIDraft ? '#FFFFFF' : '',
  position: 'sticky',
  top: 0,
  zIndex: 1002,
}));

const TemplateContainer = styled('div')(() => ({
  padding: '16px',
}));

export const FormContext = createContext([]);

const ComponentPane = inject("CanvasStore")(
  observer((props) => {
    // ================================================================= State =========================================

    const { savingInfo, canvasViewport, canvasInstance } = useContext(
      CanvasContext
    );
    const { CanvasStore } = props;
    const [saving, setSaving] = savingInfo ? savingInfo : useState({});
    const [instruction, setInstruction] = useState(
      props.editComponent && props.editComponent.description
        ? props.editComponent.description
        : ""
    );
    const [instructions, setInstructions] = useState(
      props.editComponent && props.editComponent.instructions
        ? props.editComponent.instructions
        : null
    );

    // ================================================================= CSS =========================================

    const classes = props.classes;
    const navigate = useNavigate();

    // ================================================================= COMPONENT INFO =============================


    const [element, setElement] = useState(<IndividualComponentLoader />); //react component to be rendered in the pane (or loading)

    // ================================================================= PANE UI ====================================

    const [open, setOpen] = useState(false); // controls wether pane is open or closed
    const [loading, setLoading] = useState(false); //to disable buttons
    const [error, setError] = useState(false); //for the errors
    const [templateLoaded, setTemplateLoaded] = useState(false); //for the template loading

    // ================================================================= Context Values ====================================

    const [openSettings, setOpenSettings] = useState(false);
    const [openFormLink, setOpenFormLink] = useState(false);
    const [openExport, setOpenExport] = useState(false);
    const [openFormEmbed, setOpenFormEmbed] = useState(false);
    const [openComponentSettings, setOpenComponentSettings] = useState(false);
    const [openUploadDialog, setOpenUploadDialog] = useState(false);
    const [isDynamicForm, setIsDynamicForm] = useState(false);

    // ================================================================ MOBX =========================================

    const [lastModified, setLastModified] = useState(observable.box("")); //using lastModified with mobx
    const [viewport, setViewport] = canvasViewport
      ? canvasViewport
      : useState({});
    const [reactFlowInstance, setReactFlowInstance] = canvasInstance
      ? canvasInstance
      : "";
    const [showChildren, setShowChildren] = useState(true); // For controlling when the child unmounts
    const [fullScreen, setFullScreen] = useState(
      window.location.href.includes("fullScreen=true")
    );

    // **************************************************************** FUNCTIONS THAT INITIALIZE COMPONENT PANE ****************************************************************

    // on mount
    useEffect(async () => {
      if (!props) return;

      if (props.editComponent) {
        // Begin initializing component
        runComponentInitialization();

        // Set the param in the url
        const params = new URLSearchParams();

        params.append("view", props.editComponent.componentId);
        if (fullScreen) {
          params.append("fullScreen", true);
        }

        navigate({ search: params.toString() });

        // Focus the component
        const xOffset = !props.addressError ? -100 : 200;
        const remainingCanvasSpaceAsDecimal = 0.6;

        handleFocusComponent(xOffset, remainingCanvasSpaceAsDecimal);
        setInstruction(props.editComponent.description);
        setInstructions(
          props.editComponent.instructions
            ? props.editComponent.instructions
            : null
        );

        setShowChildren(true); // Show the child component since editComponent not null
      }

      if (props.setOpenTestPanel) props.setOpenTestPanel(false);

      /**
       * This is added here when the editComponent is set to null i.e: the pane
       * is closed. We want to perform a save before unmounting
       */
      if (props.editComponent === null) {
        // Perform save before child unmounts
        handleSave(true);
        setShowChildren(false);
        props.setSaving(false);
        ProjectStore.clearObservs();
        handleClose();
      }
      
    }, [props.editComponent]);

    useEffect(() => {
      if (
        !props ||
        (!props.openTestPanel && !props.aiComponentBuilderOpen) ||
        !props.editComponent
      )
        return;

      const xOffset = -100;
      const remainingCanvasSpaceAsDecimal = props.openSessionHistory
        ? 0.1
        : 0.3;

      handleFocusComponent(xOffset, remainingCanvasSpaceAsDecimal);
    }, [
      props.openTestPanel,
      props.aiComponentBuilderOpen,
      props.openSessionHistory,
    ]);

    useEffect(() => {
      setTemplateLoaded(props.childTemplateLoaded);
    }, [props.childTemplateLoaded]);

    const handleFocusComponent = (xOffset, remainingCanvasSpaceAsDecimal) => {
      if (!props.editComponent) return;
      let nodes =
        props.mode === "DEFAULT" || props.mode === "SAVE_PAUSED"
          ? CanvasStore.nodes
          : props.nodes;

      if (!nodes) return;

      // Grab the component from the nodes
      const component = nodes.find(
        (ele) =>
          ele.type === "component" && ele.id === props.editComponent.componentId

      );
      if (!component) return;

      // Create new viewport object
      const newViewport = {
        ...component.position,
        zoom: viewport ? viewport.zoom : DEFAULT_ZOOM_FOR_FOCUSING_VIEWPORT,
      };

      setPositionInCanvasView(
        newViewport,
        xOffset,
        reactFlowInstance,
        true,
        props.projectId,
        remainingCanvasSpaceAsDecimal
      );
    };

    useEffect(() => {
      if (props && props.setIsInit) props.setIsInit(false);
      if (props.paneSkeleton) {
        setElement(<IndividualComponentLoader />);
      } else if (props.editComponent) {
        runComponentInitialization();
      }
    }, [props.paneSkeleton]);

    const runComponentInitialization = async () => {
      //we pause all save events for the canvas
      props.updatePauseSave(true);
      //here project has already been put into the store
      setLoading(true);

      //initialize component name from the canvas
      //this is likely to be overwritten in the componentDidMount of the template
      ProjectStore.ComponentName.set(props.editComponent.label);
      ProjectStore.ComponentDescription.set(props.editComponent.description);

      //if we are here we need to re-request placeholders
      await initializePlaceholders((placeholders) => {
        setElement(
          React.cloneElement(getTemplate(props.editComponent.type), {
            component_id: props.editComponent.componentId,
            projectId: props.projectId,
            project_id: props.projectId, // Add in to handle components thats use project_id
            version: props.version,
            onComponentNameChanged: onComponentNameChanged,
            onComponentDescriptionChanged: onComponentDescriptionChanged,
            onBackProjectCanvas: onBackProjectCanvas,
            onLastModifiedChanged: onLastModifiedChanged,
            showSuccess: props.showSuccess,
            showError: showError,
            availablePlaceholders: placeholders,
            onPlaceholderSelected: onPlaceholderSelected,
            SelectedPlaceholder: ProjectStore.SelectedPlaceholder,
            ComponentName: ProjectStore.ComponentName,
            ComponentDescription: ProjectStore.ComponentDescription,
            SaveTrigger: ProjectStore.SaveTrigger,
            isExpanded: false,
            aiComponentBuilderData: props.aiComponentBuilderData,
            isInAIDraft:props.isInAIDraft,
            setChildTemplateLoaded: (val) => {
              props.setChildTemplateLoaded(val);
            },
            handleClose: (val) => {
              handleClose(val);
            },
            handleExpand: () => {
              handleExpand();
            },
            handleCollapse: () => {
              handleCollapse();
            },
          })
        );
      });
      if (props && props.setIsInit) props.setIsInit(true);
    };
    const initializePlaceholders = async (callback) => {
      //get placeholders
      const url = `component-service/placeholder/${props.projectId}/${props.editComponent.componentId}`;
      //query component service for placeholders
      return await send_request(url, "", "", "get")
        .then((res) => {
          if (res && res.data) {
            let queryPlaceholders = res.data.placeholders;
            let merge = [];
            //go through the result and unpack it into uniform array
            queryPlaceholders.map((placeholder) => {
              if (placeholder) {
                if (placeholder.data) {
                  //this handles legacy (I think =/)
                  let result = analyzePlaceholder(placeholder.data);
                  if (result) merge.push(result.placeholders);
                } else {
                  merge.push(placeholder);
                }
              }
            });
            let placeholders = [
              {
                placeholders: merge.flat(), //.flat() merges sub-arrays
              },
            ];

            setLoading(false);
            callback(placeholders);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };

    const analyzePlaceholder = (query) => {
      //handle placeholder data structure
      let data = [];
      query.forEach((ele) => {
        if (ele.key) {
          data.push({
            key: ele.key,
            type: "text",
            length: 1,
          });
        }
      });
      if (data.length > 0) {
        return {
          placeholders: data,
        };
      } else {
        return null;
      }
    };

    // **************************************************************** UTILS TO OPEN/CLOSE PANE PARTS ****************************************************************

    useEffect(() => {
      //detect and open the pane
      setOpen(props.open);
    }, [props.open]);

    const handleClose = () => {
      if (props.isInAIDraft) {
        props.setShowClosingAIDraftDialog(true);
        return;
      }
      if (props && props.setOpenTestPanel) props.setOpenTestPanel(false);

      setElement(<IndividualComponentLoader />);

      props.close(); //shut panel
    };

    // **************************************************************** UTILS TO HANDLE COMPONENT ***********************************************************************
    const onComponentNameChanged = (newComponentName) => {
      //we record the name in the observable
      ProjectStore.ComponentName.set(newComponentName);

      const updatedNodes = CanvasStore.draggingNodes.map((component) => {
        if (component.id == props.editComponent.componentId) {
          return {
            ...component,
            data: {
              ...component.data,
              label: newComponentName,
            },
          };
        }
        return component;
      });

      CanvasStore.setDraggingNodes(updatedNodes);
    };

    const onComponentDescriptionChanged = (newDescription) => {
      //we record the name in the observable
      ProjectStore.ComponentDescription.set(newDescription);

      const updatedNodes = CanvasStore.draggingNodes.map((component) => {
        if (component.id == props.editComponent.componentId) {
          return {
            ...component,
            data: {
              ...component.data,
              description: newDescription,
            },
          };
        }
        return component;
      });

      CanvasStore.setDraggingNodes(updatedNodes);
    };

    const onLastModifiedChanged = (lastModified) => {
      //record last modified changes
      setLastModified(lastModified);
    };

    const showSuccess = () => {
      //show snackbar on save
    };

    const updateCanvasComponentInstruction = (newInstruction) => {
      try {
        let currId = props.editComponent.componentId;

        let ele = CanvasStore.draggingNodes.find((ele) => ele.id === currId);
        if (!ele) return;

        ele.data.description = newInstruction;

        //update reference in the store
        ProjectStore.components.map((component, index) => {
          if (component.componentId == currId) {
            component.description = newInstruction;
          }
        });
      } catch (e) {
        console.log(e);
      }
    };
    const updateCanvasComponentInstructions = (newInstruction, isEdit) => {
      try {
        let currId = props.editComponent.componentId;

        let ele = CanvasStore.draggingNodes.find((ele) => ele.id === currId);
        if (!ele) return;

        if (ele.data && ele.data.instructions) {
          if (isEdit) ele.data.instructions.editInstruction = newInstruction;
          else ele.data.instructions.buildInstruction = newInstruction;
        }

        //update reference in the store
        ProjectStore.components.map((component, index) => {
          if (component.componentId == currId) {
            component.instruction = newInstruction;
          }
        });
      } catch (e) {
        console.log(e);
      }
    };

    const showError = () => {
      //TODO handle errors
      setError(true);
    };

    const handleSave = (skipEditCompCheck) => {
      // Check if already saving
      if (saving) return;

      // Check if not in production and not editing a component from workflow path
      if (
        !props.production &&
        (skipEditCompCheck ||
          (props.editComponent && !props.editComponent.fromWorkflowPath))
      ) {
        // Save component
        if (!ProjectStore.state.production) {
          props.setSaving(true);
          ProjectStore.SaveTrigger["executeTest"] = false;

          if (props.isInAIDraft) {
            // Set session id to ensure that proposal reloads the translation
            ProjectStore.SaveTrigger["sessionId"] = props.compSessionId;
          }
          ProjectStore.SaveTrigger["triggerValue"] =
            ProjectStore.SaveTrigger["triggerValue"] === 1 ? 0 : 1;

          // Sync dragging nodes to nodes
          CanvasStore.syncNodes();
          props.setSaving(false);
        }
      }
    };

    const handleUpdateComponent = async (
      discardBuildInstruction = false,
      discardEditInstructions = false
    ) => {
      if (saving) return;
      props.setSaving(true);

      let eventData = {
        componentId: props.editComponent.componentId,
        instruction: instruction,
        discardBuildInstructions: discardBuildInstruction,
        discardEditInstructions: discardEditInstructions,
      };

      let saveEvent = createSaveEvent(
        "UPDATE_COMPONENT",
        ProjectStore.project_id,
        { x: null, y: 0.0, zoom: 0.0 },
        [],
        eventData
      );

      //send event
      await send_request_graphql_mutation(
        `project-service/graphql/project/save/${ProjectStore.project_id}`,
        SAVE_EVENT(saveEvent),
        "",
        "POST"
      )
        .then((response) => {
          if (response.data.saveEvent && response.data.saveEvent.updated) {
            setLastModified(response.data.saveEvent.lastModified);
          }

          updateCanvasComponentInstruction(instruction);
          if (discardBuildInstruction)
            updateCanvasComponentInstructions(null, false);
          if (discardEditInstructions)
            updateCanvasComponentInstructions(null, true);

          CanvasStore.syncNodes();
          //insert delay here
          props.setSaving(false);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    //keep these methods so the templates don't crash
    const onBackProjectCanvas = () => {};

    const onPlaceholderSelected = (placeholderUpdateEvent) => {
      ProjectStore.SelectedPlaceholder["selected"] = placeholderUpdateEvent;
    };

    const handleExpand = () => {
      setFullScreen(true);
      const params = new URLSearchParams();
      params.append("view", props.editComponent.componentId);
      params.append("fullScreen", true);

      navigate({ search: params.toString() });
    };

    const handleCollapse = () => {
      setFullScreen(false);
      const params = new URLSearchParams();

      params.append("view", props.editComponent.componentId);

      navigate({ search: params.toString() });
    };

    const isForm = () => {
      const formTypes = ["form", "sequential_form", "form_section"];
      return (
        props.editComponent && formTypes.includes(props.editComponent.type)
      );
    };

    // Function to calculate the width of the pane
    const calculatePaneWidth = () => {
      // If the screen is in full screen mode
      if (fullScreen) {
        // If both the AI Component Builder and the Test Panel are open
        if (props.aiComponentBuilderOpen && props.openTestPanel) {
          // Return a width of 47.5vw regardless of whether it's a form or not
          return config.COMPONENT_BUILDER_BASE_WIDTH;
        }
        // If only the AI Component Builder is open
        if (props.aiComponentBuilderOpen) {
          // Return a width of 72.75vw
          return config.COMPONENT_BUILDER_EXPANDED_WIDTH;
        }
        // If only the Test Panel is open
        if (props.openTestPanel) {
          // Return a width of 72.75vw regardless of whether it's a form or not
          return config.COMPONENT_BUILDER_EXPANDED_WIDTH;
        }
        // If neither the AI Component Builder nor the Test Panel are open, return the full screen width
        return config.FULL_SCREEN_WIDTH;
      }

      // If the screen is not in full screen mode
      // If it's a form and both the AI Component Builder and the Test Panel are open
      if (isForm() && props.aiComponentBuilderOpen && props.openTestPanel) {
        // Return a width of 47.5vw
        return config.COMPONENT_BUILDER_BASE_WIDTH;
      }
      // If it's a form and either the AI Component Builder or the Test Panel are not open
      if (isForm()) {
        // Return the form pane width
        return config.FORM_PANE_WIDTH;
      }
      // If it's not a form, return the default pane width
      return config.PANE_WIDTH;
    };

    const isPanelOpen = () =>
      props.aiComponentBuilderOpen || props.openTestPanel;
    // Constants for translation percentages

    // Function to calculate the translation percentage based on the state of the panels and form
    const calculateTranslationPercentage = () => {
      // Initialize the translation variable
      let translation = "";

      // Both panels open
      if (props.openTestPanel && props.aiComponentBuilderOpen) {
        // If fullScreen is true or the component is a form, translate by TRANSLATION_BOTH_PANELS_OPEN, else translate by TRANSLATION_BOTH_PANELS_OPEN_NOT_FORM
        translation =
          fullScreen || isForm()
            ? config.TRANSLATION_BOTH_PANELS_OPEN
            : config.TRANSLATION_BOTH_PANELS_OPEN_NOT_FORM;
      }
      // Only openTestPanel is open
      else if (props.openTestPanel) {
        // If fullScreen is true, translate by TRANSLATION_ONLY_TEST_PANEL_OPEN, else if the component is a form, translate by TRANSLATION_ONLY_TEST_PANEL_OPEN_FORM, else translate by TRANSLATION_ONLY_TEST_PANEL_OPEN_NOT_FORM
        translation = fullScreen
          ? config.TRANSLATION_ONLY_TEST_PANEL_OPEN
          : isForm()
          ? config.TRANSLATION_ONLY_TEST_PANEL_OPEN_FORM
          : config.TRANSLATION_ONLY_TEST_PANEL_OPEN_NOT_FORM;
      }
      // Only aiComponentBuilderOpen is open
      else if (props.aiComponentBuilderOpen) {
        // If fullScreen is true, translate by TRANSLATION_ONLY_BUILDER_PANEL_OPEN, else if the component is a form, translate by TRANSLATION_ONLY_BUILDER_PANEL_OPEN_FORM, else translate by TRANSLATION_ONLY_BUILDER_PANEL_OPEN_NOT_FORM
        translation = fullScreen
          ? config.TRANSLATION_ONLY_BUILDER_PANEL_OPEN
          : isForm()
          ? config.TRANSLATION_ONLY_BUILDER_PANEL_OPEN_FORM
          : config.TRANSLATION_ONLY_BUILDER_PANEL_OPEN_NOT_FORM;
      }

      // Return the calculated translation
      return translation;
    };
    const paneWidth = calculatePaneWidth();
    const translationPercentage = calculateTranslationPercentage();

    const handleFormPreview = () => {
      const { componentId } = props.editComponent;
      if (!ProjectStore.state.production) {
        ProjectStore.SaveTrigger["triggerValue"] =
          ProjectStore.SaveTrigger["triggerValue"] === 1 ? 0 : 1;

        send_request(
          `project-service/project/last-version/${props.projectId}`,
          "",
          "",
          "get"
        ).then((res) => {
          if (res && res.data) {
            let { draftVersion } = res.data;
            const url = `${config.FORM.FORM_FRONT_END}form/form_preview/${componentId}/${draftVersion}`;
            window.open(url, "_blank");
          }
        });
      }
    };

    const isFormComponent = () => {
      return props.editComponent && props.editComponent.type === "form";
    };

    return (
      <FormContext.Provider
        value={{
          settings: [openSettings, setOpenSettings],
          formLink: [openFormLink, setOpenFormLink],
          formExport: [openExport, setOpenExport],
          formEmbed: [openFormEmbed, setOpenFormEmbed],
          componentSettings: [openComponentSettings, setOpenComponentSettings],
          styling: [openUploadDialog, setOpenUploadDialog],
          placeholders: [isDynamicForm, setIsDynamicForm],
        }}
      >
        {showChildren && (
          <Drawer
            open={props.open}
            anchor={"right"}
            hideBackdrop={true}
            variant={"permanent"}
            onClose={handleClose}
            PaperProps={{
              sx: {
                width: paneWidth,
                backgroundColor: "#F8F8F8",
                height: "100%",
                maxHeight: "100%",
                zIndex: 1001,
                transform: isPanelOpen() ? translationPercentage : "",
              },
            }}
          >
            {(ProjectStore.state.production || props.isInAIDraft) && (
              <ReadOnlyBanner isInAIDraft={props.isInAIDraft}>
                {props.isInAIDraft
                  ? "This is a preview of an AI change. Any changes you make will not be saved."
                  : "🔒 This component is read only and changes will not be saved"}
              </ReadOnlyBanner>
            )}
            <TemplateContainer>
              <ComponentPaneHeader
                production={props.production}
                setOpenTestPanel={props.setOpenTestPanel}
                setTestFromCompPanel={props.setTestFromCompPanel}
                loading={loading}
                lastUpdated={lastModified}
                type={props.editComponent ? props.editComponent.type : ""}
                name={ProjectStore.ComponentName.get()}
                componentDescription={ProjectStore.ComponentDescription.get()}
                updateComponentDescription={onComponentDescriptionChanged}
                componentId={
                  props.editComponent ? props.editComponent.componentId : ""
                }
                handleClose={handleClose}
                updateName={onComponentNameChanged}
                save={handleSave}
                buildInstruction={instruction}
                instructions={instructions}
                setInstructions={setInstructions}
                setBuildInstruction={setInstruction}
                saveBuildInstruction={handleUpdateComponent}
                aiComponentBuilderOpen={props.aiComponentBuilderOpen}
                setAiComponentBuilderOpen={(val) => {
                  props.setAiComponentBuilderOpen(val);
                  props.setShowAIBuilder(val);
                }}
                isInAIDraft={props.isInAIDraft}
                showBuildPrompt={props.showBuildPrompt}
                setShowBuildPrompt={props.setShowBuildPrompt}
                setSidebarState={props.setSidebarState}
                handleExpand={handleExpand}
                handleCollapse={handleCollapse}
                isExpanded={fullScreen}
                templateLoaded={!templateLoaded}
                additionalButtons={
                  isForm() ? (
                    <FormAdditionalButtons
                      templateLoaded={!templateLoaded}
                      isForm={isFormComponent() ? true : false}
                      handleSettings={() => {
                        setOpenSettings(!openSettings);
                      }}
                      handlePreview={handleFormPreview}
                      handleLink={() => setOpenFormLink(!openFormLink)}
                      handleData={() => setOpenExport(!openExport)}
                      handleEmbed={() => setOpenFormEmbed(!openFormEmbed)}
                      handleFormSettings={() =>
                        setOpenComponentSettings(!openComponentSettings)
                      }
                      handleStyling={() =>
                        setOpenUploadDialog(!openUploadDialog)
                      }
                      isDynamicForm={isDynamicForm}
                      handleEnablePlaceholders={(event) => {
                        setIsDynamicForm(event.target.checked);
                      }}
                    />
                  ) : (
                    ""
                  )
                }
                baseColor={"#7ac0f8"}
              />

              {showChildren && element ? element : null}
            </TemplateContainer>
          </Drawer>
        )}
      </FormContext.Provider>
    );
  })
);


export default withStyles(styles)(
  inject("ProjectStore")(
    observer(
      decorate(ComponentPane, {
        SaveTrigger: observable,
        SelectedPlaceholder: observable,
      })
    )
  )
);

ComponentPane.propTypes = {
  open: PropTypes.bool.isRequired,
  editComponent: PropTypes.object.isRequired,
  production: PropTypes.bool,
  updateCanvas: PropTypes.func.isRequired,
  projectId: PropTypes.string.isRequired,
  close: PropTypes.func.isRequired,
};