import React from "react";
import PropTypes from "prop-types";
import { CircularProgress, Grid } from "@mui/material";
import { styled } from "@mui/system";
import CheckCircle from "@mui/icons-material/CheckCircle";
import Fade from "@mui/material/Fade";

const LoadingContainer = styled(Grid)(({ theme }) => ({
  width: "100%!important",
  background: "#F5F5F5",
  borderRadius: "16px 16px 16px 0px",
  padding: "16px",
  fontSize: "14px",
  marginBottom: "16px",
}));

const ContentContainer = styled(Grid)(({ fullWidth }) => ({
  background: "#FFF",
  boxShadow: "0px 4px 4px 0px #00000040",
  padding: "16px",
  borderRadius: "6px",
  width: fullWidth ? "95%" : "fit-content",
}));

const CenterGrid = styled(Grid)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const CheckCircleStyled = styled(CheckCircle)({
  height: "18px",
  width: "auto",
  color: "#55A77A",
});

const InsertLoadingMessage = ({ isStreaming }) => {
  return (
    <Grid item xs>
      <Fade timeout={1000} in={true}>
        <div>
          <LoadingContainer container rowGap={2}>
            <Grid item xs={12}>
              <ContentContainer container columnGap={1}>
                <CenterGrid item>
                  {isStreaming ? (
                    <CircularProgress size={24} />
                  ) : (
                    <CheckCircleStyled />
                  )}
                </CenterGrid>
                <CenterGrid item>
                  <span>Saving to the canvas</span>
                </CenterGrid>
              </ContentContainer>
            </Grid>
          </LoadingContainer>
        </div>
      </Fade>
    </Grid>
  );
};

InsertLoadingMessage.propTypes = {
  isStreaming: PropTypes.bool.isRequired,
};

export default InsertLoadingMessage;
