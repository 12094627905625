import React from "react";
import MergeWorkflowStore from "./MergeWorkflowStore";

import {
  Grid, 
  Select,
  MenuItem
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { toJS } from "mobx";

function OrOfAndInputComponent() {
  return(
    <>
      {
        MergeWorkflowStore.getCondition() &&
        MergeWorkflowStore.getCondition()["OR"] &&
        MergeWorkflowStore.getCondition()["OR"].map((orCondition, orConditionindex) => {

          return (
            
            <>
              {orConditionindex >= 1 && (<Grid item xs={12} style={{paddingTop:"24px", paddingBottom:"24px"}}>OR</Grid>)}

              <Grid item xs={12}               
                style={{borderLeft:"#DADADA",borderLeftStyle:"solid", 
                borderLeftWidth:"thin",
                marginLeft:"8px",
                paddingLeft:"16px" }}>

                {
                  orCondition &&
                  orCondition["AND"] && orCondition["AND"].map((aCondition, index) => {
                    return (
                      <Grid item xs={12}
                        style={{paddingBottom:"8px"}}>
                        <div style={{display:"flex", alignItems:"center"}}>   
                          {index >= 1 && (<span style={{paddingRight:"24px"}}>AND</span>)}
                          <Select
                            style={{
                              width: "max-content",
                              backgroundColor: "#F8F8F8",
                              borderRadius: "4px",
                              color: "#868686",
                              visibility: "",
                              minWidth:"200px"
                            }}
                            disableUnderline
                            variant="standard"

                            value={aCondition}
                            onChange={(e) => {
                              MergeWorkflowStore.setConditionValue(index,e.target.value, orConditionindex);
                            }}
                            autoWidth
                            >
                              <MenuItem disabled value={"null"}> Select a component </MenuItem>
                              {( MergeWorkflowStore.getRelatedComponents() &&
                                MergeWorkflowStore.getRelatedComponents().map((aRelatedComponent) => {
                                return (<MenuItem
                                          value={aRelatedComponent.componentId}
                                        >
                                          {aRelatedComponent.componentTitle ? aRelatedComponent.componentTitle : aRelatedComponent.componentId}
                                        </MenuItem>);
                              })
                            
                            )}
                          </Select>
                          
                          <span style={{paddingLeft:"24px"}}>
                            <DeleteIcon
                              style={{ cursor: 'pointer' }}
                              className="conditional-logic-del"
                              onClick={() => {
                                MergeWorkflowStore.deleteCondition(index,orConditionindex);
                              }
                              }
                            />
                          </span>
                        </div>
                      </Grid>
                    );
                  })
                }

                <Grid item xs={12}>
                  <div
                    className="hover-add-button"
                    onClick={() => {MergeWorkflowStore.addCondition(orConditionindex);}}
                  >
                    <AddIcon /> Add component
                  </div>
                </Grid>
              </Grid>

             
            </>
          );
        })

      }
        <Grid item xs={12} style={{paddingTop:"24px"}}>
          <div
            className="hover-add-button"
            onClick={() => {
              MergeWorkflowStore.addORBlock();
            }}
          >
            <AddIcon /> Add OR block
          </div>
        </Grid>
    </>
  );
}


function AnyInputComponent() {

  return (
    <>
      <Grid item xs={12} 
        style={{borderLeft:"#DADADA",borderLeftStyle:"solid", 
                  borderLeftWidth:"thin",
                  marginLeft:"8px",
                  paddingLeft:"16px" }}>
    
        {
          MergeWorkflowStore.getCondition() &&
          MergeWorkflowStore.getCondition()["OR"] &&
          MergeWorkflowStore.getCondition()["OR"].map((aCondition, index) => {

            return(
              <Grid item xs={12} 
                  style={{paddingBottom:"8px"}}>

                <div style={{display:"flex", alignItems:"center"}}>
                  {index >= 1 && (<span style={{paddingRight:"24px"}}>OR</span>)}
                  
                  <Select
                    style={{
                      width: "max-content",
                      backgroundColor: "#F8F8F8",
                      borderRadius: "4px",
                      color: "#868686",
                      visibility: "",
                      minWidth:"200px"
                    }}
                    disableUnderline
                    variant="standard"

                    value={aCondition}
                    onChange={(e) => {
                      MergeWorkflowStore.setConditionValue(index,e.target.value);
                    }}                 
                    autoWidth
                    >
                      <MenuItem disabled value={"null"}> Select a component </MenuItem>
                      {( MergeWorkflowStore.getRelatedComponents() &&
                        MergeWorkflowStore.getRelatedComponents().map((aRelatedComponent) => {
                        return (<MenuItem
                                  value={aRelatedComponent.componentId}
                                >
                                  {aRelatedComponent.componentTitle? aRelatedComponent.componentTitle : aRelatedComponent.componentId}
                                </MenuItem>);
                      })
                    
                    )}
                  </Select>

                  <span style={{paddingLeft:"24px"}}>  
                  <DeleteIcon
                    style={{ cursor: 'pointer' }}
                    className="conditional-logic-del"
                    onClick={() => {
                      MergeWorkflowStore.deleteCondition(index);
                    }
                    }
                  />
                  </span>
                </div>
              </Grid>
            );
          })
        }

      </Grid>

      <Grid item xs={12}>
        <div
          className="hover-add-button"
          onClick={() => {MergeWorkflowStore.addCondition();}}
        >
          <AddIcon /> Add component
        </div>
      </Grid>
    </>
  );
}

export {AnyInputComponent, OrOfAndInputComponent};