import React, { useState } from "react";
import { getIcon } from "../ChunkRenderer";

//mui
import { Grid, CircularProgress, Skeleton } from "@mui/material";
import ReplayIcon from "@mui/icons-material/Replay";

//styles
import {
  CheckCircle,
  Error,
  ExpandLess,
  ExpandMore,
  OpenInNew,
} from "@mui/icons-material";
import { styled } from "@mui/system";
import * as PropTypes from "prop-types";
import { focusOnComponent } from "../../../util/TranslateUtil";

const LoadingContainer = styled(Grid)(({ theme }) => ({
  maxWidth: "100%!important",
  background: "#F5F5F5",
  borderRadius: "16px 16px 16px 0px",
  padding: "16px",
  fontSize: "14px",
  // minWidth: "400px",
}));

const ContentContainer = styled(Grid)(({ isClickable, fullWidth }) => ({
  background: "#FFF",
  boxShadow: "0px 4px 4px 0px #00000040",
  padding: "10px",
  marginBottom: "8px",
  borderRadius: "6px",
  width: fullWidth ? "95%" : "fit-content",
  "&:hover": {
    cursor: isClickable && "pointer",
    color: isClickable && "rgba(0, 0, 0, 0.6)",
  },
}));

const StyledSkeleton = styled(Skeleton)(({ height, margin }) => ({
  borderRadius: "6px",
  background: "#E8E8E8",
  width: "95%",
  height: height,
  margin: margin,
}));

const BlueText = styled("span")({
  fontSize: "12px",
  fontWeight: "400",
  color: "#2196F3",
  "&:hover": {
    cursor: "pointer",
  },
});

const RetryButton = styled("span")({
  display: "flex",
  alignItems: "center",
  paddingLeft: "16px",
  fontWeight: "700",
  fontSize: "12px",
  "&:hover": {
    cursor: "pointer",
    color: "#2196F3",
  },
});

const RedText = styled("span")({
  fontSize: "12px",
  fontWeight: "400",
  color: "#E10000",
});

const Open = styled(ExpandMore)({});

const Close = styled(ExpandLess)({});

const CenterGrid = styled(Grid)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const COMPLETED_STATUS = new Set(["AI_BUILD_GENERATED", "AI_EDIT_GENERATED"]);
const ERROR_STATUS = new Set([
  "AI_BUILD_ERROR",
  "AI_BUILD_CANCELLED",
  "AI_EDIT_ERROR",
  "AI_EDIT_CANCELLED",
]);

const BuildingWorkflowUI = ({
  isEditor,
  status,
  title,
  type,
  id,
  error,
  restartComponentBuild,
  content,
  pollNo,
  focusObject,
}) => {
  const [openSummary, setOpenSummary] = useState(false);

  const handleItemClick = (focusCompId, focusObject) => {
    setOpenSummary(!openSummary); // Open/close summary
    focusOnComponent(focusCompId, focusObject);
  };

  return (
    <Grid container rowGap={1}>
      <Grid item xs={12}>
        <ContentContainer
          container
          columnGap={"16px"}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          fullWidth
          isClickable
          onClick={() => handleItemClick(id, focusObject)}
          style={{ flexWrap: "nowrap" }}
        >
          <CenterGrid item>
            <Grid container columnGap={"16px"} style={{ flexWrap: "nowrap" }}>
              <CenterGrid item>
                {COMPLETED_STATUS.has(status) ? (
                  <CheckCircle
                    style={{ height: "20px", width: "auto", color: "#55A77A" }}
                  />
                ) : ERROR_STATUS.has(status) ? (
                  <Error
                    style={{ height: "20px", width: "auto", color: "#E10000" }}
                  />
                ) : (
                  <CircularProgress size={18} thickness="4.4" />
                )}
              </CenterGrid>
              <CenterGrid item>{getIcon(type, "28px")}</CenterGrid>
              <CenterGrid item>
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    style={{ fontSize: "14px", color: "rgba(0, 0, 0, 1)" }}
                  >
                    <span>{title}</span>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{
                      fontSize: "11px",
                      color: "rgba(117, 117, 117, 1)",
                    }}
                  >
                    <span>{type}</span>
                  </Grid>
                </Grid>
              </CenterGrid>
            </Grid>
          </CenterGrid>
          <CenterGrid item>{openSummary ? <Open /> : <Close />}</CenterGrid>
        </ContentContainer>
      </Grid>

      {openSummary && (
        <Grid item xs={12}>
          {COMPLETED_STATUS.has(status) ? (
            <ContentContainer container rowGap={3} fullWidth>
              <Grid item xs={12} id="preview-bottom-summary">
                <Grid
                  container
                  alignItems="center"
                  onClick={() => {
                    let url = window.location.pathname + `/${type}/${id}`;
                    window.open(url, "_blank");
                  }}
                >
                  <BlueText style={{ display: "flex", alignItems: "center" }}>
                    Open component in new tab
                    <OpenInNew
                      style={{
                        height: "12px",
                        width: "auto",
                        marginLeft: "4px",
                      }}
                    />
                  </BlueText>
                </Grid>
              </Grid>
            </ContentContainer>
          ) : ERROR_STATUS.has(status) ? (
            <ContentContainer container rowGap={3} fullWidth>
              <RedText style={{ display: "flex", alignItems: "center" }}>
                {error ? error : "Error while translating component"}
              </RedText>
              <RetryButton
                onClick={() => {
                  restartComponentBuild(id, content, pollNo, isEditor);
                }}
              >
                <ReplayIcon fontSize={"small"} />
                Retry Build
              </RetryButton>
            </ContentContainer>
          ) : (
            <ContentContainer container rowGap={3} fullWidth>
              <Grid item xs={12} id="preview-bottom-summary">
                <StyledSkeleton variant="rectangular" height="24px" />
              </Grid>
            </ContentContainer>
          )}
        </Grid>
      )}
    </Grid>
  );
};

const IndividualComponentStatus = ({
  content,
  restartComponentBuild,
  pollNo,
  buildOrEditComponentInfo,
  title,
  isEditor,
  focusObject,
}) => {
  if (buildOrEditComponentInfo == null) buildOrEditComponentInfo = content;

  return (
    <LoadingContainer container rowGap={2}>
      <Grid item xs={12} columnGap={2}>
        {title && (
          <Grid item xs={12} marginBottom="16px">
            <b>{title}</b>
          </Grid>
        )}
        {buildOrEditComponentInfo &&
          buildOrEditComponentInfo.map((item) => (
            <BuildingWorkflowUI // converted function to a component to avoid render errors
              key={item.componentId}
              status={item.status}
              title={item.generatedTitle}
              type={item.componentType}
              id={item.componentId}
              error={item.error}
              restartComponentBuild={restartComponentBuild}
              content={content}
              pollNo={pollNo}
              isEditor={isEditor}
              focusObject={focusObject}
            />
          ))}
      </Grid>
    </LoadingContainer>
  );
};

IndividualComponentStatus.propTypes = {
  content: PropTypes.array.isRequired,
  restartComponentBuild: PropTypes.func.isRequired,
  pollNo: PropTypes.number.isRequired,
  buildOrEditComponentInfo: PropTypes.array,
};

export default IndividualComponentStatus;
