import React from "react";
import { Grid, Button, Tooltip } from "@mui/material";
import {
  Check,
  CheckCircleOutline,
  PlayCircle,
  Refresh,
  Replay,
  RestartAlt,
  AutoAwesome,
  SkipNext,
} from "@mui/icons-material";
import { styled } from "@mui/system";
import { fetchCorrectButtonWhenProposalDisplayed } from "./ChatStateManager";

const TestRunButton = styled(Button)({
  textTransform: "none",
  paddingLeft: "20px",
  paddingRight: "20px",
  color: "#FFF",
  background: "rgba(255, 88, 0, 1)",
  borderColor: "rgba(255, 88, 0, 1)",
  "&:hover": {
    background: "rgba(255, 88, 0, 0.85)",
    borderColor: "rgba(255, 88, 0, 1)",
    color: "#FFF",
  },
});

function ChatButtons(props) {
  const renderBuildButton = () => {
    switch (props.sidebarState) {
      case "STRATEGY":
      case "DISCOVERY":
        return (
          <Tooltip
            title={props.buttonText.buildAllComponents.tooltip}
            placement="left"
          >
            <Button
              disabled={props.loading}
              className={props.classes.buildButton}
              variant="outlined"
              startIcon={<AutoAwesome />}
              onClick={props.convertIntoWorkflow}
            >
              Convert into a workflow
            </Button>
          </Tooltip>
        );

      default:
        // If no components to build or edit
        // Don't show build button
        if (
          (!props.compForBuilding || props.compForBuilding.length <= 0) &&
          (!props.compForEditing || props.compForEditing.length <= 0)
        )
          return <></>;

        return (
          <Tooltip
            title={props.buttonText.buildAllComponents.tooltip}
            placement="left"
          >
            <Button
              disabled={props.loading}
              className={props.classes.buildButton}
              variant="outlined"
              startIcon={<Check />}
              onClick={props.buildAllComponents}
            >
              {props.buttonText.buildAllComponents.message
                ? props.buttonText.buildAllComponents.message
                : "Build all components now"}
            </Button>
          </Tooltip>
        );
    }
  };

  const getChatButtons = () => {
    if (props.loading) return;

    // If it's at the pre-build stage and we want to show accept proposal
    if (props.isPrebuild && props.prebuildProposal) {
      return (
        <div>
          <Grid
            container
            display="flex"
            justifyContent="flex-end"
            gap="10px"
            style={{ padding: "16px 0px 0px 0px" }}
          >
            <Grid item>
              {fetchCorrectButtonWhenProposalDisplayed(
                props.sidebarState,
                props.classes,
                props.loading,
                props.insert,
                props.handleBuildWithProposal,
                props.prebuildProposal
              )}
            </Grid>

            <Grid item>
              <Tooltip
                title={props.buttonText.regenerate.tooltip}
                placement="top"
              >
                <Button
                  disabled={props.loading}
                  className={props.classes.newWorkflowButton}
                  variant="outlined"
                  startIcon={<Refresh />}
                  onClick={props.regenerate}
                >
                  Regenerate
                </Button>
              </Tooltip>
            </Grid>

            {props.sidebarState === "WORKFLOW_EDITOR" ? (
              <Grid item>
                <Tooltip title={props.buttonText.restart.tooltip} placement="top">
                  <Button
                    disabled={props.loading}
                    className={props.classes.newWorkflowButton}
                    variant="outlined"
                    startIcon={<RestartAlt />}
                    onClick={props.restart}
                  >
                    {props.buttonText.restart.message
                      ? props.buttonText.restart.message
                      : "Restart"}
                  </Button>
                </Tooltip>
              </Grid>
            ) : (
              <Grid item>
                <Tooltip
                  title={props.buttonText.restart.tooltip}
                  placement="top"
                >
                  <Button
                    disabled={props.loading}
                    className={props.classes.newWorkflowButton}
                    variant="outlined"
                    startIcon={<RestartAlt />}
                    onClick={props.restart}
                  >
                    {props.buttonText.restart.message
                      ? props.buttonText.restart.message
                      : "Restart"}
                  </Button>
                </Tooltip>
              </Grid>
            )}
          </Grid>
        </div>
      );
    }
    // If it's at the prebuild stage and we are showing the textfield
    if (
      props.isPrebuild &&
      props.showTextfield &&
      props.messages &&
      props.messages.length > length
    ) {
      return (
        <div>
          <Grid
            container
            display="flex"
            justifyContent="flex-end"
            gap="10px"
            style={{ padding: "16px 0px 0px 0px" }}
          >
            {props.showConclude && props.conclude && renderBuildButton()}
            {props.showConclude && props.conclude && (
              <Tooltip title={props.buttonText.conclude.tooltip} placement="left">
                <Button
                  disabled={props.loading}
                  className={props.classes.newWorkflowButton}
                  variant="outlined"
                  startIcon={<CheckCircleOutline />}
                  onClick={props.conclude}
                >
                  End Chat{" "}
                </Button>
              </Tooltip>
            )}
            <Tooltip title={props.buttonText.regenerate.tooltip} placement="left">
              <Button
                disabled={props.loading}
                className={props.classes.newWorkflowButton}
                variant="outlined"
                startIcon={<Refresh />}
                onClick={props.regenerate}
              >
                Regenerate
              </Button>
            </Tooltip>
            {!props.showConclude && (
              <Tooltip
                title={"Skip questions and proceed to the next stage"}
                placement="left"
              >
                <Button
                  disabled={props.loading}
                  className={props.classes.newWorkflowButton}
                  variant="outlined"
                  startIcon={<SkipNext />}
                  onClick={props.skipQuestions}
                >
                  Skip questions
                </Button>
              </Tooltip>
            )}
            <Tooltip title={props.buttonText.restart.tooltip} placement="left">
              <Button
                disabled={props.loading}
                className={props.classes.newWorkflowButton}
                variant="outlined"
                startIcon={<RestartAlt />}
                onClick={props.restart}
              >
                {props.buttonText.restart.message
                  ? props.buttonText.restart.message
                  : "Restart"}
              </Button>
            </Tooltip>
          </Grid>
        </div>
      );
    }

    const notLoadingAndShowingComponentSidebarButtons =
      !props.loading && props.showComponentSidebarButtons;
    const notHidingButtonsAndShowingButtonGroup =
      !props.hideButtons && props.showButtonGroup;

    if (
      !notHidingButtonsAndShowingButtonGroup &&
      !notLoadingAndShowingComponentSidebarButtons
    )
      return "";

    return (
      <div>
        <Grid
          container
          display="flex"
          justifyContent="flex-end"
          gap="10px"
          style={{ padding: "16px 0px 0px 0px" }}
        >
          {props.hideInsert && (
            <Tooltip title={props.buttonText.insert.tooltip} placement="left">
              <Button
                disabled={props.loading}
                className={props.classes.buildButton}
                variant="contained"
                startIcon={<Check />}
                onClick={props.insert}
              >
                {props.buttonText.insert.message
                  ? props.buttonText.insert.message
                  : "Insert"}
              </Button>
            </Tooltip>
          )}
          {props.showBuildButtons &&
            props.buildAllComponents &&
            renderBuildButton()}
          {props.showBuildButtons && props.buildComponentsIndividually && (
            <Tooltip
              title={props.buttonText.buildComponentsIndividually.tooltip}
              placement="left"
            >
              <Button
                disabled={props.loading}
                className={props.classes.newWorkflowButton}
                variant="outlined"
                startIcon={<CheckCircleOutline />}
                onClick={props.buildComponentsIndividually}
              >
                {props.buttonText.buildComponentsIndividually.message
                  ? props.buttonText.buildComponentsIndividually.message
                  : "Build all components individually"}
              </Button>
            </Tooltip>
          )}
          {props.showConclude && props.conclude && (
            <Tooltip
              title={props.buttonText.conclude.tooltip}
              placement="left"
            >
              <Button
                disabled={props.loading}
                className={props.classes.newWorkflowButton}
                variant="outlined"
                startIcon={<CheckCircleOutline />}
                onClick={props.conclude}
              >
                {props.buttonText.conclude.message
                  ? props.buttonText.conclude.message
                  : "Conclude the session"}
              </Button>
            </Tooltip>
          )}
          {props.showConclude && (
            <Tooltip
              title={props.buttonText.rebuild.tooltip}
              placement="left"
            >
              <Button
                disabled={props.loading}
                className={props.classes.newWorkflowButton}
                variant="outlined"
                startIcon={<Replay />}
                onClick={props.rebuild}
              >
                {props.buttonText.rebuild.message
                  ? props.buttonText.rebuild.message
                  : "Rebuild all components"}
              </Button>
            </Tooltip>
          )}
          {!props.hideInsert && props.isCompBuilder && !props.cancelled && (
            <Tooltip title={"Start a test run"} placement="left">
              <TestRunButton
                disabled={props.loading}
                startIcon={<PlayCircle />}
                variant="outlined"
                onClick={props.startCompTestRun}
              >
                Start test run
              </TestRunButton>
            </Tooltip>
          )}
          {props.restart && (
            <Tooltip
              title={props.buttonText.restart.tooltip}
              placement="left"
            >
              <Button
                disabled={props.loading}
                className={props.classes.newWorkflowButton}
                variant="outlined"
                startIcon={<RestartAlt />}
                onClick={props.restart}
              >
                {props.buttonText.restart.message
                  ? props.buttonText.restart.message
                  : "Restart"}
              </Button>
            </Tooltip>
          )}
          {props.regenerate && !props.buildInserted && (
            <Tooltip
              title={props.buttonText.regenerate.tooltip}
              placement="left"
            >
              <Button
                disabled={props.loading}
                className={props.classes.newWorkflowButton}
                variant="outlined"
                startIcon={<Refresh />}
                onClick={props.regenerate}
              >
                {props.buttonText.regenerate.message
                  ? props.buttonText.regenerate.message
                  : "Regenerate"}
              </Button>
            </Tooltip>
          )}
        </Grid>
      </div>
    );
  };

  return getChatButtons();
}

export default ChatButtons;
