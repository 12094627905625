import React, { Component } from "react";
import { Grid, MenuItem, Paper, Select, TextField } from "@mui/material";
import { send_request } from "../../utils/Request";
import { withStyles } from "@mui/styles";

const styles = (theme) => ({
  credential: {
    backgroundColor: "#F8F8F8",
    borderRadius: "4px",
    color: "#868686",
    visibility: "",
    padding: "4px 8px",
  },
});
class SelectCredential extends Component {
  constructor(props) {
    super(props);
    this.state = { credentialData: [] };
  }

  componentDidMount() {
    this.refreshData();
  }
  refreshData = () => {
    let credentialData = [];
    send_request("credential-store/credential/user/type/smtp", "", "", "GET")
      .then((res) => {
        if (res.data) {
          credentialData = credentialData.concat(res.data);
          this.setState({
            credentialData: credentialData,
          });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  render() {
    return (
      <Grid container spacing={2}>
        <Grid item>
          <Select
            fullWidth={true}
            className={this.props.classes.credential}
            disableUnderline
            variant="standard"
            displayEmpty
            defaultValue={null}
            value={this.props.value}
            onChange={(value) => {
              this.props.onChange(value.target.value);
            }}
          >
            <MenuItem value={null}>noreply@workflow86mail.com</MenuItem>
            {this.state.credentialData &&
              this.state.credentialData.map((credential) => (
                <MenuItem value={credential.credentialId}>
                  {credential.credentialName}
                </MenuItem>
              ))}
            <MenuItem
              onClick={() => {
                window.location.href = "/credential_store";
              }}
            >
              + add credential
            </MenuItem>
          </Select>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(SelectCredential);
