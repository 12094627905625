import React, { useState, useEffect } from "react";
//nivo
import { ResponsiveBar } from "@nivo/bar";
//loading
import { runLoading } from "../../../utils/showLoadingScreen";
//sending request
import { send_request } from "../../../utils/Request";
import { CONSTRUCT_COMPONENT_SESSION_PROGRESS } from "../DashboardQueries";
//mui
import { Grid, Tooltip } from "@mui/material";
//styling
import { withStyles } from "@mui/styles";
import DashboardStyles from "../DashboardStyles";
import { Auth } from "aws-amplify";
//react router navigation
import { Link, useNavigate } from "react-router-dom";

const styles = DashboardStyles;

// Custom truncate function to replace truncate-html
const truncateText = (text, length) => {
  if (text.length <= length) {
    return text;
  }
  return text.slice(0, length) + '...';
};

const StackedBarChart = (props) => {
  const navigate = useNavigate();
  const classes = props.classes;
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const url = `/dashboard/widget/${props.widget.id}}`;

  useEffect(() => {
    //subscribe to listening
    props.stompClient.subscribe(
      `/topic/diagram/${props.widget.id}`,
      onDiagramReceived
    );
    //request to construct diagram
    constructDiagram();
  }, []);

  const constructDiagram = async () => {
    try {
      let header = {};
      header["Authorization"] = (await Auth.currentSession())
          .getIdToken()
          .getJwtToken();
      props.stompClient.send(
          `/app/diagram/${props.widget.id}`,
          header,
          String(
              CONSTRUCT_COMPONENT_SESSION_PROGRESS(props.widget.widgetData.projectId)
          )
      );
    } catch (err) {
      if (err === "No current user" || (err.code && err.code === "NotAuthorizedException")) {
        Auth.signOut().then(() => console.log("graceful sign out on UI"));
      }
    }
  };

  const onDiagramReceived = (payload) => {
    var payloadData = JSON.parse(payload.body);
    getComponentNames(payloadData.constructComponentSessionProgress);
  };

  const getComponentNames = (components) => {
    let componentIds = components.map((component) => component.componentId);
    send_request(
      "project-service/project/get_project_info",
      componentIds,
      "",
      "POST"
    )
      .then((res) => {
        components.map((component) => {
          res.data.map((componentWithName) => {
            if (component.componentId == componentWithName.componentId) {
              if (componentWithName.componentName)
                component.name = componentWithName.componentName;
              else component.name = component.componentId;
            }
          });
        });
        setData(components);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleReload = async () => {
    setLoading(true);
    constructDiagram();
  };

  if (!loading) {
    return (
      <>
        <Grid container item xs={12} direction={"column"}>
          <Grid container item direction={"row"} alignItems={"center"}>
            <p>{props.widget.name}</p>
            <Tooltip title={"Refresh data"}>
              <i
                className={`material-icons ${classes.icons}`}
                onClick={handleReload}
              >
                replay
              </i>
            </Tooltip>
            <Tooltip title={"Delete dashboard"}>
              <i
                className={`material-icons ${classes.icons}`}
                onClick={() => {
                  props.handleDeleteWidget(props.widget.id);
                }}
              >
                delete
              </i>
            </Tooltip>
          </Grid>
          <Link
            className={classes.link}
            to={`/project/canvas/${props.widget.widgetData.projectId}`}
            target="_blank"
          >
            {props.projectName}
          </Link>
        </Grid>

        <ResponsiveBar
          data={data}
          keys={["success", "error", "terminated", "paused", "waiting"]}
          indexBy="componentId"
          margin={{ top: 50, right: 130, bottom: 100, left: 60 }}
          padding={0.3}
          valueScale={{ type: "linear" }}
          indexScale={{ type: "band", round: true }}
          enableGridY={false}
          colors={["#BBDDBD", "#EC5C5C", "#E7B3BC", "#D8D8D8", "#FAC87D"]}
          borderRadius={4}
          borderWidth={1}
          borderColor={"#FFFFFF"}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickSize: 2,
            tickPadding: 36,
            legend: "components",
            legendPosition: "middle",
            legendOffset: 42,
            renderTick: ({ value, x, y }) => {
              let componentName = data.find((component) => {
                return component.componentId == value;
              }).name;
              componentName = componentName ? componentName : value;
              return (
                <Tooltip title={componentName}>
                  <g transform={`translate(${x},${y})`}>
                    <text transform={`translate(${-16},${15}) rotate(18)`}>
                      <tspan className={"customTick"}>
                        {truncateText(componentName, 5)}
                      </tspan>
                    </text>
                  </g>
                </Tooltip>
              );
            }
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: "number of sessions",
            legendPosition: "middle",
            legendOffset: -40
          }}
          labelSkipWidth={12}
          labelSkipHeight={12}
          labelTextColor={{
            from: "color",
            modifiers: [["darker", 1.6]]
          }}
          legends={[
            {
              dataFrom: "keys",
              anchor: "top",
              direction: "row",
              translateX: 120,
              translateY: -46,
              justify: false,
              itemsSpacing: 2,
              itemWidth: 100,
              itemHeight: 20,
              itemDirection: "left-to-right",
              itemOpacity: 0.85,
              symbolSize: 20,
              effects: [
                {
                  on: "hover",
                  style: {
                    itemOpacity: 1
                  }
                }
              ]
            }
          ]}
          role="application"
          ariaLabel="Bar chart demo"
          barAriaLabel={function(e) {
            return (
              e.id + ": " + e.formattedValue + " in country: " + e.indexValue
            );
          }}
        />
      </>
    );
  } else {
    return (
      <Grid
        className={classes.loading}
        container
        item
        xs={12}
        direction={"row"}
        justifyContents={"center"}
      >
        {runLoading()}
      </Grid>
    );
  }
};

export default withStyles(styles)(StackedBarChart);
