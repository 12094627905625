import React from "react";
import { Fade } from "@mui/material";
import AIBuilderStyles from "../../../../AIBuilderStyles";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";

const WorkflowEditorMessage = ({ classes }) => {
    return (
        <Fade in timeout={1000}>
            <div className={classes.gpt}>
                <b> ✍️ Edit this workflow </b>
                <p>Describe the changes you would like to make to the current workflow</p>
            </div>
        </Fade >
    );
};

WorkflowEditorMessage.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(AIBuilderStyles())(WorkflowEditorMessage);
