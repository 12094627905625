import React, {Component} from "react";
import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {Alert, CircularProgress, Grid, TextField} from "@mui/material";
import "../APIResReqStyle.css";
import {FormControl} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import APIResReqStore from "../APIResReqStore";
import DialogActions from "@mui/material/DialogActions";
import ErrorIcon from "@mui/icons-material/Error";
import {convertHTMLToRaw} from "braft-convert";
import Icon from "@mui/material/Icon";
import SendRequest from "./SendRequest";
import ResponseReceived from "./ResponseReceived";
import SetUpPlaceholders from "./SetUpPlaceholders";
import {toJS} from "mobx";
import AlertMessage from "./AlertMessage";
import AceEditor from "react-ace";
import {validURL} from "../../../utils/CanvasUtil";
import ExclamationIcon from "../../Component/Icons/ExclamationIcon";
class SetUpSample extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      errorMessage: "",
      boolState: false,
      placeholders: [],
      headerErrors: false,
      sampleResponseReceived: toJS(APIResReqStore).responseData.json !== ""
    };
  }

  handleOnChange = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    this.setState({
      name: value
    });
    if (name === "method") {
      this.chooseRequest(value);
    }
    if (name === "dataType") {
      this.chooseDataType(value);
    }
  };

  chooseRequest = (requestType) => {
    APIResReqStore.setSampleRequestType(requestType);
  };

  chooseDataType = (dataType) => {
    APIResReqStore.setSampleDataType(dataType);
  };

  insertHeaders = () => {
    this.setState({
      headerErrors: false
    });
    APIResReqStore.addSampleCustomHeaders();
  };


  isJsonString = (str) => {
    let json = convertHTMLToRaw(str)
      .blocks.reduce(
        (prevTexts, currBlock) => prevTexts.concat([currBlock.text]),
        []
      )
      .join("\n");
    json = json.replaceAll("“", '"');
    json = json.replaceAll("”", '"');
    try {
      JSON.parse(json);
    } catch (err) {
      this.setState({
        error: true,
        errorMessage: "Error found in body: " + err.message
      });
      return false;
    }
    return true;
  };

  handleSampleRequest = () => {
    const validateHeaders = APIResReqStore.sampleData.headers.filter(
      (h) => h.header_name == "" || h.header_value == ""
    );
    if (validateHeaders.length > 0) {
      this.setState({
        headerErrors: true
      });
      return;
    } else {
      this.setState({
        headerErrors: false
      });
      if (validURL(APIResReqStore.sampleData.endpoint_url)) {
        if (this.isJsonString(APIResReqStore.sampleData.body)) {
          this.setState({error: false, errorMessage: ""});
          this.props.onChangeState(2, 2);
        }
      } else {
        this.setState({
          error: true,
          errorMessage: "Invalid endpoint URL. Please check the URL is correct."
        });
        return;
      }
    }
    this.setState({
      sendingRequest: true
    })
  };

  render() {
    let {error, errorMessage, boolState, sampleResponseReceived} = this.state;
    return (
      <div>
        <Grid container xs={12}
              style={{
                width: "100%",
                padding: "24px 24px 12px 24px",
                background: "white"
              }}
              className="editor-container"
        >
          <Grid item style={{width: "100%"}}>
            <FormControl style={{width: "50%"}}>
              <Grid
                item
                xs={12}
                style={{
                  marginBottom: "8px",
                  fontSize: "14px",
                  fontFamily: "Open Sans",
                  fontWeight: "normal",
                  lineHeight: "19px",
                  color: "#868686"
                }}
              >
                Setup Mode
              </Grid>
              <Select
                name="method"
                labelId="demo-simple-select-outlined-label"
                className="dropdown-mat"
                value={APIResReqStore.sampleData.setupMode}
                defaultValue={"MANUAL"}
                disableUnderline
                variant="standard"
                align="left"
                inputProps={{ readOnly: true }}
              >
                <MenuItem value={"MANUAL"}>Manually set each field</MenuItem>
                <MenuItem value={"CURL"}>Write cURL</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <div hidden={APIResReqStore.sampleData.setupMode !== "MANUAL"}>
          <Grid
            container
            style={{
              width: "100%",
              padding: "24px 24px 24px 24px",
              background: "white"
            }}
            className="editor-container"
          >
            <Grid container flexDirection={"column"}>
              <AlertMessage
                message={"Configure a sample request to retireve a response from the API"}
                style={{
                  width: "470px",
                  marginBottom: "1rem"
                }}
              />
              <AlertMessage
                message={"Replace any placeholders with test values"}
                style={{
                  width: "330px",
                }}
              />
            </Grid>
            <Grid item xs={12} style={{marginTop: "8px", width: "100%"}}>
              <FormControl style={{width: "50%"}}>
                <Grid
                  item
                  xs={12}
                  style={{
                    marginBottom: "8px",
                    fontSize: "14px",
                    fontFamily: "Open Sans",
                    fontWeight: "normal",
                    lineHeight: "19px",
                    color: "#868686"
                  }}
                >
                  Method
                </Grid>
                <Select
                  name="method"
                  labelId="demo-simple-select-outlined-label"
                  className="dropdown-mat"
                  value={APIResReqStore.sampleData.request_type}
                  defaultValue={"POST"}
                  disableUnderline
                  variant="standard"
                  align="left"
                  onChange={this.handleOnChange}

                >
                  <MenuItem value={"POST"}>POST</MenuItem>
                  <MenuItem value={"GET"}>GET</MenuItem>
                  <MenuItem value={"PATCH"}>PATCH</MenuItem>
                  <MenuItem value={"PUT"}>PUT</MenuItem>
                  <MenuItem value={"DELETE"}>DELETE</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} style={{width: "100%"}}>
              <FormControl fullWidth style={{marginTop: "24px", width: "50%"}}>
                <Grid
                  item
                  xs={12}
                  style={{
                    marginBottom: "8px",
                    fontSize: "14px",
                    fontFamily: "Open Sans",
                    fontWeight: "normal",
                    lineHeight: "19px",
                    color: "#868686"
                  }}
                >
                  Content Type
                </Grid>

                <Select
                  name="dataType"
                  labelId="demo-simple-select-outlined-label"
                  className="dropdown-mat"
                  defaultValue={"JSON"}
                  value={APIResReqStore.sampleData.data_type}
                  // value={"JSON"}
                  disableUnderline
                  variant="standard"
                  align="left"
                  onChange={this.handleOnChange}

                >
                  <MenuItem value={"JSON"}>JSON</MenuItem>
                  {/* <MenuItem value={"XML"}>XML</MenuItem>
                <MenuItem value={"HTML"}>HTML</MenuItem>
                <MenuItem value={"TEXT"}>TEXT</MenuItem> */}
                </Select>
              </FormControl>
            </Grid>
            <FormControl fullWidth style={{marginTop: "24px"}}>
              <Grid
                item
                xs={12}
                style={{
                  marginBottom: "8px",
                  fontSize: "14px",
                  fontFamily: "Open Sans",
                  fontWeight: "normal",
                  lineHeight: "19px",
                  color: "#868686"
                }}
              >
                Endpoint URL
              </Grid>
              <Grid item xs={12} style={{width: "100%"}}>
                <TextField
                  style={{
                    fontSize: "14px",
                    fontFamily: "Open Sans",
                    fontWeight: "normal",
                    lineHeight: "19px",
                    color: "#868686"
                  }}
                  variant="outlined"
                  fullWidth
                  className={""}
                  placeholder="Insert API endpoint URL here"
                  defaultValue={""}
                  value={APIResReqStore.sampleData.endpoint_url}
                  onChange={(event) => {
                    APIResReqStore.setSampleEndPointUrl(event.target.value);
                  }}
                  onFocus={(e) => {
                    this.setState({focus: "endpoint"});
                  }}
                />
              </Grid>
            </FormControl>
            <FormControl fullWidth style={{marginTop: "24px"}}>
              <Grid item xs={12} className="bodyInput">
                Body
              </Grid>
              <Grid item xs={12} style={{width: "100%"}}>
                <div id="aceditor" style={{width: "100%", height: "700px"}}>
                  <AceEditor
                    mode="javascript"
                    theme="github"
                    width="100%"
                    height="100%"
                    showPrintMargin={false}
                    showGutter={true}
                    wrapEnabled={true}
                    highlightActiveLine={true}
                    value={APIResReqStore.sampleData.body}
                    onChange={(html) => {
                      if (html !== APIResReqStore.sampleData.body) {
                        APIResReqStore.setSampleApiReqBody(html);
                      }
                    }}
                    fontSize={16}
                    setOptions={{
                      enableBasicAutocompletion: true,
                      enableLiveAutocompletion: true,
                      enableSnippets: false,
                      showLineNumbers: true,
                      tabSize: 2
                    }}
                    editorFocus={() => {
                    }}/>
                </div>
              </Grid>
            </FormControl>
            {APIResReqStore.sampleData.headers.length ? (
              <FormControl
                fullWidth
                style={{marginTop: "24px", color: "#868686"}}
              >
                <Grid
                  item
                  xs={12}
                  style={{
                    marginBottom: "8px",
                    fontSize: "14px",
                    fontFamily: "Open Sans",
                    fontWeight: "normal",
                    lineHeight: "19px"
                  }}
                >
                  Custom Header
                </Grid>
                <CustomHeader
                  headerErrors={this.state.headerErrors}
                  availablePlaceholders={this.props.availablePlaceholders}
                  onFocus={this.props.onFocus}
                  keyPlaceholders={this.props.keyPlaceholders}
                  valuePlaceholders={this.props.valuePlaceholders}
                />
              </FormControl>
            ) : (
              <Grid></Grid>
            )}
            <Grid item>
              <Button
                variant={"text"}
                className={"custom-icon-btn"}
                onClick={() => {
                  this.insertHeaders();
                  this.setState({boolState: !boolState});
                }}
              >
                <AddIcon/> Add Header
              </Button>
            </Grid>
            <Grid item xs={12}>
              {error && (
                <p className="err-text-left">
                  <ErrorIcon className="iconDiscard"/>
                  {errorMessage}
                </p>
              )}
            </Grid>
            <SendRequest
              {...this.props}
              handleSampleResponseReceived={
                () => this.setState({sampleResponseReceived: true})
              }
            />
            {
              this.state.sampleResponseReceived &&
              <>
                <ResponseReceived
                  {...this.props}
                  sampleResponseReceived={sampleResponseReceived}
                />
              </>
            }

          </Grid>
        </div>

        <div hidden={APIResReqStore.sampleData.setupMode !== "CURL"} style={{width: "100%"}}>
          <Grid
            container
            style={{
              padding: "12px 24px 24px 24px",
              background: "white"
            }}
            className="editor-container"
          >
            <Grid item xs={12} style={{width: "100%"}}>
              <FormControl fullWidth style={{marginTop: "24px"}}>
                <Grid
                  item
                  xs={12}
                  style={{
                    marginBottom: "8px",
                    fontSize: "14px",
                    fontFamily: "Open Sans",
                    fontWeight: "normal",
                    lineHeight: "19px",
                    color: "#868686"
                  }}
                >
                  cURL
                </Grid>
                <Grid item xs={12} style={{width: "100%"}}>
                  <div id="aceditor" style={{width: "100%", height: "200px"}}>
                    <AceEditor
                      mode="javascript"
                      theme="github"
                      width="100%"
                      height="100%"
                      showPrintMargin={false}
                      showGutter={true}
                      wrapEnabled={true}
                      highlightActiveLine={true}
                      value={APIResReqStore.sampleData.curl}
                      onChange={(value) => {
                        if (value) {
                          APIResReqStore.setSampleCurl(value);
                        
                        }
                      }}
                      fontSize={16}
                      setOptions={{
                        enableBasicAutocompletion: true,
                        enableLiveAutocompletion: true,
                        enableSnippets: false,
                        showLineNumbers: true,
                        tabSize: 2
                      }}
                      editorFocus={() => {
                        this.setState({focus: "editor"});
                      }}/>
                  </div>
                </Grid>
              </FormControl>
            </Grid>
            <SendRequest
              {...this.props}
              handleSampleResponseReceived={
                () => this.setState({sampleResponseReceived: true})
              }
            />
            {
              this.state.sampleResponseReceived &&
              <>
                <ResponseReceived
                  {...this.props}
                  sampleResponseReceived={sampleResponseReceived}
                />
              </>
            }
          </Grid>
        </div>
      </div>
    );
  }
}

const deleteHeaders = (headerId) => {
  APIResReqStore.sampleData.headers = APIResReqStore.sampleData.headers.filter(
    (h) => h.header_id !== headerId
  );
};

const CustomHeader = (props) => {
  return (
    <Grid>
      <Grid xs={12} container spacing={3} direction="row">
        <Grid item xs={5}>
          <p>Key</p>
        </Grid>
        <Grid item xs={5}>
          <p className="textRightLabelJson">Value</p>
        </Grid>
      </Grid>
      {APIResReqStore.sampleData.headers.map((h) => (
        <Grid container direction="row">
          <Grid item xs={5}>
            <TextField
              value={h.header_name}
              placeholder="Key"
              variant="outlined"
              className="textfieldPaddingJson key_padding"
              onChange={(event) => {
                h.header_name = event.target.value;
              }}
              error={
                h.header_name.trim() == "" && props.headerErrors ? true : false
              }
              InputProps={{
                endAdornment: h.header_name.trim() == "" &&
                  props.headerErrors && (
                    <ExclamationIcon/>
                  )
              }}
              helperText={
                h.header_name.trim() == "" && props.headerErrors
                  ? "This field cannot be empty"
                  : null
              }
            />
          </Grid>
          <Grid item xs={5}>
            <TextField
              value={h.header_value}
              placeholder="Value"
              variant="outlined"
              className="textfieldPaddingJson key_padding"
              onChange={(event) => {
                h.header_value = event.target.value;
              }}
              error={
                h.header_value.trim() == "" && props.headerErrors ? true : false
              }
              InputProps={{
                endAdornment: h.header_value.trim() == "" &&
                  props.headerErrors && (
                    <ExclamationIcon/>
                  )
              }}
              helperText={
                h.header_value.trim() == "" && props.headerErrors
                  ? "This field cannot be empty"
                  : null
              }
            />
          </Grid>
          <Grid item xs={1}>
            <i
              onClick={(event) => {
                deleteHeaders(h.header_id);
              }}
              className="material-icons custom-icon-btn"
              style={{marginTop: 8}}
            >
              delete
            </i>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};

export default SetUpSample;
