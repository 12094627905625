import {getOutgoers, isEdge, isNode, MiniMap, Position} from "reactflow";

// Custom nodes
import Component from "../components/pages/Canvas/ReactFlowUtil/CustomNodes/Component"
import Note from "../components/pages/Canvas/ReactFlowUtil/CustomNodes/Note";
import DBNode from "../components/pages/Canvas/ReactFlowUtil/CustomNodes/DBNode";
import SessionNode from "../components/pages/Canvas/ReactFlowUtil/CustomNodes/SessionNode";

// Custom edges
import Link from "../components/pages/Canvas/ReactFlowUtil/CustomEdges/Link";
import FloatingLink from "../components/pages/Canvas/ReactFlowUtil/CustomEdges/FloatingLink";

export const nodeTypes = {
    component: Component,
    note: Note,
    dbNode: DBNode,
    sessionComponent: SessionNode,
};

export const edgeTypes = {
    link: Link,
    floatingLink: FloatingLink
};

export const proOptions = { hideAttribution: true };
export const CANVAS_MIN_ZOOM_OUT = 0.1;
export const DEFAULT_VIEWPORT = {
    x: 0,
    y: 0,
    zoom: 1,
};

export const tooltips = {
    test: "Run a test of the draft workflow",
    save: "Save all changes to the current draft workflow",
    publish: "Publish the current draft workflow into production. You must publish the workflow before it can be run. ",
    start: "Click to manually start the workflow",
    readme: "View and edit instructions for this workflow",
    access: "Control who can run each component in this workflow",
    files: "View and download files uploaded and generated in this workflow",
    logs: "Logs tracking each time this workflow is run",
    version: "View and revert to past versions of this workflow",
    data: "View all workflow data in a table",
    share: "Share this workflow as a template",
    schema: "View and compare different versions of this workflow",
    database: "View all workflow related databases",
};

// Returns all the nodes and edges together
// Mainly used for our save request to the backend
export const getElements = (reactFlowInstance) => {
    if (!reactFlowInstance) return [];

    const nodes = reactFlowInstance.getNodes() ? reactFlowInstance.getNodes() : [];
    const edges = reactFlowInstance.getEdges() ? reactFlowInstance.getEdges() : [];
    
    return [...nodes, ...edges];
}

// Gets a given note in the correct structure
// i.e: removes added props that makes the backend save fail
export const getNoteBackendSaveStructure = (note) => {
    if (!note) return;
    return {
        id: note.id,
        type: note.type,
        position: note.position,
        data: note.data,
      };
}

// Gets a given link in the correct backend saving structure
// i.e: removes markerEnd as this will cause the graphQL to fail the save
export const getLinkBackendSaveStructure = (link) => {
    if (!link) return;
    let linkRestructured = { 
        ...link,
        arrowHeadType: "arrowclosed",
    };
    delete linkRestructured.markerEnd; // Remove marker end
    return linkRestructured
}

// Extracts all nodes from a given array
export const extractNodes = (array) => {
    if (!array) return [];
    return array.filter((item) => item && isNode(item));
}

// Extracts all edges from a given array
export const extractEdges = (array) => {
    if (!array) return [];
    return array.filter((item) => item && isEdge(item));
}

export const isEdgeType = (type) => {
    if (!type) return false;
    return type in edgeTypes;
}

// Returns true or false depending on if two links
// Are the same
export const isSameLink = (link1, link2) => {
    if (!link1 || !link2) return false;
    if (link1.source !== link2.source) return false;
    if (link1.target !== link2.target) return false;
    if (link1.sourceHandle !== link2.sourceHandle) return false;
    if (link1.targetHandle !== link2.targetHandle) return false;

    return true; // If we reach here, we know that they are the same
}

export const calculateNewItemPostion = (currX, currY, itemWidth, itemHeight, xOffset, yOffset, existingCanvasItems) => {
    let newNoteX = currX + itemWidth + xOffset;
    let newNoteY = currY;

    let itemExists;

    do {
        itemExists = false;

        for (const item of existingCanvasItems) {
            if (item.type !== "note" && item.type !== "component") continue;

            const { x, y } = item.position;

            let itemWidth = (item.data && item.data.width) ? item.data.width : 150;
            let itemHeight = (item.data && item.data.height) ? item.data.height : 150;

            if (newNoteX < x + itemWidth && newNoteX + itemWidth > x && newNoteY < y + itemHeight && newNoteY + itemHeight > y) {
                newNoteY += itemHeight + yOffset; // Move the new item down
                itemExists = true;
                break;
            }
        }
    } while (itemExists);

    return {
        x: newNoteX,
        y: newNoteY,
    };
};

/** LINKS UTIL (HELPER FUNCTIONS FROM REACT FLOW) */

const width = 250; // width of the overall component
const height = 54 // height of the component.

// this helper function returns the intersection point
// of the line between the center of the intersectionNode and the target node
function getNodeIntersection(sourcePosition, targetPosition) {

    // https://math.stackexchange.com/questions/1724792/an-algorithm-for-finding-the-intersection-point-between-a-center-of-vision-and-a
    let w = width / 2;
    const h = height / 2;


    //coordinate of center
    const x2 = sourcePosition.x+w;
    const y2 = sourcePosition.y+h
    //coordinates of target position
    const x1 = targetPosition.x + w;
    const y1 = targetPosition.y + h;

    w = h // changing  the radius after finding center to ensure that it just revolve around the component

    const xx1 = (x1 - x2) / (2 * w) - (y1 - y2) / (2 * h);
    const yy1 = (x1 - x2) / (2 * w) + (y1 - y2) / (2 * h);
    const a = 1 / (Math.abs(xx1) + Math.abs(yy1));
    const xx3 = a * xx1;
    const yy3 = a * yy1;
    const x = w * (xx3 + yy3) + x2;
    const y = h * (-xx3 + yy3) + y2;

    return { x, y };
}

// returns the position (top,right,bottom or right) passed node compared to the intersection point
function getEdgePosition(n, intersectionPoint) {
    const nx = Math.round(n.x+(height*2)); // this helps identify the start pos of the component in the wrapper
    const ny = Math.round(n.y);
    const px = Math.round(intersectionPoint.x);
    const py = Math.round(intersectionPoint.y);

    if (px <= nx + 1) {
        return Position.Left;
    }
    if (px >= nx + (height/2)- 1) { // if it is more than half of the starting position
        return Position.Right;
    }
    if (py <= ny + 1) {
        return Position.Top;
    }
    if (py >= n.y + height/2 - 1) {
        return Position.Bottom;
    }

    return Position.Top;
}

// returns the parameters (sx, sy, tx, ty, sourcePos, targetPos) you need to create an edge
export function getEdgeParams(sourcePosition,targetPosition) {
    const sourceIntersectionPoint = getNodeIntersection(sourcePosition,targetPosition);
    const targetIntersectionPoint = getNodeIntersection(targetPosition,sourcePosition);

    const sourcePos = getEdgePosition(sourcePosition, sourceIntersectionPoint);
    const targetPos = getEdgePosition(targetPosition, targetIntersectionPoint);

    return {
        sx: sourceIntersectionPoint.x,
        sy: sourceIntersectionPoint.y,
        tx: targetIntersectionPoint.x,
        ty: targetIntersectionPoint.y,
        sourcePos,
        targetPos,
    };
}

export function isValidConnection(connection, nodes,edges) {
      const target = nodes.find((node) => node.id === connection.target);
      const hasCycle = (node, visited = new Set()) => {
        // we are checking if node type is component as we need to check cycles only in components
        // so this will skip dbNode type o any new component we add later on
        // https://workflow86.atlassian.net/browse/W86-6677
        if(node.type!=="component") return;
        if (visited.has(node.id)) return false;

        visited.add(node.id);

        for (const outgoer of getOutgoers(node, nodes, edges)) {
          if (outgoer.id === connection.source) return true;
          if (hasCycle(outgoer, visited)) return true;
        }
      };

      if (target.id === connection.source) return false;
      return !hasCycle(target);

};

/**
 * Returns true or false depending on if it's an invalid branch path connection or not
 * true -> this means the user tried to connect a branch path to something thats not a conditional_path OR
 *         they tried to connect something thats not a branch path into a conditional path 
 * false -> all other cases
 * @param {*} source 
 * @param {*} target 
 * @param {*} setShowErrorSnackbar 
 * @returns 
 */
export function isInvalidBranchPathsConnection(source, target, setShowErrorSnackbar) {
    if (!source || !source.data || !target || !target.data) return false;

    /**
     * Conditions:
     * 1. Branch paths can only be connected to a conditional path (conditional_workflow)
     * 2. Only a branch_path can be connected into a conditional path (conditional_workflow)
     */
    if (source.data.type === "branch_paths" && target.data.type !== "conditional_workflow") {
        setShowErrorSnackbar(<span>⚠️ <b>Branch Path</b> must be connected to <b>Conditional Path</b></span>)
        return true;
    }

    if (source.data.type !== "branch_paths" && target.data.type === "conditional_workflow") {
        setShowErrorSnackbar(<span>⚠️ Only <b>Branch Path</b> can connect into <b>Conditional Path</b></span>);
        return true;
    }

    return false;
}

export const CanvasMinimap = () => {
    return (
        <MiniMap pannable zoomable maskStrokeColor={"rgba(224, 224, 224, 1)"} maskColor={"rgba(0, 0, 0, 0.3)"} ariaLabel={"MiniMap"} padding={6} style={{ width: '320', height: '200', borderRadius: '6px', overflow: 'hidden'} }/>
    )
}
export const validURL = (str) => {
    let pattern = new RegExp(
        "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*"  // port and path
        ,
        "i"
    ); // fragment locator
    return !!pattern.test(str);
};

export function areSetsEqual(arr1, arr2) {
    // Handles the case where both are null, undefined, or the same reference
    if (arr1 === arr2) return true;
    // Ensure both are arrays
    if (!Array.isArray(arr1) || !Array.isArray(arr2)) return false;
    // Early return if different lengths
    if (arr1.length !== arr2.length) return false;

    const set1 = new Set(arr1);
    const set2 = new Set(arr2);

    // Check size equality
    if (set1.size !== set2.size) return false;

    for (let item of set1) {
        // Check if every item in set1 exists in set2
        if (!set2.has(item)) return false;
    }

    return true;
}
