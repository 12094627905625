import React from "react";
//mui
import { Grid, Fade } from "@mui/material";
//styling
import { withStyles } from "@mui/styles";

const styles = () => ({
    container: {
        maxWidth: "100%!important",
        borderRadius: "16px 16px 0px 16px",
        fontSize: "14px",
        display: "flex",
        flexDirection: "column",
        border: "1px solid rgba(185, 185, 185, 0.50)",
        "&:hover": {
            background: "#944BA81A",
            border: " 1px solid #944BA8",
            cursor: "pointer"
        },
    }
});

const SuggestionBox = ({ classes, title, description, setUserPrompt }) => {
    return (
        <Fade timeout={1000} in={true}>
            <div className={classes.container} onClick={() => {
                let prompt = title+": "+description;
                setUserPrompt(prompt);
            }}>
                    <Grid item xs={12} padding={"16px"}>
                        <strong>{title}</strong>: {description}
                    </Grid>
            </div>
        </Fade>
    );
};

export default withStyles(styles)(SuggestionBox);
