import React from "react";
//mui
import { Grid, Fade } from "@mui/material";

//styles
import { withStyles } from "@mui/styles";

const styles = () => ({
  success: {
    maxWidth: "100%!important",
    background: "#F5F5F5",
    borderRadius: "16px 16px 16px 0px",
    width: "fit-content",
    padding: "16px",
    fontSize: "14px",
  },
});

const Success = ({ classes, content }) => {
  return (
    <Fade timeout={1000} in={true}>
      <Grid className={classes.success}>
        {content}
      </Grid>
    </Fade>
  );
};

export default withStyles(styles)(Success);
