import {computed, observable, decorate, action, toJS} from "mobx";
import {JsDemoCode, PythonDemoCode} from "./CodeComponent/AceEditorPlaceholder";

class CodeStore {
  constructor() {
    this.initialiseEmpty();
  }

  getNodeJSLangName = () =>{
    return "NodeJS"
  }

  getPythonLangName = () =>{
    return "Python"
  }

  setTemplateData = (data) => {
    this.data = data;
  }

  setCodeData = (component, currentVersion) => {
    this.componentId = component.componentId;
    this.type = component.type;
    // this.data = {
    //   code: "function()=>1234",
    //   codeLang: "JavaScript",
    //   enableVpc: false,
    //   vpcIp: "192.168.0.1",
    //   parameters: [],
    //   placeholders: [],
    // }; // mock
    if (component.componentData) {
      this.data = component.componentData.data;
      this.lastModified = component.componentData.lastModified;
      this.name = component.componentData.name;
      if (this.data.code === "CodePlaceholder"){
        if(this.data.codeLang.includes(this.getNodeJSLangName())){
          this.data.code = JsDemoCode;
        } else if(this.data.codeLang.includes(this.getPythonLangName())){
          this.data.code = PythonDemoCode;
        }
      }
    } else {
      if (typeof (currentVersion) != "number") {
        currentVersion = Number(currentVersion);
      }
      this.data = this.codeData(currentVersion);
      this.lastModified = "";
      this.name = "Run Code";
    }
    if(!this.data.placeholders || this.data.placeholders.length === 0){
      this.data.placeholders = [];
      const newPlaceholder = {
        key: "",
        label: "",
        text: "",
        length: 1,
        style: {},
        type: "text",
      }
      this.data.placeholders.push(newPlaceholder);
    }
  };

  setCodeName = name => {
    this.name = name;
  };

  setLastModified = lastModified => {
    this.lastModified = lastModified;
  };

  deleteChip = (index) => {
    const newChips = [...this.data.parameters];
    newChips.splice(index, 1);
    this.data.parameters = newChips;
  };

  addPlaceholders = (value) => {
    for (let i = 0; i < this.placeholders.length; i++) {
      if (value.key && value.key === this.placeholders[i].key) {
        return;
      }
    }
    this.placeholders.push(value);
  }

  deletePlaceholders = (index) => {
    const newPlaceholders = [...this.placeholders];
    newPlaceholders.splice(index, 1);
    this.placeholders = newPlaceholders;
  }

  deleteCodePlaceholder = (index) => {
    if (this.data.placeholders.length > index && index >= 0) {
      const newPlaceholders = [...this.data.placeholders];
      newPlaceholders.splice(index, 1);
      this.data.placeholders = newPlaceholders;
    }
  }

  getNewPlaceholderFromValue = (payload) => {
    const index = payload.index;
    const attribute = payload.attribute;
    let attributeValue = payload.value
    let newPlaceholder = {
      key: "",
      label: "",
      text: "",
      length: 1,
      style: {},
      type: "text",
    }
    if (this.data.placeholders.length <= index) {
      this.data.placeholders.push(newPlaceholder);
    }

    switch (attribute) {
      case "key":
        this.data.placeholders[index].key = attributeValue;
        break;
      case "label":
        this.data.placeholders[index].label = attributeValue;
        break;
      case "type":
        this.data.placeholders[index].type = attributeValue;
        break;
      case "text":
        this.data.placeholders[index].text = attributeValue;
        break;
      case "length":
        if (typeof value != "number") {
          attributeValue = Number(value);
        }
        this.data.placeholders[index].length = attributeValue;
        break;
      case "style":
        this.data.placeholders[index].style = attributeValue;
        break;
    }

    if (!this.data.placeholders) {
      this.data.placeholders = [];
    }

    return this.data.placeholders;
  }

  codeData = (currentVersion) => {
    const placeholderArray = [];
    const newPlaceholder = {
      key: "",
      label: "",
      text: "",
      length: 1,
      style: {},
      type: "text",
    }
    placeholderArray.push(newPlaceholder);
    return {
      code: PythonDemoCode,
      codeLang: "Python_3_9",
      enableVpc: false,
      vpcIp: "",
      parameters: [],
      placeholders: placeholderArray,
      version: currentVersion,
    }
  };

  setCode = (value, type) => {
    if (value !== undefined) {
      switch (type) {
        case "code":
          this.data.code = value;
          return;
        case "codeLang":
          this.data.codeLang = value;
          return;
        case "parameters":
          if (this.data.parameters) {
            for (let i = 0; i < this.data.parameters.length; i++) {
              if (this.data.parameters[i] && this.data.parameters[i] === value) {
                return;
              }
            }
            this.data.parameters.push(value);
          }
          return;
        case "placeholders":
          this.data.placeholders = this.getNewPlaceholderFromValue(value);
          return;
        case "vpc":
          this.data.enableVpc = value;
          return;
        case "version":
          if (typeof (value) != "number") {
            value = Number(value);
          }
          this.data.version = value;
          return;
      }
    }
  };

  initialiseEmpty = () => {
    this.name = "Run code";
    this.position = null;
    this.componentId = "";
    this.type = "code";
    this.lastModified = "";
    this.data = this.codeData(0);
    this.error = false;
    this.placeholders = [];
  };

  get template() {
    return {
      componentId: this.componentId,
      name: this.name,
      position: this.position,
      data: toJS(this.data),
      type: this.type,
      lastModified: this.lastModified,
      placeholders: this.placeholders,
    };
  }
}

decorate(CodeStore, {
  componentId: observable,
  position: observable,
  name: observable,
  data: observable.shallow,
  type: observable,
  lastModified: observable,
  placeholders: observable,
  error: observable,
  setCodeData: action,
  setCode: action,
  setCodeName: action,
  setLastModified: action,
  initialiseEmpty: action,
  deleteCodePlaceholder: action,
  template: computed
});

const codeStore = new CodeStore();
export default codeStore;
