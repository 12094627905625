import React from "react";

// MUI
import ErrorIcon from "@mui/icons-material/Error";
import { Grid, styled, Skeleton } from "@mui/material";

// Util
import { Link } from "react-router-dom";

const AlertBox = styled(Grid)(({ bgColor, borderColor }) => ({
    fontSize: "14px",
    backgroundColor: bgColor,
    border: `1px solid ${borderColor}`,
    padding: "8px 16px",
    borderRadius: "8px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
}));

export const StyledErrorIcon = styled(ErrorIcon)({
    color: "#b00020",
    marginRight: "8px",
    height: "16px",
    width: "auto",
});

const StyledSkeleton = styled(Skeleton)(({ width, height, margin }) => ({
    borderRadius: "10px",
    background: "#E8E8E8",
    width: "100%",
    height: height,
    margin: margin,
}));

function AfterRunAlert({ alertType, sessionId, projectId, isLoading, isTest }) {
    const getSuccessAlert = () => {
        return (
            <AlertBox container bgColor="rgba(229, 242, 235, 1)" borderColor="rgba(85, 167, 122, 1)">
                <Grid item xs={12}>
                    ✅ {!isTest ? "Workflow " : "Test "} run started from this component. You can view and track its progress{" "}
                    <Link to={`/project/logs/progress_view/${projectId}/${sessionId}?test=${isTest}`} target="_blank" style={{ color: "rgba(33, 150, 243, 1)" }}>
                        here
                    </Link>
                </Grid>
            </AlertBox>
        );
    };

    const getErrorAlert = () => {
        return (
            <AlertBox container bgColor="rgba(225, 0, 0, 0.15)" borderColor="rgba(225, 0, 0, 1)">
                <StyledErrorIcon /> There was an error force-starting this component
            </AlertBox>
        );
    };

    const getAlert = () => {
        switch (alertType) {
            case "success":
                return getSuccessAlert();
            default:
                return getErrorAlert();
        }
    }

    return isLoading ? <StyledSkeleton variant="rectangular" height="50px" /> :  getAlert();
}

export default AfterRunAlert;
