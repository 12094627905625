import React, { useEffect } from "react";
import { CustomDialog } from "../../Component/Dialog";
import { Grid, TextField } from "@mui/material";
import Button from "@mui/material/Button";
import PropTypes from "prop-types";

export const FormEmbedDialog = (props) => {
  const {
    openEmbed,
    setOpenEmbed,
    embedWidth,
    setEmbedWidth,
    embedHeight,
    setEmbedHeight,
    generateCode,
    iframeLink,
  } = props;

  useEffect(() => {
    props.generateCode();
  }, []);
  
  return (
    <CustomDialog
      isOpen={openEmbed}
      handleClose={() => {
        setOpenEmbed(false);
      }}
      title={"Embed your form"}
      contents={
        <>
          <p className={"s-text"}>
            Generate HTML code to embed your form into another web page. Specify
            the size of the window below and then click Generate Code.
          </p>
          <Grid xs={12} container item className={"s-text"} direction={"row"}>
            <Grid xs={5} container item direciton={"row"} alignItems={"center"}>
              <span>Width </span>
              <TextField
                type="number"
                variant="outlined"
                fullWidth
                className={"sizeEmbed  key_padding"}
                defaultValue={""}
                onChange={(e) => {
                  setEmbedWidth(e.target.value);
                }}
                value={embedWidth}
              />
              <span>px</span>
            </Grid>
            <Grid xs={6} container item direciton={"row"} alignItems={"center"}>
              <span>Height </span>
              <TextField
                type="number"
                variant="outlined"
                fullWidth
                className={"sizeEmbed  key_padding"}
                defaultValue={""}
                onChange={(e) => {
                  setEmbedHeight(e.target.value);
                }}
                value={embedHeight}
              />
              <span>px</span>
            </Grid>
          </Grid>

          <Button
            className="generateButton"
            color={"info"}
            onClick={() => {
              generateCode();
            }}
          >
            GENERATE CODE
          </Button>
          <div className="embed-iframe">{iframeLink}</div>
        </>
      }
      buttons={
        <Button
          onClick={() => {
            setOpenEmbed(false);
          }}
          variant={"contained"}
          color={"primary"}
        >
          {" "}
          Done
        </Button>
      }
    />
  );
};
FormEmbedDialog.propTypes = {
  openEmbed: PropTypes.bool.isRequired,
  setOpenEmbed: PropTypes.func.isRequired,
  formId: PropTypes.string.isRequired,
  embedWidth: PropTypes.number.isRequired,
  setEmbedWidth: PropTypes.func.isRequired,
  embedHeight: PropTypes.number.isRequired,
  setEmbedHeight: PropTypes.func.isRequired,
  generateCode: PropTypes.func.isRequired,
  iframeLink: PropTypes.string.isRequired,
};
