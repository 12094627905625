import React from "react";
import { Grid, TableHead, TableRow, TableCell, Table, TableContainer, TableBody, Paper} from "@mui/material";
import { ComponentFactory } from "../../../../../ProjectCanvas/ComponentRegistry";
import { focusOnComponent, getTranslatedComponentType } from "../../util/TranslateUtil";
import { Shortcut } from "@mui/icons-material";
import { styled } from '@mui/system';
import { toJS } from "mobx";
import LinkOffIcon from '@mui/icons-material/LinkOff';
import AddIcon from '@mui/icons-material/Add';

const ComponentBlock = styled(Grid)({
    border: "1px solid rgba(217, 217, 217, 1)",
    padding: "16px",
    borderRadius: "6px",
    "&:hover": {
        cursor: "pointer",
        backgroundColor: "#F8F8F8",
    }
})

const AssumptionsBlock = styled(Grid)`
    border-radius: 6px;
    border: 1px solid #ECECEC;
    background: #FFF;
    color: #363636;
    font-size:12px;
    padding:8px 16px 8px 16px;
`;

const IconWrapper = styled(Grid)`
    margin: 16px 4px 0px 8px;
`;

const WorkaroundBlock = styled(Grid)`
    border-radius: 6px;
    border: 1px solid #FFB74D;
`;

const Note = styled(Grid)`
    border-radius: 6px;
    border: 1px solid #000;
    background: #FBF6BE;
    display: flex;
    padding: 8px;
    align-items: flex-start;
    gap: 10px;
    flex: 1 0 0;
`;

const BoldText = styled('span')({
    fontWeight: 'bold',
  });

const Summary = styled('span')({
    fontWeight: 'bold',
    fontSize: '14px'
});

const WorkflowName = styled('span')({
    fontWeight: 'bold',
    fontSize: '18px' 
})

const IconText = styled('p')({
    margin:"0px 0px 0px 4px"
})


const StyledCell = styled(TableCell)`
    border: 1px solid rgba(185, 185, 185, 1);
    background-color: ${props => props.header ? 'rgba(0, 0, 0, 0.06)' : 'none'};
    width: 96px;
   white-space: nowrap;
`;

// This function acts as a dispatcher. Given a segment type and payload, it determines which 'chunk' function to call. 
// Chunks are JSX components that display a segment of the project in a specific structure based on its type.
export function getStructuredChunk(segmentType, segmentPayload, focusObject) {
    const chunkMapping = {
        'PROJECT_NAME': projectNameChunk,
        'PROJECT_DESCRIPTION': descriptionChunk,
        'PROJECT_COMPONENT': componentChunk,
        'PROJECT_DATABASE': databaseChunk,
        'PROJECT_RELATIONSHIP': relationshipChunk,
        'CHAT_TITLE': chatTitleChunk,
        'PROCESS_TITLE': processTitleChunk,
        'PROJECT_NOTE': noteChunk,
        'NEW_SUMMARY' : textChunk,
        'UPDATE_SUMMARY': textChunk,
        'EDIT_ACTIONS_SUMMARY': editActionsChunk,
        'REMOVE_SUMMARY':textChunk, 
        'NEW_PLACEHOLDERS': placeholdersChunk,
        'OBSOLETE_PLACEHOLDERS': placeholdersChunk,
        'ASSUMPTIONS': assumptionsChunk,
        'WORKAROUND': workaroundChunk,
        'COMPONENTS_TO_ADD': newComponentsChunk,
        'COMPONENTS_TO_UPDATE': updateComponentChunk,
        'COMPONENTS_TO_DELETE':removeComponentChunk,
        'DATABASES_TO_CREATE': newDatabasesChunk,
        'DATABASES_TO_UPDATE': updateDatabaseChunk,
        'DATABASES_TO_DISCONNECT': removeDatabaseChunk,
        'DATABASE_NAME': databaseNameChunk,
    };


    const chunkFunc = chunkMapping[segmentType];
    if(chunkFunc)
        return chunkFunc(segmentPayload, focusObject) 
}

// Renders the name of the project workflow. Displays the name in a bold, larger font inside a grid container.
const projectNameChunk = (payload) => (
    <Grid item xs={12} marginBottom="12px">
        <WorkflowName style={{ fontSize: '18px' }}>{payload.workflowName}</WorkflowName>
    </Grid>
);

// Renders the name of the project workflow. Displays the name in a bold, larger font inside a grid container.
const chatTitleChunk = (payload) => (
    <Grid item xs={12} marginBottom="12px">
        <WorkflowName style={{ fontSize: '18px' }}>{payload.chatTitle}</WorkflowName>
    </Grid>
);

// Renders the name of the project workflow. Displays the name in a bold, larger font inside a grid container.
// Also includes the notes associated with the process title.
const processTitleChunk = (payload) => (
    <Summary>{payload.collection ? payload.collection : payload.title}</Summary>
);

// Renders a description of the project. It has a header "Summary" followed by the actual explanation.
const descriptionChunk = (payload) => (
    <Grid container rowGap={2}>
        <Grid item xs={12}>
            <Summary>Summary</Summary>
        </Grid>
        <Grid item xs={12}>
            {payload.explanation}
        </Grid>
    </Grid>
);

//Given a component type, this function fetches a corresponding logo/icon. If it doesn't find a sidebar-specific logo, it defaults to the general logo.
export const getIcon = (type, size) => {
    if (!size) size = "28px";
    
    const componentType = getTranslatedComponentType(type);

    const logo = ComponentFactory[componentType]?.sidebarLogo || ComponentFactory[componentType]?.logo;

    return logo ? <img src={logo} height={size} width="auto" /> : null;
};
//Returns a formatted list of items with a provided text. If the list is empty or null, it returns null.
const getItemList = (list, text) => {
    if (!list || list.length <= 0) return null;

    // Normalize the list to an array of strings
    list = toJS(list).map(item => 
        (typeof item === 'object' && item.componentId) ? item.componentId : item
    );

    return (
        <Grid item xs={12}>
            {text}: {list.join(', ')}
        </Grid>
    );
};

// Displays the main project component. The display includes the component's icon, title, description, type, ID, input and output placeholders, and next components. 
// Additionally, if the component type is "conditional_workflow_paths", it also renders the workflow paths with a specific structure.
const componentChunk = (payload, focusObject) => (
    <div style={{ padding: '0px 4px' }}>
          <ComponentBlock container columnGap="16px" onClick={() => focusOnComponent(payload.componentId, focusObject)}>
                    <Grid item>{getIcon(payload.componentType, "28px")}</Grid>
                    <Grid item xs>
                        <Grid container rowGap="10px">
                            <Grid item xs={12} style={{ fontSize: "14px", paddingTop: "2px" }}>
                                <b>{`${payload.editConnectionsOnly ? "Update connections - " : ""}${payload.componentTitle ? payload.componentTitle : payload.componentType}`}</b>
                            </Grid>
                            {payload.componentDescription && <Grid item xs={12} style={{ fontSize: "14px" }}>
                                {payload.componentDescription}
                            </Grid>}
                            <Grid item xs={12} style={{ fontSize: "12px" }}>
                                <Grid container rowGap={"2px"} style={{ color: "rgba(117, 117, 117, 1)" }}>
                                    {payload.componentType && <Grid item xs={12}>
                                        {payload.componentType}
                                    </Grid>}
                                    {/* <Grid item xs={12}>
                                        ID: {payload.componentId}
                                    </Grid> */}
                                    {/* {getItemList(payload.inputPlaceholders, "Input")} */}
                                    {getItemList(payload.outputPlaceholders, "Output")}
                                    {/* {getItemList(payload.nextComponents, "Next Components")} */}
                                </Grid>
                            </Grid>
                            {payload.componentType === "conditional_workflow_paths" && (
                                <Grid item xs={12}>
                                    <Grid container spacing={2}>
                                        {payload.workflowPaths &&
                                            payload.workflowPaths.map((workflowPath, key) => {
                                                return (
                                                    <Grid item xs={12} key={key}>
                                                        <Grid container columnGap="16px">
                                                            <Grid item>
                                                                <Shortcut style={{ height: "22px", width: "auto" }} />
                                                            </Grid>
                                                            <Grid item xs>
                                                                <Grid container rowGap={"2px"}>
                                                                    <Grid item xs={12} style={{ fontSize: "14px !important", marginBottom: "4px", paddingTop: "2px" }}>
                                                                        {workflowPath.pathTitle}
                                                                    </Grid>
                                                                    <Grid item xs={12} style={{ color: "rgba(117, 117, 117, 1)", fontSize: "12px !important" }}>
                                                                        {workflowPath.condition}
                                                                    </Grid>
                                                                    <Grid item xs={12} style={{ color: "rgba(117, 117, 117, 1)", fontSize: "12px !important" }}>
                                                                        ID: {workflowPath.componentId}
                                                                    </Grid>
                                                                    <div style={{ color: "rgba(117, 117, 117, 1)", fontSize: "12px !important" }}>
                                                                        {getItemList(workflowPath.inputPlaceholders, "Input")}
                                                                        {getItemList(workflowPath.nextComponents, "Next Component")}
                                                                    </div>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                );
                                            })}
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </ComponentBlock>
    </div>
);

//Renders a database structure. Displays the database name and its associated columns in a scrollable table. 
//The table headers show the names of the columns, but the table body is empty (only the cell borders are rendered).
const databaseChunk = (payload) => (
    <Grid container spacing={1} item xs={12}>
        <Grid item xs={12}>
            <BoldText>{payload.databaseName}</BoldText>
        </Grid>
        <Grid item xs={12} style={{ width: '200px', overflowX: 'scroll' }}>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            {payload.columns && toJS(payload.columns).filter((col) => col.databaseColumnId !== -1 && col.databaseColumnId !== -2).map((col, key) => (
                                <StyledCell key={key} align="center" header>
                                    <BoldText>{col.databaseColumnName}</BoldText>
                                </StyledCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            {payload.columns && payload.columns.filter((col) => col.databaseColumnId !== -1 && col.databaseColumnId !== -2).map((col, key) => (
                                <StyledCell key={key} />
                            ))}
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Grid>
    </Grid>
);

const relationshipChunk = () => <BoldText>Relationship chunk</BoldText>;

const noteChunk = (payload) => <Note id={payload.id}>{payload.content}</Note>;

const textChunk = (payload) => {
    if (payload) {
        let content = "";

        if (payload.new) {
            content = payload.new;
        } else if (payload.update) {
            content = payload.update;
        } else if (payload.remove) {
            content = payload.remove;
        }

        return <p className={"m-0"}>{content}</p>;
    }
}

const placeholdersChunk = (payload) => {
    if (payload) {
        if (payload.newPlaceholders) {
            // Splitting the string into an array of placeholders
            const newPlaceholdersArray = Array.isArray(payload.newPlaceholders)? payload.newPlaceholders: payload.newPlaceholders.split(",");
            return (
                <>
                    <p className={"m-0"}>These placeholders will be added: </p>
                    <ul>
                        {newPlaceholdersArray.map((placeholder, index) => (
                            <li key={index}>{placeholder}</li>
                        ))}
                    </ul>
                </>
            );
        } else if (payload.obsoletePlaceholders) {
            // Splitting the string into an array of placeholders
            const obsoletePlaceholdersArray = Array.isArray(payload.obsoletePlaceholders)? payload.obsoletePlaceholders :  payload.obsoletePlaceholders.split(",");
            return (
                <>
                    <p className={"m-0"}>These placeholders will be removed: </p>
                    <ul>
                        {obsoletePlaceholdersArray.map((placeholder, index) => (
                            <li key={index}>{placeholder}</li>
                        ))}
                    </ul>
                </>
            );
        }
    }
}

const editActionsChunk = (payload) => {
    if (!payload && !payload.editActionsSummary) return <></>;

    const editActions = Array.isArray(payload.editActionsSummary) ? payload.editActionsSummary : payload.editActionsSummary.split(",");

    return (
        <>
            <Summary>Summary</Summary>
            <div>
                {editActions.map((editAction, index) => {
                    // Trim any whitespace and any quotes at the beginning/end of the string
                    const editActionTrimmed = editAction.trim().replace(/^"(.*)"$/, '$1');
                    return <p key={index}>{editActionTrimmed}</p>;
                })}
            </div>
        </>
    )
}

const newComponentsChunk = (payload) => {
    if (payload) {
        return (
            <Grid container item xs={12} direction="row" alignItems="center">            
                <img src="/svgs/add_ad.svg" alt="Add Component"></img>
                <IconText>Components to add:</IconText>
            </Grid>
        );
    }
}

const updateComponentChunk = (payload) => {
    if (payload) {
        return (
            <Grid container item xs={12} direction="row" alignItems="center">         
                <img src="/svgs/upgrade.svg" alt="Upgrade Component"></img>
                <IconText>Components to update:</IconText>
            </Grid>
        );
    }
}

const removeComponentChunk = (payload) => {

    let components = payload instanceof Array ? payload : null;

    if (payload && payload.component && !components) {
        try {
            components = JSON.parse(payload.component);
        } catch (error) {
            console.error('Error parsing component data:', error);
            return null; 
        }
    }

    if (components && components.length > 0) {
        return (
            <Grid container item xs={12} direction="row" alignItems="center">
                <img src="/svgs/delete_sweep.svg" alt="Delete Component"></img>
                <IconText>Components to delete:</IconText>
                <ul>
                    {components.map((component, index) => (
                        <li key={index}>{component.componentId}</li>
                    ))}
                </ul>
            </Grid>
        );
    }
}

const newDatabasesChunk = (payload) => {
    if (payload) {
        return (
            <Grid container item xs={12} direction="row" alignItems="center">         
                <img src="/svgs/database_filled.svg" ></img>
                <IconText>Databases to be created:</IconText>
            </Grid>
        );
    }
}

const updateDatabaseChunk = (payload) => {
    if (payload ) {
        return (
            <Grid container item xs={12} direction="column" alignItems="flex-start">
                <p className={"m-0"}>Updates to existing databases:</p>
            </Grid>
        );
    }
    return null;
};

const removeDatabaseChunk = (payload) => {
    
    let databases = payload instanceof Array ? payload : null;

    if (payload && payload.database && !databases) {
        try {
            databases = JSON.parse(payload.database);
        } catch (error) {
            console.error('Error parsing database data:', error);
            return null; 
        }
    }

    if (databases && databases.length > 0) {
        return (
            <Grid container item xs={12} direction="column" alignItems="flex-start">
                <p>Databases to disconnect:</p>
                <ul style={{ listStyle: 'none', padding: 0, width: '100%' }}>
                    {databases.map((database, index) => (
                        <li key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                            <LinkOffIcon style={{ marginRight: '8px' }}/> 
                            <span>{database.databaseID ? database.databaseID : database.databaseId}</span>
                        </li>
                    ))}
                </ul>
            </Grid>
        );
    }   
};


export const assumptionsChunk = (payload) => {

    // Use a regular expression to extract assumptions from the string
    const regex = /"([^"]+\.)/g;
    let match;
    let assumptionsArray = [];

    if(Array.isArray(payload.assumptions)){
    
        assumptionsArray = payload.assumptions;
    
    } else {

        while ((match = regex.exec(payload.assumptions)) !== null) {
            // Remove the quotes and add the assumption to the array
            assumptionsArray.push(match[1]);
        }
    }

    return (
        <AssumptionsBlock container item xs={12} direction="row" alignItems="flex-start" >     
                <p>The following assumptions were made when developing this proposal:</p>
                <ul  style={{ paddingLeft: '20px', marginTop: '0', marginBottom: '0' }}>
                    {assumptionsArray.map((assumption, index) => (
                        <li key={index} style={{ paddingLeft: '4px', marginTop: '0', marginBottom: '0' }}>{assumption}</li>
                    ))}
                </ul>
                <p>If any of these assumptions are incorrect, let me know and I can revise the proposal.</p>
        </AssumptionsBlock>
    );
};

const workaroundChunk = (payload) => {
    if(payload && payload.workaround){
            return (
                <WorkaroundBlock container item xs={12} direction="row" alignItems="flex-start">
                <IconWrapper xs={1}>
                    <i className="material-icons" style={{ color: 'orange' }}>error</i>
                </IconWrapper>
                <Grid xs={9}>
                    <p>{payload.workaround}</p>
                </Grid>
            </WorkaroundBlock>
        );
    }
}

const databaseNameChunk = (payload) => {
    if (payload) {
        let database = toJS(payload.database) ? toJS(payload.database) : payload;
        let columns = database.newColumns ? database.newColumns : database.columns;

        return (
            <Grid container item xs={12} direction="column" alignItems="flex-start">
                <Grid container item xs={12} direction="row" alignItems="center">
                    <Grid container item xs={1}>
                        <img src="/svgs/splitscreen_right.svg" alt="Database Icon" />
                    </Grid>
                    <Grid item xs={10}>
                        <p className={"m-0"}>{database.databaseName ? database.databaseName : database.databaseId}</p>
                    </Grid>
                </Grid>
                {columns.map((newColumn, index) => (
                    <Grid key={index} container item xs={12} direction="row" alignItems="center">
                        <IconWrapper xs={1}>
                           <AddIcon style={{ height: "22px", width: "auto", marginBottom:"16px" }}></AddIcon>
                        </IconWrapper>
                        <Grid item xs={10}>
                            <Grid container direction="column" alignItems="flex-start">
                                <p className="m-0">{newColumn.databaseColumnName}</p>
                                <p className="m-0">Type: {newColumn.databaseColumnType}</p>
                            </Grid>
                        </Grid>
                    </Grid>
                ))}
            </Grid>
        );
    }
};