import React, { useState, useEffect } from "react";

// MUI
import { Grid, Button, TextField, styled } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";

// MUI Icons
import ErrorIcon from "@mui/icons-material/Error";
import FileCopyIcon from "@mui/icons-material/FileCopy";

// Util
import { CopyToClipboard } from "react-copy-to-clipboard";
import GenerateToken from "../../Component/GenerateToken";
import WarningBox from "../../pages/CommonStyledComponents/WarningBox";
import { toJS } from "mobx";
import W86PlaceholderTextField from "../../W86Components/W86PlaceholderTextField";
import checkKey from "../../../utils/CheckKey";
import replacePlaceholderValue from "../../../utils/ReplacePlaceholderValue";

const TitleGrid = styled("span")({
    color: "#000",
    fontSize: "14px",
    fontWeight: "bold",
});

const SubTitleTextGrid = styled(Grid)({
    color: "#757575",
    fontSize: "14px",
    fontWeight: 400,
});

function OpenEndpointStep(props) {
    const [hasToken, setHasToken] = useState(props.hasToken);
    const [errorMessage, setErrorMessage] = useState("");

    useEffect(() => {
        if (!props.componentStore.data) return;

        // If not correct structure, set auto placeholder
        if (!props.componentStore.data.samplePlaceholders || props.componentStore.data.samplePlaceholders.length !== 1) {
            props.componentStore.setRawResponsePH(props.componentStore.keyAuto());
        }
    }, []);

    const changeTokenStatus = (status) => {
        setHasToken(status);
        props.changeTokenStatus(status);
    };

    return (
        <Grid container rowSpacing={3}>
            <Grid item xs={12}>
                <Grid container rowSpacing={4}>
                    <Grid item xs={12}>
                        <Grid container rowSpacing={1}>
                            <TitleGrid item xs={12}>
                                Production endpoint
                            </TitleGrid>
                            <SubTitleTextGrid item xs={12}>
                                Direct your API to send a payload to the Production Endpoint URL below
                            </SubTitleTextGrid>
                            <Grid item xs={12}>
                                <TextField
                                    value={"https://webhook.workflow86.com/webhook/" + props.componentId}
                                    className={`key_padding ${props.isExp && "textFieldWebhook"}`}
                                    variant="outlined"
                                    disabled={true}
                                    fullWidth
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <CopyToClipboard text={"https://webhook.workflow86.com/webhook/" + props.componentId}>
                                                    <FileCopyIcon className="custom-icon-btn" />
                                                </CopyToClipboard>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <Grid container rowSpacing={1}>
                            <TitleGrid item xs={12}>
                                Output placeholder
                            </TitleGrid>
                            <SubTitleTextGrid item xs={12}>
                                The entire raw payload will be outputted as this placeholder. You may need to use a JSON parser or the AI component to extract specific values from this payload.{" "}
                            </SubTitleTextGrid>
                            <Grid item xs={12}>
                                <W86PlaceholderTextField
                                    value={props.componentStore.data.samplePlaceholders[0].key}
                                    error={errorMessage}
                                    helperText={errorMessage}
                                    placeholderText="Enter placeholder for item"
                                    handleChange={(event) => {
                                        const val = replacePlaceholderValue(event.target.value);

                                        // Check the placeholder key
                                        const check = checkKey(val);
                                        if (check && check[0] && check.length > 1) {
                                            setErrorMessage(check[1]);
                                            return;
                                        } else setErrorMessage("");

                                        props.componentStore.setRawResponsePH(val);
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <Grid container rowSpacing={1}>
                            <TitleGrid item xs={12}>
                                🔑 Security token
                            </TitleGrid>
                            <SubTitleTextGrid item xs={12}>
                                Generate a token and then set that in the header (recommended) of the incoming request or in the endpoint URL.{" "}
                                <a href="https://docs.workflow86.com/docs/components/webhook_import/#how-to-set-the-token" style={{ color: "#2196F3", textDecoration: "none" }} target="_blank">
                                    Learn more
                                </a>
                            </SubTitleTextGrid>
                            <Grid item xs={12}>
                                <GenerateToken
                                    hasToken={(status) => {
                                        changeTokenStatus(status);
                                    }}
                                    tokenStatus={props.hasToken}
                                    name={props.name}
                                    component_id={props.componentId}
                                    isAlignCenter={false}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <WarningBox
                                    text={
                                        hasToken
                                            ? "A token has already been generated. If you have lost this token, you will need to generate a new one."
                                            : "A token must be generated before you can continue"
                                    }
                                    width="100%"
                                    horizontal="flex-start"
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container display="flex" alignItems="flex-end" justifyContent="flex-end">
                    <Grid item>
                        <Button onClick={() => props.onChangeState(0, 0)} className="btnSpace" variant={"outlined"} color={"info"}>
                            BACK
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default OpenEndpointStep;
