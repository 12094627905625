import React from "react";
import { Fade, Grid, MenuItem, Select, Typography } from "@mui/material";
import AIBuilderStyles from "../../../../AIBuilderStyles";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { AutoAwesome } from "@mui/icons-material";

const BuildComponentMessage = ({
  classes,
  sidebarState,
  setSidebarState,
  messageSent,
}) => {
  return (
    <Fade in timeout={1000}>
      <div className={classes.gpt}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Select
              onChange={(e) => {
                setSidebarState(e.target.value);
              }}
              variant={"outlined"}
              value={sidebarState}
              size="small"
              disabled={messageSent}
            >
              <MenuItem value="EDIT"> Edit this Component </MenuItem>
              <MenuItem value="BUILD"> Build new Component </MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12}>
            <Typography>
              <span className={"bold"}>
                <AutoAwesome
                  style={{
                    verticalAlign: "bottom",
                    height: "20px",
                    width: "20px",
                    marginRight: "8px",
                    filter: "brightness(0) saturate(100%)",
                  }}
                />
                Build New Component
              </span>
            </Typography>
          </Grid>

          <Grid item xs={12}>
            I will build a new component based on your instructions. Note this will overwrite any existing configuration or settings in the component.
          </Grid>
          <Grid item xs={12}>
            <b>💡 Tips</b>
          </Grid>
          <Grid item xs={12}>
            For the best results, include as much information as possible or be
            specific with your requirements for you what you want this component
            to do.
          </Grid>
        </Grid>
      </div>
    </Fade>
  );
};

BuildComponentMessage.propTypes = {
  classes: PropTypes.object.isRequired,
  setSidebarState: PropTypes.func.isRequired,
  sidebarState: PropTypes.string.isRequired,
  messageSent: PropTypes.bool.isRequired,
};

export default withStyles(AIBuilderStyles())(BuildComponentMessage);
