import React, { Component } from "react";
import Alert from "@mui/material/Alert";
import { Auth } from "aws-amplify";
import Snackbar from "@mui/material/Snackbar";
import { Navigate } from "react-router-dom";
import { withParams } from "../../Routes";

class ConfirmUser extends Component {
  state = {
    authData: "",
    openError: false,
    openConfirm: false,
    verify: false,
    status: false,
    confirmMessage: "",
    errorMessage: ""
  };

  componentDidMount() {
    const clientId = new URLSearchParams(this.props.location.search).get(
      "client_id"
    );
    const userName = new URLSearchParams(this.props.location.search).get(
      "user_name"
    );
    const code = new URLSearchParams(this.props.location.search).get(
      "confirmation_code"
    );
    // this.props.onChangeMessage('Your account has been verified');
    Auth.confirmSignUp(userName, code)
      .then(() => {
        this.setState({
          confirmMessage: "Your account has been verified",
          openConfirm: true
        });
      })
      .catch((e) => {
        this.setState({
          confirmMessage: "Your account has been verified",
          openConfirm: true
        });
      });
  }

  handleClose = () => {
    const { openError, openConfirm } = this.state;
    if (openError) {
      this.setState({ openError: false, verify: true });
    } else if (openConfirm) {
      this.setState({ openConfirm: false, verify: true });
    }
  };

  render() {
    let {
      errorMessage,
      openError,
      openConfirm,
      confirmMessage,
      verify
    } = this.state;
    if (verify) {
      return <Navigate to="/" />;
    }
    return (
      <div>
        {openError && (
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={this.state.openError}
            autoHideDuration={6000}
            onClose={this.handleClose}
            key={"top" + "center"}
          >
            <Alert onClose={this.handleClose} severity="error" variant="filled">
              {errorMessage}
            </Alert>
          </Snackbar>
        )}
        {openConfirm && (
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={this.state.openConfirm}
            autoHideDuration={6000}
            onClose={this.handleClose}
            key={"top" + "center"}
          >
            <Alert
              onClose={this.handleClose}
              severity="success"
              variant={"filled"}
            >
              {confirmMessage}
            </Alert>
          </Snackbar>
        )}
      </div>
    );
  }
}

export default withParams(ConfirmUser);
