import React, { useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";

import { Link } from "react-router-dom";
import { renderIcon } from "./Util";

// Material UI
import RefreshIcon from "@mui/icons-material/Refresh";
import AssignmentIcon from "@mui/icons-material/Assignment";
import HistoryIcon from "@mui/icons-material/History";
import EventIcon from "@mui/icons-material/Event";
import { styled } from "@mui/styles";
import {Button, CircularProgress, Grid, Snackbar, Alert } from "@mui/material";
import { send_request, send_request_graphql } from "../../../utils/Request";
import {CancelPresentation, Code, DataObject, Fingerprint, List, SubdirectoryArrowRight} from "@mui/icons-material";
import ReRunSelectionBox from "../ProjectLog/ReRunSelectionBox";
import ConfirmTerminateSessionDialog from "../ProjectLog/ConfirmTerminateSessionDialog";
import {CopyToClipboard} from "react-copy-to-clipboard";
import OpenExistingSession from "./OpenExistingSession";

const TopGridContainer = styled(Grid)({
    position: "absolute",
    paddingTop: "24px",
    paddingLeft: "32px",
    zIndex: "999 !important",
    boxSizing: "border-box",
});

const WorkflowNameGrid = styled(Grid)({
    fontSize: "24px",
    fontWeight: 400,
});

const InfoContainer = styled(Grid)({
    fontSize: "14px",
});

const InfoItem = styled(Grid)({
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
});

const ButtonContainer = styled(Grid)({
    marginTop: "4px",
});


const Refresh = styled(RefreshIcon)({
    width: "18px",
    height: "auto",
    marginRight: "8px",
});

const History = styled(HistoryIcon)({
    width: "18px",
    height: "auto",
    marginRight: "8px",
});

const Event = styled(EventIcon)({
    width: "18px",
    height: "auto",
    marginRight: "8px",
});

const Loader = styled(CircularProgress)({
    position: "absolute",
    color: "rgba(33, 150, 243, 0.55)",
});

const TerminateButton = styled(Button)(({ isLatestRun }) => ({
    backgroundColor: isLatestRun ? "#B00020" : "rgba(218, 218, 218, 1)",
    color: "#FFF",
    display: "flex",
    justifyContent: "center",
    border: `1px solid ${isLatestRun ? "#B00020" : "rgba(218, 218, 218, 1)"}`,
    padding: "5px 28px",
    "&:hover": {
        background: "rgba(176,0,32, 0.90)",
    },
    "&:disabled": {
        background: "rgba(0, 0, 0, 0.1)",
        color: "#FAFAFA",
    },
}));

const Cancel = styled(CancelPresentation)({
    color: "#FFF",
    height: "auto",
    width: "16px",
    marginRight: "8px",
});

const SessionArrow = styled(SubdirectoryArrowRight)(({ margin }) => ({
    height: "22px",
    width: "auto",
    margin: margin
}))


function TopGrid({ workflow, sessionData, projectId, sessionId, getSessionData, setModalData, showTerminateButton, isTest, testId, setLoadReRun, isLatestRun, setIsLatestRun, reRuns, setSessionData,productionVersion, draftVersion, isPolling, setIsPolling, isSplitView, navigate }) {

    const [refreshing, setRefreshing] = useState(false);
    const [terminateLoading, setTerminateLoading] = useState(false);
    const [openTerminateDialog, setOpenTerminateDialog] = useState(false);
    const[showCopySnackbar, setShowCopySnackBar] = useState(false);

    const handleCloseSnackbar = () => {
        setShowCopySnackBar(false);

    };

    const capitaliseFirstLetter = (s) => {
        try {
            let lowerS = s.toLowerCase();
            return lowerS.charAt(0).toUpperCase() + lowerS.slice(1);
        } catch (e) {
            return s;
        }
    };


     const getVersionBehindText = (draftVersion,version,projectId,isProd=false) => {
             if (!draftVersion || !version) return;
             let versionDiff = parseInt(draftVersion - version);
             let aheadFlag = false;

             if (versionDiff < 0) {
                 aheadFlag = true;
                 versionDiff = versionDiff *- 1;
             }

             let url =`/project/canvas/${projectId}`
             if (isProd) url =`/project/production/${projectId}`
             
             switch (versionDiff) {
                 case 0:
                     return <>Same version as 
                     <Link to={url} style={{textDecoration: "none", color: "rgba(33, 150, 243, 1)",paddingLeft:"5px"}}>
                        {isProd ? "Production" : "Draft" }
                    </Link></>;
                 case 1:
                     return (
                         <>
                             1 version {aheadFlag ? "ahead" : "behind"}
                             <Link to={url}
                                   style={{textDecoration: "none", color: "rgba(33, 150, 243, 1)",paddingLeft:"5px"}}>
                                 {isProd ? "Production" : "Draft"}
                             </Link>
                         </>
                     );
                 default:
                     return (
                         <>
                             {versionDiff} versions {aheadFlag ? "ahead" : "behind"}
                             <Link to={url}
                                   style={{textDecoration: "none", color: "rgba(33, 150, 243, 1)",paddingLeft:"5px"}}>
                                 {isProd ? "Production" : "Draft"}

                             </Link>
                         </>
                     );
             }

     };


    // Calculate the latest event date
    const calculateLastEvent = () => {
        let relativeDate = moment(sessionData.lastUpdated).fromNow();
        if (relativeDate === "Invalid date") return "NaN ago";
        return relativeDate;
    };

    const terminateSession = async (projectSessionId) => {
        if (!projectSessionId) return;
        setTerminateLoading(true);

        let url = `project-service/project/terminate/${projectSessionId}`;

        return send_request(url, "", {isTest: isTest}, "post")
            .then((res) => {
                getSessionData();
            })
            .then(() => {
                setTerminateLoading(false);
                setModalData({});
            })
            .catch((err) => {
                console.log(err);
                setTerminateLoading(false);
            });
    };

    const getRedirectUrl = () => {
        if (isTest) return `/test_workflow/test_result/${testId}/${sessionId}`
        else return `/project/logs/${projectId}/${sessionId}`
    }

    return (
        <TopGridContainer container rowSpacing={"12px"}>
            <WorkflowNameGrid item xs={12}>
                {workflow.name}
            </WorkflowNameGrid>
            <ButtonContainer item xs={12}>
                <Grid container spacing={1}>
                    {!isSplitView && <Grid item>
                        <Button
                            onClick={() => {
                                // Navigate
                                const url = `/project/logs/${projectId}?test=${Boolean(isTest)}&viewSession=${sessionId}`;
                                navigate(url);
                            }}
                            variant="outlined"
                            startIcon={<List />}
                        >
                            All Sessions
                        </Button>
                    </Grid>}
                    <Grid item>
                        <Button
                            disabled={refreshing || isPolling}
                            onClick={() => {
                                setIsPolling(true);
                            }}
                            variant="outlined"
                            startIcon={<Refresh />}
                        >
                            Refresh {refreshing && <Loader size={24}/>}
                        </Button>
                    </Grid>
                    <Grid item>
                        <Link to={getRedirectUrl()} style={{ textDecoration: "none" }} target={"_blank"}>
                            <Button variant="outlined" startIcon={<DataObject /> }>
                                Session Data
                            </Button>
                        </Link>
                    </Grid>
                    {showTerminateButton && (
                        <Grid item>
                            <TerminateButton
                                onClick={() => {
                                    setOpenTerminateDialog(true);
                                }}
                                disabled={terminateLoading || !isLatestRun}
                                startIcon={<Cancel />}
                                isLatestRun={isLatestRun}
                            >
                                Terminate Session
                                {terminateLoading && <Loader size={24}/>}
                            </TerminateButton>
                        </Grid>
                    )}
                </Grid>
            </ButtonContainer>
            <InfoContainer item xs={12}>
                <Grid container spacing={1}>
                    <InfoItem item>
                        <Button
                            size={"small"}
                            style={{backgroundColor: "black", border: "none"}}
                            variant={"contained"}
                            className="draftVersionButton"
                        >
                            <Code style={{color: "white"}}/>
                            <span style={{paddingLeft: "5px"}}>
                            <Link to={`/project/view_canvas/${projectId}&version=${workflow.version}`} target="_blank"
                                    rel="noopener noreferrer" style={{color: "white", textDecoration: "none"}}>
                            Version {workflow.version}
                        </Link>
                            </span>
                        </Button>
                    </InfoItem>
                    <InfoItem item>
                        {getVersionBehindText(productionVersion,workflow.version,projectId,true)}
                    </InfoItem>
                    <InfoItem item>
                        {getVersionBehindText(draftVersion,workflow.version,projectId)}
                    </InfoItem>
                </Grid>
            </InfoContainer>
            <InfoContainer item xs={12}>
                <Grid container spacing={1}>
                    {isTest && 
                        <InfoItem item>
                            <SessionArrow margin="0px 4px 0px 0px" />
                            <Button
                                size={"small"}
                                className={"test-session-chip"}
                                variant={"outlined"}
                            >
                                <span style={{ fontSize: "14px", fontWeight: "bold" }}>
                                    Test session
                                </span>
                            </Button>           
                        </InfoItem>
                    }
                    <InfoItem item>
                        {!isTest && <SessionArrow margin="0px 4px 0px 0px" />}
                        <CopyToClipboard text={sessionData.projectSessionId} onCopy={setShowCopySnackBar}>
                        <Button
                            size={"small"}
                            style={{backgroundColor: "#E8EAFE", border: "1px solid #2137FF"}}
                            variant={"outlined"}
                            className="draftVersionButton"
                        >
                            <Fingerprint/>
                            <span style={{paddingLeft: "5px"}}>
                        Session {sessionData.projectSessionId}
                        </span>
                        </Button>
                        </CopyToClipboard>

                    </InfoItem>
                    <InfoItem item>
                        <Event/>
                        Last event {calculateLastEvent()}
                    </InfoItem>
                </Grid>
            </InfoContainer>
            {reRuns !== null && reRuns.length > 0 &&
                <InfoContainer item xs={12}>
                    <Grid container spacing={2}>
                        <InfoItem item>
                            <SessionArrow margin="0px 4px 0px 32px" />
                            <ReRunSelectionBox
                                reRuns={reRuns}
                                session={sessionData}
                                setSession={setSessionData}
                                isLatestRun={isLatestRun}
                                setIsLatestRun={setIsLatestRun}
                                calculateTimeElapsed={(a) => {
                                    return a;
                                }}
                                setLoadReRun={setLoadReRun}
                                isProgressView={true}
                            />
                        </InfoItem>
                    </Grid>
                </InfoContainer>
            }
            {sessionData.existingSessionIdRanFrom &&
                <OpenExistingSession 
                    sessionId={sessionData.existingSessionIdRanFrom} 
                    projectId={projectId}
                    InfoContainer={InfoContainer} />
            }
            {openTerminateDialog &&
                <ConfirmTerminateSessionDialog isOpen={openTerminateDialog} close={() => setOpenTerminateDialog(false)}
                                               sessionId={sessionId} terminateSession={terminateSession}/>
            }
            {showCopySnackbar  && (
                <Snackbar
                    open={showCopySnackbar}
                    onClose={handleCloseSnackbar}
                    autoHideDuration={1000}
                    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                    style={{
                        backgroundColor:"black",
                    }}
                >
                    <Alert
                        variant="outlined"
                        icon={false}
                        sx={{border:"none"}}
                    >
                        <span style={{color:"white",fontWeight:"500"}}>
                         ✂️ Session ID copied to clipboard
                            </span>
                    </Alert>
                </Snackbar>
            )}
        </TopGridContainer>
    );
}

TopGrid.propTypes = {
    workflow: PropTypes.object.isRequired,
    sessionData: PropTypes.object.isRequired,
    projectId: PropTypes.string.isRequired,
    sessionId: PropTypes.string.isRequired,
    getSessionData: PropTypes.func.isRequired,
};

export default TopGrid;
