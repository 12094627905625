import React, { useState, useEffect } from "react";
//mui
import { Grid, Fade } from "@mui/material";
//styling
import { withStyles } from "@mui/styles";
import SuggestionBox from "./SuggestionBox";
import { Sync } from "@mui/icons-material";
import { determineAIEventSource } from "../../ChatStateManager";
import { send_request } from "../../../../../../../../utils/Request";

const styles = () => ({
    container: {
        color: "#686868",
        fontSize: "12px",
        cursor: "pointer"
    }
});

const Suggestions = ({ classes, setUserPrompt, sidebarState }) => {
    const [suggestionsJson, setSuggestionsJson] = useState(null);
    const [suggestions, setSuggestions] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const url = `project-service/project-ai/assistant/retrieveSuggestions/${determineAIEventSource(sidebarState)}`;
                const response = await send_request(url, null, null, "GET");
                if (response && response.data) {
                    setSuggestionsJson(response.data);
                    setSuggestions(pickRandomSuggestion(response.data));
                }
            } catch (error) {
                console.error("Error fetching suggestions:", error);
            }
        };

        fetchData();
    }, [sidebarState]);

    const pickRandomSuggestion = (data) => {
        // no data -> no suggestions
        if (!data) return [];
        const useCases = data.useCases;
        const shuffled = useCases.slice(0);
        let result = [];
        for (let i = 0; i < 3; i++) {
            //get a random index from the array
            let randomIndex = Math.floor(Math.random() * shuffled.length);
            //add it to shuffled result
            result.push(shuffled[randomIndex]);
            //remove from the array to avoid duplicates
            shuffled.splice(randomIndex, 1);
        }

        return result;
    };

    const handleShuffle = () => {
        setSuggestions(pickRandomSuggestion(suggestionsJson));
    };

    return (
        <Fade timeout={1000} in={true}>
            <div>
                <Grid container rowGap={2}>
                    Suggestions
                    {suggestions &&
                        suggestions.map((item) => (
                            <SuggestionBox
                                key={item.title}
                                title={item.title}
                                description={item.description}
                                setUserPrompt={setUserPrompt}
                            />
                        ))}
                    {suggestions &&  sidebarState!=="WORKFLOW_EDITOR" && <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        className={classes.container}
                        onClick={handleShuffle}
                    >
                        <Sync fontSize="small"/> Shuffle suggestions
                    </Grid>
                    }
                </Grid>
            </div>
        </Fade>
    );
};

export default withStyles(styles)(Suggestions);
