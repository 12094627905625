import React, {Component} from 'react';
import ErrorIcon from "@mui/icons-material/Error";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import {toJS} from "mobx";
import {CopyToClipboard} from "react-copy-to-clipboard";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import PlaceholderJson from "../../Component/PlaceholderJson";
import GenerateToken from "../../Component/GenerateToken";
import {Grid} from "@mui/material";

class Summary extends Component {

  constructor(props) {
    super(props);
    this.state = {
      hasToken: this.props.hasToken
    };
  }

  changeTokenStatus = (status) => {
    this.setState({hasToken: status})
    this.props.changeTokenStatus(status)
  }

  render() {
    const { data } = toJS(this.props.componentStore);

    return (
      <Grid item xs={12} className="textAlignLeftWebhook">
        <Grid container>
          <Grid item xs={12}>
            ✅ Set up has been completed for this Webhook Import component. A summary of this component is detailed below.
          </Grid>
          <Grid item xs={12} className="webhook-title">
            <Grid item xs={12}>
              <span>When a webhook is sent to this URL</span>
            </Grid>
            <Grid item xs={12} marginTop={this.props.isExp ? 0 : "24px"}>
              <TextField
                disabled={true}
                value={
                  "https://webhook.workflow86.com/webhook/" +
                  this.props.component_id
                }
                className={`key_padding ${this.props.isExp && "textFieldWebhook"}`}
                variant="outlined"
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <CopyToClipboard
                        text={
                          "https://webhook.workflow86.com/webhook/" +
                          this.props.component_id
                        }
                      >
                        <FileCopyIcon className="custom-icon-btn"/>
                      </CopyToClipboard>
                    </InputAdornment>
                  )
                }}
              />
            </Grid>
          </Grid>

          <Grid item xs={12} className="generateWebhook">
            <span>The token must be set in the header (recommended) or in the URL of the endpoint. See further instructions <a
              href="https://docs.workflow86.com/docs/components/webhook_import/#how-to-set-the-token" style={{color:"#2196F3"}} target="_blank">here</a></span>
          </Grid>
          {
            <Grid item xs={12} className="generateWebhook">
              {
                !this.state.hasToken ?
                  (
                    <span className="err-text" style={{justifyContent:"flex-start",fontWeight:"bold"}}>
                      <ErrorIcon className="iconDiscard" />
                      A token must be generated before the Webhook Import component can be used.
                    </span>
                  )
                  :
                  (
                    <span className="err-text" style={{justifyContent:"flex-start",fontWeight:"bold"}}>
                      <ErrorIcon className="iconDiscard" />
                      A token has already been generated. If you have lost this token, you will need to generate a new one.
                    </span>
                  )
              }

              <GenerateToken hasToken={(status) => {this.changeTokenStatus(status)}} tokenStatus={this.props.hasToken} name={toJS(this.props.ComponentName)} component_id={this.props.component_id} />

            </Grid>
          }
          <Grid item xs={12} className="webhook-title">
            <span>The following placeholders will be generated from the incoming data </span><span onClick={() => this.props.onChangeState(3,3)} style={{color:"#2196F3",fontWeight:"normal",cursor: "pointer"}}>edit placeholders</span>
          </Grid>
          <PlaceholderJson
          componentStore={this.props.componentStore}
          data={toJS(this.props.componentStore).data}
          stepStatus={this.props.stepStatus} 
          placeholderJson={data.samplePlaceholders} />
        </Grid>
        <Grid item xs={12} className="dialogContentRight">
          <Grid item xs={12}>
            <Button
              onClick={() => {this.props.onChangeState(5,5)}}
              className="btnSpace"
              variant={"outlined"}  
              color={"info"}

            >
              {" "}
              BACK
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default Summary;