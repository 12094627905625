import React, { useState, useEffect } from "react";

import { CardTitle, DashboardCard, StyledSkeleton, StyledButton, CenterGrid, StyledGrid } from "./CommonComponents";
import { send_request } from "../../../utils/Request";
import TaskChip from "../../Task/TaskChip";

// MUI
import { Grid, styled, Tooltip, Table, TableBody as Body, TableCell as Cell, TableHead as Head, TableRow as Row, TableSortLabel as SortLabel } from "@mui/material";
import ArchiveIconOutlined from "@mui/icons-material/Archive";
import { getProjectInfoWithComponentId } from "../../../utils/Requests/Projects";
import config from "../../../config";

const ToTextCell = styled(Cell)(({ task }) => ({
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "10%",
    maxWidth: "200px",
    color: task.overDue && task.status === "TODO" && "#E10000",
    fontWeight: task.overDue && task.status === "TODO" && "bold",
    paddingLeft: "0px",
}));

const TaskSubjectCell = styled(Cell)(({ task }) => ({
    color: task.overDue && task.status === "TODO" ? "#E10000" : "",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "600px",
    paddingRight: "0px",
    paddingLeft: "0px",
}));

const TaskRow = styled(Row)({
    padding: "8px",
    "&:hover": {
        cursor: "pointer",
        background: "rgb(244, 244, 244)",
        transition: "0.3s",
        borderRadius: "4px",
    },
});

const TaskCountBox = styled("span")(({ textColor, bgColor }) => ({
    color: textColor,
    backgroundColor: bgColor,
    borderRadius: "4px",
    fontSize: "20px",
    padding: "0px 8px",
    marginRight: "8px",
}));

const TaskText = styled("span")({
    fontSize: "14px",
    lineHeight: "24px",
    marginLeft: "4px",
});

const HEADER = [
    { id: "status", name: "Status", sortable: false, filter: false },
    { id: "assignedTo", name: "To", sortable: false, filter: false },
    { id: "subject", name: "Subject", sortable: false, filter: false },
];

function TaskSummaryCard() {
    const [displayedTasks, setDisplayedTasks] = useState([]);
    const [todoTaskCount, setTodoTaskCount] = useState(null);
    const [overdueTaskCount, setOverdueTaskCount] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(async () => {
        handleTodoTaskCount();
        handleOverdueTaskCount();
        handleDisplayedTasks();
    }, []);

    useEffect(() => {
        return () => {
            setDisplayedTasks([]);
            setTodoTaskCount(null);
            setOverdueTaskCount(null);
        };
    }, []);

    const handleTodoTaskCount = async () => {
        const json = await send_request("task/task/get_incomplete_task_count", null, null, "GET");
        if (json) setTodoTaskCount(json.data);
    };

    const handleOverdueTaskCount = async () => {
        const json = await send_request("task/task/get_overdue_task_count", null, null, "GET");
        if (json) setOverdueTaskCount(json.data);
    };

    const handleDisplayedTasks = async () => {
        setLoading(true);

        let queryParams = {
            statusArray: ["TODO", "DONE", "TERMINATED", "ERROR"],
        };

        const json = await send_request("task/task/get_tasks/0", null, queryParams, "GET");

        if (json && json.data && json.data.tasks) {
            let resTasks = [];

            for (let j = 0; (resTasks.length < 5 && j < json.data.tasks.length); j++) {
                let task = json.data.tasks[j];
                if (!task.taskSubject || !task.taskDescription) continue;

                let resTask = { ...task };
                
                resTask["overDue"] = false;

                if (resTask.dueDate) {
                    const nowDate = new Date();
                    const nowDataMillis = nowDate.getTime();

                    const dueDate = new Date(resTask.dueDate);
                    const dueDateMillis = dueDate.getTime();

                    if (nowDataMillis > dueDateMillis) {
                        resTask.overDue = true;
                    }
                }
                
                resTasks.push(resTask);
            }

            setDisplayedTasks(resTasks);
        }

        setLoading(false);
    };

    const openTask = async (task) => {
        try {
            let url = `task?status=TODO&status=DONE&status=TERMINATED&status=ERROR&openPanel=${task.taskId}`;
            window.open(url, "_blank");
        } catch (e) {
            alert(e);
        }
    };

    const getTableHead = () => {
        return (
            <Head>
                <Row>
                    {HEADER.map((label, idx) => {
                        return (
                            <Cell className={"removeUnderline"} align={"left"} key={idx} style={{ paddingLeft: "0px" }}>
                                {label.name}
                            </Cell>
                        );
                    })}
                </Row>
            </Head>
        );
    };

    const getTableBody = () => {
        if (!displayedTasks || displayedTasks.length <= 0) {
            return (
                <Body>
                    <p>You have no assigned tasks</p>
                </Body>
            );
        }

        return (
            <Body>
                {displayedTasks.map((task) => {
                    if (!task) return;
                    return (
                        <TaskRow hover onClick={() => openTask(task)}>
                            <Cell
                                className={"removeUnderline"}
                                align={"left"}
                                style={{
                                    overflow: "hidden",
                                    width: "10%",
                                    maxWidth: "150px",
                                    paddingLeft: "0px",
                                }}
                            >
                                <TaskChip overDue={task.overDue} value={task.status} extras={task} />
                            </Cell>
                            <Tooltip title={task.assignedTo ? task.assignedTo.join(", ") : "-"} position="left">
                                <ToTextCell className={"removeUnderline"} align={"left"} task={task}>
                                    {task.assignedTo ? task.assignedTo.join(", ") : "-"}
                                </ToTextCell>
                            </Tooltip>
                            <Tooltip title={task.taskSubject}>
                                <TaskSubjectCell className={"removeUnderline"} align={"left"} task={task}>
                                    {task.taskSubject ? (
                                        <span style={{ fontWeight: "bold" }}>
                                            {task.isExternal && "[External] "} {task.taskSubject}
                                        </span>
                                    ) : (
                                        ""
                                    )}{" "}
                                    {task.taskDescription && task.taskDescription.replace(/(<([^>]+)>)/gi, "")}
                                </TaskSubjectCell>
                            </Tooltip>
                        </TaskRow>
                    );
                })}
            </Body>
        );
    };

    return (
        <DashboardCard container rowGap={2}>
            <Grid item xs={12}>
                <CardTitle>Task summary</CardTitle>
            </Grid>
            <Grid item xs={12}>
                <Grid container columnSpacing={4} marginBottom={2}>
                    <CenterGrid item>
                        {todoTaskCount !== null ? (
                            <TaskCountBox textColor="#FFF" bgColor="rgba(245, 147, 0, 1)">
                                {todoTaskCount}
                            </TaskCountBox>
                        ) : (
                            <StyledSkeleton variant="rectangular" height="30px" width="35px" />
                        )}{" "}
                        <TaskText>To do tasks</TaskText>
                    </CenterGrid>
                    <CenterGrid item>
                        {overdueTaskCount !== null ? (
                            <TaskCountBox textColor="#FFF" bgColor="rgba(225, 0, 0, 1)">
                                {overdueTaskCount}
                            </TaskCountBox>
                        ) : (
                            <StyledSkeleton variant="rectangular" height="30px" width="35px" />
                        )}{" "}
                        <TaskText>Overdue tasks</TaskText>
                    </CenterGrid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <CardTitle>Recent tasks</CardTitle>
            </Grid>
            <Grid item xs={12}>
                <Grid container>
                    <StyledGrid item xs={12} overflow="hidden">
                        {!loading ? (
                            <Table size={"small"} stickyHeader>
                                {getTableHead()}
                                {getTableBody()}
                            </Table>
                        ) : (
                            <Grid container rowGap={2} marginTop={1}>
                                {[1, 2, 3, 4, 5, 6].map(() => {
                                    return (
                                        <Grid item xs={12}>
                                            <StyledSkeleton variant="rectangular" height="40px" />
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        )}
                    </StyledGrid>
                    <CenterGrid item xs={12} marginTop={1}>
                        <StyledButton
                            variant="outlined"
                            startIcon={<ArchiveIconOutlined />}
                            textColor="rgba(33, 150, 243, 1)"
                            hoverTextColor="rgba(33, 150, 243, 0.8)"
                            border="none"
                            onClick={() => window.open("/task?status=TODO", "_target")}
                        >
                            Go to task inbox
                        </StyledButton>
                    </CenterGrid>
                </Grid>
            </Grid>
        </DashboardCard>
    );
}

export default TaskSummaryCard;
