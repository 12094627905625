import React, { useState, useEffect } from "react";

// Util
import { send_request } from "../../../../utils/Request";

// MUI
import { Button, CircularProgress, DialogActions, DialogContent, DialogTitle, Grid } from "@mui/material";
import { runLoading } from "../../../../utils/showLoadingScreen";
import MappingTable from "./MappingTable";
import { CheckCircle, ErrorRounded } from "@mui/icons-material";
import getPlaceholderColors from "vardogyir-ui/PlaceholderColors";
import { BrokenPlaceholder, CenterGrid, Right, StyledAlert, StyledChip, TopText } from "./RenameStyledComponents";

function FixBrokenPlaceholders({ projectId, closeDialog }) {
    const [isLoading, setIsLoading] = useState(true);
    const [isChanging, setIsChanging] = useState(false);
    const [showResultsPage, setShowResultsPage] = useState(false);

    const [brokenPHs, setBrokenPHs] = useState(null);
    const [availablePHs, setAvailablePHs] = useState(null);
    const [values, setValues] = useState(null);

    useEffect(() => {
        if (showResultsPage) return;
        setIsLoading(true);

        let fetchBrokenPHs = getBrokenPHs();
        let fetchAvailablePHs = getAvailablePHs();

        Promise.all([fetchBrokenPHs, fetchAvailablePHs]).then((res) => {
            let broken = res[0];
            let available = res[1];

            if (!broken || broken.data === null) setBrokenPHs([]);
            else setBrokenPHs(broken.data);

            if (!available || available.data === null) setAvailablePHs([]);
            else setAvailablePHs(getPHList(available.data));

            setIsLoading(false);
        });
    }, [showResultsPage]);

    const getBrokenPHs = async () => {
        let url = `project-service/project_placeholder/get_broken_placeholders/${projectId}`;
        let fetchBrokenPHs = await send_request(url, null, null, "GET");
        return fetchBrokenPHs;
    };

    const getAvailablePHs = async () => {
        let url = `component-service/placeholder/${projectId}`;
        let fetchAvailablePHs = await send_request(url, null, null, "GET");
        return fetchAvailablePHs;
    };

    const getPHList = (components) => {
        let res = [];

        for (let i = 0; i < components.length; i++) {
            let pHs = components[i].placeholders;
            if (!pHs) continue;
            res = [...res, ...pHs];
        }

        return res;
    };

    const getLoader = () => {
        return (
            <DialogContent>
                <Grid container marginBottom={2}>
                    <Grid xs={12} direction={"column"} alignItems={"center"} container item>
                        <Grid item>{runLoading()}</Grid>
                        <p style={{ textAlign: "center", whiteSpace: "pre-line" }}>Checking workflow for issues...</p>
                    </Grid>
                </Grid>
            </DialogContent>
        );
    };

    const handleChangeSelected = async () => {
        setIsChanging(true);

        let data = [];
        for (let i = 0; i < brokenPHs.length; i++) {
            let val = values[i];
            if (!val || val.key === "Ignore/Do not change") continue;
            data.push({
                oldKey: brokenPHs[i].key,
                newKey: val.key,
            });
        }

        let url = `project-service/project_placeholder/replace_used_placeholders/${projectId}`;

        send_request(url, data, null, "POST")
            .then(() => {
                setShowResultsPage(true);
                setIsChanging(false);
            })
            .catch((e) => {
                console.log(e);
                setIsChanging(false);
                handleFixMore();
            });
    };

    const handleFixMore = () => {
        setShowResultsPage(false);
        setValues(null);
        setBrokenPHs(null);
    };

    const getPlaceholderChip = (item) => {
        const placeholderColor = getPlaceholderColors(item.type, item.length);
        return <StyledChip backgroundColor={placeholderColor.hover} borderColor={placeholderColor.default} icon={placeholderColor.icon}>{`$\{${item.key}\}`}</StyledChip>;
    };

    const getResultsPageContent = () => {
        let anyReplaced = values !== null && values.filter((val) => val.key !== "Ignore/Do not change").length > 0;
        return (
            <Grid container marginTop={2}>
                <Grid item xs={12}>
                    <StyledAlert
                        severity={anyReplaced ? "success" : "warning"}
                        icon={anyReplaced ? <CheckCircle /> : <ErrorRounded />}
                        borderColor={anyReplaced ? "rgba(85, 167, 122, 1)" : "rgba(255, 167, 34, 1)"}
                        bgColor={anyReplaced ? "rgba(85, 167, 122, 0.1)" : "rgba(255, 167, 34, 0.1)"}
                    >
                        <Grid container>
                            <Grid item>{anyReplaced ? "The following broken placeholders have been successfully remapped" : "No placeholders have been remapped"}</Grid>
                        </Grid>
                    </StyledAlert>
                </Grid>
                <Grid container marginTop={1} rowSpacing={3}>
                    {showResultsPage &&
                        values.map((item, idx) => {
                            if (item.key !== "Ignore/Do not change") {
                                return (
                                    <Grid item xs={12}>
                                        <Grid container>
                                            <Grid item xs>
                                                <BrokenPlaceholder>{`$\{${brokenPHs[idx].key}\}`}</BrokenPlaceholder>
                                            </Grid>
                                            <CenterGrid item xs={1}>
                                                <Right />
                                            </CenterGrid>
                                            <Grid item xs>
                                                {getPlaceholderChip(item)}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                );
                            }
                        })}
                </Grid>
            </Grid>
        );
    };

    const getFixBrokenPlaceholdersContent = () => {
        return (
            <Grid container marginTop={2}>
                <Grid item xs={12}>
                    <TopText>Broken placeholders do not have a source and can be fixed by renaming and re-mapping them to another existing placeholder in the workflow</TopText>
                </Grid>
                <Grid item xs={12}>
                    {brokenPHs && brokenPHs.length > 0 ? (
                        <MappingTable
                            brokenPHs={brokenPHs}
                            availablePHs={availablePHs}
                            isChanging={isChanging}
                            setIsChanging={setIsChanging}
                            values={values}
                            setValues={setValues}
                            projectId={projectId}
                        />
                    ) : (
                        <Grid container marginTop={3}>
                            <Grid item xs={12}>
                                <StyledAlert severity={"warning"} icon={<ErrorRounded />} borderColor={"rgba(255, 167, 34, 1)"} bgColor={"rgba(255, 167, 34, 0.1)"}>
                                    <Grid container>
                                        <Grid item>No broken placeholders found</Grid>
                                    </Grid>
                                </StyledAlert>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </Grid>
        );
    };

    const getContent = () => {
        return (
            <>
                <DialogTitle>🔨 Fix broken placeholders</DialogTitle>
                <DialogContent>{!showResultsPage ? getFixBrokenPlaceholdersContent() : getResultsPageContent()}</DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={closeDialog} disabled={isChanging}>
                        Close
                    </Button>
                    {!showResultsPage ? (
                        <Button variant="contained" onClick={handleChangeSelected} disabled={isChanging || !brokenPHs || brokenPHs.length <= 0}>
                            Change Selected
                            {isChanging && <CircularProgress size={24} className="buttonProgress" />}
                        </Button>
                    ) : (
                        <Button variant="contained" onClick={handleFixMore}>
                            Fix More
                        </Button>
                    )}
                </DialogActions>
            </>
        );
    };

    return !isLoading ? getContent() : getLoader();
}

export default FixBrokenPlaceholders;
