import React from "react";
//mui
import { Grid, Fade } from "@mui/material";
//styling
import { withStyles } from "@mui/styles";

const styles = () => ({
  user: {
    background: "#944BA8",
    borderRadius: " 16px 16px 0px 16px",
    maxWidth: "100%!important",
    padding: "16px",
    width: "fit-content",
    alignSelf: "flex-end",
    wordWrap: "anywhere"
  },
  userText: {
    fontSize: "14px",
    color: "white",
    padding: "0px",
    margin: "0px",
  },
});

const User = ({ content, classes }) => {
  return (
    <Fade timeout={1000} in={true}>
      <Grid container item className={classes.user}>
        <p className={classes.userText}>{content}</p>
      </Grid>
    </Fade>
  );
};

export default withStyles(styles)(User);
