import React from "react";
//mui
import { Grid, Fade } from "@mui/material";

//styles
import { withStyles } from "@mui/styles";

const styles = () => ({
    instruction: {
        maxWidth: "90%!important",
        background: "#F5F5F5",
        borderRadius: "16px 16px 16px 0px",
        width: "fit-content",
        padding: "0px 16px 0px 16px",
    },
});

const ComponentWorkflowInstruction = ({ classes }) => {
    return (
        <Fade timeout={1000} in={true}>
            <Grid className={classes.instruction}>
                <p>
                    <b> Build all components now (recommended)</b>
                </p>
                <p>
                    This workflow has been inserted and saved, but the components still need to be configured. I can automatically configure all of the components for you. Estimated time: 30 to 60 seconds.
                </p>
            </Grid>
        </Fade>
    );
};

export default withStyles(styles)(ComponentWorkflowInstruction);
