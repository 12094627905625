import React, { Component } from "react";

// Util
import { Provider } from "mobx-react";
import { withParams } from "../../Routes";
import { Route, Routes } from "react-router-dom";

// Custom components
import InternalApp from "./InternalApp";
import { CustomSignIn } from "./CustomSignIn";
import PaymentSuccess from "./PaymentSuccess";
import CanvasPreview from "../ProjectCanvas/Canvas/CanvasPreview";
import MarketplaceTemplate from "../Marketplace/MarketplaceTemplate";

// Stores
import PreviewStore from "../ProjectCanvas/PreviewStore";
import CanvasStore from "../pages/Canvas/CanvasStore";

class AuthWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      firstLogin: false
    };
  }

  updateUsername(newUsername) {
    this.setState({ username: newUsername });
  }

  setFirstLogin = (status) => {
    this.setState({
      firstLogin: status
    });
  };

  render() {
    const loggedIn = this.props.authState == "signedIn" ? true : false;
    return (
      <Provider PreviewStore={PreviewStore} CanvasStore={CanvasStore}>
        <Routes>
          <Route path={"/template/:id"} element={<MarketplaceTemplate />} />
          <Route
            path={"/project/canvas_preview/:id"}
            element={<CanvasPreview />}
          />
          {!loggedIn ? (
            <>
              <Route
                path={"/*"}
                element={
                  <CustomSignIn
                    authState={this.props.authState}
                    updateUsername={this.updateUsername}
                    onStateChange={this.props.onStateChange}
                    firstLogin={(status) =>
                      this.setState({ firstLogin: status })
                    }
                  />
                }
              />
            </>
          ) : (
            <>
              <Route
                path={"/*"}
                element={
                  <InternalApp
                    authState={this.props.authState}
                    updateUsername={this.updateUsername}
                    onStateChange={this.props.onStateChange}
                    dialogTour={this.state.firstLogin}
                    setFirstLoginState={(status) => {
                      this.setFirstLogin(status);
                    }}
                  />
                }
              />
              <Route path="/payment_success" element={<PaymentSuccess />} />
            </>
          )}
        </Routes>
      </Provider>
    );
  }
}

export default withParams(AuthWrapper);
