import React, { Component } from "react";
import Grid from "@mui/material/Grid";
import {
  TextField,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
} from "@mui/material";
import Icon from "@mui/material/Icon";
import { toJS } from "mobx";
import checkKey from "../../utils/CheckKey";
import APIResReqStore from "../APIRequestAndResponse/APIResReqStore";
import replacePlaceholderValue from "../../utils/ReplacePlaceholderValue";
import Tooltip from "@mui/material/Tooltip";
import parseJSONStore from "../ParseJSON/ParseJSONStore";

class PlaceholderJson extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checkKey: false,
      indexError: [],
      allPlaceholders: this.props.placeholderJson,
      selectedPlaceholders: [],
      placeholders: [],
      checkAll: false,
      result: [],
      checkState: false,
      placeholderKeys: [],
      errors: [],
    };
  }

  componentDidMount() {
    let placeholderKeys = [], indexError = [],checkKey = false, errors= [], placeholderCheckedObj = {};
    for (let i = 0; i < this.props.placeholderJson.length; i++) {
      const placeholder = this.handleReplacePlaceholder(this.props.placeholderJson[i].key)
      placeholderKeys.push(placeholder)
      if (placeholder.trim().length === 0 ) {
        indexError.push(i);
        checkKey = true
        errors.push({
          index: i,
          errorMessage: "This field cannot be empty"
        })
      }
      
      this.props.componentStore.onChangeField(
        placeholder,
        this.props.placeholderJson[i],
        "key"
      );
    }
    this.handleError(indexError)
    this.setState({
      placeholders: this.props.placeholderJson,
      placeholderKeys,
      indexError,
      checkKey,
      errors,
      placeholderChecked: placeholderCheckedObj
    });
  }


  isJsonString = (str) => {
    try {
      JSON.parse(str);
    } catch (err) {
      this.setState({ errorMessage: "Error with sample: " + err.message });
      return false;
    }
    return true;
  };

  handleReplacePlaceholder = (value) => {
    value = value.replaceAll(" ", "_")
    value = value.replace(/[^a-zA-Z0-9]/g, '_');
    return value
  }

  handleInputKey = (event, index) => {
    const { placeholderJson } = this.props;
    let { placeholderKeys, errors } = this.state;
    placeholderKeys[index] = this.handleReplacePlaceholder(event.target.value)

    const placeholderValue = replacePlaceholderValue(event.target.value)

    let value = [false, ""]
    if (placeholderKeys[index].trim().length === 0 ) {
      value = [true, "This field cannot be empty"]
    } 

    this.setState({ placeholderValue, placeholderKeys });

    if (value[0] == true && !this.state.indexError.includes(index)) {
      this.state.indexError.push(index);
    } else if (value[0] == false && this.state.indexError.includes(index)) {
      let i = this.state.indexError.indexOf(index);
      this.state.indexError.splice(i, 1);
    }
    if (this.state.indexError.length > 0) {
      let placeholderError = errors.filter(e => e.index === index)
      if (placeholderError.length > 0){
        const placeholderErrorIndex = errors.indexOf(placeholderError[0])
        placeholderError[0].errorMessage = value[1]
        errors.splice(placeholderErrorIndex,1)
        errors.push({
          index: index,
          errorMessage: value[1]
        })
      }else {
        errors.push({
          index: index,
          errorMessage: value[1]
        })
      }

      this.setState({ checkKey: true, errors });
    } else {

      let placeholderError = errors.filter(e => e.index === index)
      if (placeholderError.length > 0){
        const placeholderErrorIndex = errors.indexOf(placeholderError[0])
        errors.splice(placeholderErrorIndex,1)
      }

      this.setState({ checkKey: false, errors });
    }
    this.handleError(this.state.indexError)
  };

  handleCheckKey = (event, index, placeholder) => {
    const { placeholderJson } = this.props;
    const value = checkKey(event, index, placeholderJson);

    if (value[0] == true && !this.state.indexError.includes(index)) {
      this.state.indexError.push(index);
    } else if (value[0] == false && this.state.indexError.includes(index)) {
      let i = this.state.indexError.indexOf(index);
      this.state.indexError.splice(i, 1);
    }
    if (this.state.indexError.length > 0) {
      this.setState({ checkKey: true });
    } else {
      this.setState({ checkKey: false });
    }
  };

  handleCheckAll = () => {
    if (this.state.checkAll) {
      this.setState({ checkAll: false });
      let placeholders = this.props.placeholderJson;

      if (this.props.componentStore == APIResReqStore) {
        for (let i = 0; i < placeholders.length; i++) {
          this.props.componentStore.responseData.samplePlaceholders[
            i
          ].checked = false;
        }
      } 
      else if (this.props.componentStore == parseJSONStore) {
        for (let i = 0; i < placeholders.length; i++) {
          this.props.componentStore.componentData.samplePlaceholders[
            i
          ].checked = false;
        }
      }
      else {
        for (let i = 0; i < placeholders.length; i++) {
          this.props.componentStore.data.samplePlaceholders[i].checked = false;
        }
      }
    } else {
      this.setState({ checkAll: true });

      let placeholders = this.props.placeholderJson;

      if (this.props.componentStore == APIResReqStore) {
        for (let i = 0; i < placeholders.length; i++) {
          this.props.componentStore.responseData.samplePlaceholders[
            i
          ].checked = true;
        }
      } 
      else if (this.props.componentStore == parseJSONStore) {
        for (let i = 0; i < placeholders.length; i++) {
          this.props.componentStore.componentData.samplePlaceholders[
            i
          ].checked = true;
        }
      }
      else {
        for (let i = 0; i < placeholders.length; i++) {
          this.props.componentStore.data.samplePlaceholders[i].checked = true;
        }
      }
    }
  };

  handleError = (indexError) => {
    this.props.componentStore.storeError(indexError)
  }

  handleCheck = (placeholder) => {
    let placeholders = this.props.placeholderJson;
    let index = placeholders.findIndex((x) => x.key === placeholder.key);
    if (this.props.componentStore == APIResReqStore) {
      let checked = toJS(this.props.componentStore).responseData
        .samplePlaceholders[index].checked;
      this.props.componentStore.responseData.samplePlaceholders[index].checked =
        !checked;
    }
    else if (this.props.componentStore == parseJSONStore) {
      let checked = toJS(this.props.componentStore).componentData
        .samplePlaceholders[index].checked;
      this.props.componentStore.componentData.samplePlaceholders[index].checked =
        !checked;
    }
    else {
      let checked = toJS(this.props.componentStore).data.samplePlaceholders[
        index
      ].checked;
      this.props.componentStore.data.samplePlaceholders[index].checked =
        !checked;
    }
  };


  render() {
    const { stepStatus } = this.props;
    const { checkState, errors } = this.state;
    let placeholders = this.props.placeholderJson;
    const rows = [
      "Key from sample",
      "Data from sample",
      "Will be exported as this placeholder",
    ];
    return (
      <Grid xs={12} container item>
        {placeholders.length > 0
          ? stepStatus == "setPlaceholders" && (
              <span className="general-span">
                Select which of the following placeholders should be exported
                from this component.
              </span>
            )
          : ""}
        <Table>
          <TableHead>
            <TableRow>
              {placeholders.length > 0
                ? stepStatus == "setPlaceholders" && (
                    <TableCell className={"table-cell"}>
                      <Checkbox
                        className={"placeholder-json-checkbox"}
                        onChange={this.handleCheckAll}
                        checked={this.state.checkAll}
                      />
                    </TableCell>
                  )
                : ""}
              {stepStatus != "setPlaceholders" && checkState
                ? rows.map((header, index) => {
                    return (
                      <>
                        <TableCell className={"table-cell"}>
                          <p>{header}</p>
                        </TableCell>
                      </>
                    );
                  })
                : ""}

              {placeholders.length > 0 && stepStatus == "setPlaceholders"
                ? rows.map((header, index) => {
                    return (
                      <>
                        <TableCell className={"table-cell"}>
                          <p>{header}</p>
                        </TableCell>
                      </>
                    );
                  })
                : ""}
            </TableRow>
          </TableHead>
          <TableBody>
            {placeholders.map((placeholder, index) => {
              return (
                <TableRow>
                  {stepStatus == "setPlaceholders" ? (
                    <TableCell className={"table-cell"}>
                      <Checkbox
                        className={"placeholder-json-checkbox"}
                        checked={placeholder.checked}
                        onChange={(e) => {
                          this.handleCheck(placeholder);
                        }}
                      />
                    </TableCell>
                  ) : (
                    ""
                  )}
                  {stepStatus != "setPlaceholders" && placeholder.checked ? (
                    <TableCell className={"table-cell"}>
                      <TextField
                        value={placeholder.sampleKey}
                        placeholder={"Insert key value here"}
                        variant="outlined"
                        disabled={true}
                        InputProps={{
                          style: { color: "black", backgroundColor: "#EEEEEE" },
                        }}
                        className="textfieldPaddingJson key_padding"
                      />
                    </TableCell>
                  ) : (
                    ""
                  )}

                  {stepStatus != "setPlaceholders" && placeholder.checked ? (
                    <TableCell className={"table-cell"}>
                      <TextField
                        value={placeholder.sampleData}
                        placeholder={"Insert key value here"}
                        variant="outlined"
                        InputProps={{
                          style: { color: "black", backgroundColor: "#EEEEEE" },
                        }}
                        disabled={true}
                        className="textfieldPaddingJson key_padding"
                      />
                    </TableCell>
                  ) : (
                    ""
                  )}

                  {stepStatus == "setPlaceholders" ? (
                    <TableCell className={"table-cell"}>
                      <TextField
                        value={placeholder.sampleKey}
                        placeholder={"Insert key value here"}
                        variant="outlined"
                        disabled={true}
                        InputProps={{
                          style: { color: "black", backgroundColor: "#EEEEEE" },
                        }}
                        className="textfieldPaddingJson key_padding"
                      />
                    </TableCell>
                  ) : (
                    ""
                  )}

                  {stepStatus == "setPlaceholders" ? (
                    <TableCell className={"table-cell"}>
                      <TextField
                        value={placeholder.sampleData}
                        placeholder={"Insert key value here"}
                        variant="outlined"
                        InputProps={{
                          style: { color: "black", backgroundColor: "#EEEEEE" },
                        }}
                        disabled={true}
                        className="textfieldPaddingJson key_padding"
                      />
                    </TableCell>
                  ) : (
                    ""
                  )}
                  {stepStatus == "setPlaceholders" ? (
                    <TableCell className={"table-cell"}>
                      <TextField
                        container
                        fullWidth={true}
                        placeholder={"placeholder"}
                        variant="outlined"
                        defaultValue={this.state.placeholderKeys[index]}
                        value={this.state.placeholderKeys[index]}
                        error={
                          this.state.checkKey &&
                          this.state.indexError.includes(index)
                            ? true
                            : false
                        }
                        InputProps={{
                          endAdornment: this.state.checkKey &&
                            this.state.indexError.includes(index) && (
                              <Tooltip title={errors[index] ? errors[index].errorMessage : ""} placement="right">
                                <Icon
                                  className="fa fa-exclamation-circle"
                                  style={{ color: "#f44336" }}
                                />
                              </Tooltip>
                            ),
                        }}
                        onChange={(event) =>
                          this.handleInputKey(event, index)
                        }
                        onBlur={(event) => {
                          this.props.componentStore.onChangeField(
                            event.target.value,
                            placeholder,
                            "key",
                            index
                          );
                        }}
                        className="textfieldPaddingJson key_padding"
                      />
                    </TableCell>
                  ) : (
                    ""
                  )}

                  {stepStatus != "setPlaceholders" && placeholder.checked ? (
                    <TableCell className={"table-cell"}>
                      <TextField
                        fullWidth={true}
                        placeholder={"placeholder"}
                        value={this.state.placeholderKeys[index]}
                        variant="outlined"
                        error={this.state.checkKey ? true : null}
                        InputProps={{
                          endAdornment: this.state.checkKey && (
                            <Icon
                              className="fa fa-exclamation-circle"
                              style={{ color: "#f44336" }}
                            />
                          ),
                          style: { color: "black" },
                        }}
                        disabled={true}
                        onChange={(event) => {
                          this.props.componentStore.onChangeField(
                            event.target.value,
                            placeholder,
                            "key"
                          );
                          this.handleCheckKey(event, index, placeholder);
                        }}
                        className="textfieldPaddingJson key_padding "
                      />
                    </TableCell>
                  ) : (
                    ""
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Grid>
    );
  }
}

export default PlaceholderJson;