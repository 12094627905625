import React, { useEffect, useRef } from "react";

// util
import { useAIOnboardingContext } from "../OnboardingContextProvider";

// mui
import { Grid } from "@mui/material";
import Loading from "../../../pages/Canvas/AIBuilder/components/Chat/Messages/Loading";
import GPTResponse from "../../../pages/Canvas/AIBuilder/components/Chat/Messages/GPTResponse";

// Messages
import UserInfoMessage from "./UserInfoMessage";
import ChoiceMadeLoadingMessage from "./ChoiceMadeLoadingMessage";
import CompanyInfoMessage from "./CompanyInfoMessage";
import RoleAndTeamInfoMessage from "./RoleAndTeamInfoMessage";

function MessageRendering(props) {
    const bottomRef = useRef(null);

    const { messages, setMessages, loadingBubble, setLoadingBubble, step, firstName } = useAIOnboardingContext();

    useEffect(() => {
        if (step !== 1 && step !== 2) return;

        setMessages([]);
        setLoadingBubble(true);

        let initialMessages = step === 1 ? getStep1Messages() : getStep2Messages();


        if (props.onboardingStatus === "CHOICE_MADE_LOADING") {
            // If we're returning to the choice made loading state, load instantly
            initialMessages.push({
                position: "flex-end",
                content: <ChoiceMadeLoadingMessage classes={props.classes} />,
            });
            initialMessages.push({
                position: "flex-start",
                content: <GPTResponse content={<Grid container>Got it. Just a moment while I work my magic 🪄</Grid>} />,
            });

            setMessages(initialMessages);
        } else if (step === 1 && (props.onboardingStatus === "ROLE_AND_TEAM_INFO" || props.onboardingStatus === "COMPANY_INFO")) {

            // Add the role and team info message
            initialMessages.push({
                position: "center",
                content: <RoleAndTeamInfoMessage classes={props.classes} position={5} />,
            })

            // If company info, add that message aswell
            if (props.onboardingStatus === "COMPANY_INFO") {
                initialMessages.push({
                    position: "center",
                    content: <CompanyInfoMessage classes={props.classes} position={6} />,
                })
            }

            setMessages(initialMessages);
            setLoadingBubble(false);
        } else {
            initialMessages.forEach((msg, i) => {
                setTimeout(() => {
                    setMessages((prevMessages) => [...prevMessages, msg]);
                    if (i === initialMessages.length - 1) setLoadingBubble(false);
                }, i * 1000); // 1 second delay between each message
            });
        }
    }, [step]);

    useEffect(() => {
        setTimeout(() => {
            bottomRef.current?.scrollIntoView({ behavior: "smooth" });
        }, 5);
    }, [messages, loadingBubble]);

    const getStep1Messages = () => {
        let messages = [
            {
                position: "flex-start",
                content: (
                    <GPTResponse
                        content={
                            <Grid container rowGap={2}>
                                <Grid item xs={12}>
                                    <span>👋 Hello and welcome to Workflow86!</span>
                                </Grid>
                                <Grid item xs={12}>
                                    <span>Workflow86 is an AI-powered platform that helps you manage and automate your business processes and workflows.</span>
                                </Grid>
                                <Grid item xs={12}>
                                    <span>
                                        I’m your AI assistant that can help you design, build, edit, operate and manage your workflows inside of Workflow86. AI is deeply embedded across all of the
                                        functions and capabilities in Workflow86, so you will be seeing me around a lot.
                                    </span>
                                </Grid>
                            </Grid>
                        }
                    />
                ),
            },
            {
                position: "flex-start",
                content: <GPTResponse content={<span>To get things started, I just need to collect a few details to help set up your account.</span>} />,
            },
            {
                position: "flex-start",
                content: (
                    <GPTResponse
                        content={
                            <span>Some fields are marked as required and others are optional. However, the more information you provide me, the more context I have and the better I perform.</span>
                        }
                    />
                ),
            },
            {
                position: "center",
                content: <UserInfoMessage classes={props.classes} position={4} />,
            },
        ];

        return messages;
    };

    const getStep2Messages = () => {
        let messages = [
            {
                position: "flex-start",
                content: (
                    <GPTResponse
                        content={
                            <Grid container rowGap={"8px"}>
                                <Grid item xs={12}>
                                    Hi {firstName}, looking forward to the exciting journey ahead of us!
                                </Grid>
                                <Grid item xs={12}>
                                    One of my core capabilities is that I can build a workflow for you based on your description or requirements. Just tell me what you need, and I can design, build
                                    and configure the entire workflow for you.
                                </Grid>
                            </Grid>
                        }
                    />
                ),
            },
            {
                position: "flex-start",
                content: (
                    <GPTResponse
                        content={
                            <Grid container rowGap={"8px"}>
                                <Grid item xs={12}>
                                    Here are some suggestions if you’re not sure where to start.
                                </Grid>
                            </Grid>
                        }
                    />
                ),
            },
        ];

        return messages;
    };

    return (
        <Grid container spacing={2}>
            {messages.map((message, index) => {
                const displayCenter = message.position === "center";
                return (
                    <Grid item xs={12} display={!displayCenter && "flex"} justifyContent={!displayCenter && message.position} key={index}>
                        {message.content}
                    </Grid>
                );
            })}
            {loadingBubble && (
                <Grid item xs={12} display="flex" justifyContent={"flex-start"}>
                    <Loading showAnimation={true} />
                </Grid>
            )}
            <div ref={bottomRef} />
        </Grid>
    );
}

export default MessageRendering;
