import React, { useContext, useState, useEffect, useRef, useMemo } from "react";
//css
import { withStyles } from "@mui/styles";
import NoteStyles from "../../NoteStyles";
import "../../Canvas.css";

//util
import uuid from "uuid";
import { Handle } from "reactflow";
import { useNavigate } from "react-router";
import { HexColorPicker, HexColorInput } from "react-colorful";
import { send_request, send_request_graphql_mutation } from "../../../../../utils/Request";

//jodit
import JoditEditor from "jodit-pro-react";
import { CanvasContext } from "../../WorkflowCanvas";

//mui
import { Menu, MenuItem, styled, TextField, Tooltip } from "@mui/material";
import { Button } from "@mui/material";

//project store to get canvas out
import ProjectStore from "../../../../ProjectCanvas/ProjectStore";
import { SAVE_EVENT } from "../../CanvasQueries";
import { Rnd } from "react-rnd";

import { createSaveEvent } from "../../../../../utils/createSaveEvent";
import NotesLoadingSkeleton from "../../../../../utils/NotesLoadingSkeleton";
import { AddCircle, AutoAwesome } from "@mui/icons-material";

import { getAINotesSent, getCanvasWithAINotesBorderSet } from "../../../../../utils/AIUtil";
import { getElements, getNoteBackendSaveStructure, calculateNewItemPostion, getLinkBackendSaveStructure } from "../../../../../utils/CanvasUtil";

// MobX
import { isAIEnabled } from "../../../../../utils/AIUtil";
import { inject, observer } from 'mobx-react';

// Jodit
import insertImage from "../../../../../utils/Jodit/insertImage";
import { preventDefaultPaste } from "../../../../../utils/Jodit/preventDefaultPaste";

const styles = NoteStyles;

const AIDraft = styled("div")(({ background, border }) => ({
  background: background,
  border: "1px solid " + border,
  borderRadius: "4px",
  margin: "8px",
  fontSize: "11px",
  padding: "4px",
  maxWidth:"68px",
  right:"-104px!important",
  position:"relative"
}));

const LinkedNote = styled("div")(() => ({
  position: "absolute",
  zIndex: "9999",
  paddingTop: "4px",
  right: "-32px",
  marginBottom: "4px",
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  fontSize: "10px",
  lineHeight: "14px",
  fontWeight: 400,
  color: "#868686",
  "&:hover": {
    color: "#2196f3",
    cursor: "pointer"
  }
}))

const Note = inject('CanvasStore')(observer((props) => {
  //use context to retrieve canvas and mode
  const { canvasInstance } = useContext(CanvasContext);
  const { modeInfo } = useContext(CanvasContext);
  const { savingInfo } = useContext(CanvasContext);
  const { updated } = useContext(CanvasContext);
  const {  notesLoaded, isCanvasLocked } = useContext(CanvasContext);
  const [isNotesLoaded,setIsNotesLoaded] = notesLoaded ? notesLoaded : useState({});

  const [canvasLocked, setCanvasLocked] = isCanvasLocked ? isCanvasLocked : useState(false);

  const { CanvasStore } = props;
  const { nodes, edges } = CanvasStore;

  const [mode, setMode] = modeInfo ? modeInfo : useState({});
  const [reactFlowInstance, setReactFlowInstance] = canvasInstance
    ? canvasInstance
    : useState({});
  const [saving, setSaving] = savingInfo ? savingInfo : useState({});
  const [lastModified, setLastModified] = updated ? updated : useState({});
  //css
  const classes = props.classes;
  //state
  const [noteColor, setNoteColor] = useState(props.data.color);
  const [showPicker, setShowPicker] = useState(false);
  const [showResize, setShowResize] = useState(false);
  const [width, setWidth] = useState(props.data.width);
  const [height, setHeight] = useState(props.data.height);
  const [content, setContent] = useState(props.data.content);
  const [escapeSave, setEscapeSave] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const [hasConnection, setHasConnection] = useState(false);
  const navigate = useNavigate();
  const joditRef = useRef(null);
  const isFirstRun = useRef(true);
  const config = useMemo(() => getConfig(joditRef), [joditRef]);

  const {isNoteClicked} = useContext(CanvasContext);
  const [noteClicked, setNoteClicked] = isNoteClicked ? isNoteClicked : useState(false);
  const [editor, setEditor] = useState(false);
  // record last modified value for the canvas
  // I am taking out hooks from the conditional because
  // The order of the hooks being called is important, and if we write code that causes hooks to not be called,
  // React will not be able to match the hook call with its values.
  useEffect(() => {
    //watch props for content change between production and draft
    setContent(props.data.content);
  }, [props.data.content]);

  const updateNote = async (updatedItem) => {
    if (saving || !updatedItem || props.data.isTemp === true) return;

    setSaving(true);

    let rfiObj = reactFlowInstance.toObject();

    let viewport = {
      x: rfiObj.viewport.x.toFixed(5),
      y: rfiObj.viewport.y.toFixed(5),
      zoom: rfiObj.viewport.zoom.toFixed(5),
    };

    // Update the content to the latest whenever we're saving
    if (updatedItem.data) updatedItem.data.content = content;
    let eventData = getNoteBackendSaveStructure(updatedItem);

    //create event for the new note
    let saveEvent = createSaveEvent(
      "UPDATE_NOTE",
      ProjectStore.project_id,
      viewport,
      getElements(reactFlowInstance),
      eventData
    );

    //send event
    await send_request_graphql_mutation(
      `project-service/graphql/project/save/${ProjectStore.project_id}`,
      SAVE_EVENT(saveEvent),
      "",
      "POST"
    )
      .then((response) => {
        if (response.data.saveEvent && response.data.saveEvent.updated) {
          setLastModified(response.data.saveEvent.lastModified);
        }
        if(escapeSave)
        setEscapeSave(false);
        //insert delay here
        setSaving(false);
        setShowPicker(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    setShowPicker(false);
    setShowResize(false);
    setEditor(false);

    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    } else if (!props.selected && mode == "DEFAULT" && noteClicked === true) {
      setNoteClicked(false);
      var index = nodes.findIndex((x) => x.id === props.id);

      // we allow an update if either the content is changed or escape save is not true
      if (index !== -1 && (!escapeSave || content !== nodes[index].data.content)) {
        const item = nodes[index];

        let updatedItem;
        const newNodes = nodes.map((n) => {
          if (n.id !== item.id) return n;
          updatedItem = {
            ...n,
            data: {
              ...n.data,
              color: noteColor
            },
            dragHandle: null
          };
          return updatedItem;
        });

        CanvasStore.setNodes(newNodes);
        updateNote(updatedItem);

        setEscapeSave(false);
      }
    }
  }, [props.selected]);

  useEffect(() => {
    if (!editor || mode !== "DEFAULT") return;
    toggleNoteDragging();
  }, [editor]);

  const toggleNoteDragging = () => {
    // We want to disable dragging for the note now
    let index = nodes.findIndex((x) => x.id === props.id);
    if (index === -1) return;

    const item = nodes[index];

    const newNodes = nodes.map((n) => {
      if (n.id !== item.id) return n;
      return {
        ...n,
        dragHandle: ".note-drag-handle",
      }
    });

    CanvasStore.setNodes(newNodes);
  }

  if (nodes && mode == "DEFAULT") {
    //updating note contents
    const changeNoteContents = (value) => {
      setContent(value);
    };

    const handleOnNoteClick = () => {
      setNoteClicked(true);
    }

    const handleSaveEdits = async () => {
      if (escapeSave) setEscapeSave(false);

      var index = CanvasStore.nodes.findIndex((x) => x.id === props.id);

      const nodeItem = nodes[index];
      if (nodeItem) {

        const attributes = {
          data: {
            ...nodeItem.data,
            content: content
          },
          dragHandle: null,
        }

        let updatedItem;
        const newNodes = nodes.map((n) => {
          if (n.id !== nodeItem.id) return n;
          updatedItem = {
            ...n,
            data: {
              ...n.data,
              content: content
            },
            dragHandle: null,
          };
          return updatedItem;
        });

        CanvasStore.setNodes(newNodes);
        await updateNote(updatedItem);
      }
      setEditor(false);
    };

    const handleDelete = async () => {
      if (saving) return;

      setSaving(true);

      let result = nodes;

      result = result.filter((node) => {
        return (
          node.id != props.id &&
          node.target != props.id &&
          node.source != props.id
        );
      });

      CanvasStore.setNodes(result);

      let rfiObj = reactFlowInstance.toObject();

      let viewport = {
        x: rfiObj.viewport.x.toFixed(5),
        y: rfiObj.viewport.y.toFixed(5),
        zoom: rfiObj.viewport.zoom.toFixed(5),
      };

      let eventData = {
        noteId: props.id,
      };

      let saveEvent = createSaveEvent(
        "DELETE_NOTE",
        ProjectStore.project_id,
        viewport,
        getElements(reactFlowInstance),
        eventData
      );

      //send event
      await send_request_graphql_mutation(
        `project-service/graphql/project/save/${ProjectStore.project_id}`,
        SAVE_EVENT(saveEvent),
        "",
        "POST"
      )
        .then((response) => {
          if (response.data.saveEvent && response.data.saveEvent.updated) {
            setLastModified(response.data.saveEvent.lastModified);
          }
          //insert delay here
          setSaving(false);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const handleChangeNoteColor = (color) => {
      if (escapeSave) setEscapeSave(false);

      const index = CanvasStore.nodes.findIndex((x) => x.id === props.id);
      if (nodes[index]) {
        const item = nodes[index];


        const updatedNodeItem = {
          ...item,
          data: {
            ...item.data,
            color: color,
          }
        }

        CanvasStore.updateNode(updatedNodeItem);
      }
      setNoteColor(color);
    };

    const handleCopy = async () => {
      if (saving) return;

      setSaving(true);

      let toCopy = nodes.find((node) => node.id == props.id);

      let newNote = {
        id: uuid(),
        type: "note",
        position: { x: toCopy.position.x + 160, y: toCopy.position.y + 160 },
        data: {
          content: toCopy.data.content,
          color: toCopy.data.color,
          width: toCopy.data.width,
          height: toCopy.data.height,
        },
      };

      CanvasStore.setNodes([...nodes, newNote]);

      let rfiObj = reactFlowInstance.toObject();

      let viewport = {
        x: rfiObj.viewport.x.toFixed(5),
        y: rfiObj.viewport.y.toFixed(5),
        zoom: rfiObj.viewport.zoom.toFixed(5),
      };

      let eventData = {
        note: newNote,
      };

      //create event for the new note
      let saveEvent = createSaveEvent(
        "ADD_NOTE",
        ProjectStore.project_id,
        viewport,
        getElements(reactFlowInstance),
        eventData
      );

      //send event
      await send_request_graphql_mutation(
        `project-service/graphql/project/save/${ProjectStore.project_id}`,
        SAVE_EVENT(saveEvent),
        "",
        "POST"
      )
        .then((response) => {
          if (response.data.saveEvent && response.data.saveEvent.updated) {
            setLastModified(response.data.saveEvent.lastModified);
          }
          //insert delay here
          setSaving(false);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const handleResize = () => {
      if (escapeSave) setEscapeSave(false);
      
      const index = CanvasStore.nodes.findIndex((x) => x.id === props.id);

      if (nodes[index]) {
        const nodeItem = nodes[index];

        let updatedItem;
        const newNodes = nodes.map((n) => {
          if (n.id !== nodeItem.id) return n;
          updatedItem = {
            ...n,
            data: {
              ...n.data,
              width: width,
              height: height,
            }
          };
          return updatedItem;
        });

        CanvasStore.setNodes(newNodes);
        updateNote(updatedItem);
      }

      setShowResize(false);
    };

    const sendNote = (moveToFront) => {
      const noteIndex = CanvasStore.nodes.findIndex((x) => x.id === props.id);
      let result = [...CanvasStore.nodes];

      const note = result[noteIndex];
      result.splice(noteIndex, 1);

      if(moveToFront){
        result.push(note);
      }
      else{
        result.splice(0, 0, note);
      }
      CanvasStore.setNodes(result);
      moveNoteFullyBackFront(note.id, moveToFront)
    }

    const moveNoteFullyBackFront = async (currentId,moveToFront) => {
      if(currentId===undefined||moveToFront===undefined)
        return

      // new order, that we want to show.
      if (saving) return; // If saving, return
      
      setSaving(true);
      let rfiObj = reactFlowInstance.toObject();

      let viewport = {
        x: rfiObj.viewport.x.toFixed(5),
        y: rfiObj.viewport.y.toFixed(5),
        zoom: rfiObj.viewport.zoom.toFixed(5),
      };

      let eventData = { id: "moveNoteFullyBackFront", currentId: currentId, moveToFront: moveToFront };

      let saveEvent = createSaveEvent(
          "UPDATE_NOTE",
          ProjectStore.project_id,
          viewport,
          getElements(reactFlowInstance),
          eventData
      );

      //send event
      await send_request_graphql_mutation(
          `project-service/graphql/project/save/${ProjectStore.project_id}`,
          SAVE_EVENT(saveEvent),
          "",
          "POST"
      )
          .then((response) => {
            if (response.data.saveEvent && response.data.saveEvent.updated) {
              setLastModified(response.data.saveEvent.lastModified);
            }
            setEscapeSave(true);
            //insert delay here
            setSaving(false);
            setShowPicker(false);
          })
          .catch((error) => {
            console.log(error);
          });
    };
    
    const handleChangeOrder = (down) => {

      const pos = CanvasStore.nodes.findIndex((x) => x.id === props.id);
      const canvasNodes = [...CanvasStore.nodes];
      let posToSwap;

      if (down) {
        if (pos === 0) {
          return;
        }

        for (let i = pos - 1; i >= 0; i--) {
          if (canvasNodes[i].type === "note") {
            posToSwap = i;
            const noteBefore = canvasNodes[posToSwap];
            canvasNodes[posToSwap] = canvasNodes[pos];
            canvasNodes[pos] = noteBefore;
            break;
          }
        }
      } else {
        if (pos === CanvasStore.nodes.length - 1) {
          return;
        }

        for (let i = pos + 1; i <= canvasNodes.length - 1; i++) {
          if (canvasNodes[i].type === "note") {
            posToSwap = i;
            const noteAfter = canvasNodes[posToSwap];
            canvasNodes[posToSwap] = canvasNodes[pos];
            canvasNodes[pos] = noteAfter;
            break;
          }
        }
      }
      CanvasStore.setNodes(canvasNodes);
      updateNotePosition(canvasNodes[posToSwap], canvasNodes[pos]);
    };

    const handleWidth = (event) => {
      if (escapeSave) setEscapeSave(false);

      let value = parseInt(event.target.value);
      const index = CanvasStore.nodes.findIndex((x) => x.id === props.id);

      if (nodes[index]) {
        const nodeItem = nodes[index];

        let updatedItem;
        const newNodes = nodes.map((n) => {
          if (n.id !== nodeItem.id) return n;
          updatedItem = {
            ...n,
            data: {
              ...n.data,
              width: value
            }
          };
          return updatedItem;
        });

        CanvasStore.setNodes(newNodes);
        updateNote(updatedItem);
      }
      setWidth(value);
    };

    const handleHeight = (event) => {
      if (escapeSave) setEscapeSave(false);

      let value = parseInt(event.target.value);
      const index = CanvasStore.nodes.findIndex((x) => x.id === props.id);

      if (nodes[index]) {
        const nodeItem = nodes[index];

        let updatedItem;
        const newNodes = nodes.map((n) => {
          if (n.id !== nodeItem.id) return n;
          updatedItem = {
            ...n,
            data: {
              ...n.data,
              height: value
            }
          };
          return updatedItem;
        });

        CanvasStore.setNodes(newNodes);
        updateNote(updatedItem);
      }

      setHeight(value);
    };

    const handleSaveColor = async () => {
      if (escapeSave) setEscapeSave(false);
      const index = CanvasStore.nodes.findIndex((x) => x.id === props.id);
      if (nodes[index]) {
        const nodeItem = nodes[index];

        let updatedItem;
        const newNodes = nodes.map((n) => {
          if (n.id !== nodeItem.id) return n;
          updatedItem = {
            ...n,
            data: {
              ...n.data,
              color: noteColor
            }
          };
          return updatedItem;
        });

        CanvasStore.setNodes(newNodes);
        updateNote(updatedItem);
      }
    };

    const handleNotesToAIWorkflow = async () => {
      if (saving) return;

      setAnchorEl(null); // Close the popup

      await handleSaveEdits(); // Save the edits
      
      setCanvasLocked("Collecting notes for AI");

      const dto = {
        noteIds: [props.id],
        projectId: ProjectStore.project_id,
      };

      // Send request to create ai session for the notes
      const url = `project-service/project-ai/assistant/create_ai_session_for_notes`;
      const json = await send_request(url, dto, null, "POST");
      
      if (json && json.data) {
        const { projectId, chatSessionId, threadId } = json.data;
        navigate(`/project/canvas/${projectId}?chatSession=${chatSessionId}&threadId=${threadId}&startBuild=true`);
      }

      setCanvasLocked(false);
    };

    const updateNotePosition = async (currentIndex,previousIndex) => {
      if(currentIndex===undefined||previousIndex===undefined)
        return

      // new order, that we want to show.
      if (saving) return; // If saving, return

      setSaving(true);
      let rfiObj = reactFlowInstance.toObject();

      let viewport = {
        x: rfiObj.viewport.x.toFixed(5),
        y: rfiObj.viewport.y.toFixed(5),
        zoom: rfiObj.viewport.zoom.toFixed(5),
      };

      let eventData = { id: "swapNotes", currentId: currentIndex.id, previousId: previousIndex.id };

      let saveEvent = createSaveEvent(
        "UPDATE_NOTE",
        ProjectStore.project_id,
        viewport,
        getElements(reactFlowInstance),
        eventData
      );

      //send event
      await send_request_graphql_mutation(
        `project-service/graphql/project/save/${ProjectStore.project_id}`,
        SAVE_EVENT(saveEvent),
        "",
        "POST"
      )
        .then((response) => {
          if (response.data.saveEvent && response.data.saveEvent.updated) {
            setLastModified(response.data.saveEvent.lastModified);
          }
          setEscapeSave(true);
              //insert delay here
          setSaving(false);
          setShowPicker(false);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const handleAddLinkedNote = async () => {
      if (saving) return; // If saving, return

      setSaving(true);

      let rfiObj = reactFlowInstance.toObject();

      const note = {
        id: uuid(),
        type: "note",
        position: calculateNewItemPostion(props.xPos, props.yPos, width, height, 200, 100, getElements(reactFlowInstance)),
        data: {
          content: "",
          color: props.data.color,
          width: width,
          height: height,
        },
      };

      const link = {
        source: props.id,
        sourceHandle: "note-right",
        target: note.id,
        targetHandle: "note-left",
        style: { strokeWidth: "3px", stroke: "rgba(0,0,0,0.3)" },
        type: "link",
        markerEnd: {
          type: "arrowclosed",
          color: "#868686",
        },
        id: uuid(),
      };

      let viewport = {
        x: rfiObj.viewport.x.toFixed(5),
        y: rfiObj.viewport.y.toFixed(5),
        zoom: rfiObj.viewport.zoom.toFixed(5),
      };

      // Add it to the canvas
      CanvasStore.setNodes([...nodes, note]);
      CanvasStore.setEdges([...edges, link]);

      let eventData = {
        notesSection: [getLinkBackendSaveStructure(link), note],
      };


      let saveEvent = createSaveEvent(
        "ADD_MULTIPLE_NOTES",
        ProjectStore.project_id,
        viewport,
        getElements(reactFlowInstance),
        eventData
      );

      //send event
      await send_request_graphql_mutation(
        `project-service/graphql/project/save/${ProjectStore.project_id}`,
        SAVE_EVENT(saveEvent),
        "",
        "POST"
      )
        .then((response) => {
          if (response.data.saveEvent && response.data.saveEvent.updated) {
            setLastModified(response.data.saveEvent.lastModified);
          }
          setSaving(false);
        })
        .catch((error) => {
          console.log(error);
        });
    }

    return (
      <>
        {props.data.isTemp ? (
              <AIDraft
                style={{ display: props.data.isTemp == null ? "none" : "" }}
                background={"#F2E5FA"}
                border={"#8000CE"}
              >
                📝 AI draft
              </AIDraft>
              ) : (
                ""
          )}
        
        <div id={props.id} className={classes.nodeWrapper} onClick={handleOnNoteClick}>
          <Rnd
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              position: "static !important",
            }}
            size={{ width: width, height: height }}
            onResize={(e, direction, ref, delta, position) => {
              setWidth(ref.offsetWidth);
              setHeight(ref.offsetHeight);
            }}
            enableResizing={{ bottom: true, right: true, bottomRight: true }}
            resizeHandleClasses={{
              bottomRight: "nodrag",
              bottom: "nodrag",
              right: "nodrag"
            }}
            onResizeStop={(e, direction, ref, delta, position) => {
              handleResize();
            }}
            disableDragging={true}
            className={showPicker ? "nodrag" : ""} // Need to add this for colour picker when not selected
          >
            <Handle
              id={"note-left"}
              type={"target"}
              position="left"
              className={` ${classes.handle}`}
              isConnectable={true}
            />

            <Handle
              id={"note-top"}
              type={"target"}
              position="top"
              className={` ${classes.handle}`}
              isConnectable={true}
            />
            <Handle
              id={"note-bottom"}
              type={"source"}
              position="bottom"
              className={` ${classes.handle}`}
              isConnectable={true}
            />
            <div
              className={`note ${props.selected && "note-shadow"}`}
              onDoubleClick={() => {
                if(isNotesLoaded)
                {
                  if(props.data.isTemp !== true){
                  // don't allow edit if content is not loaded
                  setEditor(true);
                  }
                }
              }}
              style={{
                cursor: !editor ? "pointer" : "text",
                backgroundColor: noteColor,
                width: width < 100 ? 100 : width,
                height: height < 100 ? 100 : height,
                border: props.data.sentToAI ? "1px solid #000000" : "1px solid #000000",
              }}
            >

              <div style={{ height: "100%", width: "100%" }}>
                {isNotesLoaded? <JoditEditor
                    value={content}
                    config={editor ? config : read_only}
                    className={classes.editor}
                    onChange={changeNoteContents}
                /> :
                    <NotesLoadingSkeleton/>}
              </div>
            </div>
            {width && (
              <Handle
                id={"note-right"}
                type="source"
                className={` ${classes.handle}`}
                position="right"
                isConnectable={true}
              />
            )}
            {props.selected && (
              <div className={classes.icons}>
                {editor ? (
                  <Button
                    size={"small"}
                    variant={"contained"}
                    className={classes.saveEdits}
                    onClick={handleSaveEdits}
                  >
                    save
                  </Button>
                ) : (
                  <Tooltip title="Edit note">
                    <i
                      className={`material-icons ${classes.icon}`}
                      onClick={(event) => {
                        if(isNotesLoaded)
                        {
                          if(props.data.isTemp !== true){
                            // don't allow edit if content is not loaded
                            setEditor(true);
                          }
                        }
                        event.stopPropagation();
                      }}
                    >
                      edit
                    </i>
                  </Tooltip>
                )}

                <Tooltip title="Send to the back">

                  <i
                      className={`material-icons ${classes.icon}`}
                      onClick={(event) => {
                        sendNote(false);
                        event.stopPropagation();
                      }}
                      style={{ fontSize: "35px " }}

                  >
                    first_page
                  </i>
                </Tooltip>
                <Tooltip title="Send backwards">
                  <i
                    className={`material-icons ${classes.icon}`}
                    onClick={(event) => {
                      handleChangeOrder(true);
                      event.stopPropagation();
                    }}
                    style={{ width: "18px " }}
                  >
                    arrow_back_ios
                  </i>
                </Tooltip>
                <Tooltip title="Send forwards">
                  <i
                    className={`material-icons ${classes.icon}`}
                    onClick={(event) => {
                      handleChangeOrder(false);
                      event.stopPropagation();
                    }}
                    style={{ width: "18px " }}
                  >
                    arrow_forward_ios
                  </i>
                </Tooltip>
                <Tooltip title="Send to the front">
                  <i
                      className={`material-icons ${classes.icon}`}
                      onClick={(event) => {
                        sendNote(true);
                        event.stopPropagation();
                      }}
                      style={{ fontSize: "35px " }}

                  >
                    last_page
                  </i>
                </Tooltip>
                {!showPicker ? (
                  <Tooltip title="Change background color">
                    <i
                      className={`material-icons ${classes.icon}`}
                      style={{ backgroundColor: props.data.color }}
                      onClick={(event) => {
                        setShowPicker(!showPicker);
                        event.stopPropagation();
                      }}
                    >
                      crop_square
                    </i>
                  </Tooltip>
                ) : (
                  <i
                    className={`material-icons ${classes.icon}`}
                    onClick={(event) => {
                      handleSaveColor();
                      event.stopPropagation();
                    }}
                  >
                    done
                  </i>
                )}
                {/*Remove resize button but may need it in the future*//*!showResize ? (
                  <Tooltip title="Change size">
                    <i
                      className={`material-icons ${classes.icon}`}
                      onClick={(event) => {
                        setShowResize(true);
                        event.stopPropagation();
                      }}
                    >
                      aspect_ratio
                    </i>
                  </Tooltip>
                ) : (
                  <i
                    className={`material-icons ${classes.icon}`}
                    onClick={(event) => {
                      handleResize();
                      event.stopPropagation();
                    }}
                  >
                    done
                  </i>
                )*/}
                <Tooltip title="Duplicate">
                  <i
                    className={`material-icons ${classes.icon}`}
                    onClick={(event) => {
                      handleCopy();
                      event.stopPropagation();
                    }}
                  >
                    file_copy
                  </i>
                </Tooltip>
                <Tooltip title="Delete">
                  <i
                    onClick={(event)=> {
                      handleDelete();
                      event.stopPropagation();
                    }}
                    className={`material-icons ${classes.icon}`}
                  >
                    delete
                  </i>
                </Tooltip>
                {isAIEnabled(ProjectStore.state.clientId) && <Tooltip title="Perform an AI action on this note" placement="right">
                  <Button
                    size="small"
                    variant="contained"
                    onClick={(event) => setAnchorEl(event.currentTarget)}
                    className={classes.useAIToButton}
                    disabled={canvasLocked || saving}
                  >
                    <div className={classes.aiMenuItem}><AutoAwesome className={classes.aiIconBtn} />AI</div>
                  </Button>
                </Tooltip>}
                <Menu
                  keepMounted
                  id="notes-ai-dropdown"
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={() => setAnchorEl(null)}
                  className={classes.aiMenu}
                >
                  <MenuItem onClick={handleNotesToAIWorkflow}>
                    <div className={classes.aiMenuItem}><img src="/svgs/ai-stars-with-color.svg" className={classes.aiIconBtn} /> Build a workflow from this note</div>
                  </MenuItem>
                </Menu>
                <div className={classes.extraSettings}>
                  {showPicker && (
                    <div className={classes.colorPicker}>
                      <HexColorPicker
                        color={noteColor}
                        onChange={(color) => {
                          handleChangeNoteColor(color);
                        }}
                      />
                      <HexColorInput
                        color={noteColor}
                        onChange={(color) => {
                          handleChangeNoteColor(color);
                        }}
                      />
                    </div>
                  )}
                  {showResize && (
                    <div className={classes.resize}>
                      <p className={"s-text"}>Height (px)</p>
                      <TextField
                        size={"small"}
                        type={"number"}
                        value={height}
                        onChange={handleHeight}
                      ></TextField>
                      <p className={"s-text"}>Width (px)</p>
                      <TextField
                        size={"small"}
                        type={"number"}
                        value={width}
                        onChange={handleWidth}
                      ></TextField>
                    </div>
                  )}
                </div>
              </div>
            )}
            {props.selected && (
              <LinkedNote>
                <AddCircle onClick={(event) => {
                    handleAddLinkedNote();
                    event.stopPropagation();
                  }}
                />
              </LinkedNote>
            )}
          </Rnd>
        </div>
      </>
    );
  } else {
    return (
      <div id={props.id}>
        <Handle
          id={"note-left"}
          type={"target"}
          position="left"
          className={` ${classes.handle}`}
          isConnectable={true}
        />
        <div
          className={`note ${props.selected && "note-shadow"}`}
          style={{
            cursor: !editor ? "pointer" : "text",
            backgroundColor: noteColor,
            width: width < 100 ? 100 : width,
            height: height < 100 ? 100 : height,
            border: props.data.sentToAI ? "1px solid #000000" : "1px solid #000000"
          }}
        >
          <Handle
            id={"note-top"}
            type={"target"}
            position="top"
            className={` ${classes.handle}`}
            isConnectable={true}
          />
          <Handle
            id={"note-bottom"}
            type={"source"}
            position="bottom"
            className={` ${classes.handle}`}
            isConnectable={true}
          />
            <div style={{ height: "100%", width: "100%" }}>
              {isNotesLoaded ?
                    <JoditEditor
                      value={props.data.content}
                      config={read_only}
                      className={classes.editor}
                    /> :
                    <NotesLoadingSkeleton/>}
          </div>
        </div>
        {width && (
          <Handle
            id={"note-right"}
            type="source"
            className={` ${classes.handle}`}
            position="right"
          />
        )}
      </div>
    );
  }
}));

export default withStyles(styles)(Note);
const getConfig =(joditRef)=> ({
  license: "CC7FX-1D761-AUOP5-JG0GX",
  readonly: false,
  minHeight: "0px",
  toolbar: false,
  preset: "inline",
  width: "100%",
  overflow: "hidden",
  disablePlugins: [
    "keyboard",
    "google-search",
    "fullsize",
    "about",
    "classSpan",
    "google-maps",
    "export-docs",
    "tune-block"
  ],
  askBeforePasteHTML: true,
  defaultActionOnPaste: "insert_only_text",
  uploader: false,
  showCharsCounter: false,
  showWordsCounter: false,
  showXPathInStatusbar: false,
  useIframeResizer: true,
  events: {
    afterInit: instance => {
        joditRef.current = instance;
    },
    afterPaste: insertImage(joditRef, ProjectStore.project_id),
    beforePaste: (event) =>{preventDefaultPaste(event)},
  },
  
});

//jodit read_only config
const read_only = {
  license: "CC7FX-1D761-AUOP5-JG0GX",
  readonly: true,
  toolbar: false,
  preset: "inline",
  width: "100%",
  disablePlugins: [
    "keyboard",
    "resizer",
    "google-search",
    "fullsize",
    "about",
    "classSpan",
    "google-maps",
    "export-docs",
  ],
  askBeforePasteHTML: true,
  showCharsCounter: false,
  showWordsCounter: false,
  showXPathInStatusbar: false,
  useIframeResizer: false,
};
