import React from "react";
import { CustomDialog } from "../../Component/Dialog";
import {
  Grid,
  Button,
  ButtonGroup,
  TextField,
  Select,
  MenuItem,
  Tooltip,
} from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import checkKey from "../../../utils/CheckKey";
import PropTypes from "prop-types";

export const FormComponentSettings = (props) => {
  const {
    openComponentSettings,
    setOpenComponentSettings,
    exposeMeta,
    prefixLabel,
    formSubmittedDateFormat,
    formId,
    setExposeMeta,
    setPrefixLabel,
    setPrefixLabelErrorMsg,
    handleSubmit,
    setFormSubmittedDateFormat,
    isForm,
    isDynamicForm,
    setIsDynamicForm
  } = props;

  return (
    <CustomDialog
      isOpen={openComponentSettings}
      handleClose={() => {
        setOpenComponentSettings(false);
      }}
      title={<>Component settings</>}
      contents={
        <>
          <Grid container item xs={12} direction={"column"}>
            <Grid
              container
              item
              xs={12}
              direction={"row"}
              alignItems={"center"}
            >
              <p className={"s-text"}>Component ID </p>
              <Tooltip
                title="This is a unique identification code for this component."
                placement="right"
              >
                <HelpOutlineIcon className={"custom-icon-btn"} />
              </Tooltip>
            </Grid>

            <Grid item xs={12}>
              {formId}
            </Grid>
            {!isForm && (
              <>
                {" "}
                <Grid
                  container
                  item
                  xs={12}
                  direction={"row"}
                  alignItems={"center"}
                >
                  <p className={"s-text"}>
                    Allow placeholders in form content{" "}
                  </p>
                  <Tooltip
                    title="Turn this on to export the component metadata as placeholders"
                    placement="right"
                  >
                    <HelpOutlineIcon className={"custom-icon-btn"} />
                  </Tooltip>
                </Grid>
                <ButtonGroup>
                  <Button
                    className="d-button"
                    color={isDynamicForm ? "primary" : "info"}
                    variant={isDynamicForm ? "contained" : "outlined"}
                    onClick={() => {
                      setIsDynamicForm(true);
                    }}
                  >
                    ON
                  </Button>
                  <Button
                    className="d-button"
                    color={isDynamicForm ? "info" : "primary"}
                    variant={isDynamicForm ? "outlined" : "contained"}
                    onClick={() => {
                      setIsDynamicForm(false);
                    }}
                  >
                    OFF
                  </Button>
                </ButtonGroup>
              </>
            )}

            <Grid
              container
              item
              xs={12}
              direction={"row"}
              alignItems={"center"}
            >
              <p className={"s-text"}>Expose component metadata</p>
              <Tooltip
                title="Turn this on to allow placeholders to be used inside the form content."
                placement="right"
              >
                <HelpOutlineIcon className={"custom-icon-btn"} />
              </Tooltip>
            </Grid>
            <ButtonGroup>
              <Button
                className="d-button"
                color={exposeMeta ? "primary" : "info"}
                variant={exposeMeta ? "contained" : "outlined"}
                onClick={() => {
                  setExposeMeta(true);
                }}
              >
                ON
              </Button>
              <Button
                className="d-button"
                color={exposeMeta ? "info" : "primary"}
                variant={exposeMeta ? "outlined" : "contained"}
                onClick={() => {
                  setExposeMeta(false);
                  setPrefixLabel("");
                }}
              >
                OFF
              </Button>
            </ButtonGroup>

            {exposeMeta && (
              <>
                <Grid
                  container
                  item
                  xs={12}
                  direction={"row"}
                  alignItems={"center"}
                >
                  <p className={"s-text"}>Component Label </p>
                  <Tooltip
                    title="The component label is the prefix in front of all placeholders e.g. ${componentlabel.placeholderlabel}."
                    placement="right"
                  >
                    <HelpOutlineIcon className={"custom-icon-btn"} />
                  </Tooltip>
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    error={
                      exposeMeta && (prefixLabel == "" || prefixLabel == null)
                    }
                    fullWidth
                    defaultValue={""}
                    onChange={(e) => {
                      let value = undefined;
                      if (e.target.value && e.target.value.length > 0) {
                        value = checkKey(e);
                      }

                      if (value && value[0]) {
                        setPrefixLabelErrorMsg(value[0]);
                      } else {
                        setPrefixLabel(e.target.value);
                      }
                    }}
                    value={prefixLabel}
                  />
                  {exposeMeta && (prefixLabel == "" || prefixLabel == null) && (
                    <p className={"warning"}>
                      Component label must be set to expose metadata
                    </p>
                  )}
                </Grid>
              </>
            )}
            <Grid
              container
              item
              xs={12}
              direction={"row"}
              alignItems={"center"}
            >
              <p className={"s-text"}>Timestamp format</p>
              <Tooltip
                title="This sets the timestamp format for formSubmittedDate"
                placement="right"
              >
                <HelpOutlineIcon className={"custom-icon-btn"} />
              </Tooltip>
            </Grid>

            <Grid item xs={12} className={"s-text"}>
              <Select
                onChange={(e) => {
                  setFormSubmittedDateFormat(e.target.value);
                }}
                variant={"outlined"}
                value={formSubmittedDateFormat}
                defaultValue={"dd MMMM yyyy HH:mm"}
              >
                <MenuItem value={"yyyy-MM-dd'T'HH:mm:ssX"}>
                  yyyy-mm-dd'T'HH:mm:ssZ (2013-09-29T18:46:19Z)
                </MenuItem>
                <MenuItem value={"dd MMMM yyyy HH:mm"}>
                  dd Month yyyy hh:mm (01 February 2021 14:01)
                </MenuItem>
                <MenuItem value={"dd/MM/yy HH:mm"}>
                  dd/mm/yy hh:mm (15/12/21 14:01)
                </MenuItem>
                <MenuItem value={"dd/MM/yyyy HH:mm"}>
                  dd/mm/yyyy hh:mm (15/12/2021 14:01)
                </MenuItem>
                <MenuItem value={"dd-MM-yyyy HH:mm"}>
                  dd-mm-yyyy hh:mm (15-12-2021 14:01)
                </MenuItem>
                <MenuItem value={"MM/dd/yyyy HH:mm"}>
                  mm/dd/yyyy hh:mm (12/15/2021 14:01)
                </MenuItem>
                <MenuItem value={"yyyy-MM-dd HH:mm"}>
                  yyyy-mm-dd hh:mm (2021-02-01 14:01)
                </MenuItem>
              </Select>
            </Grid>
          </Grid>
        </>
      }
      buttons={
        <>
          <Button
            onClick={() => {
              setOpenComponentSettings(false);
            }}
            variant={"outlined"}
            color={"info"}
          >
            {" "}
            cancel
          </Button>

          <Button
            disabled={exposeMeta == true && prefixLabel == ""}
            onClick={() => {
              setPrefixLabel(prefixLabel);
              handleSubmit("published").then(() => {
                setOpenComponentSettings(false);
              });
            }}
            variant={"contained"}
            color={"primary"}
          >
            {" "}
            Save
          </Button>
        </>
      }
    />
  );
};
FormComponentSettings.propTypes = {
  openComponentSettings: PropTypes.bool.isRequired,
  setOpenComponentSettings: PropTypes.func.isRequired,
  exposeMeta: PropTypes.bool.isRequired,
  prefixLabel: PropTypes.string,
  formSubmittedDateFormat: PropTypes.string.isRequired,
  prefixLabelErrorMsg: PropTypes.string,
  formId: PropTypes.string.isRequired,
  setExposeMeta: PropTypes.func.isRequired,
  setPrefixLabel: PropTypes.func.isRequired,
  setPrefixLabelErrorMsg: PropTypes.func.isRequired,
  setFormSubmittedDateFormat: PropTypes.func.isRequired,
  setIsDynamicForm: PropTypes.func,
  isDynamicForm: PropTypes.bool,
  isForm: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
};
