import React from "react";
import PropTypes from "prop-types";
//mui
import { Grid, Fade } from "@mui/material";
//styles
import { styled } from "@mui/system";
import AnimatedLoadingMessage from "./AnimatedLoadingMessage";

const LoadingContainer = styled(Grid)(({ theme }) => ({
  maxWidth: "100%!important",
  background: "#F5F5F5",
  borderRadius: "16px 16px 16px 0px",
  width: "fit-content",
  padding: "16px",
  fontSize: "14px",
}));

const Loading = ({ content, title, showAnimation}) => {
  return (
    <Grid item xs>
      <Fade timeout={1000} in={true}>
        <div>
          {content &&
            <LoadingContainer container style={{marginBottom:showAnimation?"16px":"0px"}} >
              {title &&
                <Grid item xs={12} marginBottom="16px">
                  <b>
                    {title}
                  </b>
                </Grid>}
              <Grid item xs={12}>
                {content}
              </Grid>
            </LoadingContainer>}

          {showAnimation &&
            <AnimatedLoadingMessage  />}
        </div>
      </Fade>
    </Grid>
  );
};

Loading.propTypes = {
  content: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  showAnimation: PropTypes.bool.isRequired
};

export default Loading;
