import React from "react";
import { inject, observer } from "mobx-react";
import Grid from "@mui/material/Grid";
import "./stylesheet/dashboardPage.css";
import { send_request } from "../../utils/Request";
import FadeIn from "react-fade-in";
import { StylesProvider } from "@mui/styles";
import ProjectStore from "../ProjectCanvas/ProjectStore";
import "./Achievement/AchievementStyle.css";
import { achievements } from "../../utils/Achievements";
import OutstandingTasksCard from "./DashboardComponents/OutstandingTasksCard";
import AvailableWorkflowsCard from "./DashboardComponents/AvailableWorkflowsCard";
import UsefulLinksUserCard from "./DashboardComponents/UsefulLinksUserCard";
import PointsEarnedCard from "./DashboardComponents/PointsEarnedCard";
import PublishedWorkflowsCard from "./DashboardComponents/PublishedWorkflowsCard";
import UnpublishedWorkflowsCard from "./DashboardComponents/UnpublishedWorkflowsCard";
import UsefulLinksAdminCard from "./DashboardComponents/UsefulLinksAdminCard";
import AchievementsCard from "./DashboardComponents/AchievementsCard";
import ProjectsCard from "./DashboardComponents/ProjectsCard";
import ActionsUsedCard from "./DashboardComponents/ActionsUsedCard";
import TimeSavedCard from "./DashboardComponents/TimeSavedCard";
import { Skeleton } from "@mui/material";
import FreeTrialCard from "./DashboardComponents/FreeTrialCard";
import RecentlyUpdatedWorkflowsCard from "./DashboardComponents/RecentlyUpdatedWorkflowsCard";
import StartAWorkflowCard from "./DashboardComponents/StartAWorkflowCard";
import TaskSummaryCard from "./DashboardComponents/TaskSummaryCard";

class DashboardPage extends React.Component {
  constructor(props) {
    super(props);
    // Initialize state with default values
    this.state = {
      cards: [],
      activeProjectNumber: "",
      publishedProjectNumber: "",
      unpublishedProjectNumber: "",
      roleAdministrator: false,
      roleEditor: false,
      rolePrivateViewer: false,
      clientName: "",
      achievements: [],
      totalPoints: 0,
      achievementsAll: [],
      allAchievementCompleted: false,
      displayName: "",
      tier: "",
      loading: false,
    };
  }

  async componentDidMount() {
    // Fetch user info and update state
    this.setState({ loadingScreen: true, loading: true });
    const json = await send_request(`authz-service/user-info`, "", {});
    if (json && json.data) {
      this.setState({
        roleAdministrator: json.data.roleAdministrator,
        roleEditor: json.data.roleEditor,
        rolePrivateViewer: json.data.rolePrivateViewer,
        clientName: json.data.client.clientName,
        displayName: json.data.displayName,
        tier: json.data.client.tier,
        loading: false
      });
    }

    // Fetch and process card data
    this.queryCard().then((value) => {
      if (value && value.data) {
        const { data } = value;
        const filterData = data.filter((ele) => ele.status != "DELETED");
        if (filterData) {
          const cards = filterData.map((card) => {
            return {
              project_id: card.projectId,
              hasPublicVersion: card.hasPublicVersion
            };
          });
          this.setState({ cards });
        }
      }
      this.setState({ activeProjectNumber: this.state.cards.length });
      for (let index in this.state.cards) {
        if (this.state.cards[index].hasPublicVersion) {
          this.state.publishedProjectNumber++;
        }
      }
      this.state.unpublishedProjectNumber =
        this.state.activeProjectNumber - this.state.publishedProjectNumber;
      this.setState({
        publishedProjectNumber: this.state.publishedProjectNumber,
        unpublishedProjectNumber: this.state.unpublishedProjectNumber
      });
    });

    // Fetch all achievements
    this.getAllAchievement();
  }

  // Fetch and process client achievements
  getAllClientAchievements = async (allAchievement) => {
    await send_request(
      `achievement-service/achievement/get_all_client_achievements`,
      "",
      "",
      "get"
    ).then((res) => {
      const maxDisplay = 3;
      if (res.data.achievements !== null) {
        res.data.achievements.map((achievement) => {
          const finishedAchievement = achievements.filter(
            (a) => a.id === achievement.achievementId
          );
          if (finishedAchievement.length > 0) {
            finishedAchievement[0].complete = true;
          }
        });
      }

      let achievementData = [];
      let allAchievementCompleted = true;
      achievements.map((achievement) => {
        if (achievementData.length < maxDisplay) {
          achievementData.push(achievement);
        }

        if (
          allAchievement.filter((a) => a.achievementId === achievement.id)
            .length > 0
        ) {
          if (!achievement.complete) {
            allAchievementCompleted = false;
          }
        }
      });

      this.setState({
        achievements: achievementData,
        totalPoints: res.data.totalPoints,
        allAchievementCompleted: allAchievementCompleted,
        loadingScreen: false
      });
    });
  };

  // Fetch all achievements
  getAllAchievement = async () => {
    await send_request(
      `achievement-service/achievement/get_all_achievements`,
      "",
      "",
      "get"
    ).then((res) => {
      this.setState({
        achievementsAll: res.data
      });

      this.getAllClientAchievements(res.data);
    })  .catch((err) => {
      console.log(err);
    });

  };

  // Query card data
  queryCard() {
    let client_id = "000001";
    return send_request(
      "project-service/project/getProjects",
      "",
      { clientId: client_id },
      "get"
    )
      .then((value) => {
        console.log(value);
        return value;
      })
      .catch((err) => console.log(err));
  }

  render() {
    // Destructure state for easier access
    const {
      roleAdministrator,
      rolePrivateViewer,
      roleEditor,
      clientName,
      achievements,
      totalPoints,
      achievementsAll,
      loadingScreen,
      allAchievementCompleted,
      displayName,
      loading
    } = this.state;

    return (
      <StylesProvider injectFirst>
        <div>
          <h3 className={"pageHeader"}>Hello {displayName} 👋</h3>
          <FadeIn transitionDuration="1000">
            {/* Render different dashboard layouts based on user role */}
            {!roleAdministrator && !roleEditor && rolePrivateViewer ? (
              // Private viewer dashboard layout
              <Grid container spacing={3} className="dashboard-container">
                <Grid item xs={12}>
                  <StartAWorkflowCard />
                </Grid>
                <Grid item xs={12}>
                  <TaskSummaryCard />
                </Grid>
              </Grid>
            ) : (
              // Admin/Editor dashboard layout
              <Grid container spacing={3} className="dashboard-container">
                {/* Left column */}
                <Grid item xs={12} sm={12} md={12} lg={8}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      {!loading ? <RecentlyUpdatedWorkflowsCard /> : <Skeleton variant={"rectangular"} height={440} style={{ background: "#E8E8E8", borderRadius: "10px" }} />}
                    </Grid>
                    <Grid item xs={12}>
                      <StartAWorkflowCard />
                    </Grid>
                    <Grid item xs={12}>
                      <TaskSummaryCard />
                    </Grid>
                  </Grid>
                </Grid>
                {/* Right column */}
                <Grid item xs={12} sm={12} md={12} lg={4}>
                  <Grid container spacing={3}>
                    {this.state.tier === "FREE_TRIAL" &&
                      <Grid item xs={12}>
                        {!loadingScreen ? <FreeTrialCard /> : <Skeleton variant={"rectangular"} height={440} style={{ background: "#E8E8E8", borderRadius: "10px" }} />}
                      </Grid>
                    }
                    <Grid item xs={12}>
                      {!loadingScreen ? <ActionsUsedCard tier={this.state.tier} /> : <Skeleton variant={"rectangular"} height={440} style={{ background: "#E8E8E8", borderRadius: "10px" }} />}
                    </Grid>
                    <Grid item xs={12}>
                      {!loadingScreen ? <AchievementsCard allAchievementCompleted={allAchievementCompleted} achievements={achievements} achievementsAll={achievementsAll} /> : <Skeleton variant={"rectangular"} height={300} style={{ background: "#E8E8E8", borderRadius: "10px" }} />}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </FadeIn>
        </div>
      </StylesProvider>
    );
  }
}

export default observer(DashboardPage);