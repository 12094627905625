import "./App.css";
import { Provider } from "mobx-react";
import React, { Suspense, useEffect } from "react";
import {
  Route,
  Routes,
  useParams,
  useNavigate,
  useLocation,
  Outlet,
  Navigate,
} from "react-router-dom";
import SetPlan from "./components/Component/SetPlan";
import DashboardPage from "./components/pages/DashboardPage";
import PaymentSuccess from "./components/Component/PaymentSuccess";
import PreviewStore from "./components/ProjectCanvas/PreviewStore";
import CanvasStore from "./components/pages/Canvas/CanvasStore";
import MarketplacePage from "./components/Marketplace/MarketplacePage";
import ProjectStore from "./components/ProjectCanvas/ProjectStore";
import Readme from "./components/Readme/index.js";
import WorkflowCanvas from "./components/pages/Canvas/WorkflowCanvas";
import Dashboard from "./components/Dashboard/Dashboard";
import Dashboards from "./components/Dashboard/Dashboards";
import WorkflowTestPage from "./components/pages/WorkflowTestPage";
import WorkflowTestResultPage from "./components/pages/WorkflowTestResultPage";
import WorkflowProgressView from "./components/pages/WorkflowProgressView/WorkflowProgressView";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";
import setPageTitle from "./utils/setPageTitle";
import TaskDetailsPage from "./components/Task/TaskDetailsPage";
import ExpandedTaskPage from "./components/Task/ExpandedTaskPage";
import componentRoutes from "./utils/componentRoutes.js";
import ParseJSONTemplate from "./components/ParseJSON/ParseJSONTemplate";
import SessionVariablesTemplate from "./components/SessionVariable/SessionVariablesTemplate";
import EmailTriggerTemplate from "./components/EmailTrigger/EmailTriggerTemplate";
import AIAssistantTemplate from "./components/AIAssistant/AIAssistantTemplate";
import ConditionalWorkflowPathTemplate from "./components/ConditionalWorkflowPath/ConditionalWorkflowPathTemplate";
import WorkflowSchema from "./components/WorkflowSchema/WorkflowSchema";

const AppRoutes = (props) => {
  let location = useLocation();
  const { firstLogin } = props;

  const queryParams = new URLSearchParams(location.search);

  // Set page title based on location
  useEffect(() => {
    setPageTitle(location);
  }, [location]);

  return (
    <Suspense fallback={<div className="loadingGif" />}>
      <Provider
        PreviewStore={PreviewStore}
        ProjectStore={ProjectStore}
        CanvasStore={CanvasStore}
      >
        <QueryParamProvider adapter={ReactRouter6Adapter}>
          <Routes>
            {/* APP PAGES ROUTES */}
            <Route path="/" element={<DashboardPage />} />

            <Route path="/project/folder/:id" element={<InsideFolderPage />} />

            <Route path="/activity" element={<ActivityPage />} />

            <Route path="/project" element={<WorkflowPage />} />

            <Route path="/credential_store" element={<CredentialStore />} />

            <Route path="/achievements" element={<AchievementPage />} />

            <Route path="/repository" element={<RepositoryPage />} />

            <Route path={"/marketplace"} element={<MarketplacePage />} />
            <Route path={"/task"} exact element={<TaskPage />} />
            <Route path={"/task/:id"} exact element={<TaskDetailsPage />} />

            <Route path={"/task_expanded"} element={<ExpandedTaskPage />} />

            <Route path={"/user_management"} element={<UserTemplate />} />

            <Route path={"/portal"} element={<Portal />} />

            <Route path="/databases" element={<DatabasesPage />} />

            <Route
              path="/databases/database_management/:id"
              element={
                <DatabaseManagement
                  navigate={useNavigate()}
                  queryParams={queryParams}
                />
              }
            />

            <Route path={"/profile"} element={<ProfilePage />} />

            <Route
              path="/set_plan"
              element={<SetPlan firstLogin={firstLogin} history={history} />}
            />

            <Route path="/payment_success" element={<PaymentSuccess />} />

            <Route path={"/organization"} element={<Organization />} />

            {/* WORKFLOW CANVAS ROUTES */}

            <Route
              path="/project_data/:project_id"
              element={<ProjectDataPage />}
            />
            <Route
              path={`/project/logs/:id/:project_session_id`}
              element={<LogSession />}
            />
            <Route path={`/project/logs/:id`} element={<ProjectLog />} />
            <Route
              path={`/project/unsubmittedForms/:id`}
              element={<ProjectLog />}
            />

            <Route
              path={`/project/logs/progress_view/:id/:project_session_id`}
              element={<WorkflowProgressView />}
            />

            <Route path={"/project/readme/:id"} element={<Readme />} />

            <Route
              path={"/project/version_history/:id"}
              element={<VersionHistory />}
            />

            <Route
              path={"/project/view_canvas/:id&version=:version"}
              element={<VersionCanvasView />}
            />

            <Route
              path={"/project/production/:id"}
              element={<ProductionCanvasView />}
            />
            
            <Route
              path={"/project/workflow_schema/:id"}
              element={<WorkflowSchema />}
            />

            <Route
              path={`/project/file_library/:id`}
              element={<FileLibrary />}
            />

            <Route path={`/dashboard/:id`} element={<Dashboard />} />

            <Route path={`/dashboards`} element={<Dashboards />} />

            <Route path={"/project/canvas/"} element={<Outlet />}>
              {/*This is project canvas main route*/}
              <Route
                path={`:id`}
                element={<WorkflowCanvas ProjectSTore={ProjectStore} />}
              />
              {/*This is legacy handler for full page components*/}
              {componentRoutes.map((path, index) => (
                <Route
                  key={index}
                  path={`:id/${path}`}
                  element={<RedirectWithParams />}
                />
              ))}
            </Route>
            <Route
              path={`test_workflow/:project_id/:component_id`}
              element={<WorkflowTestPage />}
            />
            <Route
              path={`test_workflow/test_result/:test_id/:session_id`}
              element={<WorkflowTestResultPage />}
            />


          </Routes>
        </QueryParamProvider>
      </Provider>
    </Suspense>
  );
};

export default AppRoutes;

// This function enables the use of PARAMS, NAVIGATION (instead of history.push) and LOCATION props in a class-based react components,
// To use it just wrap withParams(**class-react-component-name**); function around the outer most export
// Use react-router-dom hooks on function-based components instead
export function withParams(Component) {
  const WithParamsComponent = (props) => (
      <Component
          {...props}
          params={useParams()}
          navigate={useNavigate()}
          location={useLocation()}
      />
  );

  WithParamsComponent.displayName = `withParams(${Component.displayName ||
    Component.name})`;

  return WithParamsComponent;
}

//This is a redirect handler for old component routes
const RedirectWithParams = () => {
  const { id, component_id } = useParams();
  const targetId = component_id;
  const targetPath = `/project/canvas/${id}?view=${targetId}`;

  return <Navigate to={targetPath} replace />;
};

//Lazy imports
const WorkflowPage = React.lazy(() =>
  import("./components/pages/WorkflowPage")
);
const InsideFolderPage = React.lazy(() =>
  import("./components/pages/InsideFolderPage")
);
const DatabasesPage = React.lazy(() =>
  import("./components/pages/DatabasesPage")
);
const DatabaseManagement = React.lazy(() =>
  import("./components/pages/DatabasesComponents/DatabaseManagement")
);

const FileLibrary = React.lazy(() =>
  import("./components/FileLibrary/FileLibrary")
);

const VersionHistory = React.lazy(() =>
  import("./components/VersionHistory/VersionHistory")
);
const VersionCanvasView = React.lazy(() =>
  import("./components/VersionHistory/VersionCanvasView")
);
const ProductionCanvasView = React.lazy(() =>
  import("./components/pages/Canvas/ProductionCanvasView")
);
const ProjectLog = React.lazy(() =>
  import("./components/pages/ProjectLog/ProjectLog")
);
const LogSession = React.lazy(() =>
  import("./components/pages/ProjectLog/LogSession")
);
const ProfilePage = React.lazy(() =>
  import("./components/Profile/ProfilePage")
);
const UserTemplate = React.lazy(() => import("./components/User/UserTemplate"));

const Portal = React.lazy(() => import("./components/Portal/Portal"));

const Organization = React.lazy(() =>
  import("./components/Organization/OrganizationSettings")
);

const ProjectDataPage = React.lazy(() =>
  import("./components/pages/ProjectData")
);

const CredentialStore = React.lazy(() =>
  import("./components/CredentialStore/CredentialStorePage")
);

const AchievementPage = React.lazy(() =>
  import("./components/pages/Achievement/AchievementPage")
);

const RepositoryPage = React.lazy(() =>
  import("./components/Repository/RepositoryPage")
);

const TaskPage = React.lazy(() => import("./components/Task/TaskPage"));

const ActivityPage = React.lazy(() =>
  import("./components/pages/ActivityPage")
);
