import React from "react";
import { Grid, Card, CardContent, Typography, Fade } from "@mui/material";
import PropTypes from "prop-types";
import projectStore from "../../../../../../ProjectCanvas/ProjectStore";
import { withStyles } from "@mui/styles";
import AIBuilderStyles from "../../../AIBuilderStyles";
import { AutoAwesome } from "@mui/icons-material";

const styles = {
  card: {
    border: "1px solid #8000CE",
    borderRadius: "6px",
    paddingBottom: "0px",
    cursor: "pointer",
    background: "#FFF",
    "&:hover": {
      backgroundColor: "#F4EDF6",
      borderRadius: " 6px",
      border: "1px solid #944BA8",
      boxShadow: " 4px 4px 0px 0px rgba(0, 0, 0, 0.25)",
    },
  },
  cardIcon: {
    verticalAlign: "bottom",
    height: "20px",
    width: "20px",
    marginRight: "8px",
    filter: "brightness(0) saturate(100%)",
  },
  ...AIBuilderStyles(),
};

const SessionCard = ({ icon, title, description, classes, onClick }) => (
  <Grid item xs={12}>
    <Card className={classes.card} onClick={onClick}>
      <CardContent>
        <Typography>
          {icon}
          <span className={"bold"}>{title}</span>
        </Typography>
        <p className={"terminate-button"}>{description}</p>
      </CardContent>
    </Card>
  </Grid>
);

SessionCard.propTypes = {
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  onClick: PropTypes.func,
};

const ComponentInitialMessage = ({ classes, setSidebarState }) => {
  return (
    <Fade in timeout={1000}>
      <div className={classes.gpt}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <p>Hi {projectStore.state.userName}, welcome back.</p>
            <p className={"m-0"}>How can I help you today?</p>
          </Grid>
          <SessionCard
            classes={classes}
            icon={<AutoAwesome className={classes.cardIcon} />}
            title="Build New Component"
            description={
              <div>
                <p>
                  Building a new component means I will treat the component as
                  blank and be able to configure any part or all parts of this
                  component.
                </p>
                <p>
                  Note that any existing configuration or settings in the
                  component can be overwritten in build mode.
                </p>
              </div>
            }
            onClick={() => setSidebarState("BUILD")}
          />
          <SessionCard
            classes={classes}
            icon={
              <img src="/svgs/edit_square.svg" className={classes.cardIcon} />
            }
            title="Edit this Component"
            description="Editing a component means I will only make specific changes according to your instructions or requirements, and leave everything else in the component unchanged."
            onClick={() => setSidebarState("EDIT")}
          />
        </Grid>
      </div>
    </Fade>
  );
};
ComponentInitialMessage.propTypes = {
  classes: PropTypes.object.isRequired,
  setSidebarState: PropTypes.func.isRequired,
};
export default withStyles(styles)(ComponentInitialMessage);
