import ReactFlow, {applyNodeChanges, Background, ControlButton, Controls, useStoreApi} from "reactflow";
import {
    CANVAS_MIN_ZOOM_OUT,
    CanvasMinimap,
    DEFAULT_VIEWPORT,
    edgeTypes,
    nodeTypes,
    proOptions
} from "../../../../utils/CanvasUtil";
import {Tooltip} from "@mui/material";
import BrandingWatermarkIcon from "@mui/icons-material/BrandingWatermark";
import HideNotesIcon from "../../../../utils/Icons/HideNotesIcon";
import {Description} from "@mui/icons-material";
import React, {useCallback, useEffect} from "react";
import {inject, observer} from "mobx-react";
import {COMPONENT_ICON_ALT, NOTE_TYPE} from "../../../../utils/CanvasConstants";

const HIDE_NOTES = "Hide Notes";
const SHOW_NOTES = "Show Notes";
/* Created a new TemplateCanvas to be able to manipulate node select using useStoreApi like in CanvasWithRFInstance.jsx
* if useStoreApi is used outside of ReactFlowProvider the useStoreApi would not have initialised yet*/
const TemplateCanvas = inject("CanvasStore")(
    observer((props) => {
        const { CanvasStore } = props;
        const store = useStoreApi();
        const { addSelectedNodes,resetSelectedElements, getNodes, setNodes} = store.getState()
        const onNodesChange = useCallback((changes) => {
            if (changes[0].type === "select") {
                CanvasStore.setNodesFromDraggingNodesFn((ns) => applyNodeChanges(changes, ns));
                return;
            }

            CanvasStore.setDraggingNodesFn((ns) => applyNodeChanges(changes, ns));
            return;

        }, []);

        return (
        <ReactFlow
            nodes={props.nodes}
            edges={props.edges}
            onNodesChange={onNodesChange}
            nodeTypes={nodeTypes}
            edgeTypes={edgeTypes}
            arrowHeadColor={"#868686"}
            defaultViewport={props.viewport ? props.viewport : DEFAULT_VIEWPORT}
            onInit={props.onInit}
            nodesDraggable={false}
            minZoom={CANVAS_MIN_ZOOM_OUT}
            proOptions={proOptions}
            deleteKeyCode={null}
            showMinimap={props.showMinimap}
            setShowMinimap={props.setShowMinimap}
            onNodeMouseEnter={(event,node) => {
                props.handleOnNodeMouseEnter(node,addSelectedNodes);
            }}
            onSelectionChange={ (selection) => {
                props.handleSelectionChange(selection, false);
            }}
            onNodeMouseLeave={(event,node) => {
                props.handleOnNodeMouseLeave(node,resetSelectedElements, getNodes, setNodes);

            }}
            onPaneClick={() => {
                props.handleOnPaneClick();
                }
            }

            onNodeClick={(event, node) => {
                props.handleOnNodeClick(event,node);

            }}
        >
            <Controls className={props.classes.controls}>
                <Tooltip title={"Toggle Minimap"} placement={"right-end"}>
                  <span>
                      <ControlButton
                          onClick={props.switchShowMinimap}
                      >
                          <BrandingWatermarkIcon/>
                      </ControlButton>
                  </span>
                </Tooltip>
                {props.showNotesProd !== undefined && (
                    // this is in case the request comes from anywhere else except production canvas
                    <Tooltip
                        title={props.showNotesProd ? HIDE_NOTES : SHOW_NOTES}
                        placement={"right-end"}
                    >
                    <span>
                      <ControlButton
                          onClick={props.switchShowNotesProd}
                      >
                        {props.showNotesProd ? <HideNotesIcon/> : <Description/>}
                      </ControlButton>
                    </span>
                    </Tooltip>

                )}
            </Controls>
            {props.showMinimap && (
                <CanvasMinimap/>
            )}
            <Background variant="dots" gap={16} color="#868686"/>
        </ReactFlow>
        )
    })
);
export default TemplateCanvas;