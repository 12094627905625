import React, { useContext } from "react";

// Util
import { Handle } from "reactflow";
import { getIconFromPath } from "../../../WorkflowProgressView/SessionComponentIcons";
import { calculateTimeElapsedForLastComponent, calculateTimeElapsedForWaiting, getBorderAndBGColorFromStatus } from "../../../WorkflowProgressView/Util";

// Canvas
import { CanvasContext } from "../../WorkflowCanvas";

// MUI
import { withStyles } from "@mui/styles";
import { Button, styled } from "@mui/material";
import AccountTreeIcon from '@mui/icons-material/AccountTree';

const TimeElapsedBox = styled("div")(({ color, isFormComp }) => ({
    backgroundColor: color.bg,
    border: `1px solid ${color.border}`,
    borderRadius: "4px",
    fontSize: "12px",
    width: "fit-content",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "2px",
    position: "absolute",
    top: isFormComp ? "-22px" : "-28px"
}));

const styles = () => ({
    nodeInfo: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        position: "absolute",
        top: "74px",
    },
    settings: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },
    nodeWrapper: {
        minWidth: "160px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    node: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },
    rmvMargin: {
        margin: "0px!important",
        fontSize: "12px",
    },
    port: {
        position: "relative !important",
        transform: "unset !important",
        right: "unset",
        height: "15px",
        width: "15px",
        backgroundColor: "#868686",
        " &:hover": {
            backgroundColor: "#2196f3",
        },
        " &:active": {
            backgroundColor: "#2196f3",
        },
    },
    filler: {
        width: "15px",
    },
    title: {
        fontSize: "8px",
    },
    nodeInfoBackground: {
        backgroundColor: "rgba(255, 255, 255, 0.5)",
        borderRadius: "8px",
        marginTop: "8px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        whiteSpace: "nowrap",
    },
    downsize: {
        height: "16px",
    },
    threadContainer: {
        backgroundColor: "rgba(142, 0, 209, 0.15)",
        border: "1px solid rgba(142, 0, 209, 1)",
        borderRadius: "4px",
        fontSize: "12px",
        width: "fit-content",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "2px",
        position: "absolute",
        top: "-28px"
    }, 
    accountTreeIcon: {
        height: "14px",
        width: "auto",
        marginRight: "2px"
    },
    selectedText: {
        color: "#2196F3",
      },
    selectedIcon: {
        filter: "drop-shadow(6px 6px 0px rgba(0, 0, 0, 0.25))",
    },
    labelText: {
        maxWidth: "250px",
        overflow: "hidden",
        textOverflow: "ellipsis"
      }
});

const SessionNode = ({ id, data, isConnectable, classes, selected }) => {
    const { modeInfo, currentSelected, workflow } = useContext(CanvasContext);
    
    const hasInput = data.hasInput;
    const hasOutput = data.hasOutput;

    const isSelected = data.sessionData && (currentSelected.componentId === data.sessionData.componentId);

    const getThreadText = (info) => {
        try {
            let data = info.sessionData;
            let sessionData = info.allSessionData;
            let parentSEThreadId = data.thread.parentThreadId;

            let successItems = [];

            for (let sDComp of sessionData.components) {
                if (sDComp.type !== "loop_through_list" || sDComp.status !== "SUCCESS") continue;
                if (sDComp.thread.parentThreadId !== parentSEThreadId) continue;
                successItems.push(sDComp);
            }

            let currIdx = successItems.findIndex((item) => item.thread.threadId === data.thread.threadId);

            return <div className={classes.threadContainer}>
                <AccountTreeIcon className={classes.accountTreeIcon} />
                <span>Thread {currIdx + 1} of {successItems.length}</span>
            </div>;
        } catch (e) { return; }
    }

    // Returns true/false depending on if a component is the last of its branch
    const isLastComponentOnBranch = (data) => { 
        try {
            for (let i = 0; i < workflow.length; i++) {
                let comp = workflow[i];
                if (comp.type !== "link") continue;

                // Need to check if the current comp is the source of any links
                if (comp.source === data.sessionData.componentId) return false;
            }
            return true;
        } catch (e) { return false; }
    }

    // Attempts to find the link connected to a component
    // If it exists
    const findPreviousComponentLink = (data) => {
        try {
            for (let i = 0; i < workflow.length; i++) {
                let comp = workflow[i];
                if (comp.type !== "link") continue;
                if (comp.target === data.sessionData.componentId) {
                    return comp;
                }
            }
        } catch (e) { return; }
    }

    // Returns true/false depending on if it's a form type component or not
    const isFormComp = (data) => {
        if (!data) return false;
        if (data.type === "form" || data.type === "form_section" || data.type === "sequential_form") return true;
        return false;
    }

    const calculateTime = (data) => {
        if (!data || !data.sessionData) return;

        // Handle waiting
        if (data.sessionData.status === "WAITING") {
            let time = calculateTimeElapsedForWaiting(data.sessionData);
            if (!time) return;
            return <TimeElapsedBox color={getBorderAndBGColorFromStatus(data.sessionData.status)} isFormComp={isFormComp(data.sessionData)}>
                <span id={`waiting-time-${data.sessionData.componentId}`}>{time}</span>
            </TimeElapsedBox>;
        } 
        
        else { // Handle checking last component
            
            // If it has an output and it isn't the last comp on the branch, return
            if (data.hasOutput && !isLastComponentOnBranch(data)) return;

            // Need to find previous component
            let prevLink = findPreviousComponentLink(data);
            if (!prevLink) return;

            let endComponentTime = calculateTimeElapsedForLastComponent(prevLink, data.allSessionData.components);
            if (!endComponentTime) return;

            return <TimeElapsedBox color={getBorderAndBGColorFromStatus(data.sessionData.status)} isFormComp={isFormComp(data.sessionData)}>
                <span id={`end-time-${data.sessionData.componentId}`}>{endComponentTime}</span>
            </TimeElapsedBox>
        }
    }

    if (modeInfo == undefined) {
        return (
            <>
                <div className={classes.nodeWrapper}>
                    <div className={classes.node}>
                        {hasInput == true ? <Handle className={`${classes.port}`} type="target" position="left" isConnectable={true} /> : <div className={classes.filler} />}
                        {data.fromWorkflowPath ? (<Button
                            size={"small"}
                            style={{
                                backgroundColor: data.colour,
                                border: `1px solid ${data.colour}`,
                                color:"white"
                            }}
                            variant={"outlined"}
                            className="draftVersionButton"
                        >
              <span style={{paddingLeft: "5px"}}>
                       {data.label}
                        </span>
                        </Button>) : getIconFromPath(data.logo, data.colour, data.colour)
                        }

                        {hasOutput == true ? <Handle className={classes.port} position="right" type="source" isConnectable={isConnectable} /> : <div className={classes.filler} />}
                    </div>
                    {data.fromWorkflowPath ?"": <div className={classes.nodeInfoBackground}>
                        <>
                            <p className={`${classes.rmvMargin} bold`}>{data.displayName}</p>
                            <p className={`${classes.rmvMargin} ${classes.labelText}`}>{data.label}</p>
                        </>
                    </div>}
                </div>
            </>
        );
    } else {
        return (
            <div
                draggable={false}
                onDragOver={(event) => {
                    event.preventDefault();
                }}
                onClick={() => {
                    data.setModalData({ ...data.sessionData, hasRun: true });
                    if (!data.sessionData) data.setModalData({ componentId: "", hasRun: false });
                }}
            >
                <div className={classes.nodeWrapper}>
                    {data.type === "loop_through_list" && getThreadText(data)}   
                    {data && calculateTime(data)}
                    <div className={classes.node}>
                        {hasInput == true ? <Handle className={classes.port} type="target" position="left" isConnectable={true} /> : <div className={classes.filler} />}
                        <div className={isSelected && classes.selectedIcon}>
                            {data.fromWorkflowPath ? (<Button
                                size={"small"}
                                style={{
                                    backgroundColor: data.colour,
                                    border: `1px solid ${data.colour}`,
                                    color:"white"
                                }}
                                variant={"outlined"}
                                className="draftVersionButton"
                            >
              <span style={{paddingLeft: "5px"}}>
                       {data.label}
                        </span>
                            </Button>) : getIconFromPath(data.logo, data.colour, data.colour)
                            }                        </div>
                        {hasOutput == true ? <Handle className={classes.port} position="right" type="source" isConnectable={isConnectable} /> : <div className={classes.filler} />}
                    </div>
                    {data.fromWorkflowPath ?"": <div className={classes.nodeInfoBackground}>
                        <>
                            <p className={`${classes.rmvMargin} bold`}>{data.displayName}</p>
                            <p className={`${classes.rmvMargin} ${classes.labelText}`}>{data.label}</p>
                        </>
                    </div>}
                </div>
            </div>
        );
    }
};
export default withStyles(styles)(SessionNode);
