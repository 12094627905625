import React, { Component } from "react";
import "./index.css";
import "./App.css";
import { Authenticator } from "aws-amplify-react";
import Amplify from "aws-amplify";
import awsconfig from "./auth/Config";
import AuthWrapper from "./components/Component/AuthWrapper";
import aws_config from "./auth/awsConfig";
import { Routes, Route, BrowserRouter } from "react-router-dom";

Amplify.configure({
  Auth: {
    mandatorySignId: true,
    region: awsconfig.REGION,
    userPoolId: awsconfig.USER_POOL_ID,
    userPoolWebClientId: awsconfig.APP_CLIENT_ID,
    authenticationFlowType: 'USER_PASSWORD_AUTH',
    cookieStorage: {
      domain: window.location.host.includes("localhost")
        ? "localhost"
        : awsconfig.DOMAIN,
      path: "/",
      expires: 1,
      secure: false
    }
  }
});

Amplify.configure(aws_config);

class App extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <BrowserRouter>
        <Routes>
          <Route
            path="*"
            index
            element={
              <Authenticator hideDefault={true} amplifyConfig={awsconfig}>
                <AuthWrapper />
              </Authenticator>
            }
          />
        </Routes>
      </BrowserRouter>
    );
  }
}

export default App;
