import React, { useState, useEffect } from 'react';

import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import { CircularProgress, Grid, Tooltip } from "@mui/material";

import "../stylesheet/dashboardPage.css";

import { send_request } from "../../../utils/Request";
import NumberFormat from "react-number-format";
import FadeIn from "react-fade-in";
import { Link } from "react-router-dom";
import "../Achievement/AchievementStyle.css";

function ProjectsCard(props) {

  const { unpublishedProjectNumber, publishedProjectNumber } = props;

  let projectsComponent = (<Card className="cardStyle">
    <CardContent style={{ padding: "32px" }}>
        <Typography
        variant="h4"
        component="p"
        className="cardHeader">
        Projects
        </Typography>
    </CardContent>
    
    <Grid container>
        <Grid xs={6} item>
            <CardMedia
                className="cardImage"
                image="/images/dashboardUnpublished.jpg"
            />
            <CardContent className="textContent">
                <FadeIn transitionDuration="1000">
                <Typography
                    variant="h0"
                    component="p"
                    className="activeNumber"
                >
                    {unpublishedProjectNumber}
                </Typography>
                </FadeIn>
                <Typography className="cardDescription">
                    Draft Projects
                </Typography>
            </CardContent>
        </Grid>
        <Grid xs={6} item> 
            <CardMedia
                className="cardImage"
                image="/images/dashboardPublished.jpg"/>
            <CardContent className="textContent">
                <FadeIn transitionDuration="1000">
                <Typography
                    variant="h0"
                    component="p"
                    className="activeNumber"
                >
                    {publishedProjectNumber}
                </Typography>
                </FadeIn>
                <Typography className="cardDescription">
                    Published Projects
                </Typography>
            </CardContent>
        </Grid>
    </Grid>

</Card>);


  return projectsComponent;
}

export default ProjectsCard;
