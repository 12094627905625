import React, { Component } from "react";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { toJS } from "mobx";
import HandleJsonFunction from "../../Component/HandleJsonFunction";
import ReactJson from "react-json-view";
import { Grid } from "@mui/material";

class SampleReceived extends Component {
  constructor(props) {
    super(props);
  }

  handleJson = async () => {
    const { data } = toJS(this.props.componentStore);
    await HandleJsonFunction.handleAddPlaceholdersJson(data.json,this.props.componentStore)
    await this.props.onChangeState(3,3)
  }

  handleReactJson = () => {
    const { data } = toJS(this.props.componentStore);
    try {
      if (data.json != "") {
        let jsonData = JSON.parse(data.json);

        return (
          <ReactJson
            src={jsonData ? jsonData : {}}
            collapsed="true"
            name="Data"
            displayDataTypes={false}
            style={{ fontFamily: "Open Sans !important" }}
            theme={{
              base00: "rgba(1, 1, 1, 0)",
              base01: "rgba(1, 1, 1, 0.1)",
              base02: "rgba(0, 0, 0, 0.2)",
              base03: "rgba(1, 1, 1, 0.3)",
              base04: "rgba(0, 0, 0, 0.4)",
              base05: "rgba(1, 1, 1, 0.5)",
              base06: "rgba(1, 1, 1, 0.6)",
              base07: "rgba(1, 1, 1, 0.7)",
              base08: "rgba(1, 1, 1, 0.8)",
              base09: "rgba(1, 1, 1, 0.8)",
              base0A: "rgba(1, 1, 1, 0.8)",
              base0B: "rgba(1, 1, 1, 0.8)",
              base0C: "rgba(1, 1, 1, 0.8)",
              base0D: "rgba(1, 1, 1, 0.8)",
              base0E: "rgba(1, 1, 1, 0.8)",
              base0F: "rgba(1, 1, 1, 0.8)"
            }}
          />
        );
      }
    } catch (e) {
      this.props.componentStore.setErrorMessage(
        "Error with sample: " + e.message
      );
      this.props.onChangeState(1, 1);
    }
  };

  render() {
    const { data, name } = toJS(this.props.componentStore);
    console.log(data);
    return (
      <Grid
        item
        xs={12}
        className="textAlignLeftWebhook"
        style={{ padding: 0 }}
      >
        <span>
          We received the following sample data. Click next if this looks
          correct otherwise go back and try again by sending another sample.{" "}
        </span>
        <br />
        <Grid item xs={12} className="json-webhook">
          {this.handleReactJson()}
        </Grid>
        <Grid item xs={12} className="dialogContentRight">
          <Grid item xs={12}>
            <Button
              onClick={() => this.props.onChangeState(1, 1)}
              className="btnSpace"
              variant={"outlined"}
              color={"info"}
            >
              BACK
            </Button>
            <Button
              onClick={this.handleJson}
              variant={"contained"}
              color={"primary"}
            >
              {" "}
              Next
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default SampleReceived;
