import React, { useState } from "react";

// MUI
import { Grid, Paper, styled, Button, Tooltip } from "@mui/material";
import { Add, Fullscreen, Remove, ZoomInOutlined, ZoomOut } from "@mui/icons-material";

// Util
import Draggable from "react-draggable";
import { Resizable } from "react-resizable";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

// CSS
import "react-resizable/css/styles.css";

// Min & Max height of pane
const MIN_HEIGHT = 250;
const MAX_HEIGHT = 750;

// Min & Max width of pane
const MIN_WIDTH = 500;
const MAX_WIDTH = 1500;

// Styled components
const StyledPaper = styled(Paper)(({ dimensions }) => ({
    width: dimensions.width,
    height: dimensions.height,
    display: "flex",
    flexDirection: "column",
    padding: "16px",
    borderRadius: "6px",
    boxShadow: "4px 4px 0px 0px rgba(0, 0, 0, 0.25)",
    border: "1px solid rgba(184, 184, 184, 1)",
    overflow: "auto",
    zIndex: 1,
}));

const TopButtons = styled("div")({
    marginBottom: "16px",
});

const BottomContent = styled("div")({
    overflow: "hidden",
    flexGrow: 1,
});

const BoxItem = styled("div")({
    background: "#fefefe",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "5px",
    width: "22px",
    height: "22px",
    "&:hover": {
        cursor: "pointer",
        color: "#2196f3",
    },
});

function W86DraggableResizeablePane({ topButtons, content, defaultPosition }) {
    const [dimensions, setDimensions] = useState({ width: 950, height: 500 });

    // Applies the min and max width
    const applyMinAndMaxWidth = (givenWidth) => {
        if (givenWidth >= MIN_WIDTH && givenWidth <= MAX_WIDTH) return givenWidth;
        else return dimensions.width;
    };

    // Applies the min and max height
    const applyMinAndMaxHeight = (givenHeight) => {
        if (givenHeight >= MIN_HEIGHT && givenHeight <= MAX_HEIGHT) return givenHeight;
        else return dimensions.height;
    };

    // Handles resizing the component
    const handleResize = (event, { element, size }) => {
        setDimensions({ width: applyMinAndMaxWidth(size.width), height: applyMinAndMaxHeight(size.height) });
    };

    return (
        <Draggable handle=".drag-handle" defaultPosition={defaultPosition}>
            <Resizable width={dimensions.width} height={dimensions.height} onResize={handleResize}>
                <StyledPaper elevation={5} dimensions={dimensions}>
                    <TopButtons>{topButtons}</TopButtons>
                    <TransformWrapper className="transform-component-drag" initialScale={1} centerOnInit={true} smooth={true} limitToBounds={true}>
                        {({ zoomIn, zoomOut, resetTransform }) => (
                            <React.Fragment>
                                <TransformComponent className="transform-component-drag" centerOnInit={true}>
                                    <BottomContent>{content}</BottomContent>
                                </TransformComponent>
                                <Grid container columnGap="6px">
                                    <Grid item>
                                        <Tooltip title="Zoom in">
                                            <BoxItem
                                                onClick={() => {
                                                    zoomIn();
                                                }}
                                            >
                                                <Add
                                                    style={{
                                                        height: "16px",
                                                        "&:hover": {
                                                            cursor: "pointer",
                                                        },
                                                    }}
                                                />
                                            </BoxItem>
                                        </Tooltip>
                                    </Grid>
                                    <Grid item>
                                        <Tooltip title="Zoom out">
                                            <BoxItem
                                                onClick={() => {
                                                    zoomOut();
                                                }}
                                            >
                                                <Remove
                                                    style={{
                                                        height: "16px",
                                                        "&:hover": {
                                                            cursor: "pointer",
                                                        },
                                                    }}
                                                />
                                            </BoxItem>
                                        </Tooltip>
                                    </Grid>
                                    <Grid item>
                                        <Tooltip title="Reset">
                                            <BoxItem
                                                onClick={() => {
                                                    resetTransform();
                                                }}
                                            >
                                                <Fullscreen
                                                    style={{
                                                        height: "16px",
                                                        "&:hover": {
                                                            cursor: "pointer",
                                                        },
                                                    }}
                                                />
                                            </BoxItem>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                            </React.Fragment>
                        )}
                    </TransformWrapper>
                </StyledPaper>
            </Resizable>
        </Draggable>
    );
}

export default W86DraggableResizeablePane;
