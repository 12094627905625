import React from "react";

// MUI
import { makeStyles } from "@mui/styles";
import { Button, Grid } from "@mui/material";

const useStyles = makeStyles({
    box: {
        boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
        borderRadius: "10px",
        // marginTop: "24px",
    },
    title: {
        padding: "10px",
        borderRadius: "10px 10px 0px 0px",
        fontWeight: "400 !important",
        fontSize: "18px",
        lineHeight: "24px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#000",
        border: "1px solid #000",
        color: "#ffffff",
    },
    contactUsButton: {
        background: "#000",
        color: "#FFF",
        borderRadius: "4px",
        padding: "8px 24px",
        "&:hover": {
            background: "rgba(0, 0, 0, 0.85)",
        },
    },
    topText: {
        color: "#000",
        textAlign: "center",
        fontSize: "14px",
        fontWeight: 400,
    },
});

function EnterprisePlanBanner(props) {
    const classes = useStyles();

    return (
        <Grid container className={classes.box}>
            <Grid item xs={12} className={classes.title}>
                Custom/Enterprise Plan
            </Grid>
            <Grid item xs={12} padding="24px">
                <Grid container display="flex" alignItems="center" justifyContent="center" spacing={2}>
                    <Grid item xs={12} display="flex" alignItems="center" justifyContent="center">
                        <span className={classes.topText}>Need a custom plan with more actions, or additional requirements like SLAs, implementation, or private VPC deployments?</span>
                    </Grid>
                    <Grid item xs={12} display="flex" alignItems="center" justifyContent="center">
                        <span className={classes.topText}>Contact us for a custom Enterprise plan and pricing</span>
                    </Grid>
                    <Grid item xs={12} display="flex" alignItems="center" justifyContent="center">
                        <Button variant="contained" className={classes.contactUsButton} id="chat_with_someone">
                            CONTACT US
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default EnterprisePlanBanner;
