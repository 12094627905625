import React, { Component } from "react";
import { observer, inject } from "mobx-react";
//vardogyir
import getPlaceholderColors from "vardogyir-ui/PlaceholderColors";
import JoditEditor, {Helpers} from "jodit-pro-react";
import * as JoditCore from "jodit-pro/build/jodit";
import { configJodit } from "../../utils/configJodit";
import { Tooltip } from "@mui/material";
import { PLACEHOLDER_COLOR, PLACEHOLDER_ERROR_COLOR } from "../../utils/PlaceholderUtil";
import JoditSuperClass from "./JoditSuperClass";
import './JoditStyle.css';

class MentionEditor extends JoditSuperClass {
  constructor(props) {
    super(props);
    this.state = {
      content: this.props.text ? this.props.text.toString() : "",
      oldContent: this.props.text ? this.props.text.toString() : "",
    };
  }

  focus = () => {
    this.props.onFocus();
  };

  componentDidMount() {
    this.setContentFromText();
  }

  setContentFromText() {
    let content = this.props.text;
    if (content) {
      content = content.toString();
      content = content.replace(/(?:\r\n|\r|\n\n)/g, "<br>");
      content = content.replace(/(?:\n)/g, "");
    } else {
      content = "";
    }
    this.setState({
      content,
    });
  }

  componentDidUpdate(prevProps) {
    if(this.props.text && this.state.content!==this.props.text) {
      this.setContentFromText();
    }
    const { placeholders } = this.props;
    if (prevProps.placeholders === null || prevProps.placeholders === undefined) {
      if (placeholders !== undefined && placeholders !== null) {
        this.insertPlaceholder(placeholders[placeholders.length - 1]);
        return;
      }
    }
    if (placeholders && placeholders.length !== prevProps.placeholders.length) {
      this.insertPlaceholder(placeholders[placeholders.length - 1]);
    }
  }

  insertPlaceholder = (placeholder) => {
    if (placeholder) {
      const text = "${" + placeholder.text + "}";
      this.jodit.selection.insertHTML(text);
      this.props.onFocus();
    }
  };





  updateContent = (value) => {
    value = value.replaceAll("&nbsp;", " ");
    value = JoditCore.Jodit.modules.Helpers.stripTags(value);

    this.props.onChange(value);
  };

  config = {
    placeholder: this.props.placeholder ? this.props.placeholder : "",
    readonly: false,
    toolbar: false,
    defaultActionOnPaste: "insert_only_text",
    showCharsCounter: false,
    showWordsCounter: false,
    showXPathInStatusbar: false,
    disablePlugins:
      "add-new-line,about,class-span,backup,button-generator,bold,clean-html,delete,wrap-text-nodes,copy-format,clipboard,paste,paste-storage,color,drag-and-drop,drag-and-drop-element,key-arrow-outside,error-messages,font,format-block,fullsize,hotkeys,iframe,indent,hr,inline-popup,justify,limit,link,mobile,ordered-list,placeholder,redo-undo,search,select,source,stat,sticky,symbols,tooltip,xpath,image-properties,image-processor,image,media,video,file,resize-cells,select-cells,table-keyboard-navigation,table,preview,print",
    toolbarAdaptive: false,
    height: "auto",
    minHeight: this.props.minHeight ? this.props.minHeight : 18,
    useSearch: false,
    spellcheck: false,
    allowResizeY: false,

    events: {
      afterInit: (instance) => {
        this.jodit = instance;
      },
    },
    autocomplete: {
      sources: [
        {
          feed: (query) =>{
            return this.sourceFeed(query,'$');
          },

          itemRenderer: (item) => {
            return this.itemRenderer(item,'$');
          },
          insertValueRenderer: ({ key, type, length }) => {
            return this.insertValueRenderer(type, length, key,'$');
          },
        },
      ],
    },
    highlightSignature: {
      schema: {
        "\\$\\{([^}]+)\\}": (jodit, matched) => {
          return this.getPlaceholderStyleForExistingPlaceholders(matched, jodit);
        },
      },
    },
    ...configJodit,
    ...this.props.style,
  };


  render() {
    const { error, helperText } = this.props;

    return (
      <Tooltip
        placement="bottom"
        title="Type “$” to view and insert placeholders"
      >
        <div
          onClick={this.focus}
          style={this.props.style}
          className={this.props.className}
        >
          <span className={ error ? "jderror" : "jodit-mention"}>
            <JoditEditor
              ref={this.setRef}
              value={this.state.content}
              config={this.config}
              tabIndex={1} // tabIndex of textarea
              onBlur={this.updateContent}
            />
          </span>
          {error && (
            <p style={{ color: "red", margin: "5px 0" }}>{helperText}</p>
          )}
        </div>
      </Tooltip>
    );
  }
}
//
export default observer(MentionEditor);
