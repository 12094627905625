import React from "react";

// util
import { useAIOnboardingContext } from "../OnboardingContextProvider";
import { ItemTitle } from "../Util/OnboardingStyledComponents";
import RoleAndTeamInfoMessage from "./RoleAndTeamInfoMessage";
import { send_request } from "../../../../utils/Request";
import { updateNextStepInDB } from "../Util/Util";

// mui
import { Grid, TextField, Button, CircularProgress } from "@mui/material";
import { Send } from "@mui/icons-material";

function UserInfoMessage(props) {
    const { loading, setLoading, surname, firstName, setFirstName, setSurname, firstNameError, setFirstNameError, loadingBubble, setLoadingBubble, messages, setMessages } = useAIOnboardingContext();

    const isLatestMsg = props.position === messages.length;

    const handleSubmitFirstAndLastName = async () => {
        setLoading(true);
        setLoadingBubble(true);

        const trimmedFirstName = firstName ? firstName.trim() : "";
        const trimmedSurname = surname ? surname.trim() : "";

        if (!trimmedFirstName) {
            // Handle
            setFirstNameError("This field can not be empty");
            setLoading(false);
            return;
        }

        await setAccountDetails(trimmedFirstName, trimmedSurname);
        updateNextStepInDB("ROLE_AND_TEAM_INFO");

        setTimeout(() => {
            setLoadingBubble(false);
            setLoading(false);

            const nextMsg = {
                position: "center",
                content: <RoleAndTeamInfoMessage classes={props.classes} position={5} />,
            };
            setMessages((prevMessages) => [...prevMessages, nextMsg]);
        }, 2000);
    };

    const setAccountDetails = async (trimmedFirstName, trimmedSurname) => {
        // If surname is given, add it to the display name
        let displayName = trimmedFirstName;
        if (trimmedSurname) displayName += "." + trimmedSurname;

        // Construct data
        let data = {
            firstName: trimmedFirstName,
            lastName: trimmedSurname,
            displayName: displayName,
        };

        const url = `authz-service/set-account-details`;
        return await send_request(url, data, null, "POST");
    };

    const checkIfDisabled = () => {
        return false;
    };

    return (
        <Grid container className={props.classes.userInfoBox} rowGap={2}>
            <Grid item xs={12}>
                <Grid container rowGap={"6px"}>
                    <Grid item xs={12}>
                        <ItemTitle>First name (required)</ItemTitle>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            size="small"
                            fullWidth
                            className={props.classes.inputField}
                            variant="outlined"
                            placeholder="Enter your first name"
                            value={firstName}
                            onChange={(e) => {
                                if (e.target.value.length > 64) return;
                                setFirstName(e.target.value);
                                setFirstNameError(null);
                            }}
                            disabled={loading || !isLatestMsg}
                            error={firstNameError}
                            helperText={firstNameError}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container rowGap={"6px"}>
                    <Grid item xs={12}>
                        <ItemTitle>Surname</ItemTitle>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            size="small"
                            fullWidth
                            className={props.classes.inputField}
                            variant="outlined"
                            placeholder="Enter your surname"
                            value={surname}
                            onChange={(e) => {
                                if (e.target.value.length > 64) return;
                                setSurname(e.target.value);
                            }}
                            disabled={loading || !isLatestMsg}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} style={{ display: "flex", alignItems: "flex-end", justifyContent: "flex-end" }}>
                <Button
                    className={props.classes.sendButton}
                    variant={"contained"}
                    onClick={handleSubmitFirstAndLastName}
                    disabled={loading || loadingBubble || !firstName || firstName.length <= 0 || !isLatestMsg}
                >
                    <Send />
                </Button>
            </Grid>
        </Grid>
    );
}

export default UserInfoMessage;
