import { Button, Grid, Skeleton, styled } from "@mui/material";

export const DashboardCard = styled(Grid)({
    borderRadius: "4px",
    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
    padding: "32px",
    background: "#FFFFFF",
});

export const CardTitle = styled("span")({
    fontSize: "18px",
    lineHeight: "26px",
    fontWeight: 400,
});

export const StyledSkeleton = styled(Skeleton)(({ width, height, margin }) => ({
    borderRadius: "4px",
    background: "#E8E8E8",
    width: "100%",
    height: height,
    margin: margin,
}));

export const CenterGrid = styled(Grid)({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
});

export const StyledGrid = styled(Grid)(({ props }) => ({
    ...props,
}));

export const StyledButton = styled(Button)(({ textColor, hoverTextColor, bgColor, hoverBgColor, border }) => ({
    color: textColor,
    backgroundColor: bgColor,
    border: border,
    textTransform: "none",
    "&:hover": {
        color: hoverTextColor,
        backgroundColor: hoverBgColor,
        border: border,
    },
}));
