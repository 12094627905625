import React, {Component} from 'react';
import {
  Grid, Paper,
  Select,
  MenuItem
} from "@mui/material"
import MergeWorkflowStore from "./MergeWorkflowStore";
import {send_component_save_request, send_request} from "../../utils/Request";
import {observer, inject} from "mobx-react";
import {autorun, toJS, reaction, trace} from "mobx";
import projectStore from '../ProjectCanvas/ProjectStore';
import "../WebhookImport/WebhookImport.css";
import {AnyInputComponent, OrOfAndInputComponent} from "./Components";
import {IndividualComponentLoader} from '../../utils/ComponentLoadingSkeleton';
import component from "../pages/Canvas/ReactFlowUtil/CustomNodes/Component";

class MergeWorkflowTemplate extends Component {

  constructor(props) {
    super(props);
    this.state = {
      hasToken: false,
      loading: true,
      updateState: false,
      description:null,
      status:null
    }
  }

  forceUpdateState = () => {
    let currentState = this.state.updateState;

    this.setState({updateState: !currentState});
  }

  componentDidMount() {
    if (this.props.component_id !== undefined) {
      this.setState({loading: true});
      let waitPromise = new Promise((resolve, reject) => {
        resolve("done");
      });

      waitPromise.then(() => {
          return send_request(
            `project-service/project/component/query/${this.props.component_id}/at-version/${this.props.version}`,
            "",
            {}
          );
        }
      )
        .then((response) => {
          if (response && response.data) {
            const {components} = response.data;
            if (components) {
              MergeWorkflowStore.setComponentData(components[0]);
              this.props.onComponentNameChanged(MergeWorkflowStore.name);
              this.props.onComponentDescriptionChanged(components[0].description);
              this.props.onLastModifiedChanged(MergeWorkflowStore.lastModified);
              if(components[0].status!=="AI_BUILD_GENERATED")
              {
                  // skip setting status in case the previous build was ai generated
                  this.setState({
                      status: components[0].status
                  })
              }

              send_request("component-service/merge_workflow/components/" + this.props.component_id,
                "", {})
                .then((response) => {

                  if (response && response.data) {
                    MergeWorkflowStore.setRelatedComponents(response.data);
                  }

                  if (this.props.aiComponentBuilderData !== undefined && this.props.aiComponentBuilderData["data"] !== undefined && this.props.aiComponentBuilderData["data"] !== '') {
                      this.props.onComponentNameChanged(this.props.aiComponentBuilderData["name"]);
                      this.props.onComponentDescriptionChanged(this.props.aiComponentBuilderData["explanation"]);
                      MergeWorkflowStore.updateComponentData(this.props.aiComponentBuilderData["data"]);
                    this.setState({ description: this.props.aiComponentBuilderData["explanation"] });
                    this.setState({ status: "AI_BUILD_GENERATED" });
                  }
                  this.setState({loading: false});
                });
            }
          } else {
            this.setState({loading: false});
          }
          this.props.setChildTemplateLoaded(true);
        })
        .catch((err) => {
          this.setState({loading: false});
          throw Error(err.message);

        });
    }
  }

  componentWillUnmount() {
    // Clear the mobX reactions
    this.saveThisComponent();
  }

  saveThisComponent = reaction(
    () => this.props.SaveTrigger.triggerValue,
    () => {

      let dataComponent = toJS(MergeWorkflowStore).data;

      let dataSave = toJS(MergeWorkflowStore).data;
      let data = {
        componentData: {
          data: dataSave,
          name: toJS(this.props.ComponentName)
        },
        componentId: this.props.component_id,
        description: this.props.ComponentDescription.value,
        status: this.state.status

      };
      send_component_save_request("component-service/merge_workflow/data", data, "", "POST", this.props.SaveTrigger)
        .then((response) => {
          this.props.showSuccess(data, this.props.SaveTrigger);

          /**
           * This direct call to componentDidMount was commented because it looks like it was causing
           * issues with saving - it would re-query the component data before it actually saved
           * since the save is not sync here
           */
          // this.componentDidMount();

          projectStore.savedComponent = true;
        })
        .catch((err) => {
          throw err;
        });
    }
  );


  setComponentName = autorun(() => {
    MergeWorkflowStore.setName(this.props.ComponentName);
  });

  addNewData = () => {
    MergeWorkflowStore.addNewData();
  }

  addNewCondition = () => {
    MergeWorkflowStore.addCondition();
  }

  deleteCondition = (index) => {
    MergeWorkflowStore.deleteCondition(index);
  }

  changeTokenStatus = (status) => {
    this.setState({hasToken: status})
    this.props.changeTokenStatus(status)
  }

  render() {
    toJS(MergeWorkflowStore);
    if (this.state && !this.state.loading) {
      return (
        <Paper className="componentPaper" style={{padding: "24px"}}>
          <Grid container className="componentGrid" spacing={2}>

            <Grid item xs={12}>
              Merge and continue when
            </Grid>

            <Grid item xs={12}>
              <Select
                style={{
                  width: "max-content",
                  backgroundColor: "#F8F8F8",
                  borderRadius: "4px",
                  color: "#868686",
                  visibility: "",
                }}
                disableUnderline
                variant="standard"
                onChange={(e) => {
                  MergeWorkflowStore.setFunction(e.target.value);
                  MergeWorkflowStore.setFunctionDefaultValue();
                }}

                value={MergeWorkflowStore.getFunction()}
                defaultValue={"ANY"}
              >

                <MenuItem value="ANY">
                  any of the following input components are run
                </MenuItem>

                <MenuItem value="OROFAND">
                  any combination of the following components are run
                </MenuItem>

              </Select>
            </Grid>

            {
              MergeWorkflowStore.getFunction() === "ANY" && <AnyInputComponent/>
            }

            {
              MergeWorkflowStore.getFunction() === "OROFAND" && <OrOfAndInputComponent/>
            }

          </Grid>
        </Paper>
      );
    } else return <IndividualComponentLoader/>;
  }
}

export default inject(
  "SelectedPlaceholder",
  "ComponentName",
  "ComponentDescription",
  "SaveTrigger"
)(observer(MergeWorkflowTemplate));
