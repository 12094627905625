import React, { Component } from "react";
import Typography from "@mui/material/Typography";
import { Button as ButtonUI, CircularProgress, Grid } from "@mui/material";
import { withStyles } from "@mui/styles";
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import styles from "../User/UserStyle";
import { send_request, send_request_without_auth } from "../../utils/Request";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Amplify, { Auth } from "aws-amplify";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import ReCAPTCHA from "react-grecaptcha";
import CONFIG from "../../config";
import axios from "axios";
import GoogleIcon from "./Icons/GoogleIcon";
import W86AlphanumericTextfield from "../W86Components/W86AlphanumericTextField";
import { disposableEmail } from "../../utils/DisposableEmail";

const captchaKey = CONFIG.CAPTCHA;

class CustomSignUp extends Component {
  state = {
    formState: "",
    loading: false,
    username: "",
    email: "",
    password: "",
    secret: "",
    user: {},
    isVerified: false
  };

  componentDidMount = () => {
    this.setState({
      formState: this.props.formState,
      username: this.props.username
    });
  };

  handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    const check_type = ["radio", "checkbox"].includes(type);
    this.state[name] = check_type ? checked : value;
    this.state["checkedValue"] = check_type ? value : null;
  };

  checkEmailDomain = (domain) => {
    const domainList = disposableEmail();
    return domainList.includes(domain);
  };

  handleRegister = async (e) => {
    e.preventDefault();
    const { username, email, password, formState, secret } = this.state;
    if (this.state.isVerified) {
      if (email == "") {
        this.setState({
          openError: true,
          errorMessage: "Email cannot be empty.",
          formState
        });
      } else {
        this.setState({
          loading: true
        });

        const emailDomain = email.split("@")[1];
        if (this.checkEmailDomain(emailDomain)) {
          this.setState({
            openError: true,
            loading: false,
            errorMessage:
              "😬 Sorry, we do not allow sign ups with a disposable email",
            formState
          });
        } else {
          this.checkSecretKey();
        }
      }
    } else {
      this.setState({
        openError: true,
        errorMessage:
          "The reCAPTCHA was not completed. Please try again to confirm you are not a robot 🤖.",
        formState
      });
    }
  };
  updateLastLogged = async () => {
    const json = await send_request(`authz-service/update-last-logged`, "", {});
  };

  checkSecretKey = async () => {
    const { username, email, password, formState, secret } = this.state;
    //const json = await send_request_without_auth(`authz-service/check-secret-key`, {secret}, "","POST");

    //not using the secretKey anymore, so we just mock the data
    let json = { data: true, status: "200" };

    if (json && json.data == true && json.status == "200") {
      Auth.signUp({
        username,
        password,
        attributes: {
          email
        }
      })
        .then((user) => {
          setTimeout(() => {
            this.setState({
              formState: "signUpSuccess",
              loading: false,
              confirmMessage:
                "Further instructions have been sent to your email 📨.",
              openConfirm: true,
              email: email
            });
          }, 2000);
        })
        .catch((e) => {
          console.log(e)
          if (e.message == "User already exists") {
            this.setState({
              loading: false,
              openError: true,
              errorMessage:
                "This username is not available 😕. Please try another.",
              formState
            });
          } else if(e.message.indexOf("This email is already being used by another account")!=-1){
            this.setState({
              loading: false,
              openError: true,
              errorMessage: "This email is already being used by another account",
              formState,
            });
          } else {
            this.setState({
              loading: false,
              openError: true,
              errorMessage: e.message,
              formState
            });
          }
        });
    } else {
      this.setState({
        openError: true,
        errorMessage: "Invalid secret key 😬",
        loading: false
      });
    }
  };

  handleClose = () => {
    const { openError, openConfirm } = this.state;
    if (openError) {
      this.setState({ openError: false, verify: true });
    } else if (openConfirm) {
      this.setState({ openConfirm: false, verify: true });
    }
  };

  handleResend = () => {
    const { username } = this.state;
    this.setState({
      loading: true
    });
    Auth.resendSignUp(username)
      .then((user) => {
        setTimeout(() => {
          this.setState({
            loading: false,
            confirmMessage: "Instructions have been sent to your email 📨.",
            openConfirm: true
          });
        }, 2000);
      })
      .catch((e) => {
        setTimeout(() => {
          this.setState({
            loading: false,
            openError: true,
            errorMessage: e.message
          });
        }, 2000);
      });
  };
  callback = () => {
    this.setState({
      isVerified: true
    });
  };

  expiredCallback = () => {
    console.log("expired call back");
  };

  signUpWithGG = () => {
    this.setState({ loading: true });
    Auth.federatedSignIn({ provider: "Google" }).then(() => {
      setTimeout(() => {
        this.setState({ loading: false });
      }, 20000);
    }).catch((e)=>{
      console.log(e)
      
    });
  };

  render() {
    const {
      formState,
      openError,
      errorMessage,
      openConfirm,
      confirmMessage
    } = this.state;
    const { classes } = this.props;
    return (
      <div className="mainDiv">
        {openError && (
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={this.state.openError}
            autoHideDuration={6000}
            onClose={this.handleClose}
            key={"top" + "center"}
          >
            <Alert onClose={this.handleClose} severity="error" variant="filled">
              {errorMessage}
            </Alert>
          </Snackbar>
        )}
        {openConfirm && (
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={this.state.openConfirm}
            autoHideDuration={6000}
            onClose={this.handleClose}
            key={"top" + "center"}
          >
            <Alert
              onClose={this.handleClose}
              severity="success"
              variant="filled"
            >
              {confirmMessage}
            </Alert>
          </Snackbar>
        )}
        <Typography component="div">
          <div>
            <img src="/svgs/logo.svg" alt="logo" />
          </div>
          {this.state.formState === "signUpSuccess" ? (
            <h4 style={{ color: "#000000" }}>
              Instructions sent to {this.state.email}
            </h4>
          ) : this.state.formState === "referral" ? (
            <h4>💌 Invite code</h4>
          ) : (
            <h4>Sign up for an account</h4>
          )}
        </Typography>
        {formState === "signUp" ? (
          <Typography component="div" className="login-with-app-another">
            <div>
              <ButtonUI
                variant="contained"
                color="secondary"
                className="sign-in-with-gg"
                style={{
                  marginBottom: 16,
                  background: "#DD4B39",
                  color: "#ffffff"
                }}
                startIcon={<GoogleIcon />}
                onClick={this.signUpWithGG}
                disabled={this.state.loading}
              >
                SIGN UP WITH GOOGLE
                {this.state.loading && (
                  <CircularProgress size={24} className="buttonProgress" />
                )}
              </ButtonUI>
            </div>
            <div>
              <ButtonUI
                variant="contained"
                color="primary"
                startIcon={<AlternateEmailIcon />}
                onClick={() => this.setState({ formState: "signUpWithEmail" })}
              >
                {" "}
                SIGN UP WITH EMAIL{" "}
              </ButtonUI>
            </div>
            <div className="fix-link">
              <Link href="/" underline="hover">
                <Button
                  style={{ marginTop: "16px" }}
                  className="backButton"
                  variant={"outlined"}
                  color={"info"}
                >
                  ALREADY HAVE AN ACCOUNT? SIGN IN{" "}
                </Button>
              </Link>
            </div>
          </Typography>
        ) : (
          ""
        )}
        {formState === "signUpWithEmail" ? (
          <form onSubmit={this.handleRegister}>
            <div className="inputArea">
            <p className="inputDescription">Work Email</p>
              <TextField
                style={{ width: "100%" }}
                placeholder="Enter your work email"
                variant="outlined"
                id="email"
                key="email"
                name="email"
                type="email"
                onChange={this.handleInputChange}
              
              />
              <p className="inputDescription">Password</p>
              <TextField
                style={{ width: "100%" }}
                placeholder="Enter your password"
                variant="outlined"
                id="password"
                key="password"
                name="password"
                type="password"
                onChange={this.handleInputChange}
              />
              <p className={"s-text noWrap"}>
                Must contain 6+ characters, special character(s), number(s),
                uppercase and lower case letter(s).
              </p>
              <p className="inputDescription">Username</p>
              <W86AlphanumericTextfield 
                props={{
                  id: "username",
                  key: "username",
                  name: "username",
                  placeholder: "Enter your username",
                }}
                value={this.state.username}
                handleChange={this.handleInputChange}
              />
            </div>
            <div className="actionArea">
              <Typography style={{ marginBottom: "20px" }}>
                <Link
                  href="https://www.workflow86.com/terms-and-conditions-of-use/"
                  target="_blank"
                  onClick={this.handleResetPassword}
                  underline="hover">
                  By signing up for an account, you agree to our Terms of
                  Service
                </Link>
              </Typography>
              <ReCAPTCHA
                sitekey={captchaKey}
                callback={this.callback}
                expiredCallback={this.expiredCallback}
                locale="en"
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: "16px"
                }}
                className="flexStartMobile"
              >
                <br />
                <Button
                  className="signInButton"
                  variant={"contained"}
                  color={"primary"}
                  type="submit"
                  onClick={this.handleRegister}
                  disabled={this.state.loading}
                >
                  Register
                  {this.state.loading && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </Button>
              </div>
            </div>
          </form>
        ) : (
          ""
        )}

        {this.state.formState === "signUpSuccess" ? (
          <div className="inputArea">
            <p className="inputDescription">
              Further instructions have been sent to your email address. If you
              do not receive this email, please check your junk and spam
              folders. Click the button below to resend the email.
            </p>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <br />
              <Button
                variant={"outlined"}
                type="submit"
                onClick={this.handleResend}
                disabled={this.state.loading}
              >
                RESEND EMAIL
                {this.state.loading && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </Button>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default withStyles(styles)(CustomSignUp);
