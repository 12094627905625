import React, { useEffect } from "react";

import mermaid from "mermaid";

mermaid.initialize({
    startOnLoad: true,
});

/**
 * Function for loading the mermaid diagram
 * You pass the "chart" which is the markdown diagram
 * You can also optionally pass the width and height of the diagram
 */
function Mermaid({ chart, width, height }) {
    useEffect(() => {
        mermaid.contentLoaded();
    }, []);

    return (
        <div className="mermaid" style={{ width: width, height: height }}>
            {chart}
        </div>
    );
}

export default Mermaid;
