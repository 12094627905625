import React, { useState } from "react";
//mui
import { CircularProgress, Grid } from "@mui/material";

import { styled } from "@mui/system";
import { CheckCircle } from "@mui/icons-material";
import PropTypes from "prop-types";

const LoadingContainer = styled(Grid)(({ theme }) => ({
  maxWidth: "100%!important",
  background: "#F5F5F5",
  borderRadius: "16px 16px 16px 0px",
  padding: "16px",
  fontSize: "14px",
}));
const ContentContainer = styled(Grid)(({ isClickable, fullWidth }) => ({
  background: "#FFF",
  boxShadow: "0px 4px 4px 0px #00000040",
  padding: "10px",
  borderRadius: "6px",
  width: fullWidth ? "100%" : "fit-content",
  "&:hover": {
    cursor: isClickable && "pointer",
    color: isClickable && "rgba(0, 0, 0, 0.6)",
  },
}));

const CenterGrid = styled(Grid)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  wordWrap: "break-word"
});

const getBuildingWorkflowUI = (syncCompleted) => {
  const [openSummary, setOpenSummary] = useState(false);

  return (
    <Grid container rowGap={1}>
      <Grid item xs={12}>
        <ContentContainer
          container
          columnGap={1}
          isClickable
          onClick={() => setOpenSummary(!openSummary)}
        >
          <CenterGrid item>
            {syncCompleted ? (
              <CheckCircle
                style={{ height: "18px", width: "auto", color: "#55A77A" }}
              />
            ) : (
              <CircularProgress size={14} thickness="4.4" />
            )}
          </CenterGrid>
          <CenterGrid item xs>
            <span>
              Syncing with current configuration{" "}
              {syncCompleted ? "settings" : ""}
            </span>
          </CenterGrid>
        </ContentContainer>
      </Grid>
    </Grid>
  );
};

const SyncStateSkeleton = ({ syncCompleted }) => {
  return (
    <LoadingContainer container rowGap={2}>
      <Grid item xs={12} columnGap={2}>
        {getBuildingWorkflowUI(syncCompleted)}
      </Grid>
    </LoadingContainer>
  );
};

SyncStateSkeleton.propTypes = {
  syncCompleted: PropTypes.bool.isRequired,
};

export default SyncStateSkeleton;
