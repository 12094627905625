import React, { useState } from "react";

import { Button, CircularProgress, TextField, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { send_request_without_auth } from "../../utils/Request";

function ForgotUsername({ backToSignIn, validateEmail }) {
    const [hasSubmitted, setHasSubmitted] = useState(false);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [email, setEmail] = useState("");
    const [error, setError] = useState("");

    const handleForgotUsername = () => {
        setError("");
        setButtonLoading(true);

        let lowerCaseEmail = email.toLowerCase(); // Convert to lower case
        let isErrorEmail = validateEmail(lowerCaseEmail);

        if (!isErrorEmail) {
            // This means it's a valid email
            // Hence, send request to forgot_username endpoint
            send_request_without_auth("authz-service/forgot_username", { email: lowerCaseEmail }, null, "POST")
                .then(() => {
                    setHasSubmitted(true);
                    setButtonLoading(false);
                })
                .catch((e) => {
                    console.log(e);
                    setError("There was an error processing your request");
                    setButtonLoading(false);
                });
        } else {
            setError("Please enter a valid email");
            setButtonLoading(false);
        }
    };

    const submittedComponent = (
        <div>
            <Typography component="div">
                <div>
                    <img src="/svgs/logo.svg" alt="logo" />
                </div>
                <h4>📩 Recover your username</h4>
            </Typography>
            <div className="inputArea">
                <p className="inputDescription">We’ve sent a message to {email} with any usernames connected with that email address</p>
            </div>
            <div
                style={{
                    display: "flex",
                    justifyContent: "flex-start",
                }}
            >
                <Link to="/">
                    <Button
                        style={{
                            textDecoration: "none",
                            fontSize: "14px",
                            padding: "6px 30px",
                            marginTop: "20px",
                            letterSpacing: "1.25px",
                        }}
                        className="backButton"
                        variant={"outlined"}
                        color={"info"}
                        onClick={backToSignIn}
                    >
                        BACK TO SIGN IN
                    </Button>
                </Link>
            </div>
        </div>
    );

    const enterEmailComponent = (
        <div>
            <Typography component="div">
                <div>
                    <img src="/svgs/logo.svg" alt="logo" />
                </div>
                <h4>Recover your username</h4>
            </Typography>
            <div className="inputArea">
                <p className="inputDescription">Enter your work email</p>
                <TextField
                    id="resetUserName"
                    key="resetUserName"
                    name="resetUserName"
                    fullWidth
                    placeholder="Enter your work email here"
                    variant="outlined"
                    type="text"
                    value={email}
                    onChange={(e) => {
                        if (error != "") setError("");
                        setEmail(e.target.value);
                    }}
                    error={error != ""}
                    helperText={error}
                    style={{ marginBottom: "8px" }}
                />
                <p className="inputDescription">We’ll send you an email with all usernames connected to that email address. </p>
            </div>
            <div className="actionArea">
                <div
                    style={{
                        display: "flex",
                        justifyContent: "flex-end",
                    }}
                >
                    <Link to="/">
                        <Button
                            style={{
                                marginRight: "10px",
                                textDecoration: "none",
                            }}
                            className="backButton"
                            variant={"outlined"}
                            color={"info"}
                            onClick={backToSignIn}
                        >
                            BACK
                        </Button>
                    </Link>
                    <Button className="resetButton" variant={"contained"} color={"primary"} type="submit" onClick={handleForgotUsername} disabled={buttonLoading}>
                        {buttonLoading && <CircularProgress size={24} className="buttonProgress" />}
                        SUBMIT
                    </Button>
                </div>
            </div>
        </div>
    );

    return hasSubmitted ? submittedComponent : enterEmailComponent;
}

export default ForgotUsername;
