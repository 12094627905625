import dagre from "dagre";
import { isNode } from "reactflow";
import { ComponentFactory } from "../../../../ProjectCanvas/ComponentRegistry";
import uuid from "uuid";
import { getColourFromString } from "../../../../../utils/ColourNameGenerator";
import { SAVE_EVENT } from "../../CanvasQueries";
import { createSaveEvent } from "../../../../../utils/createSaveEvent";
import { send_request_graphql_mutation } from "../../../../../utils/Request";
import { extractEdges, extractNodes, getElements } from "../../../../../utils/CanvasUtil";

import { toJS } from "mobx"


// Converts a given component chunk
export const convertComponentChunk = (componentChunk, isEditor, compIdToCompMap, drawCompIdToSegment) => {
  if (!componentChunk.segmentPayload) return; // If it doesn't have a payload, return

  // Grab the payload
  let compSegment = componentChunk.segmentPayload;

  // Grab the component type and the registry item
  const compType = getTranslatedComponentType(compSegment.componentType); // Get comp type
  if (compType === "split_paths") return { components: [], links: [] }; // Skip the split

  let registryItem = ComponentFactory[compType]; // Get registry item

  if (!registryItem) return { components: [], links: [] };

  // Construct the next components list correctly i.e: [{ componentId: "..."}, ...]
  let nextComps = compSegment.nextComponents
    ? compSegment.nextComponents
        .filter(Boolean)
        .map(compId => ({ componentId: compId }))
    : [];

  // Go over previous components if it is a non trigger component
  // For each previous component, we create a link for it and add it to the links list
  let links = [];
  if (registryItem.hasInput && compSegment.previousComponents) {
    links = compSegment.previousComponents.map(prevCompId => {
      return constructLinkObject(
        prevCompId,
        compSegment.componentId,
        compType,
        registryItem.fromWorkflowPath,
        registryItem.baseColor
      );
    });
  }

  if (isEditor && compIdToCompMap && compSegment.nextComponents) {
    if (!links) links = [];
  
    // Check next components for workflow editor
    // Since components can point to existing components
    for (let i = 0; i < compSegment.nextComponents.length; i++) {
      const currNextComp = compSegment.nextComponents[i];
      if (!currNextComp || !(currNextComp in compIdToCompMap)) continue;

      const nextComp = compIdToCompMap[currNextComp];

      // Add to links list
      const newLink = constructLinkObject(
        compSegment.componentId,
        nextComp.id,
        nextComp.data.type,
        nextComp.data.fromWorkflowPath,
        nextComp.data.baseColor
      );
      links.push(newLink);
    }
  }
  // w86-6574 fetching description here to ensure that edit instructions don't overlap with description
  let description = null;
  if(isEditor && compIdToCompMap)
  {
    let componentOnCanvas = compIdToCompMap[ compSegment.componentId];
    if(componentOnCanvas && componentOnCanvas.data && componentOnCanvas.data.description)
      description = componentOnCanvas.data.description;
  }


  // Add the workflow path components if they exist
  // If workflow paths exist, we also create the link for them
  let workflowPathComps = [];
  if (compSegment.workflowPaths && compSegment.workflowPaths.length > 0) {
    for (let i = 0; i < compSegment.workflowPaths.length; i++) {
      let wfPathComponent = handleWorkflowPathComponent(
        compSegment.workflowPaths[i],
        compSegment.componentId
      );
      if (!wfPathComponent) continue;

      workflowPathComps.push(wfPathComponent); // Add to paths list
      nextComps.push({ componentId: wfPathComponent.id }); // Add id to next component

      // Create a link for it
      let link = constructLinkObject(
        compSegment.componentId,
        wfPathComponent.id,
        "conditional_workflow_path",
        true,
        null
      );
      links.push(link);
    }
  }

  // Construct component structure
  let component = {
    id: compSegment.componentId,
    sourcePosition: "right",
    targetPosition: "left",
    type: "component",
    position: {
      x: 500,
      y: 600
    },
    data: {
      baseColor: registryItem.baseColor,
      displayName: registryItem.displayName,
      fromWorkflowPath: registryItem.fromWorkflowPath,
      hasInput: registryItem.hasInput,
      hasOutput: registryItem.hasOutput,
      instructions: {
        buildInstruction: compSegment.componentDescription,
        editInstruction: compSegment.editInstructions ? compSegment.editInstructions : null
      }, // this is buid instruciton now
      isTemp: true,
      description: description,
      label: compSegment.componentTitle,
      logo: registryItem.logo,
      type: compType,
      next: nextComps,
      color: getColourFromString()
    }
  };

  // drawCompIdToSegment[compSegment.componentId] = compSegment;

  // Return a list of components and links created
  return {
    components: [component, ...workflowPathComps],
    links: links
  };
};

// Constructs a link object
const constructLinkObject = (
  source,
  target,
  compType,
  fromWorkflowPath,
  baseColor
) => {
  return {
    id: uuid(),
    source: source,
    target: target,
    markerEnd: {
      type: compType === "conditional_workflow_path" ? "" : "arrowclosed",
      color: "#868686"
    },
    type: "link",
    label: "componentLink",
    isTemp: true,
    data: {},
    style: {
      strokeWidth: "3px",
      stroke: fromWorkflowPath ? "#868686" : baseColor
    }
  };
};
// Converts a given component chunk
export const convertNoteChunk = noteChunk => {
  if (!noteChunk.segmentPayload) return; // If it doesn't have a payload, return

  // Grab the payload
  let noteSegment = noteChunk.segmentPayload;

  // Go over previous components if it is a non trigger component
  // For each previous component, we create a link for it and add it to the links list
  let links = [];
  if (noteSegment.prev && noteSegment.prev.length > 0) {
    links = noteSegment.prev.map(prevCompId => {
      if (prevCompId && noteSegment.id)
        return constructNoteLinkObject(prevCompId, noteSegment.id);
    });
  }
  // Construct note structure
  let note = {
    id: noteSegment.id,
    type: "note",
    sourcePosition: "right",
    targetPosition: "left",
    position: {
      x: 500,
      y: 600
    },
    data: {
      content: noteSegment.content,
      color: "#fbf6be",
      width: 300,
      height: 200,
      isTemp: true
    }
  };

  // Return a list of components and links created
  return {
    components: [note],
    links: links
  };
};

// Constructs a link object
const constructNoteLinkObject = (prevCompId, noteId) => {
  return {
    id: uuid(),
    source: prevCompId,
    target: noteId,
    sourceHandle: "note-right",
    targetHandle: "note-left",
    markerEnd: {
      type: "arrowclosed",
      color: "#868686"
    },
    type: "link",
    label: "noteLink",
    isTemp: true,
    data: {},
    style: {
      strokeWidth: "3px",
      stroke: "rgba(0,0,0,0.3)"
    }
  };
};

// Handles creating a workflow path component
export const handleWorkflowPathComponent = (workflowPath, parentId) => {
  let nextComps = [];

  // If we have the next components
  if (workflowPath.nextComponents) {
    for (let i = 0; i < workflowPath.nextComponents.length; i++) {
      if (!workflowPath.nextComponents[i]) continue;
      nextComps.push({
        componentId: workflowPath.nextComponents[i]
      });
    }
  }

  let registryItem = ComponentFactory["conditional_workflow_path"];

  // Construct component structure
  let component = {
    id: workflowPath.componentId,
    sourcePosition: "right",
    targetPosition: "left",
    type: "component",
    position: {
      x: 500,
      y: 600
    },
    data: {
      baseColor: registryItem.baseColor,
      displayName: registryItem.displayName,
      fromWorkflowPath: true,
      hasInput: registryItem.hasInput,
      hasOutput: registryItem.hasOutput,
      instruction: "",
      isTemp: true,
      label: workflowPath.pathTitle,
      logo: registryItem.sidebarLogo
        ? registryItem.sidebarLogo
        : registryItem.logo,
      type: "conditional_workflow_path",
      next: nextComps,
      color: getColourFromString(),
      parentId: parentId
    }
  };

  // drawCompIdToSegment[workflowPath.componentId] = workflowPath;

  return component;
};

// Translates the component type from gpt
export const getTranslatedComponentType = type => {
  try {
    if (type === "trigger_form") return "form";
    else if (type === "query_database") return "query_database_record";
    else if (type === "conditional_variable") return "conditional_logic";
    else if (type === "conditional_workflow_paths")
      return "conditional_workflow_path";
    else if (type === "query_database") return "query_database_record";
    else if (type === "workflow_path") return "conditional_workflow";
    else if (type === "conditional_path") return "conditional_workflow";

    return type;
  } catch (e) {
    return type;
  }
};

export const moveCollidingComponents = (newItems, currentCanvas) => {
  const padding = 75; // Add some padding to avoid components being too close to each other

  const allNodes = [...currentCanvas, ...newItems].filter(isNode);
  const maxIterations = 50; // Limit the number of iterations to avoid infinite loops

  for (let iteration = 0; iteration < maxIterations; iteration++) {
    let hasOverlapOccured = false;

    allNodes.forEach((nodeA, indexA) => {
      allNodes.forEach((nodeB, indexB) => {
        if (indexB > indexA) {
          if (hasOverlap(nodeA, nodeB, padding)) {
            // If any overlap has occurred in this iteration, set the flag to true
            hasOverlapOccured = true;

            // Find the new position for the second node (nodeB) to avoid the collision
            const newPosition = findNewPosition(nodeB, currentCanvas, padding);
            nodeB.position.x = newPosition.x;
            nodeB.position.y = newPosition.y;
          }
        }
      });
    });

    // If no overlap occurred in the current iteration, break the loop
    if (!hasOverlapOccured) {
      break;
    }
  }

  return newItems;
};

const hasOverlap = (nodeA, nodeB, padding) => {
  let width = 75;
  let height = 75;

  if (isLargerIcon(nodeB)) {
    width = 150;
    height = 150;
  }

  return (
    Math.abs(nodeA.position.x - nodeB.position.x) < width + padding &&
    Math.abs(nodeA.position.y - nodeB.position.y) < height + padding
  );
};

const findNewPosition = (node, existingNodes, padding) => {
  let newPosition = { ...node.position };

  if (!existingNodes) return newPosition;

  let hasCollision = true; // Set has collision to initially true
  let infLoopSafe = 0;

  // While we still have a collision, update the new position
  while (hasCollision) {
    hasCollision = false; // Set to false

    if (infLoopSafe > 100) break; // Just in case we get in an infinite loop, fail-safe

    // Go over the existing nodes in the canvas
    existingNodes.forEach(existingNode => {
      if (!isNode(existingNode)) return; // If it's not a node, return

      let currNodeUpdatedPosition = {
        position: newPosition
      };

      if (!hasOverlap(currNodeUpdatedPosition, existingNode, padding)) return; // If it doesn't have overlap, return

      newPosition.y += padding; // Move the node
      hasCollision = true;
    });

    infLoopSafe++;
  }

  return newPosition;
};

export const applyDagreLayouting = (nodesItems, edges) => {
  const DagreGraph = new dagre.graphlib.Graph();
  DagreGraph.setDefaultEdgeLabel(() => ({}));

  const graphSetup = {
    rankdir: "LR", // Left -> Right structure
    nodesep: 150, // Horizontal node spacing in px
    ranksep: 150, // Vertical node spacing in px
  };

  // Set the widths/heights of each node accordingly
  const nodes = [];
  for (let i = 0; i < nodesItems.length; i++) {
    const node = nodesItems[i];
    nodes.push({
      ...node,
      width: calculateNodeWidthOrHeight(node, true),
      height: calculateNodeWidthOrHeight(node, false),
    })
    
  }

  // Pass in the desired graph setup
  DagreGraph.setGraph(graphSetup);

  // Set the nodes in the dagre graph
  nodes.forEach((node) => {
    DagreGraph.setNode(node.id, { width: node.width, height: node.height });
  });

  // Set the edges in the dagre graph
  edges.forEach((edge) => {
    DagreGraph.setEdge(edge.source, edge.target);
  });

  // Execute the layout
  dagre.layout(DagreGraph);

  nodes.forEach((node) => {
    const nodeWithPosition = DagreGraph.node(node.id);
    node.targetPosition = "left"
    node.sourcePosition = "right"
  
    // We are shifting the dagre node position (anchor=center center) to the top left
    // so it matches the React Flow node anchor point (top left).
    node.position = {
      x: nodeWithPosition.x -  node.width / 2,
      y: nodeWithPosition.y -  node.height / 2,
    };

    return node;
  });

  return [...nodes, ...edges];  
};

const calculateNodeWidthOrHeight = (node, isWidth) => {
  if (isWidth) {
    // This is fetching the width
    const defaultWidth = 250;
    
    if (node && node.width) return node.width;
    else return defaultWidth;

  } else {
    // This is fetching the height
    const defaultHeight = 150;

    let additionalHeightFromInstructions = 0;

    // Add extra height if we have the instructions/description underneath
    if (node && node.data && (node.data.instructions || node.data.description) && !node.data.heightAdded) {
      additionalHeightFromInstructions += 100;
      node.data.heightAdded = true;
    }

    if (node && node.height) {
      return (node.height + additionalHeightFromInstructions);
    } else {
      return (defaultHeight + additionalHeightFromInstructions);
    }
  }

}

// Our layouting function for applying the edit
export const applyEditLayout = (allNodesAndEdges, drawComponents, drawLinks, compIdToCompMap, drawCompIdToSegment) => {
  return applyDagreLayouting([...extractNodes(allNodesAndEdges), ...drawComponents], [...extractEdges(allNodesAndEdges), ...drawLinks])
}

/**
 * Function created for applying the edit layout manually
 * We dont currently use this
 */
export const applyEditLayoutManual = (allNodesAndEdges, drawComponents, drawLinks, compIdToCompMap, drawCompIdToSegment) => {
  let result = [...allNodesAndEdges, ...drawLinks];

  // Go over drawComponents to add
  for (let i = 0; i < drawComponents.length; i++) {
    const compToDraw = drawComponents[i];

    if (compToDraw.id in compIdToCompMap) continue; // It has already been drawn

    // Else, we need to calculate its position and draw it in
    const spacing = 500; // Define constant x spacing

    /**
     * Find the connected node
     * 1. Look in prev components
     * 2. If not found in prev components, look in next components
     * 3. If not found in there either, set default
     */
    let connectedNodePos;
    let direction = "right";

    const compSegment = drawCompIdToSegment[compToDraw.id];

    if (compSegment && compSegment.previousComponents) {
      for (let i = 0; i < compSegment.previousComponents.length; i++) {
        const prevCompId = compSegment.previousComponents[i];
        if (prevCompId in compIdToCompMap) {
          console.log("Prev component found: " + prevCompId);
          // found it
          connectedNodePos = compIdToCompMap[prevCompId].position;
          break;
        }
      }
    }

    // Try to look into next components if connectedNodePos not found
    if (!connectedNodePos && compSegment && compSegment.nextComponents) {
      for (let i = 0; i < compSegment.nextComponents.length; i++) {
        const nextCompId = compSegment.nextComponents[i];
        if (nextCompId in compIdToCompMap) {
          console.log("Next comp found: " + nextCompId)
          // found it
          connectedNodePos = compIdToCompMap[nextCompId].position;
          direction = "left";
          break;
        }
      }
    }

    if (!connectedNodePos) {
      connectedNodePos = {
        x: 0,
        y: 0
      }
    }

    let initialNewPosition = {
      x: direction === 'right' ? connectedNodePos.x + spacing : connectedNodePos.x - spacing,
      y: connectedNodePos.y
    };

    const newPosition = findNewPosition({ position: initialNewPosition }, allNodesAndEdges, 100);

    // Add the new node in with the given position
    result.push({
      ...compToDraw,
      position: newPosition,
    })
  }

  return result;
}

export const applyLayout = (elements, viewport) => {
  const nodeWidth = 400;
  const nodeHeight = 400;
  const isHorizontal = true;

  const dagreGraph = new dagre.graphlib.Graph();
  dagreGraph.setDefaultEdgeLabel(() => ({}));


  dagreGraph.setGraph({ rankdir: "LR" });

  elements.forEach(el => {
    if (isNode(el)) {
      dagreGraph.setNode(el.id, { width: nodeWidth, height: nodeHeight });
    } else {
      dagreGraph.setEdge(el.source, el.target);
    }
  });

  dagre.layout(dagreGraph);

  let info = getCanvasSizeInfo();
  let firstNodeOffset = { x: 0, y: 0 }; // Set the offset

  return elements.map(el => {
    if (isNode(el)) {
      const nodeWithPosition = dagreGraph.node(el.id);
      el.targetPosition = isHorizontal ? "left" : "top";
      el.sourcePosition = isHorizontal ? "right" : "bottom";

      let topLeftX =
        nodeWithPosition.x - nodeWidth / 2 + -viewport.x / viewport.zoom;
      let topLeftY =
        (nodeWithPosition.y - nodeHeight / 2) / 450 +
        -viewport.y / viewport.zoom;

      if (el.id === elements[0].id) {
        firstNodeOffset = {
          x: topLeftX - (nodeWithPosition.x - nodeWidth / 2),
          y:
            topLeftY +
            (info.height - nodeHeight / 2) / viewport.zoom / 2 -
            (nodeWithPosition.y - nodeHeight / 2)
        };
      }

      el.position = {
        x: nodeWithPosition.x - nodeWidth / 2 + firstNodeOffset.x,
        y: nodeWithPosition.y - nodeHeight / 2 + firstNodeOffset.y
      };
    }

    return el;
  });
};

// Returns true if the icon is larger than the standard icon size (i.e: the forms)
const isLargerIcon = node => {
  try {
    let type = node.data.type;
    if (
      type === "form" ||
      type === "sequential_form" ||
      type === "form_section" ||
      type === "bulk_assessment"
    )
      return true;
    else return false;
  } catch (e) {
    return false;
  }
};

// Grabs the size of the canvas
export const getCanvasSizeInfo = () => {
  let res = {
    width: 0,
    height: 0
  };

  try {
    let info = document
      .getElementsByClassName("react-flow__pane")[0]
      .getBoundingClientRect();
    if (info.width && info.height) res = info;
    return res;
  } catch (e) {
    return res;
  }
};

export const DEFAULT_ZOOM_FOR_FOCUSING_VIEWPORT = 0.5;

export const focusOnComponent = (focusCompId, focusObject) => {
  if (!focusCompId || !focusObject || !focusObject.rfInstance) return;
    
  const { rfInstance, openSessionHistory, canvas, targetZoom } = focusObject;

  // Extract component position
  let rfiObj = rfInstance.toObject();
  if (!rfiObj || !rfiObj.nodes) return;

  // Use canvas if passed, else use rfiObj nodes
  const searchNodes = canvas ? canvas : rfiObj.nodes;

  const comp = searchNodes.find(comp => (comp.type === "component" || comp.type == "note") && comp.id == focusCompId);
  if (!comp || !comp.position) return;

  let xOffset = 100;
  let remainingCanvasWidthAsDecimal = 0.67;

  if (openSessionHistory) {
    xOffset = 0;
    remainingCanvasWidthAsDecimal = 0.55;
  }

  setPositionInCanvasView(
    comp.position,
    xOffset,
    rfInstance,
    false,
    null,
    remainingCanvasWidthAsDecimal,
    targetZoom
  );
}

/**
 * 
 * @param {*} targetPosition => The position of the component we want to focus on
 * @param {*} xOffset => This dictates how far left/right the viewport will move
 * @param {*} rfInstance => The react flow instance
 * @param {*} reuseZoom => true/false on whether to use the previous canvas zoom or use the default
 * @param {*} projectId => The project's id
 * @param {*} remainingCanvasWidthAsDecimal => Is a decimal representation of the remaining width of the canvas 
 *                                             (i.e: 100% - 40% (PANE_WIDTH) = 0.6)
 * @returns 
 */
export const setPositionInCanvasView = async (
  targetViewport,
  xOffset,
  rfInstance,
  reuseZoom,
  projectId,
  remainingCanvasWidthAsDecimal,
  targetZoom
) => {
  if (!rfInstance || !targetViewport) return;

  let info = getCanvasSizeInfo();

  // Set defaults
  if (!xOffset) xOffset = -100;
  if (!remainingCanvasWidthAsDecimal) remainingCanvasWidthAsDecimal = 0.6;

  let zoom = targetZoom ? targetZoom : DEFAULT_ZOOM_FOR_FOCUSING_VIEWPORT;

  // If we're re-using the zoom, set the zoom from the react flow instance
  if (reuseZoom) zoom = rfInstance.toObject().viewport.zoom;

  const x = rfInstance.toObject().viewport.x;
  const y = rfInstance.toObject().viewport.y;

  // Calculates the viewport center
  const viewPortCenterX = info.width / 2;
  const viewPortCenterY = info.height / 2;

  // Calculate the new viewport center considering the node's coordinates
  const newViewPortCenterX = viewPortCenterX - targetViewport.x * zoom;
  const newViewPortCenterY = viewPortCenterY - targetViewport.y * zoom;

  // Calculate the far left X position
  const farLeftX = newViewPortCenterX - viewPortCenterX;

  // Calculate the first quadrant line of the canvas
  let canvasQuarter = remainingCanvasWidthAsDecimal * info.width / 2;

  const newViewport = {
    x: farLeftX + canvasQuarter + xOffset,
    y: newViewPortCenterY,
    zoom: zoom
  };

  rfInstance.setViewport(
    { x: newViewport.x, y: newViewport.y, zoom: newViewport.zoom },
    { duration: 1000 }
  );

  // If a project id is given, we want to save the viewport in the backend
  if (projectId) await saveViewport(newViewport, rfInstance, projectId);
};

const saveViewport = async (viewport, reactFlowInstance, projectId) => {
  let saveEvent = createSaveEvent(
    "SAVE_VIEWPORT",
    projectId,
    viewport,
    getElements(reactFlowInstance),
    { projectId: projectId }
  );

  // Send the save viewport event
  const url = `project-service/graphql/project/save/${projectId}`;
  const json = await send_request_graphql_mutation(
    url,
    SAVE_EVENT(saveEvent),
    "",
    "POST"
  );
};

// This function does the following:
// 1. Removes the split_paths component
// 2. Updates the split_paths component "previousComponents" & "nextComponents" by linking them up together
// i.e: the previousComponents of the split_paths need to now be updated to point to the nextComponents of split_paths
export const handleSplitPathsLogic = givenSegments => {
  try {
    const resSegments = []; // Result

    // Initialise split path path and has link to split path hash set
    const hasLinkToSplitPath = new Set(); // This is so we know which component will need to be considered
    const splitPathMap = {};

    // Go over each segment
    // In this loop, we are constructing the above objects
    for (let seg of givenSegments) {
      const { segmentPayload: payload } = seg;
      if (!payload) continue;

      // If it's not a component chunk or it's not a split paths component, skip it
      if (
        seg.segmentType !== "PROJECT_COMPONENT" ||
        payload.componentType !== "split_paths"
      )
        continue;

      // We know that it's a split_paths component
      const { componentId, previousComponents, nextComponents } = payload; // Grab info needed from payload

      // Set the split_paths component ID as a key and have it set to its prev/next components
      splitPathMap[componentId] = { previousComponents, nextComponents };

      // Go over previous and next components of the split_paths
      // And add them to the hasLink set
      for (const comp of [...previousComponents, ...nextComponents])
        hasLinkToSplitPath.add(comp);
    }

    // Perform a final loop over the segments
    // This loop actually constructs the result segments
    for (let seg of givenSegments) {
      const { segmentType, segmentPayload: payload } = seg;

      if (!payload || !segmentType) continue; // If null, skip

      // If it's not a component, we just add it
      // Else if it's a split_component, skip it, else move on
      if (segmentType !== "PROJECT_COMPONENT") {
        resSegments.push(seg);
        continue;
      } else if (payload.componentType === "split_paths") continue;

      // If here, we need to check if the given component has a link to a split_paths component
      // If not, just add it like normal
      if (!hasLinkToSplitPath.has(payload.componentId)) resSegments.push(seg);
      else {
        // Else, if we're in here, we know we need to update the given components prev OR next components
        const copiedSeg = { ...seg };
        const { previousComponents, nextComponents } = payload;

        // Construct the new previous and next components
        // This involves going over the components previous and next components
        // If the split path map has the given split_path id in the prev/next comps, update the id, else keep it
        const newPreviousComponents = previousComponents.flatMap(
          prev =>
            splitPathMap[prev] ? splitPathMap[prev].previousComponents : prev
        );
        const newNextComponents = nextComponents.flatMap(
          next =>
            splitPathMap[next] ? splitPathMap[next].nextComponents : next
        );

        // Set it to the copied updated segment and add it to the result
        copiedSeg.segmentPayload.previousComponents = newPreviousComponents;
        copiedSeg.segmentPayload.nextComponents = newNextComponents;
        resSegments.push(copiedSeg);
      }
    }

    return resSegments;
  } catch (e) {
    console.log(e);
    return givenSegments;
  }
};

export const handleNotesHistory = history => {
  const segments = [];

  // Add the chatTitle as a CHAT_TITLE segment
  if (history.chatTitle) {
    segments.push({
      segmentType: "CHAT_TITLE",
      segmentPayload: { chatTitle: history.chatTitle }
    });
  }

  // Add the explanation as a PROJECT_DESCRIPTION segment
  if (history.explanation) {
    segments.push({
      segmentType: "PROJECT_DESCRIPTION",
      segmentPayload: { explanation: history.explanation }
    });
  }

  // Handling the 'collection' field as PROJECT_NOTE segments
  if (history.collection && history.collection.length > 0) {
    history.collection.forEach((collectionItem, index) => {
      // Add each collection item title as a PROCESS_TITLE segment
      segments.push({
        segmentType: "PROCESS_TITLE",
        segmentPayload: { title: collectionItem.title }
      });

      // Add each note within the collection item
      collectionItem.notes.forEach(note => {
        segments.push({
          segmentType: "PROJECT_NOTE",
          segmentPayload: {
            id: note.id.toString(),
            content: note.content
          }
        });
      });
    });
  }

  return segments;
};
export const handleEditChanges = (segments, editChanges) => {

  editChanges = editChanges.editChangesDTO;

  let newSegments = [];

  if(editChanges.chatTitle){
    let newStaticSegment = addStaticSegment("CHAT_TITLE", editChanges.chatTitle, newSegments.length+1, "chatTitle")
    newSegments.push(newStaticSegment);
  }
  if(editChanges.assume && editChanges.assume.length > 0){
    let newStaticSegment = addStaticSegment("ASSUMPTIONS", editChanges.assume, newSegments.length+1, "assumptions")
    newSegments.push(newStaticSegment);
  }
  if(editChanges.workaround){
    let newStaticSegment = addStaticSegment("WORKAROUND", editChanges.workaround, newSegments.length+1, "workaround")
    newSegments.push(newStaticSegment);
  }
  if(editChanges.updateSummary){
    let newStaticSegment = addStaticSegment("UPDATE_SUMMARY", editChanges.updateSummary, newSegments.length+1, "update")
    newSegments.push(newStaticSegment);
  }
  if(editChanges.editActionsSummary){
    let newStaticSegment = addStaticSegment("EDIT_ACTIONS_SUMMARY", editChanges.editActionsSummary, newSegments.length+1, "editActionsSummary")
    newSegments.push(newStaticSegment);
  }
  if(editChanges.newSummary){
    let newStaticSegment = addStaticSegment("NEW_SUMMARY", editChanges.newSummary, newSegments.length+1, "new")
    newSegments.push(newStaticSegment);
  }
  if(editChanges.removeSummary){
    let newStaticSegment = addStaticSegment("REMOVE_SUMMARY", editChanges.removeSummary, newSegments.length+1, "remove")
    newSegments.push(newStaticSegment);
  }
  if(editChanges.newPlaceholders && editChanges.newPlaceholders.length > 0){
    let newStaticSegment = addStaticSegment("NEW_PLACEHOLDERS", editChanges.newPlaceholders, newSegments.length+1, "newPlaceholders")
    newSegments.push(newStaticSegment); 
  }
  if(editChanges.obsoletePlaceholders && editChanges.obsoletePlaceholders.length>0){
    let newStaticSegment = addStaticSegment("OBSOLETE_PLACEHOLDERS", editChanges.obsoletePlaceholders, newSegments.length+1, "obsoletePlaceholders")
    newSegments.push(newStaticSegment);
  }
  //now we process all the segments that translation does return
  if(editChanges.componentsToAdd && editChanges.componentsToAdd.length>0){
    let newHeader = addHeader("componentsToAdd:","COMPONENTS_TO_ADD", newSegments.length+1);
    newSegments.push(newHeader);
    editChanges.componentsToAdd.forEach((item) => {
      if (item.type !== "conditional_workflow") {
        if (item.type === "conditional_workflow_paths") item.type = "conditional_workflow_path";
        let newComponent = createComponent(item, newSegments.length+1,false);
        newSegments.push(newComponent);
      }
    })
  } 

  if(editChanges.componentsToUpdate && editChanges.componentsToUpdate.length>0){  
    let newHeader = addHeader("componentsToUpdate:","COMPONENTS_TO_UPDATE", newSegments.length+1);
    newSegments.push(newHeader);
    editChanges.componentsToUpdate.forEach((item) => {
      if (item.type !== "conditional_workflow") {
        if (item.type === "conditional_workflow_paths") item.type = "conditional_workflow_path";
        let newComponent = createComponent(item, newSegments.length+1,true);
        newSegments.push(newComponent);
      }
    })
  }

  if(editChanges.componentsToDelete && editChanges.componentsToDelete.length>0){
    let newArraySegment = addArraySegment ("COMPONENTS_TO_DELETE", editChanges.componentsToDelete,newSegments.length+1);
    newSegments.push(newArraySegment);
  }
  
  //now we process all the segments that translation does return
  if(editChanges.databasesToAdd && editChanges.databasesToAdd.length>0){
    let newHeader = addHeader("databasesToAdd:","DATABASES_TO_CREATE", newSegments.length+1);
    newSegments.push(newHeader);
    editChanges.databasesToAdd.forEach((item) => {
      let newComponent = createDatabase(item, newSegments.length+1);
      newSegments.push(newComponent);
    })
  } 

  if(editChanges.databasesToUpdate && editChanges.databasesToUpdate.length>0){  
    let newHeader = addHeader("databasesToUpdate:","DATABASES_TO_UPDATE", newSegments.length+1);
    newSegments.push(newHeader);
    editChanges.databasesToUpdate.forEach((item) => {
      let newComponent = createDatabase(item, newSegments.length+1);
      newSegments.push(newComponent);
    })
  }

  if(editChanges.databasesToDelete && editChanges.databasesToDelete.length>0){
    let newArraySegment = addArraySegment ("DATABASES_TO_DISCONNECT", editChanges.databasesToDelete, newSegments.length+1);
    newSegments.push(newArraySegment);
  }

  // Filter out segments with undefined segmentType
  const filteredSegments = newSegments.filter(segment => segment.segmentType !== undefined);
  return filteredSegments;
};
//creates component from edit changes
const createComponent = (item, sequence,isUpdate) =>{
  if (item.type === "conditional_workflow_path") {
    return {
      segmentType: "PROJECT_COMPONENT",
      segmentPayload: {
        componentType: item.type,
        nextComponents: [],
        componentId: item.componentId,
        componentTitle: item.generatedTitle,
        componentDescription: isUpdate?item.editInstructions : item.description   || item.instructions?.buildInstruction,
        workflowPaths: item.componentData && item.componentData.data && item.componentData.data.map(path => ({
          componentId: path.comPId,
          pathTitle: path.pathName,
          inputPlaceholders: item.inputPlaceholders,
          nextComponents: item.nextComponents,
          condition: path.condition,
        })),
        outputPlaceholders: item.outputPlaceholders,
        inputPlaceholders: item.inputPlaceholders,
        previousComponents: item.previousComponents,
      },
      sequence: sequence
    };
  } else {
  return {
    segmentType: "PROJECT_COMPONENT",
    segmentPayload: {
      componentType: item.type,
      nextComponents: item.nextComponents,
      componentId: item.componentId,
      componentTitle: item.generatedTitle,
      componentDescription: isUpdate?item.editInstructions : item.description   || item.instructions?.buildInstruction,
      outputPlaceholders: item.outputPlaceholders,
      inputPlaceholders: item.inputPlaceholders ,
      previousComponents: item.previousComponents, 
      editConnectionsOnly: item.editConnectionsOnly
    },
    sequence: sequence
  };}
}

const createDatabase = (item, sequence) =>{

  //is a project database
  let isNew = item && item.columns && item.columns.length?true:false;

  return {
    segmentType: isNew ? "PROJECT_DATABASE" : "DATABASE_NAME",
    segmentPayload: {
      databaseId: item.databaseId,
      databaseName: item.name,
      componentLinks: item.componentLinks, 
      columns: item.columns ? item.columns.map(column => ({
        databaseColumnId: column.columnId,
        databaseColumnName: column.name,
        databaseColumnType: column.type,
      })) : item.newColumns.map(column => ({
        databaseColumnId: column.columnId,
        databaseColumnName: column.name,
        databaseColumnType: column.type,
    })),
    sequence: sequence
  }}
}
const addHeader = (payload, type, sequence)=>{
  return {
    segmentType:type,
    segmentPayload: payload,
    sequence: sequence
  }
}
const addArraySegment = (type, payload, sequence)=>{
  return {
    segmentType:type,
    segmentPayload: payload,
    sequence: sequence
  }
}

const addStaticSegment = (type, payload, sequence, attributeName) => {
  return {
    segmentType: type,
    segmentPayload: { [attributeName]: payload },
    sequence: sequence
  }
}

// ***** For syncing workflow builder ***** //
export const handleBuilderChanges = (workflow) => {
  let newSegments = [];

  // 1. Workflow Name
  if (workflow.name) {
    let newStaticSegment = addStaticSegment("PROJECT_NAME", workflow.name, newSegments.length+1, "workflowName")
    newSegments.push(newStaticSegment);
  }

  // 2. Explanation
  if (workflow.explanation) {
    let newStaticSegment = addStaticSegment("PROJECT_DESCRIPTION", workflow.explanation, newSegments.length+1, "explanation")
    newSegments.push(newStaticSegment);
  }

  // 3. Components
  if (workflow.components && workflow.components.length > 0) {
    workflow.components.forEach((component) => {
      if (component.type !== "conditional_workflow") {
        if (component.type === "conditional_workflow_paths") component.type = "conditional_workflow_path";
        let newComponent = createComponent(component, newSegments.length + 1, false);
        newSegments.push(newComponent);
      }
    });
  }

  // 4. Any databases
  if (workflow.aiDatabases && workflow.aiDatabases.length > 0) {
    workflow.aiDatabases.forEach((database) => {
      let newDatabases = createDatabase(database, newSegments.length + 1);
      newSegments.push(newDatabases);
    });
  }

  return newSegments;
}