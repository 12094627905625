import React, { useState, useEffect } from "react";

// MUI
import { CheckCircle, PlayCircleOutline, Security } from "@mui/icons-material";
import { Backdrop, Button, Grid } from "@mui/material";

// Util
import Confetti from "react-confetti";
import { Link } from "react-router-dom";
import { format } from "../../../../utils/Date";
import { CustomDialog } from "../../../Component/Dialog";
import ProjectStore from "../../../ProjectCanvas/ProjectStore";
import WarningBox from "../../CommonStyledComponents/WarningBox";
import { send_request } from "../../../../utils/Request";
import { ComponentFactory } from "../../../ProjectCanvas/ComponentRegistry";

function WorkflowPublishedDialog({
    projectId,
    publishConfirmDialog,
    lastPublished,
    publishedVersion,
    draftVersion,
    setPublishConfirmDialog,
    setOpenStartDialog,
    handleShare,
    onDonePublish,
    components,
    setOpenAccess,
    publishComment,
    navigate,
    setLoadingDialog,
    unpublishedChanges
}) {
    const [accessControl, setAccessControl] = useState("Restricted");

    useEffect(async () => {
        console.log("Before");
        let componentsWithAccess = [];

        // Get component ids and isAlwaysPublic from components with access;
        for (let c of components) {
            if (ComponentFactory[c.type] && ComponentFactory[c.type].hasAccessControl) {
                let newC = {};
                newC["component_id"] = c["componentId"];
                newC["isAlwaysPublic"] = ComponentFactory[c.type].isAlwaysPublic;
                componentsWithAccess.push(newC);
            }
        }

        let projectComponentData = {
            projectId: String(projectId),
            components: componentsWithAccess,
        };

        const json = await send_request("authz-service/get-project-access-status", projectComponentData, null, "POST");
        if (json && json.data) {
            setAccessControl(json.data);
        }
    }, []);

    const getAccessText = () => {
        switch (accessControl) {
            case "PUBLIC":
                return (
                    <span>
                        Access to your workflow is currently set to <b> Public Access</b>. This means all user-facing components can be viewed and accessed by anyone. Click the Access button below to
                        change this.
                    </span>
                );
            case "MIXED":
                return (
                    <span>
                        Access to your workflow is currently set to <b> Mixed Access</b>. This means some user-facing components can be viewed and accessed by anyone and others are restricted to
                        certain users only. Click the Access button below to change this.
                    </span>
                );
            default:
                return (
                    <span>
                        Access to your workflow is currently set to
                        <b> Restricted Access</b>. This means any user-facing components can only be accessed by users inside of your account. Click the Access button below to change this.
                    </span>
                );
        }
    };

    const makeOnlyFirstLetterCapitalised = (s) => {
        return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
    };

    return (
        <Backdrop open={publishConfirmDialog} style={{ zIndex: 10000 }}>
            <Confetti gravity={0.2} />
            <CustomDialog
                isOpen={publishConfirmDialog}
                fullWidth={true}
                maxWidth={"sm"}
                backdropStyle={"background"}
                publish={true}
                title={
                    <Grid style={{ display: "flex" }}>
                        <CheckCircle className="check-icon" style={{ marginRight: 8 }} /> Workflow successfully published
                    </Grid>
                }
                contents={
                    <Grid container item xs={12} direction={"column"}>
                        <p className={"bold"}>Production</p>
                        <p className={"s-text rmv-padding"}>This workflow is now running on:</p>

                        <Grid className={"frame"}>
                            <p>Version {publishedVersion}</p>
                            <p className={" s-text"}>
                                {`Published ${" "}
                    ${format(lastPublished, ProjectStore.state.timezone)}`}
                            </p>
                        </Grid>

                        <p className={"bold"}>Notes/comments</p>
                        <Grid className={"frame"}>
                            <p className="multiline-text">{publishComment}</p>
                        </Grid>

                        <p className={"bold"}>Draft</p>
                        <p className={"s-text rmv-padding"}>Any new changes to the workflow canvas will be saved as:</p>

                        <Grid className={"frame"}>
                            <p>Version {draftVersion} (Draft)</p>
                        </Grid>
                        <Grid container>
                            <Grid item xs={12}>
                                <p className={"bold"}>Your workflow is set to {makeOnlyFirstLetterCapitalised(accessControl)} Access</p>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container rowSpacing={1}>
                                    <Grid item xs={12}>
                                        <WarningBox text={getAccessText()} hideIcon={true} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button
                                            onClick={async () => {
                                                await onDonePublish();
                                                setOpenAccess(true);
                                            }}
                                            variant={"outlined"}
                                            startIcon={<Security />}
                                        >
                                            access
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <p className={"bold"}>Was this a mistake?</p>
                        <Link to={`/project/version_history/${projectId}`} className={"link"}>
                            <p className={"rmv-padding"}>Click here to reverse this deployment</p>
                        </Link>
                        <p className={"bold "}>Share this workflow as a template</p>
                        <p className={"s-text rmv-padding"}>Share the workflow you just deployed as a template so other users can run it as well. </p>
                    </Grid>
                }
                buttons={
                    <>
                        <Button
                            startIcon={<PlayCircleOutline />}
                            variant={"contained"}
                            color={"primary"}
                            className={"start"}
                            onClick={() => {
                                setPublishConfirmDialog(false);
                                setLoadingDialog(true);
                                unpublishedChanges(true);
                            }}
                            style={{
                                backgroundColor: "#55a77a",
                            }}
                        >
                            Run
                        </Button>
                        <Button onClick={handleShare} variant={"contained"} color={"error"}>
                            Share Workflow
                        </Button>
                        <Button onClick={onDonePublish} variant={"contained"} color={"primary"}>
                            Done
                        </Button>
                    </>
                }
            />
        </Backdrop>
    );
}

export default WorkflowPublishedDialog;
