import React, { useEffect, useState } from "react";
//mui
import {
  Grid,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  Button,
  Tooltip,
  IconButton,
  Menu,
  DialogContent,
  Dialog,
  Typography,
  CircularProgress,
  Skeleton
} from "@mui/material";
import FadeIn from "react-fade-in";

import RibbonItem from "vardogyir-ui/RibbonItem";
//css
import { withStyles } from "@mui/styles";
import DashboardStyles from "./DashboardStyles";
//navigation through the router
import { Link } from "react-router-dom";
import { useNavigate } from "react-router";
//utils to send requests
import { send_request } from "../../utils/Request";
//icons
import MoreVertIcon from "@mui/icons-material/MoreVert";
//dialogs
import { CustomDialog } from "../Component/Dialog";
//to handle dates
import { format } from "../../utils/Date";
import ProjectStore from "../ProjectCanvas/ProjectStore";
//icons
import { Emoji } from "emoji-mart";
import { Picker } from "emoji-mart";
import "emoji-mart/css/emoji-mart.css"; //this needs to be imported to render icons dialog properly
import { showLoadingScreen } from "../../utils/showLoadingScreen";
import CardsLoadingSkeleton from "../../utils/CardsLoadingSkeleton";
import DashboardCard from "./DashboardCard";
import { styled } from "@mui/material/styles";

const StyledSkeleton = styled(Skeleton)(({ theme }) => ({
  borderRadius: "4px",
  background: "#E8E8E8 !important",
  display: "inline-block",
  marginRight: "24px",
}));

const styles = DashboardStyles;

const Dashboards = (props) => {
  //styles
  const classes = props.classes;
  //react router
  const navigate = useNavigate();
  //ui state
  const [dashboards, setDashboards] = useState([]);
  const [menuOpen, setMenuOpen] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [changeName, setChangeName] = useState(false);
  const [activeDashboard, setActiveDashboard] = useState({});
  const [confirmValue, setConfirmValue] = useState("");
  const [newDashboardName, setNewDashboardName] = useState("");
  const [dashboardName, setDashboardName] = useState("");
  const [changeImage, setChangeImage] = useState(false);
  const [createDashboard, setCreateDashboard] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadButton, setLoadButton] = useState(false);
  //handle search
  const [searchTerm, setSearchTerm] = useState("");
  const [searchDashboards, setSearchDashboards] = useState([]);
  const [defaultDashboards, setDefaultDashboards] = useState([]);
  //on load up
  useEffect(() => {
    send_request("dashboard/dashboard/dashboards", "", {}, "get")
      .then((response) => {
        console.log(response.data);
        setDashboards(response.data);
        setDefaultDashboards(response.data);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);
  //handle searching
  const handleSearch = (e) => {
    e.preventDefault();
    const word = e.target.value.toLowerCase();
    setSearchTerm(word);
    setSearchDashboards(dashboards);

    searchDashboards.map((i) =>
      i.name === null ? (i.name = "Untitled") : (i.name = i.name)
    );

    let result = searchDashboards.filter((item) =>
      item.name.toLowerCase().includes(word)
    );

    if (result.length == 0 || word == "") {
      setDashboards(defaultDashboards);
    } else {
      setDashboards(result);
    }
  };

  const renderEmoji = (image) => {
    // Checks if image path is not an emoji
    if (!image || image.charAt(0) !== ":") {
      return ":bar_chart:";
    } else {
      return image;
    }
  };

  //create
  const handleCreateDashboard = async () => {
    setLoadButton(true);

    await send_request("dashboard/dashboard/create", "", {}, "post")
      .then(async (response) => {
        let dashboard = response.data;
        dashboard.name = newDashboardName;
        await send_request(
          `dashboard/dashboard/update/${dashboard.id}`,
          dashboard,
          "",
          "PATCH"
        ).then(() => {
          setNewDashboardName("");
          setCreateDashboard(false);
          setLoadButton(false);

          navigate(`/dashboard/${dashboard.id}`);
        });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  //update
  const handleChangeName = async () => {
    setLoadButton(true);

    activeDashboard.name = dashboardName;
    await send_request(
      `dashboard/dashboard/update/${activeDashboard.id}`,
      activeDashboard,
      "",
      "PATCH"
    )
      .then(() => {
        setChangeName(false);
        setDashboardName("");
        setLoadButton(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const handleChangeImage = async (emoji) => {
    activeDashboard.image = emoji.colons;
    setLoadButton(true);
    await send_request(
      `dashboard/dashboard/update/${activeDashboard.id}`,
      activeDashboard,
      "",
      "PATCH"
    )
      .then(() => {
        setChangeImage(false);
        setLoadButton(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  //delete
  const handleDelete = async () => {
    setLoadButton(true);
    await send_request(
      `dashboard/dashboard/delete/${activeDashboard.id}`,
      "",
      {},
      "post"
    )
      .then(() => {
        setDashboards(
          dashboards.filter((dashboard, key) => {
            return dashboard.id != activeDashboard.id;
          })
        );
        setConfirmValue("");
        setConfirmDelete(false);
        setLoadButton(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleMenuOpen = (event, dashboard) => {
    setMenuOpen(true);
    setMenuAnchorEl(event.currentTarget);
    setActiveDashboard(dashboard);
  };

  return (
    <>
      <Grid
        container
        item
        xs={12}
        direction={"column"}
        className={classes.root}
      >
        {/* Page header */}
        <h3 className={"pageHeader"}>Dashboards</h3>
        <Grid container item xs={11} direction={"row"} alignItems={"center"}>
          {loading ? (
            <div style={{ margin: "16px 0px" }}>
              <StyledSkeleton
                variant={"rectangular"}
                height={35}
                width={550}
                style={{ marginLeft: "32px" }}
              />
              <StyledSkeleton
                variant={"rectangular"}
                height={35}
                width={175}
              />
            </div>
          ) : (
            <>
              <TextField
                size={"small"}
                placeholder={"Search for a dashboard."}
                value={searchTerm}
                fullWidth
                onChange={(event) => {
                  handleSearch(event);
                }}
                className={classes.searchField}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <i className={"material-icons"}>search</i>
                    </InputAdornment>
                  )
                }}
              />
              <Button
                variant={"contained"}
                color={"info"}
                onClick={() => {
                  setCreateDashboard(true);
                }}
              >
                <i className={`material-icons ${classes.icon}`}>
                  add_circle_outline
                </i>{" "}
                NEW DASHBOARD
              </Button>
            </>
          )}
        </Grid>
        <Grid container spacing={3} className={"gridContainer"}>
          {/* Card */}
          {loading ? (
            <CardsLoadingSkeleton
              skeletonStyles={{
                borderRadius: "10px",
                height: "238px",
                background: "#E8E8E8 !important",
              }}
            />
          ) : (
            dashboards.map((dashboard, key) => (
              <Grid
                item
                key={key}
                xs={12}
                sm={6}
                md={3}
                lg={3}
                xl={2}
                className={classes.cardMargin}
              >
                <DashboardCard
                  dashboard={dashboard}
                  classes={classes}
                  onMenuOpen={handleMenuOpen}
                />
              </Grid>
            ))
          )}
        </Grid>
        {menuOpen && (
          <Menu
            className={classes.menu}
            open={menuOpen}
            anchorEl={menuAnchorEl}
            onClose={() => {
              setMenuOpen(false);
            }}
          >
            <RibbonItem
              onClick={() => {
                setChangeImage(true);
                setMenuOpen(false);
              }}
            >
              Change icon
            </RibbonItem>
            <RibbonItem
              onClick={() => {
                setChangeName(true);
                setMenuOpen(false);
              }}
            >
              Change Name
            </RibbonItem>
            <RibbonItem
              onClick={() => {
                setConfirmDelete(true);
                setMenuOpen(false);
              }}
            >
              Delete Dashboard
            </RibbonItem>
          </Menu>
        )}
        {/* Delete */}
        <CustomDialog
          isOpen={confirmDelete}
          size={"sm"}
          title={"🚩 Confirm delete database"}
          contents={
            <Grid container item xs={12} direction={"column"}>
              <p className={"s-text"}>
                Are you sure you want to delete this dashboard? This action
                cannot be undone and the dashboard cannot be recovered once it
                has been deleted. Type "permanently delete" below to confirm.
              </p>
              <TextField
                value={confirmValue}
                size={"small"}
                variant="outlined"
                placeholder="Type 'permanently delete' here to confirm"
                onChange={(event) => {
                  setConfirmValue(event.target.value);
                }}
              />
            </Grid>
          }
          buttons={
            <>
              {" "}
              <Button
                onClick={() => {
                  setConfirmValue("");
                  setConfirmDelete(false);
                  setLoadButton(false);
                }}
                variant={"outlined"}
                color={"info"}
                disabled={loadButton}
              >
                {loadButton && (
                  <CircularProgress size={24} className="buttonProgress" />
                )}
                CANCEL
              </Button>
              <Button
                onClick={() => handleDelete()}
                variant={"contained"}
                color={"secondary"}
                disabled={confirmValue == "permanently delete" ? false : true}
              >
                {loadButton && (
                  <CircularProgress size={24} className="buttonProgress" />
                )}
                CONFIRM
              </Button>
            </>
          }
        />
        {/* Change name */}
        <CustomDialog
          size={"sm"}
          isOpen={changeName}
          title={"Change dashboard name"}
          contents={
            <Grid
              container
              item
              xs={12}
              direction={"row"}
              alignItems={"center"}
            >
              <p>Dashboard name</p>
              <TextField
                size={"small"}
                value={dashboardName}
                variant="outlined"
                className={classes.name}
                onChange={(event) => {
                  setDashboardName(event.target.value);
                }}
              />
            </Grid>
          }
          buttons={
            <>
              <Button
                onClick={() => {
                  setDashboardName(activeDashboard.name);
                  setChangeName(false);
                  setLoadButton(false);
                }}
                variant={"outlined"}
                color={"info"}
                disabled={loadButton}
              >
                CANCEL
                {loadButton && (
                  <CircularProgress size={24} className="buttonProgress" />
                )}
              </Button>
              <Button
                onClick={() => {
                  handleChangeName();
                }}
                variant={"contained"}
                color={"primary"}
                disabled={loadButton}
              >
                SAVE
                {loadButton && (
                  <CircularProgress size={24} className="buttonProgress" />
                )}
              </Button>
            </>
          }
        />

        {/* New dashboard dialog */}

        <CustomDialog
          isOpen={createDashboard}
          title={"📊 Create new dashboard"}
          contents={
            <Grid container item xs={12} direction={"column"}>
              <p>Dashboard name</p>
              <TextField
                value={newDashboardName}
                variant={"outlined"}
                size={"small"}
                onChange={(event) => setNewDashboardName(event.target.value)}
              />
            </Grid>
          }
          buttons={
            <>
              <Button
                variant={"outlined"}
                disabled={loadButton}
                onClick={() => {
                  setLoadButton(false);
                  setCreateDashboard(false);
                  setNewDashboardName("");
                }}
              >
                {loadButton && (
                  <CircularProgress size={24} className="buttonProgress" />
                )}
                Cancel
              </Button>
              <Button
                color={"primary"}
                variant={"contained"}
                onClick={handleCreateDashboard}
                disabled={loadButton}
              >
                {loadButton && (
                  <CircularProgress size={24} className="buttonProgress" />
                )}
                Done
              </Button>
            </>
          }
        />

        {/* Change image */}
      </Grid>

      <Dialog
        open={changeImage}
        size={"sm"}
        onClose={() => {
          setChangeImage(false);
        }}
      >
        <DialogContent style={{ padding: 0 }}>
          <div className="imageFrame">
            <Picker
              set="twitter"
              title="Pick your emoji..."
              emoji="point_up"
              onSelect={handleChangeImage}
            />
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default withStyles(styles)(Dashboards);