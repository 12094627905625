export const insertVideoPopup = `
                <div class="jodit-popup__content">
                  <div class="jodit-tabs">
                    <div class="jodit-tabs__buttons"><button
                        class="jodit-ui-button jodit-ui-button_size_middle jodit-ui-button_status_initial jodit-ui-button_link jodit-ui-button_text-icons_true jodit-tabs__button jodit-tabs__button_columns_2"
                        type="button" role="button" aria-pressed="true" tabindex="-1" data-ref="link" ref="link"><span
                          class="jodit-ui-button__icon"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1792 1792"
                            class="jodit-icon_link jodit-icon">
                            <path
                              d="M1520 1216q0-40-28-68l-208-208q-28-28-68-28-42 0-72 32 3 3 19 18.5t21.5 21.5 15 19 13 25.5 3.5 27.5q0 40-28 68t-68 28q-15 0-27.5-3.5t-25.5-13-19-15-21.5-21.5-18.5-19q-33 31-33 73 0 40 28 68l206 207q27 27 68 27 40 0 68-26l147-146q28-28 28-67zm-703-705q0-40-28-68l-206-207q-28-28-68-28-39 0-68 27l-147 146q-28 28-28 67 0 40 28 68l208 208q27 27 68 27 42 0 72-31-3-3-19-18.5t-21.5-21.5-15-19-13-25.5-3.5-27.5q0-40 28-68t68-28q15 0 27.5 3.5t25.5 13 19 15 21.5 21.5 18.5 19q33-31 33-73zm895 705q0 120-85 203l-147 146q-83 83-203 83-121 0-204-85l-206-207q-83-83-83-203 0-123 88-209l-88-88q-86 88-208 88-120 0-204-84l-208-208q-84-84-84-204t85-203l147-146q83-83 203-83 121 0 204 85l206 207q83 83 83 203 0 123-88 209l88 88q86-88 208-88 120 0 204 84l208 208q84 84 84 204z">
                            </path>
                          </svg></span><span class="jodit-ui-button__text">Link</span></button><button
                        class="jodit-ui-button jodit-ui-button_size_middle jodit-ui-button_status_initial jodit-ui-button_source jodit-ui-button_text-icons_true jodit-tabs__button jodit-tabs__button_columns_2"
                        type="button" role="button" aria-pressed="false" tabindex="-1" data-ref="source" ref="source"><span
                          class="jodit-ui-button__icon"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1792 1792"
                            class="jodit-icon_source jodit-icon">
                            <path
                              d="M553 1399l-50 50q-10 10-23 10t-23-10l-466-466q-10-10-10-23t10-23l466-466q10-10 23-10t23 10l50 50q10 10 10 23t-10 23l-393 393 393 393q10 10 10 23t-10 23zm591-1067l-373 1291q-4 13-15.5 19.5t-23.5 2.5l-62-17q-13-4-19.5-15.5t-2.5-24.5l373-1291q4-13 15.5-19.5t23.5-2.5l62 17q13 4 19.5 15.5t2.5 24.5zm657 651l-466 466q-10 10-23 10t-23-10l-50-50q-10-10-10-23t10-23l393-393-393-393q-10-10-10-23t10-23l50-50q10-10 23-10t23 10l466 466q10 10 10 23t-10 23z">
                            </path>
                          </svg></span><span class="jodit-ui-button__text">Code</span></button></div>
                    <div class="jodit-tabs__wrapper">
                      <div class="jodit-tab jodit-tab_active">
                        <form class="jodit-ui-form" dir="auto">
                          <div class="jodit-ui-block jodit-ui-block_align_left jodit-ui-block_size_middle">
                            <div class="jodit-ui-input jodit-ui-block__url"><span class="jodit-ui-input__label">URL</span>
                              <div class="jodit-ui-input__wrapper"><input class="jodit-ui-input__input" dir="auto" name="url"
                                  type="text" data-ref="url" ref="url" required="true" placeholder="https://"></div>
                            </div>
                          </div>
                          <div class="jodit-ui-block jodit-ui-block_align_left jodit-ui-block_size_middle"><button
                              class="jodit-ui-button jodit-ui-button_size_middle jodit-ui-button_status_primary jodit-ui-button_text-icons_true"
                              type="submit" role="button" aria-pressed="false" tabindex="-1"><span
                                class="jodit-ui-button__icon"></span><span class="jodit-ui-button__text">Insert</span></button></div>
                        </form>
                      </div>
                      <div class="jodit-tab">
                        <form class="jodit-ui-form" dir="auto">
                          <div class="jodit-ui-block jodit-ui-block_align_left jodit-ui-block_size_middle">
                            <div class="jodit-ui-text-area jodit-ui-block__code"><span class="jodit-ui-text-area__label">Embed
                                code</span>
                              <div class="jodit-ui-text-area__wrapper"><textarea class="jodit-ui-text-area__input" dir="auto"
                                  name="code" type="text" data-ref="code" ref="code" required="true" placeholder=""></textarea></div>
                            </div>
                          </div>
                          <div class="jodit-ui-block jodit-ui-block_align_left jodit-ui-block_size_middle"><button id="insert"
                              class="jodit-ui-button jodit-ui-button_size_middle jodit-ui-button_status_primary jodit-ui-button_text-icons_true"
                              type="submit" role="button" aria-pressed="false" tabindex="-1"><span
                                class="jodit-ui-button__icon"></span><span class="jodit-ui-button__text jodit-ui-button_variant_primary ">Insert</span></button></div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
`