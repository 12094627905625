import React, {useCallback, useState, useEffect, useRef} from "react";
import { TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  description: (props) => ({
    margin: "8px",
    position: "absolute",
    top: props.top,
    borderRadius: "10px",
    backgroundColor: props.backgroundColor || "#f7f7f7",
    fontSize: "12px",
    zIndex: "-1!important",
    border: "1px solid #000000",
    outline: "none",
    resize: "none",
    width: "145%",
    overflow: "wrap",
    lineHeight: "1.5em",
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: "none",
      },
      "&:hover fieldset": {
        border: "none",
      },
      "&.Mui-focused fieldset": {
        border: "none",
      },
    },
    "& .MuiInputBase-input": {
      scrollbarWidth: "none",
      paddingRight: "16px",
      height: "auto",
    },
  }),
});

const NodeDescription = React.forwardRef(({ backgroundColor, parent, onDescriptionChange, ...props }, ref) => {
  const [top, setTop] = useState("300px");

  const handleDoubleClick = useCallback((event) => {
    event.stopPropagation();
  }, []);

  useEffect(() => {
    if (parent.current) {
      const resizeObserver = new ResizeObserver((entries) => {
        for (const entry of entries) {
          if (entry.target === parent.current) {
            const parentHeight = entry.contentRect.height;
            setTop(`${parentHeight}px`);
          }
        }
      });

      resizeObserver.observe(parent.current);

      return () => {
        resizeObserver.disconnect();
      };
    }
  }, [parent]);

  const handleDescriptionChange = (event) => {
    if (onDescriptionChange) {
      onDescriptionChange(event.target.value);
    }
  };

  const classes = useStyles({ backgroundColor, top });

  return (
    <TextField
      ref={ref}
      onDoubleClick={handleDoubleClick}
      multiline
      variant="outlined"
      className={classes.description}
      InputProps={{
        disableUnderline: true,
      }}
      {...props}
    />
  );
});

export default NodeDescription;