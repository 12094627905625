const styles = theme => ({
  gridContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr));",
    gridRowGap: 24,
    gridColumnGap: "15px"
  },
  headerLeft: {
    display: "grid",
    gridTemplateColumns: "auto 1fr auto",
    maxWidth: 570,
    gridColumnGap: "16px",
    alignItems: "center"
  },
  headerRight: {
    display: "flex",
    alignItems: "center"
  },
  main: {
    display: "grid",
    gridTemplateColumns: "auto 1fr"
  },
  wrap: {
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
    padding: 24,
    marginBottom: "16px",
    background: "#fff"
  },
  label: {
    fontWeight: 600,
    alignItems: "center",
    display: "grid"
  },
  label2: {
    fontWeight: 600,
    alignItems: "center",
    display: "grid",
    paddingRight: 24,
    minWidth: 90
  },
  select: {
    display: "inline-block"
  },
  reportSelect: {
    width: "100%",
    backgroundColor: "#F8F8F8",
    borderRadius: "4px",
    height: "36px",
    color: "#868686"
  },
  dropzone: {
    width: "100%",
    margin: "auto",
    backgroundColor: theme.palette.background.paper
  },
  custemIcon: {
    color: "#868686"
  },
  chipStyle: {
    backgroundColor: "rgba(33, 150, 243, 0.6)",
    "&:hover" : {
      backgroundColor: "#ED6696"
    }
  }
});
export default styles;
