import React from "react";

// MUI
import { Button, styled } from "@mui/material";

// Util
import { CustomDialog } from "../../../Component/Dialog";

const SaveAndCloseButton = styled(Button)({
    background: "#000",
    "&:hover": {
        background: "rgba(0, 0, 0, 0.85)",
    },
});

function ComponentAIDraftDiscardDialog(props) {
    const { setShowClosingAIDraftDialog, handleClosePaneSave } = props;

    return (
        <CustomDialog
            isOpen
            size={"sm"}
            title={"Save AI changes before closing?"}
            contents={
                <>
                    <p className={"s-text"}>The proposed component configuration from the AI is currently loaded into this component. Closing it will save these changes to the component.</p>
                    <p className={"s-text"}>To discard these changes without saving, close the AI panel before closing the component. </p>
                </>
            }
            buttons={
                <>
                    <Button
                        variant={"outlined"}
                        onClick={() => {
                            setShowClosingAIDraftDialog(false);
                        }}
                    >
                        Cancel
                    </Button>
                    <SaveAndCloseButton
                        variant={"contained"}
                        onClick={() => {
                            handleClosePaneSave();
                            setShowClosingAIDraftDialog(false);
                        }}
                    >
                        Save and Close
                    </SaveAndCloseButton>
                </>
            }
        />
    );
}

export default ComponentAIDraftDiscardDialog;
