import React, { Component } from "react";
import { send_component_save_request, send_request } from "../../utils/Request";
import { observer, inject } from "mobx-react";
import { withStyles } from "@mui/styles";
import { autorun, toJS, reaction, trace } from "mobx";
import { showLoadingScreen } from "../../utils/showLoadingScreen";
import AddIcon from "@mui/icons-material/Add";
import * as date from "date-and-time";
import { getCurrentTime } from "../../utils/getCurrentTime";
import { Grid } from "@mui/material";
import LoopThroughListStore from "./LoopThroughListStore";
import ItemLTL from "./Components/ItemLTL";
import projectStore from "../ProjectCanvas/ProjectStore";
import { IndividualComponentLoader } from "../../utils/ComponentLoadingSkeleton";

class LoopThroughListTemplate extends Component {
  state = {
    error: false,
    loading: false,
    description:null,
    status:null
  };
  componentDidMount() {
    if (this.props.component_id !== undefined) {
      this.setState({ loading: true });
      send_request(
        `project-service/project/component/query/${this.props.component_id}/at-version/${this.props.version}`,
        "",
        {}
      )
        .then(response => {
          if (response && response.data) {
            const { components } = response.data;
            if (components) {
              LoopThroughListStore.setComponentData(components[0]);
              this.props.onComponentDescriptionChanged(components[0].description);
              this.props.onComponentNameChanged(LoopThroughListStore.name);
              this.props.onLastModifiedChanged(
                LoopThroughListStore.lastModified
              );
              this.setState({ loading: false });
            }
          }

          if (this.props.aiComponentBuilderData && this.props.aiComponentBuilderData.data) {
            this.props.onComponentNameChanged(this.props.aiComponentBuilderData["name"]);
            LoopThroughListStore.setTemplateData(this.props.aiComponentBuilderData.data);
            this.props.onComponentDescriptionChanged(this.props.aiComponentBuilderData["explanation"]);
            this.setState({ description: this.props.aiComponentBuilderData["explanation"] });
            this.setState({ status: "AI_BUILD_GENERATED" });
          }

          this.setState({ loading: false });
          this.props.setChildTemplateLoaded(true);
        })
        .catch(err => {
          throw Error(err.message);
        });
    }
  }

  setLoopThroughListName = autorun(() => {
    LoopThroughListStore.setComponentName(this.props.ComponentName);
  });

  componentWillUnmount() {
    // Clear the mobX reactions
    this.saveLoopThroughListTemplate();
    this.reactToPlaceHolder();
  }

  saveLoopThroughListTemplate = reaction(
    () => this.props.SaveTrigger.triggerValue,
    () => {
      if (this.state.error) {
        this.props.showError();
        return;
      }
      const lastModified = getCurrentTime();
      LoopThroughListStore.setLastModified(lastModified);
      const template = toJS(LoopThroughListStore.template);

      const data = {
        type: template.type,
        componentData: {
          name: this.props.ComponentName,
          data: template.data,
          lastModified: lastModified
        },
        componentId: template.componentId
          ? template.componentId
          : this.props.component_id,
        status: this.state.status,
        description: this.props.ComponentDescription.value,
      };

      send_component_save_request(`component-service/loop-through-list/data`, data, "", "POST", this.props.SaveTrigger)
        .then(response => {
          if (response && response.status == 200) {
            this.props.showSuccess(data, this.props.SaveTrigger);
            this.props.onLastModifiedChanged(lastModified);
            projectStore.savedComponent = true;
          }
        })
        .catch(err => {
          throw err;
        });
    }
  );

  reactToPlaceHolder = reaction(
    () => toJS(this.props.SelectedPlaceholder),
    (placeholder, reaction) => {
      if (!placeholder["selected"]) return;
      const { style, key, isNotPlaceholder } = placeholder["selected"];

      this.insertPlaceholder(style.default, key, style, isNotPlaceholder);
    }
  );
  insertPlaceholder = (label, text, style, isNotPlaceholder) => {
    const template = toJS(LoopThroughListStore.template);
    const index = template.position;
    const field = template.field;
    const posiCondition = template.posiCondition;
    const posiConditionIsMet = template.posiConditionIsMet;

    if (isNotPlaceholder !== true) {
      text = "${" + text + "}";
    }

    if (text) {
      if (posiCondition != null && posiCondition >= 0) {
        LoopThroughListStore.setCondition(
          text,
          index,
          posiCondition,
          "value",
          "conditions"
        );
      } else if (posiConditionIsMet != null && posiConditionIsMet >= 0) {
        LoopThroughListStore.setCondition(
          text,
          index,
          posiConditionIsMet,
          "value",
          "conditionIsMet"
        );
      } else {
        LoopThroughListStore.setComponent(text, index, field);
      }
    }
  };

  handleKeyChanges = error => {
    this.setState({ error });
  };

  render() {
    const { data, name } = toJS(LoopThroughListStore);
    if (this.state && !this.state.loading) {
      return (
        <Grid container xs={12} direction={"column"}>
          {data.map((component, index) => (
            <ItemLTL
              key={index}
              component={component}
              index={index}
              availablePlaceholders={this.props.availablePlaceholders}
              handleKeyChanges={this.handleKeyChanges}
              onPlaceholderSelected={this.props.onPlaceholderSelected}
            />
          ))}
        </Grid>
      );
    } else return <IndividualComponentLoader />;
  }
}

export default inject(
  "SelectedPlaceholder",
  "ComponentName",
  "ComponentDescription",
  "SaveTrigger"
)(observer(LoopThroughListTemplate));
