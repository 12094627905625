import "typeface-open-sans";
import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      light: "#7AC0F8",
      main: "#2196F3"
    },
    secondary: {
      light: "#ED6696",
      main: "#E10050"
    },
    info: {
      light: "#808080",
      main: "#000000"
    }
  },
  typography: {
    fontSize: 14,
    fontFamily: '"Open Sans"',
    h1: {
      fontSize: 44,
      color: "#868686",
      lineHeight: "60px"
    },
    h2: {
      fontSize: 32,
      color: "#868686",
      lineHeight: "44px"
    },
    h3: {
      fontSize: 24,
      fontWeight: 300,
      color: "#868686",
      lineHeight: "33px"
    },
    h4: {
      fontSize: 18,
      color: "#000000",
      lineHeight: "16px"
    },
    body1: {
      fontSize: 14,
      lineHeight: "19px"
    },
    body2: {
      fontSize: 14,
      lineHeight: "19px",
      fontWeight: 300
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        contained: {
          color: "#FFFFFF",
          boxShadow: "none",
          "&:active": {
            boxShadow: "none"
          }
        },
        outlined: {
          color: "#000000",
          borderColor: "#000000",
          backgroundColor: "#FFFFFF",
          boxShadow: "none",
          "&:active": {
            boxShadow: "none",
            backgroundColor: "#FFFFFF",
            color: "#2196F3",
            borderColor: "#2196F3"
          },
          "&:hover": {
            boxShadow: "none",
            backgroundColor: "#FFFFFF",
            color: "#2196F3",
            borderColor: "#2196F3"
          }
        },
        root: {
          outline: "none",
          boxShadow: "none"
        }
      }
    }
  }
});

export default theme;
