import React, { Component } from "react";
import ErrorIcon from "@mui/icons-material/Error";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import { toJS } from "mobx";
import { CopyToClipboard } from "react-copy-to-clipboard";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import PlaceholderJson from "../../Component/PlaceholderJson";
import GenerateToken from "../../Component/GenerateToken";
import { Grid } from "@mui/material";

class Summary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasToken: this.props.hasToken
    };
  }

  changeTokenStatus = status => {
    this.setState({ hasToken: status });
    this.props.changeTokenStatus(status);
  };

  render() {
    const { data } = toJS(this.props.componentStore);
    return (
      <Grid item xs={12} className="textAlignLeftWebhook">
        <Grid item xs={12}>
          <Grid
            item
            xs={12}
            style={{
              backgroundColor: "rgba(214, 234, 223, 1)",
              padding: "5px"
            }}
          >
            ✅ Set up has been completed for this Trigger from Zapier component.
            A summary of this component is detailed below.
          </Grid>
          <Grid item xs={12} className="webhook-title">
            <span>Production Endpoint URL</span>
            <br />
            <TextField
              disabled={true}
              value={
                "https://webhook.workflow86.com/zapier/" +
                this.props.component_id
              }
              className="key_padding textFieldWebhook"
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <CopyToClipboard
                      text={
                        "https://webhook.workflow86.com/zapier/" +
                        this.props.component_id
                      }
                    >
                      <FileCopyIcon className="custom-icon-btn" />
                    </CopyToClipboard>
                  </InputAdornment>
                )
              }}
            ></TextField>
          </Grid>
          {
            <Grid item xs={12} className="generateWebhook">
              {!this.state.hasToken ? (
                <span
                  className="err-text"
                  style={{
                    justifyContent: "flex-start",
                    fontWeight: "bold",
                    margin: "16px 0"
                  }}
                >
                  <ErrorIcon className="iconDiscard" />A token must be generated
                  before the Trigger from Zapier component can be used.
                </span>
              ) : (
                <span
                  className="token-already-generated"
                  style={{
                    justifyContent: "flex-start",
                    margin: "16px 0"
                  }}
                >
                  A token has already been generated. If you have lost this
                  token, you will need to generate a new one.
                </span>
              )}

              <GenerateToken
                isSingleLine={true}
                hasToken={status => {
                  this.changeTokenStatus(status);
                }}
                tokenStatus={this.props.hasToken}
                name={toJS(this.props.ComponentName)}
                component_id={this.props.component_id}
              />
            </Grid>
          }
          <Grid item xs={12} className="webhook-title">
            <span>
              The following placeholders will be generated from the incoming
              data{" "}
            </span>
            <span
              onClick={() => this.props.onChangeState(4, 4)}
              style={{
                color: "#2196F3",
                fontWeight: "normal",
                cursor: "pointer"
              }}
            >
              edit placeholders
            </span>
          </Grid>
          <PlaceholderJson
            componentStore={this.props.componentStore}
            data={toJS(this.props.componentStore).data}
            stepStatus={this.props.stepStatus}
            placeholderJson={data.samplePlaceholders}
          />
        </Grid>
        <Grid item xs={12} className="dialogContentRight">
          <Grid item xs={12}>
            <Button
              onClick={() => {
                this.props.onChangeState(6, 6);
              }}
              className="btnSpace"
              variant={"outlined"}
              color={"info"}
            >
              {" "}
              BACK
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default Summary;
