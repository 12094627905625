import React from "react";
//mui
import { Grid, Fade } from "@mui/material";
//styling
import { withStyles } from "@mui/styles";

const styles = () => ({
  gpt: {
    maxWidth: "100%!important",
    background: "#F5F5F5",
    borderRadius: "16px 16px 16px 0px",
    width: "fit-content",
    padding: "16px",
    fontSize: "14px",
    display: "flex",
    flexDirection: "column"
  },
  select: {
    minWidth: "250px",
    background: "#FFF"
  }
});

const WorkflowBuilderMessage = ({ classes }) => {
  return (
    <Fade timeout={1000} in={true}>
      <div className={classes.gpt}>
        <b>🔧 Build a new workflow</b>
        <p>
          To get started, describe the workflow you would like me to build.
        </p>
        <b>💡 Tip</b>
        <p>
          Include as much detail as possible if you want me to follow a
          specific structure or requirements. You can also provide numbered
          points to follow a specific sequence e.g. "1. Do this 2. then do
          this".
        </p>
      </div>
    </Fade>
  );
};

export default withStyles(styles)(WorkflowBuilderMessage);