import React from "react";
//mui
import { Grid, Fade } from "@mui/material";
//styling
import { withStyles } from "@mui/styles";

const styles = () => ({
    buttonMessage: {
        width: "100%",
    },
    buttonMessageText: {
        fontSize: "14px",
        color: "white",
        padding: "0px",
        margin: "0px",
    },
});

const ButtonMessage = ({ content, classes }) => {
    return (
        <Fade timeout={1000} in={true}>
            <Grid container item className={classes.buttonMessage} display="flex" justifyContent="flex-end">
                <p className={classes.buttonMessageText}>{content}</p>
            </Grid>
        </Fade>
    );
};

export default withStyles(styles)(ButtonMessage);
