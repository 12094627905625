import React, {Component} from "react";
import {send_component_save_request, send_request} from "../../utils/Request";
import {observer, inject} from "mobx-react";
import {withStyles} from "@mui/styles";
import {autorun, toJS, reaction, trace} from "mobx";
import {getCurrentTime} from "../../utils/getCurrentTime";
import CodeStore from "./CodeStore";
import styles from "./CodeStyle";
import projectStore from "../ProjectCanvas/ProjectStore";
import {IndividualComponentLoader} from "../../utils/ComponentLoadingSkeleton";
import Grid from "@mui/material/Grid";
import ChipInput from "../ChipInput/ChipInput";
import AceEditor from "react-ace";
import { addCompleter } from 'ace-builds/src-noconflict/ext-language_tools';
import PlaceholderItem from "./CodeComponent/PlaceholderItem";
import "../../fonts/JetBrainsMono-Regular.ttf"
import { CustomDialog } from  "../Component/Dialog";
import "ace-builds/src-noconflict/mode-javascript";
import 'ace-builds/src-noconflict/mode-python';
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/ext-language_tools";
import AddIcon from "@mui/icons-material/Add";
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import {JsDemoCode, PythonDemoCode} from "./CodeComponent/AceEditorPlaceholder";


class CodeTemplate extends Component {
  state = {
    error: false,
    loading: false,
    placeholders: [],
    emptyLine: {type: "text", key: ""},
    codePlaceholders: [],
    aceLangStyle: "python",
    changeDemo: true,
    description: null,
    status: null,
    willChangeLanguage: false,
    changeLangValue: "",
  };

  getNodeJSLangName = () =>{
    return "NodeJS"
  }

  getPythonLangName = () =>{
    return "Python"
  }

  componentDidMount() {
    if (this.props.component_id !== undefined) {
      this.setState({loading: true});
      send_request(
        `project-service/project/component/query/${this.props.component_id}/at-version/${this.props.version}`,
        ""
      )
        .then((response) => {
          if (response && response.data) {
            const {components} = response.data;
            if (components && components[0]) {
              CodeStore.setCodeData(components[0], this.props.version);
              this.props.onComponentDescriptionChanged(components[0].description);
              this.props.onComponentNameChanged(CodeStore.name);
              this.props.onLastModifiedChanged(CodeStore.lastModified);

              this.setState({loading: false});
              if (components[0].componentData
                && components[0].componentData.data
                && components[0].componentData.data.placeholders) {
                this.setState({codePlaceholders: components[0].componentData.data.placeholders});
              }
            }
          }
          if(!this.state.codePlaceholders || this.state.codePlaceholders.length === 0) {
            this.state.codePlaceholders = [];
            const newPlaceholder = {
              key: "",
              label: "",
              text: "",
              length: 1,
              style: {},
              type: "text",
            }
            this.state.codePlaceholders.push(newPlaceholder);
          }

          // Set ai component builder data if given
          if (this.props.aiComponentBuilderData && this.props.aiComponentBuilderData.data) {
            this.props.onComponentNameChanged(this.props.aiComponentBuilderData["name"]);
            this.props.onComponentDescriptionChanged(this.props.aiComponentBuilderData["explanation"]);
            CodeStore.setTemplateData(this.props.aiComponentBuilderData.data);
            this.setState({ description: this.props.aiComponentBuilderData["explanation"] });
            this.setState({ status: "AI_BUILD_GENERATED" });
            this.setState({ codePlaceholders: this.props.aiComponentBuilderData.data.placeholders });
          }

          this.props.setChildTemplateLoaded(true);
          this.setState({ loading: false });
        })
        .catch((err) => {
          throw Error(err.message);
        });
    }

    const placeholderParameters = [];
    if(this.props
      && this.props.availablePlaceholders
      && this.props.availablePlaceholders.length === 1
      && this.props.availablePlaceholders[0]
      && this.props.availablePlaceholders[0].placeholders
      && this.props.availablePlaceholders[0].placeholders.length > 0
    ) {
      for (let i = 0; i < this.props.availablePlaceholders[0].placeholders.length; i++) {
        const completeKey = "${" + this.props.availablePlaceholders[0].placeholders[i].key + "}";
        const newCompleteItem = {
            name: completeKey,
            value: completeKey,
            caption: completeKey,
            meta: 'local',
            score: 1000
          };
        placeholderParameters.push(newCompleteItem)
      }
    }
    addCompleter({
      getCompletions: function(editor, session, pos, prefix, callback) {
        callback(null, placeholderParameters);
      },
    });
  }

  componentWillUnmount() {
    this.saveCodeTemplate();
    this.reactToPlaceHolder();
  }

  setCodeName = autorun(() => {
    CodeStore.setCodeName(this.props.ComponentName);
  });

  saveCodeTemplate = reaction(
    () => this.props.SaveTrigger.triggerValue,
    () => {
      if (this.state.error) {
        this.props.showError();
        return;
      }

      const lastModified = getCurrentTime();
      CodeStore.setLastModified(lastModified);
      const template = toJS(CodeStore.template);

      const data = {
        type: template.type,
        componentData: {
          name: this.props.ComponentName,
          data: template.data,
          lastModified: lastModified,
        },
        componentId: template.componentId
          ? template.componentId
          : this.props.component_id,
        description: this.props.ComponentDescription.value,
        status: this.state.status
      };
      send_component_save_request(`component-service/code/data`, data, "", "POST", this.props.SaveTrigger)
        .then((response) => {
          if (response && response.status == 200) {
            this.props.showSuccess(data, this.props.SaveTrigger);
            this.props.onLastModifiedChanged(lastModified);
            projectStore.savedComponent = true;
          }
        })
        .catch((err) => {
          throw err;
        });
    }
  );

  reactToPlaceHolder = reaction(
    () => toJS(this.props.SelectedPlaceholder),
    (placeholder, reaction) => {
      if (!placeholder["selected"]) return;
      const {style, key} = placeholder["selected"];
      if (this.props.availablePlaceholders
        && this.props.availablePlaceholders.length === 1
        && this.props.availablePlaceholders[0].placeholders
      ) {
        for (let i = 0; i < this.props.availablePlaceholders[0].placeholders.length; i++) {
          if (key === this.props.availablePlaceholders[0].placeholders[i].key) {
            this.addPlaceholders("${" + key + "}", key, style, this.props.availablePlaceholders[0].placeholders[i]);
            this.addChip("${" + key + "}", "parameters");
            return;
          }
        }
      }
    }
  );

  addPlaceholders = (label, text, style, placeholder) => {
    const key = placeholder.key;
    const length = placeholder.length;
    const type = placeholder.type;
    CodeStore.addPlaceholders({label, text, style, key, length, type});
  };

  forceUpdateState = () => {
    let currentState = this.state.updateState;
    this.setState({updateState: !currentState});
  };

  addChip = (chip) => {
    if (chip && chip.trim() !== "") {
      CodeStore.setCode(chip, "parameters");
      return true;
    } else {
      return false;
    }
  };

  getCodeParameters = (parameters) => {
    return [{
      placeholders: parameters,
    }];
  }

  updateCodePlaceholdersByValue = (value) => {
    const placeholders = this.state.codePlaceholders;
    const index = value.index;
    if (index >= 0) {
      if (index < placeholders.length) {
        switch (value.attribute) {
          case "type":
            placeholders[index].type = value.value;
            break;
          case "key":
            placeholders[index].key = value.value;
            break;
        }
      } else {
        const placeholder = {};
        switch (value.attribute) {
          case "type":
            placeholder.type = value.value;
            break;
          case "key":
            if (!placeholder.type) {
              placeholder.type = "text";
            }
            placeholder.key = value.value;
            break;
        }
        placeholders.push(placeholder);
      }
    }
    return placeholders;
  }

  deleteStateFromIndex = (index) => {
    const placeholders = this.state.codePlaceholders;
    if (placeholders.length > index && index >= 0) {
      const newPlaceholders = [...placeholders];
      newPlaceholders.splice(index, 1);
      return newPlaceholders;
    }
    return placeholders;
  }

  codeLangChange = (value) => {
    if(value.includes(this.getNodeJSLangName())){
      if(this.state.changeDemo){
        CodeStore.setCode(JsDemoCode, "code");
      }
      this.setState({aceLangStyle: "javascript"});
    } else if (value.includes(this.getPythonLangName())) {
      if(this.state.changeDemo){
        CodeStore.setCode(PythonDemoCode, "code");
      }
      this.setState({aceLangStyle: "python"});
    }
    CodeStore.setCode([], "libraries");
    CodeStore.setCode(value,"codeLang");
  };

  render() {
    const {data} = toJS(CodeStore);

    if (this.state && !this.state.loading) {
      return (
        <div>
          <div className={this.props.classes.wrapCode}>
            <Grid container rowSpacing={3} alignItems="center">
              <Grid item xs={12}>
                <div className={this.props.classes.label}>
                  Set input Variable
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className={this.props.classes.secondLevelLabel}>
                  Select the placeholder values you want to pass in as variables to the code module
                </div>
              </Grid>
              <Grid item xs={12}>
                <ChipInput
                  inputValue={data.parameters}
                  onPlaceholderSelected={this.props.onPlaceholderSelected}
                  placeholders={this.props.availablePlaceholders}
                  placeholder="Select placeholders to use in the code. "
                  fullWidth
                  onDelete={index => {
                    CodeStore.deleteChip(index);
                    CodeStore.deletePlaceholders(index);
                    this.forceUpdateState();
                  }}
                />
              </Grid>
            </Grid>
          </div>

          <div className={this.props.classes.wrapCode}>
            <Grid container rowSpacing={3} alignItems="center">
              <Grid item xs={12}>
                <div className={this.props.classes.label}>
                  Set code
                </div>
              </Grid>
              <Grid item xs={12}>
                Code Language
              </Grid>
              <Grid item xs={12}>
                <Select
                  name="codeLang"
                  labelId="demo-simple-select-outlined-label"
                  className="dropdown-mat"
                  value={data.codeLang}
                  defaultValue={"Python_3.9"}
                  disableUnderline
                  variant="standard"
                  align="left"
                  onChange={value => {
                    this.setState({ 
                      willChangeLanguage: true,
                      changeLangValue: value.target.value
                     });
                  }}
                >
                  <MenuItem value={"NodeJS_16"}>NodeJS 16</MenuItem>
                  <MenuItem value={"Python_3_9"}>Python 3.9</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={12}>
                <div id="aceditor" style={{width: "100%", height: "700px"}}>
                  <AceEditor
                    mode={this.state.aceLangStyle}
                    wrapEnabled={true}
                    theme="github"
                    width="100%"
                    height="100%"
                    onChange={(value) => {
                      CodeStore.setCode(value, "code");
                      CodeStore.setCode(this.props.version, "version");
                      this.setState({"changeDemo": false});
                    }}
                    showPrintMargin={false}
                    showGutter={true}
                    highlightActiveLine={true}
                    value={data.code}
                    fontSize={16}
                    setOptions={{
                      enableBasicAutocompletion: true,
                      enableLiveAutocompletion: true,
                      enableSnippets: false,
                      showLineNumbers: true,
                      tabSize: 2
                    }}/>
                </div>
              </Grid>
            </Grid>  
          </div>

          <div className={this.props.classes.wrapCode}>
            <Grid container rowSpacing={3} alignItems="center">
              <Grid item xs={12}>
                <div className={this.props.classes.label}>
                  Set outputs
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className={this.props.classes.secondLevelLabel}>
                  Enter the key in the return statement to output the value as a placeholder
                </div>
              </Grid>
              <Grid item xs={12}>
                <Grid container xs={12} rowSpacing={2} spacing={2} alignItems={"center"}>
                  <Grid item xs={3}>
                    <div className={this.props.classes.label}>
                      Type
                    </div>
                  </Grid>
                  <Grid item xs={8}>
                    <div className={this.props.classes.label}>
                      Key
                    </div>
                  </Grid>
                  <Grid item xs={1}>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                  {this.state.codePlaceholders.map((placeholder, index) => (
                    <div style={{width: "100%", height: "64px"}}>
                      <PlaceholderItem
                        index={index}
                        total={data.placeholders.length}
                        placeholder={placeholder}
                        onChange={(value) => {
                          CodeStore.setCode(value, "placeholders");
                          this.setState({codePlaceholders: this.updateCodePlaceholdersByValue(value)});
                        }}
                        onDelete={(index) => {
                          CodeStore.deleteCodePlaceholder(index);
                          this.setState({codePlaceholders: this.deleteStateFromIndex(index)})
                        }}
                      />
                    </div>
                  ))}
              </Grid>
              <Grid item xs={12}>
                <Button
                  size="medium"
                  onClick={() => {
                    let indexNum = 0;
                    if (this.state.codePlaceholders) {
                      indexNum = this.state.codePlaceholders.length - 1;
                    }

                    const placeholderKey = {
                      attribute: "key",
                      index: indexNum + 1,
                      value: "",
                    }
                    CodeStore.setCode(placeholderKey, "placeholders");
                    this.setState({codePlaceholders: this.updateCodePlaceholdersByValue(placeholderKey)});
                    const placeholderType = {
                      attribute: "type",
                      index: indexNum + 1,
                      value: "text",
                    }
                    CodeStore.setCode(placeholderType, "placeholders");
                    this.setState({codePlaceholders: this.updateCodePlaceholdersByValue(placeholderType)});
                  }}
                >
                  <AddIcon/> Add Placeholder
                </Button>
              </Grid>
            </Grid>
          </div>  
           <CustomDialog
            isOpen={this.state.willChangeLanguage}
            size={"sm"}
            title={"Confirm language change"}
            contents={
              <>
                <p className={"s-text"}>
                  Changing from one coding language to another will wipe any code
                  in the current selected language. If you want to keep the
                  current code, you should copy and paste it somewhere before
                  swapping languages (e.g. in a note on the canvas). 
                </p>
                  <p className={"s-text"}>
                  Are you sure you want to continue?
                  </p>
              </>
            }
            buttons={<>

              <Button
                variant={"outlined"}
                onClick={() => {
                  this.setState({ willChangeLanguage: false });
                }}
              >
                Cancel
              </Button>
              <Button
                variant={"contained"}
                className={this.props.classes.loadMore}
                onClick={() => {
                  this.setState({ willChangeLanguage: false });
                  this.codeLangChange(this.state.changeLangValue);
                  }}
              >
                Continue
              </Button>
            
            </>}
          />
        </div>
        );
    } else return <IndividualComponentLoader/>;
  }
}

export default withStyles(styles)(
  inject(
    "SelectedPlaceholder",
    "ComponentName",
    "ComponentDescription",
    "SaveTrigger"
  )(observer(CodeTemplate))
);
