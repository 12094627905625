import React, {useContext, useState, useRef, useCallback, useEffect,} from "react";

// Util
import uuid from "uuid";
import {Handle} from "reactflow";
import {useNavigate, useParams} from "react-router";
import ProjectStore from "../../../../ProjectCanvas/ProjectStore";
import {hexToPastel} from "../../../../../utils/HexToPastelUtil";
import {createSaveEvent} from "../../../../../utils/createSaveEvent";
import {ComponentFactory} from "../../../../ProjectCanvas/ComponentRegistry";
import {send_request, send_request_graphql_mutation,} from "../../../../../utils/Request";

// Canvas
import {CanvasContext} from "../../WorkflowCanvas";
import {SAVE_EVENT, COPY_COMPONENT_SAVE_EVENT} from "../../CanvasQueries";

import {getElements, calculateNewItemPostion,} from "../../../../../utils/CanvasUtil";

// Custom components
import NodeDescription from "../../NodeDescription";
import CanvasReloadDialog from "../../CanvasReloadDialog";
import {CustomDialog} from "../../../../Component/Dialog";
import ConvertFormDialog from "../../Dialogs/ConvertFormDialog";

// MUI
import {CircularProgress, Tooltip, Button, Grid, Select, MenuItem, Autocomplete, TextField,} from "@mui/material";
import {withStyles} from "@mui/styles";

// Icons
import AddIcon from "@mui/icons-material/Add";
import Error from "@mui/icons-material/Error";
import {CheckCircle} from "@mui/icons-material";
import AltRouteIcon from "@mui/icons-material/AltRoute";

// MobX
import {toJS} from "mobx";
import {styled} from "@mui/system";
import {inject, observer} from "mobx-react";
import InstructionsInfo from "../../InstructionInfo";
import {getColourFromString} from "../../../../../utils/ColourNameGenerator";
import {PRODUCTION_CANVAS_VIEW_MODE, DEFAULT_CANVAS_VIEW_MODE, COMPONENT_ICON_ALT} from "../../../../../utils/CanvasConstants";

const styles = () => ({
  nodeInfo: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "absolute",
    top: "74px",
  },
  settings: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
    backgroundColor: "rgba(64, 64, 64, 1)",
    borderRadius: "8px",
    height: "40px",
  },
  nodeWrapper: {
    width: "250px !important",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  node: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  rmvMargin: {
    margin: "0px!important",
    fontSize: "12px",
  },
  port: {
    position: "relative !important",
    transform: "unset !important",
    right: "unset",
    height: "15px",
    width: "15px",
    backgroundColor: "#868686",
    " &:hover": {
      backgroundColor: "#2196f3",
    },
    " &:active": {
      backgroundColor: "#2196f3",
    },
  },
  filler: {
    width: "15px",
  },
  title: {
    fontSize: "8px",
  },
  nodeInfoBackground: {
    borderRadius: "8px",
    marginTop: "8px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    whiteSpace: "nowrap",
  },
  downsize: {
    height: "16px",
  },
  selectedText: {
    color: "#2196F3",
  },
  selectedIcon: {
    filter: "drop-shadow(6px 6px 0px rgba(0, 0, 0, 0.25))",
  },
  centerGrid: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  labelText: {
    maxWidth: "250px",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },

  svg: {
    zIndex: 3,
  },
  addPathButton: {
    position: "absolute",
    zIndex: "9999",
    right: "48px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid rgba(0, 0, 0, 1)",
    background: "#FFF",
    borderRadius: "4px",
    color: "rgba(0, 0, 0, 1)",
    padding: "4px 2px",
    minWidth: "8px",
    "&:hover": {
      color: "#2196f3",
      borderColor: "#2196f3",
      cursor: "pointer",
    },
  },
  addPathIcon: {
    height: "14px",
    width: "auto",
  },
  addPathTooltipTitle: {
    fontSize: "12px",
  },
});

const AIDraft = styled("div")(({ background, border }) => ({
  background: background,
  border: "1px solid " + border,
  borderRadius: "4px",
  margin: "8px",
  fontSize: "11px",
  padding: "4px",
}));

//modes allow different buttons to be displayed under different nodes
const CAN_EDIT = ["DEFAULT", "PRODUCTION"];
const CAN_DELETE = ["DEFAULT"];
const CAN_TEST = ["DEFAULT"];
const CAN_COPY = ["DEFAULT"];
const CAN_CONVERT = ["DEFAULT"];
const CAN_OPEN_IN_NEW_TAB = ["DEFAULT", "SAVE_PAUSED"];
const CAN_PREVIEW = [PRODUCTION_CANVAS_VIEW_MODE, DEFAULT_CANVAS_VIEW_MODE];
const CAN_OPEN_EDITABLE = [PRODUCTION_CANVAS_VIEW_MODE];
const CAN_OPEN_PRODUCTION_VIEW = ["DEFAULT"];
const CAN_RUN = [PRODUCTION_CANVAS_VIEW_MODE];

const Component = inject("CanvasStore")(
  observer(
    ({
      id,
      data,
      isConnectable,
      classes,
      selected,
      CanvasStore,
      xPos,
      yPos,
    }) => {
      const { modeInfo } = useContext(CanvasContext);
      const parentRef = useRef(null);
      const [height, setHeight] = useState(0);
      const ref = useRef(null);
      useEffect(() => {
        if (ref.current) {
          const newHeight = ref.current.clientHeight;
          setHeight(newHeight);
        }
      }, []);

      if (modeInfo == undefined) {
        //determine when to render ports
        const hasInput = data.hasInput;
        const hasOutput = data.hasOutput;
        return (
          <>
            <div className={classes.nodeWrapper}>
              <div className={classes.node}>
                {hasInput == true ? (
                  <Handle
                    className={`${classes.port}`}
                    type="target"
                    position="left"
                    isConnectable={true}
                  />
                ) : (
                  <div className={classes.filler} />
                )}
                <img src={data.logo} alt={COMPONENT_ICON_ALT} />
                {hasOutput == true ? (
                  <Handle
                    className={classes.port}
                    position="right"
                    type="source"
                    isConnectable={isConnectable}
                  />
                ) : (
                  <div className={classes.filler} />
                )}
              </div>
              <div className={classes.nodeInfoBackground}>
                <>
                  <p className={`${classes.rmvMargin} bold`}>
                    {data.displayName}
                  </p>
                  <p className={`${classes.rmvMargin} ${classes.labelText}`}>
                    {data.label}
                  </p>
                </>
              </div>
            </div>
          </>
        );
      } else {
        const [mode, setMode] = modeInfo;
        const { componentInfoHeader } = useContext(CanvasContext);

        const { nodes, edges } = CanvasStore;

        const { edit } = useContext(CanvasContext);
        const { forceStart } = useContext(CanvasContext);
        const { handleProductionCanvasMount } = useContext(CanvasContext);
        const { testPanel } = useContext(CanvasContext);
        const { canvasInstance } = useContext(CanvasContext);
        const { savingInfo } = useContext(CanvasContext);
        const [editComponent, setEditComponent] = edit ? edit : useState({});
        const [forceStartComponent, setForceStartComponent] = forceStart
          ? forceStart
          : useState({});
        const [saving, setSaving] = savingInfo ? savingInfo : useState({});
        const [reactFlowInstance, setReactFlowInstance] = canvasInstance
          ? canvasInstance
          : useState({});
        // dialog flags
        const [deleteNode, setDeleteNode] = useState(false);
        const [copyNode, setCopyNode] = useState(false);
        const [workflowType, setWorkflowType] = useState("current_workflow");
        const [workflows, setWorkflows] = useState([]);
        const [workflow, setWorkflow] = useState("");
        const [moreComponents, setMoreComponents] = useState(false);
        const [loadCancel, setLoadCancel] = useState(false);
        const [loadCopy, setLoadCopy] = useState(false);
        const [node, setNode] = useState([]);

        //form transform flags
        const [openConvertForm, setOpenConvertForm] = useState(false);
        const [convertTo, setConvertTo] = useState({});

        //Selected components
        const [isSelectedComponent, setIsSelectedComponent] = useState(false);
        const [isSelectedForceStart, setIsSelectedForceStart] = useState(false);
        const [svgSelectedClass, setSvgSelectedClass] = useState("");

        //record last modified value for the canvas
        const { updated } = useContext(CanvasContext);
        const { productionVersion } = useContext(CanvasContext);
        const { loadDialog } = useContext(CanvasContext);
        const { unpublishedChanges } = useContext(CanvasContext);
        const [lastModified, setLastModified] = updated
          ? updated
          : useState({});
        const [showEditDialog, setShowEditDialog] = useState(false);
        const [openTestPanel, setOpenTestPanel] = testPanel
          ? testPanel
          : useState({});
        const [
          showCheckingLatestDialog,
          setShowCheckingLatestDialog,
        ] = useState(false);
        const [
          componentInfoForNodeHeader,
          setComponentInfoForNodeHeader,
        ] = componentInfoHeader ? componentInfoHeader : useState([]);

        const [loadingDialog, setLoadingDialog] = loadDialog
          ? loadDialog
          : useState(false);
        const [checkUnpublishedChanges] = unpublishedChanges
          ? unpublishedChanges
          : "";
        //determine when to render ports
        const hasInput = data.hasInput;
        const hasOutput = data.hasOutput;

        //react-router param to use navigation
        const navigate = useNavigate();

        //react-router use params
        const params = useParams();

        let projectId = params.id;
        let componentToRebuild = componentInfoForNodeHeader.filter(
          (item) => item.componentId === id
        );
        let status = null;
        if (
          componentToRebuild !== undefined &&
          componentToRebuild.length > 0 &&
          componentToRebuild[0].status
        ) {
          if (
            componentToRebuild[0].status === "AI_BUILD_GENERATED" ||
            componentToRebuild[0].status === "AI_EDIT_GENERATED"
          ) {
            status = "Completed";
            if (data.description !== componentToRebuild[0].description) {
              data.description = componentToRebuild[0].description;
            }
          } else if (
            componentToRebuild[0].status === "AI_BUILD_ERROR" ||
            componentToRebuild[0].status === "AI_BUILD_CANCELLED" ||
            componentToRebuild[0].status === "AI_EDIT_ERROR" ||
            componentToRebuild[0].status === "AI_EDIT_CANCELLED"
          )
            status = "Error";
          else {
            if (!componentToRebuild[0].isEdit) status = "Building...";
            else status = "In progress...";
          }
        }

        const handleDelete = async () => {
          if (saving) return;

          setSaving(true);
          let result = [...nodes, ...edges];

          if (data.type === "conditional_workflow_path") {
            // Delete next components on canvas
            const nextCompToDelete = toJS(data.next);
            if (Array.isArray(nextCompToDelete)) {
              const componentIdsToDelete = nextCompToDelete.map(
                (item) => item.componentId
              );
              result = result.filter(
                (node) =>
                  !(
                    componentIdsToDelete.includes(node.id) &&
                    node.data.type === "conditional_workflow" &&
                    node.data.fromWorkflowPath === true
                  )
              );
            }
          }

          result = result.filter((node) => {
            return node.id != id;
          });

          let oldDbid = ProjectStore.state.dbIds.get(id);
          if (oldDbid !== undefined) {
            ProjectStore.state.dbIds.delete(id);

            if (new Set(ProjectStore.state.dbIds.values()).has(oldDbid)) {
              if (id.displayName === "Query Database") {
                result = result.filter((node) => {
                  return !(
                    node.target === id.componentId && node.source === oldDbid
                  );
                });
              } else {
                result = result.filter((node) => {
                  return !(
                    node.source === id.componentId && node.target === oldDbid
                  );
                });
              }
            } else {
              result = result.filter((node) => {
                return node.id != oldDbid;
              });
            }
          }

          CanvasStore.setNodesAndEdges(result);

          let rfiObj = reactFlowInstance.toObject();

          let viewport = {
            x: rfiObj.viewport.x.toFixed(5),
            y: rfiObj.viewport.y.toFixed(5),
            zoom: rfiObj.viewport.zoom.toFixed(5),
          };

          let eventData = {
            componentId: id,
            type: data.type,
          };

          let saveEvent = createSaveEvent(
            "DELETE_COMPONENT",
            ProjectStore.project_id,
            viewport,
            getElements(reactFlowInstance),
            eventData
          );

          //send event
          await send_request_graphql_mutation(
            `project-service/graphql/project/save/${ProjectStore.project_id}`,
            SAVE_EVENT(saveEvent),
            "",
            "POST"
          )
            .then((response) => {
              if (response.data.saveEvent && response.data.saveEvent.updated) {
                setLastModified(response.data.saveEvent.lastModified);
              }
              //insert delay here
              setSaving(false);
            })
            .catch((error) => {
              console.log(error);
            });
        };

        const handleOpenEditable = async () => {
          let url = "";

          if (data.fromWorkflowPath)
            url = `/project/canvas/${projectId}?view=${data.parentId}`;
          else {
            url = `/project/canvas/${projectId}?view=${id}`;
          }

          ProjectStore.state.canvasView = false;
          ProjectStore.state.production = false;

          navigate(url);
        };

        const handlePreview = () => {
          if (mode === DEFAULT_CANVAS_VIEW_MODE) {
            ProjectStore.state.canvasView = true;
          } else if (mode === PRODUCTION_CANVAS_VIEW_MODE) {
            // Don't allow to editing
            ProjectStore.state.production = true;
            ProjectStore.state.canvasView = false;
          }

          if (edit) {
            if (!editComponent) {
              data.componentId = id;
              setEditComponent(data);
            }
          }
        };
        const handleRun = async () => {
          if (!data) return;
          setShowCheckingLatestDialog(true);
        };

        const handleEdit = async () => {
          if (saving) return; // If saving, return

          setSaving(true);
          //save viewport
          let rfiObj = reactFlowInstance.toObject();

          let viewport = {
            x: rfiObj.viewport.x.toFixed(5),
            y: rfiObj.viewport.y.toFixed(5),
            zoom: rfiObj.viewport.zoom.toFixed(5),
          };

          let saveEvent = createSaveEvent(
            "SAVE_VIEWPORT",
            ProjectStore.project_id,
            viewport,
            getElements(reactFlowInstance),
            {}
          );

          //send event
          await send_request_graphql_mutation(
            `project-service/graphql/project/save/${ProjectStore.project_id}`,
            SAVE_EVENT(saveEvent),
            "",
            "POST"
          )
            .then((response) => {
              // handle navigation
              if (edit) {
                if (!editComponent) {
                  data.componentId = id;
                  setEditComponent(data);
                }
              }
              if (response.data.saveEvent && response.data.saveEvent.updated) {
                setLastModified(response.data.saveEvent.lastModified);
              }
              //insert delay here
              setSaving(false);
            })
            .catch((error) => {
              console.log(error);
            });

          if (mode == DEFAULT_CANVAS_VIEW_MODE) {
            if (edit) {
              if (!editComponent) {
                data.componentId = id;
                setEditComponent(data);
              }
            }
          }
        };

        //copy dialog requests
        const handleSelectWorkflowType = async (value) => {
          setWorkflowType(value);
          setLoadCopy(true);

          if (value == "another_workflow" && workflows.length == 0) {
            const json = await send_request(
              `project-service/project/get_workflow_names/${projectId}`
            ).catch((e) => {
              console.log(e);
              closeCopy();
            });

            if (json && json.status == 200) {
              setWorkflows(json.data);
            }
          } else setLoadCopy(false);
        };

        const handleSelectWorkflow = async (input) => {
          if (input == "select_workflow" && workflowType == "another_workflow")
            setLoadCopy(true);
          else setLoadCopy(false);

          setWorkflow(input);
        };

        const closeCopy = () => {
          setWorkflow("select_workflow");
          setWorkflowType("current_workflow");
          setNode({});
          setCopyNode(false);
          setWorkflows([]);
          setLoadCopy(false);
          setLoadCancel(false);
        };

        const openInNewTab = () => {
          if (editComponent)
            //if component pane is open
            setEditComponent(null); //we unmount the component which triggers a save, closes the pane and dumps observables

          //Redirect to Component Page Url
          let url = window.location.pathname + `/${data.type}/${id}`;
          window.open(url, "_blank");
        };

        const requestCopy = async () => {
          if (nodes.length < 250) {
            setLoadCopy(true);
            setLoadCancel(true);

            let copyToId = "";

            if (workflowType == "current_workflow") {
              copyToId = projectId;
            } else {
              copyToId = workflow.projectId;
            }

            let eventData = {
              projectId: copyToId,
              componentId: id,
            };

            let rfiObj = reactFlowInstance.toObject();

            let viewport = {
              x: rfiObj.viewport.x.toFixed(5),
              y: rfiObj.viewport.y.toFixed(5),
              zoom: rfiObj.viewport.zoom.toFixed(5),
            };

            let saveEvent = createSaveEvent(
              "COPY_COMPONENT",
              ProjectStore.project_id,
              viewport,
              getElements(reactFlowInstance),
              eventData
            );

            //send event
            const json = await send_request_graphql_mutation(
              `project-service/graphql/project/save/${ProjectStore.project_id}`,
              COPY_COMPONENT_SAVE_EVENT(saveEvent),
              "",
              "POST"
            ).catch((e) => {
              console.log(e);
              closeCopy();
            });

            //handle updating canvas if we create a new component
            if (
              json &&
              json.status == 200 &&
              (workflowType == "current_workflow" || workflow == projectId)
            ) {
              console.log(json.data.saveEvent);
              let component = json.data.saveEvent.copiedComponent;
              let template = ComponentFactory[component.type];

              let nodesAndEdgesResult = [...nodes, ...edges];

              const newNode = {
                id: component.componentId,
                type: "component",
                position: component.viewData,
                data: {
                  instruction: component.instruction
                    ? component.instruction
                    : "",
                  type: component.type,
                  label: component.name,
                  displayName: template.displayName,
                  logo: template.logo,
                  hasInput: template.hasInput ? template.hasInput : false,
                  hasOutput: template.hasOutput ? template.hasOutput : false,
                  baseColor: template.baseColor,
                },
              };

              console.log(newNode);
              // Check if the copy component has a database connection
              if (ProjectStore.state.dbIds.has(id)) {
                ProjectStore.createDbNodeLogic(
                  component,
                  ProjectStore.state.dbIds,
                  nodesAndEdgesResult,
                  ProjectStore.state.dbIds.get(id),
                  newNode.data
                );
                newNode.data.hasDb = true;
              }

              nodesAndEdgesResult.push(newNode);

              // Set the nodes and edges
              CanvasStore.setNodesAndEdges(nodesAndEdgesResult);
            }
          } else setMoreComponents(true);

          closeCopy();
        };

        const isForm = (type) => {
          if (
            type == "form" ||
            type == "form_section" ||
            type == "sequential_form"
          )
            return true;

          return false;
        };

        const handleConvertTo = (value) => {
          setConvertTo(value);
        };

        const openTestComponentPanel = async () => {
          // This is used when opening the test panel from the node
          // We want to open the component pane if it isn't already open
          await handleEdit();
          setOpenTestPanel(id);
        };

        const handleComponentConverted = (newType, changeTo, componentId) => {
          nodes.map((component) => {
            if (component.id == componentId) {
              component.data.type = changeTo.name;
              component.data.logo = changeTo.logo;
              component.data.baseColor = changeTo.baseColor;
              component.data.hasInput = changeTo.hasInput;
              component.data.hasOutput = changeTo.hasOutput;
              component.data.displayName = changeTo.displayName;
            }
          });

          CanvasStore.setNodes(nodes);
        };
        const isInProductionMode = mode === PRODUCTION_CANVAS_VIEW_MODE;
        useEffect(() => {
          const dataComponentId = (data && data.componentId) ? data.componentId : id;

          setIsSelectedComponent((editComponent && dataComponentId === editComponent.componentId) ||
              (openTestPanel && data.componentId === openTestPanel));
        },[editComponent,openTestPanel]);

        useEffect(() => {
          setIsSelectedForceStart(selected ||
              (forceStartComponent &&
                  !selected &&
                  id === forceStartComponent.componentId));
        },[forceStartComponent,selected]);

        useEffect(() => {
          const isTrue = selected || isSelectedComponent || (isSelectedForceStart && isInProductionMode);
          setSvgSelectedClass(isTrue
              ? classes.selectedIcon
              : "");
        },[isSelectedComponent, isSelectedForceStart, selected]);

        const handleSaveInstruction = async () => {
          if (saving) return; // If saving, return

          setSaving(true);

          let eventData = {
            componentId: id,
            instruction: data.instruction,
          };

          let saveEvent = createSaveEvent(
            "UPDATE_COMPONENT",
            ProjectStore.project_id,
            { x: null, y: 0.0, zoom: 0.0 },
            [],
            eventData
          );

          //send event
          await send_request_graphql_mutation(
            `project-service/graphql/project/save/${ProjectStore.project_id}`,
            SAVE_EVENT(saveEvent),
            "",
            "POST"
          )
            .then((response) => {
              if (response.data.saveEvent && response.data.saveEvent.updated) {
                setLastModified(response.data.saveEvent.lastModified);
              }
              //insert delay here
              setSaving(false);
            })
            .catch((error) => {
              console.log(error);
            });
        };

        const addLinkedConditionalPath = async (data) => {
          if (saving) return;

          setSaving(true);

          const newComponentType = "conditional_workflow";
          const newPathId = uuid();

          const template = ComponentFactory[newComponentType];

          // Create new path component
          const newComponentPosition = calculateNewItemPostion(
            xPos,
            yPos,
            120,
            120,
            200,
            100,
            getElements(reactFlowInstance)
          );

          const newComponent = {
            id: newPathId,
            type: "component",
            data: {
              logo: template.logo,
              hasInput: template.hasInput ? template.hasInput : false,
              hasOutput: template.hasOutput ? template.hasOutput : false,
              type: newComponentType,
              displayName: template.displayName,
              next: [],
              label: template.displayName,
              baseColor: template.baseColor,
              color: getColourFromString(),
            },
            position: newComponentPosition,
          };

          // Create new link from branch path to the new path component
          const newLink = {
            id: uuid(),
            source: id,
            target: newPathId,
            markerEnd: {
              type: "arrowclosed",
              color: "#868686",
            },
            type: "link",
            label: "componentLink",
            data: { showDeleteButton: false },
            style: {
              strokeWidth: "3px",
              stroke: data.baseColor,
            },
          };

          // Add it to the canvas
          CanvasStore.setNodes([...nodes, newComponent]);
          CanvasStore.setEdges([...edges, newLink]);

          // Perform save on the backend
          let rfiObj = reactFlowInstance.toObject();

          let viewport = {
            x: rfiObj.viewport.x.toFixed(5),
            y: rfiObj.viewport.y.toFixed(5),
            zoom: rfiObj.viewport.zoom.toFixed(5),
          };

          let eventData = {
            sourceComponentId: id, // source is the branch_paths
            targetComponentId: newPathId,
            targetType: newComponentType,
            componentId: newPathId,
            type: newComponentType,
            viewData: newComponentPosition,
          };

          let saveEvent = createSaveEvent(
            "ADD_COMPONENT_AND_LINK",
            projectId,
            viewport,
            getElements(reactFlowInstance),
            eventData
          );

          // Send event to save
          const saveURL = `project-service/graphql/project/save/${projectId}`;
          return await send_request_graphql_mutation(
            saveURL,
            SAVE_EVENT(saveEvent),
            "",
            "POST"
          )
            .then((response) => {
              if (response.data.saveEvent && response.data.saveEvent.updated) {
                setLastModified(response.data.saveEvent.lastModified);
              }
              setSaving(false);
            })
            .catch((error) => {
              console.log(error);
            });
        };

        const getOutput = (data) => {
          // Fetch output of component based on type
          switch (data.type) {
            case "branch_paths":
              return (
                <>
                  <Handle
                    className={classes.port}
                    style={{ backgroundColor: "#000000" }}
                    position="right"
                    type="source"
                    isConnectable={data.isTemp ? false : true}
                  />
                  {mode === "DEFAULT" && (
                    <Tooltip
                      title={
                        <span className={classes.addPathTooltipTitle}>
                          Add a Conditional Path
                        </span>
                      }
                      placement="bottom"
                      slotProps={{
                        popper: {
                          modifiers: [
                            {
                              name: "offset",
                              options: {
                                offset: [0, 8],
                              },
                            },
                          ],
                        },
                      }}
                    >
                      <Button
                        variant="outlined"
                        disabled={saving}
                        className={classes.addPathButton}
                        onClick={(event) => {
                          addLinkedConditionalPath(data)
                        }}
                        id="add-conditional-path-button"
                      >
                        <AddIcon className={classes.addPathIcon} />
                        <AltRouteIcon className={classes.addPathIcon} />
                      </Button>
                    </Tooltip>
                  )}
                </>
              );

            case "conditional_workflow_path":
              return (
                <Handle
                  className={classes.port}
                  style={{ backgroundColor: "#000000" }}
                  position="right"
                  type="source"
                  isConnectable={false}
                />
              );

            default:
              return (
                <Handle
                  className={classes.port}
                  position="right"
                  type="source"
                  isConnectable={data.isTemp ? false : true}
                />
              );
          }
        };
        const renderEditStatusIcon = (editStatus) => {
          switch (editStatus) {
            case "TO_DELETE":
              return <>❌ To be removed</>;
            case "TO_ADD":
              return <></>;
            case "TO_UPDATE":
              return (
                <>
                  {" "}
                  <img src="/svgs/cycle.svg"></img> To be updated
                </>
              );

            default:
              return null; // Or some default component
          }
        };

        const handleInstructionChange = useCallback(
          (newInstruction) => {
            data.instruction = newInstruction;
            const updatedCanvas = nodes.map((component) => {
              if (component.id === id) {
                return {
                  ...component,
                  data: {
                    ...component.data,
                    instruction: newInstruction,
                  },
                };
              }
              return component;
            });
            CanvasStore.setNodes(updatedCanvas);
          },
          [nodes, id, data]
        );

        return (
          <div
            draggable={false}
            ref={parentRef}
            onDragOver={(event) => {
              event.preventDefault();
            }}
          >
            <div className={classes.nodeWrapper}>
              {data.error && (
                <Error className={`error-text ${classes.downsize}`}></Error>
              )}
              {data.isTemp ? (
                <AIDraft
                  style={{ display: data.isTemp == null ? "none" : "" }}
                  background={"#F2E5FA"}
                  border={"#8000CE"}
                >
                  📝 AI draft
                </AIDraft>
              ) : (
                ""
              )}

              {status ? (
                <AIDraft
                  style={{ display: status === null ? "none" : "" }}
                  background={"#FFFFFF"}
                  border={
                    status === "Error"
                      ? "#E10000"
                      : status === "Completed"
                      ? "#55A77A"
                      : "#757575"
                  }
                >
                  <Grid display={"flex"} alignItems={"center"} gap={1}>
                    {status === "Completed" && (
                      <CheckCircle
                        style={{
                          height: "18px",
                          width: "auto",
                          color: "#55A77A",
                        }}
                      />
                    )}
                    {status === "Error" && (
                      <Error
                        style={{
                          height: "18px",
                          width: "auto",
                          color: "#E10000",
                        }}
                      />
                    )}
                    {(status === "Building..." ||
                      status === "In progress...") && (
                      <CircularProgress size={14} thickness="4.4" />
                    )}
                    {status}
                  </Grid>
                </AIDraft>
              ) : (
                ""
              )}

              {data.editStatus ? (
                <AIDraft
                  style={{ display: data.editStatus === null ? "none" : "" }}
                  background={"#FFFFFF"}
                  border={"#000"}
                >
                  <Grid display={"flex"} alignItems={"center"} gap={1}>
                    {renderEditStatusIcon(data.editStatus)}
                  </Grid>
                </AIDraft>
              ) : (
                ""
              )}

              <div className={classes.node}>
                {hasInput == true ? (
                  data.fromWorkflowPath ? (
                    <Handle
                      className={classes.port}
                      style={{ backgroundColor: "#000000" }}
                      type="target"
                      position="left"
                      isConnectable={false}
                    />
                  ) : (
                    <Handle
                      className={classes.port}
                      type="target"
                      position="left"
                      isConnectable={data.isTemp ? false : true}
                    />
                  )
                ) : (
                  <div className={classes.filler} />
                )}
                {data.fromWorkflowPath ? (
                  <Button
                    size={"small"}
                    style={{
                      backgroundColor: data.color.bg,
                      border: `1px solid ${data.color.border}`,
                    }}
                    variant={"outlined"}
                    className="draftVersionButton"
                  >
                    <span style={{ paddingLeft: "5px" }}>{data.label}</span>
                  </Button>
                ) : (
                  <img
                    src={data.logo}
                    alt={COMPONENT_ICON_ALT}
                    className={`${svgSelectedClass} ${classes.svg}`}
                  />
                )}
                {hasOutput == true ? (
                  getOutput(data)
                ) : (
                  <div className={classes.filler} />
                )}
                <Handle
                  type={"target"}
                  position="top"
                  isConnectable={true}
                  style={{ display: "none" }}
                />
                <Handle
                  type={"source"}
                  position="top"
                  isConnectable={true}
                  style={{ display: "none" }}
                />
              </div>
              {!data.fromWorkflowPath && (
                  <div className={classes.nodeInfoBackground}>
                    <p
                        className={`${classes.rmvMargin} bold ${((editComponent &&
                                data.componentId === editComponent.componentId) ||
                            (openTestPanel &&
                                data.componentId === openTestPanel)) &&
                        CAN_OPEN_IN_NEW_TAB.indexOf(mode) !== -1 &&
                        classes.selectedText} ${(selected ||
                            (forceStartComponent &&
                                !selected &&
                                id === forceStartComponent.componentId)) &&
                        mode === PRODUCTION_CANVAS_VIEW_MODE &&
                        classes.selectedText}`}
                    >
                      {data.displayName}
                    </p>
                    <p
                        className={`${classes.rmvMargin} ${classes.labelText} ${
                            editComponent &&
                            data.componentId === editComponent.componentId &&
                            CAN_OPEN_IN_NEW_TAB.indexOf(mode) !== -1 &&
                            classes.selectedText
                        } ${(selected ||
                            (forceStartComponent &&
                                !selected &&
                                id === forceStartComponent.componentId)) &&
                        mode === PRODUCTION_CANVAS_VIEW_MODE &&
                        classes.selectedText}`}
                    >
                      {data.label}
                    </p>
                  </div>
              )}
              {!data.fromWorkflowPath && (
                <div className={classes.nodeInfoBackground}>
                  {selected ? (
                    <div className={classes.settings}>
                      <Grid container>
                        <Grid item xs={12} className={classes.centerGrid}>
                          {CAN_RUN.indexOf(mode) !== -1 && (
                              <Tooltip title={"Run from this component"}>
                                <i
                                    className="material-icons icon"
                                    onClick={(event) => {
                                      handleRun();
                                    }}
                                >
                                  play_circle
                                </i>
                              </Tooltip>
                          )}
                          {CAN_PREVIEW.indexOf(mode) !== -1 && (
                            <Tooltip title={"View component"}>
                              <i
                                className="material-icons icon"
                                onClick={(event) => {
                                  handlePreview();
                                }}
                              >
                                preview
                              </i>
                            </Tooltip>
                          )}
                          {CAN_OPEN_EDITABLE.indexOf(mode) !== -1 && (
                            <Tooltip title={"Edit this component"}>
                              <i
                                className="material-icons icon"
                                onClick={(event) => {
                                  setShowEditDialog(true)
                                }}
                              >
                                edit
                              </i>
                            </Tooltip>
                          )}
                        </Grid>
                      </Grid>

                      {CAN_EDIT.indexOf(mode) !== -1 && !data.isTemp ? (
                        <Tooltip title={"Edit this component"}>
                          <i
                            className="material-icons icon"
                            onClick={(event) => {
                              handleEdit()
                            }}
                          >
                            edit
                          </i>
                        </Tooltip>
                      ) : (
                        ""
                      )}
                      {CAN_OPEN_PRODUCTION_VIEW.indexOf(mode) !== -1 &&
                      !data.isTemp ? (
                        <Tooltip title={"Run"}>
                          <i
                            className="material-icons icon"
                            onClick={(event) => {
                              setLoadingDialog(true);
                              checkUnpublishedChanges(true, id);
                            }}
                          >
                            play_circle
                          </i>
                        </Tooltip>
                      ) : (
                        ""
                      )}
                      {CAN_TEST.indexOf(mode) !== -1 && !data.isTemp ? (
                        <Tooltip title={"Click here to start the workflow"}>
                          <i
                            className="material-icons icon"
                            onClick={(event) => {
                              openTestComponentPanel();
                            }}
                          >
                            science
                          </i>
                        </Tooltip>
                      ) : (
                        ""
                      )}
                      {CAN_COPY.indexOf(mode) !== -1 && !data.isTemp ? (
                        <Tooltip title={"Copy"}>
                          <i
                            className="material-icons icon"
                            onClick={(event) => {
                              setCopyNode(true);
                            }}
                          >
                            file_copy
                          </i>
                        </Tooltip>
                      ) : (
                        ""
                      )}
                      {CAN_CONVERT.indexOf(mode) !== -1 &&
                      isForm(data.type) &&
                      !data.isTemp ? (
                        <Tooltip title={"Convert component"}>
                          <i
                            className="material-icons icon"
                            onClick={(event) => {
                              setOpenConvertForm(true)
                            }}
                          >
                            swap_horizontal_circle
                          </i>
                        </Tooltip>
                      ) : (
                        ""
                      )}
                      {CAN_DELETE.indexOf(mode) !== -1 && !data.isTemp ? (
                        <Tooltip title={"Delete"}>
                          <i
                            className="material-icons icon"
                            onClick={(event) => {
                              setDeleteNode(true)
                            }}
                          >
                            delete
                          </i>
                        </Tooltip>
                      ) : (
                        ""
                      )}
                      {CAN_OPEN_IN_NEW_TAB.indexOf(mode) !== -1 &&
                      !data.isTemp ? (
                        <Tooltip title={"Open component in a new tab"}>
                          <i
                            className="material-icons icon"
                            onClick={(event) => {
                              openInNewTab()
                            }}
                          >
                            open_in_new
                          </i>
                        </Tooltip>
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              )}
              {data.description && (
                <NodeDescription
                  value={data.description}
                  ref={ref}
                  backgroundColor={
                    data.baseColor ? hexToPastel(data.baseColor) : "#FFFFFF"
                  }
                  onDescriptionChange={handleInstructionChange}
                  parent={parentRef}
                />
              )}

              {data.instructions &&
                (data.instructions.buildInstruction ||
                  data.instructions.editInstruction) && (
                  <InstructionsInfo
                    data={data}
                    parent={parentRef}
                    textRef={ref}
                  />
                )}
            </div>
            {/* Dialogs */}
            <CustomDialog
              isOpen={deleteNode}
              size={"sm"}
              title={<h5> Confirm Delete </h5>}
              contents={
                <>
                  <p className={" s-text"}>
                    Are you sure you want to delete this component ? All data
                    and settings for this component will be lost and this action
                    cannot be undone.
                  </p>
                </>
              }
              buttons={
                <>
                  <Button
                    onClick={handleDelete}
                    variant={"outlined"}
                    disabled={saving}
                  >
                    Delete
                  </Button>
                  <Button
                    onClick={() => setDeleteNode(false)}
                    variant={"contained"}
                    color={"primary"}
                  >
                    Cancel
                  </Button>
                </>
              }
            />
            <CustomDialog
              isOpen={moreComponents}
              title={
                "You have reached the maximum of 250 components for this canvas."
              }
              buttons={
                <Button
                  onClick={() => {
                    setMoreComponents(false);
                  }}
                  variant={"contained"}
                  color={"primary"}
                >
                  Close
                </Button>
              }
            />
            <ConvertFormDialog
              component={data}
              componentId={id}
              open={openConvertForm}
              convertTo={convertTo}
              handleChange={(value) => handleConvertTo(value)}
              projectId={projectId}
              handleComponentUpdated={(componentType, changeTo) => {
                handleComponentConverted(componentType, changeTo, id);
              }}
              handleClose={() => {
                setOpenConvertForm(false);
              }}
            />
            <CustomDialog
              isOpen={copyNode}
              title={"🖌 Copy and paste component"}
              contents={
                <Grid>
                  <p className={"s-text"}>
                    Create a copy of this component and paste it in:
                  </p>
                  <Select
                    value={workflowType}
                    fullWidth={true}
                    disableUnderline={true}
                    variant={"filled"}
                    onChange={(event) =>
                      handleSelectWorkflowType(event.target.value)
                    }
                  >
                    <MenuItem value={"current_workflow"}>
                      Current workflow
                    </MenuItem>
                    <MenuItem value={"another_workflow"}>
                      Another workflow
                    </MenuItem>
                  </Select>

                  {workflowType == "another_workflow" && (
                    <Autocomplete
                      freeSolo
                      size="small"
                      loading={workflows.length > 0 ? false : true}
                      options={workflows}
                      getOptionLabel={(option) =>
                        option.projectName
                          ? option.projectName
                          : option.projectId
                          ? option.projectId
                          : ""
                      }
                      renderOption={(props, option) => (
                        <li {...props} key={option.projectId}>
                          {option.projectName
                            ? option.projectName
                            : option.projectId}
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          margin="normal"
                          variant="outlined"
                        />
                      )}
                      onChange={(event, newValue) => {
                        if (newValue) {
                          handleSelectWorkflow(newValue);
                        }
                      }}
                      value={""}
                      className={"copySelect"}
                    />
                  )}
                </Grid>
              }
              buttons={
                <>
                  <Button
                    variant={"outlined"}
                    disabled={loadCancel}
                    onClick={() => setCopyNode(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant={"contained"}
                    color={"primary"}
                    disabled={loadCopy}
                    onClick={requestCopy}
                  >
                    Done
                  </Button>
                </>
              }
            />
            {showEditDialog && (
              <CustomDialog
                isOpen={showEditDialog}
                title="↩️ You will be switched to the draft canvas"
                contents={
                  <p>
                    To edit this component, you will be switched back to the
                    draft canvas. Make sure to save and publish your changes
                    before switching back to the production canvas.
                  </p>
                }
                buttons={
                  <>
                    <Button
                      onClick={() => {
                        setShowEditDialog(false);
                      }}
                      variant={"outlined"}
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={() => {
                        handleOpenEditable();
                      }}
                      variant={"contained"}
                    >
                      Continue
                    </Button>
                  </>
                }
              />
            )}
            {showCheckingLatestDialog && (
              <CanvasReloadDialog
                projectId={projectId}
                currentProdVersion={productionVersion}
                setShowCheckingLatestDialog={setShowCheckingLatestDialog}
                data={data}
                id={id}
                forceStartComponent={forceStartComponent}
                setForceStartComponent={setForceStartComponent}
                handleProductionCanvasMount={handleProductionCanvasMount}
              />
            )}
          </div>
        );
      }
    }
  )
);

export default withStyles(styles)(Component);
