export const DASHBOARD = (dashboardId) => {
  return `{
        dashboard(id:"${dashboardId}"){
            name
            widgetsInfo{
                id
                name
                type
                widgetData {
                    style{
                        color
                        height
                        width
                    }
                    sessionNumber
                    projectId   
                }
            }
        }
    }`;
};

export const WIDGET = (widgetId) => {
  return `{
        widget(id:"${widgetId}"){
            id
            name
            type
            diagramType
            widgetData {
                style{
                    color
                    height
                    width
                }
                sessionNumber
                projectId   
            }
        }
    }`;
};

export const CONSTRUCT_COMPONENT_SESSION_PROGRESS = (projectId) => {
  return `{
        constructComponentSessionProgress(projectId:"${projectId}"){
                componentId
                success
                error
                terminated
                paused
                waiting
          }
      }`;
};
