import React from "react";

// Util
import { Link } from "react-router-dom";
import AccessControl from "../ButtonComponents/AccessControl";

// MUI
import { Grid, Menu, MenuItem, Tooltip } from "@mui/material";

// MUI Icons
import ScienceIcon from "@mui/icons-material/Science";
import ShareIcon from "@mui/icons-material/Share";
import FindReplaceIcon from "@mui/icons-material/FindReplace";
import { CheckCircle, InsertDriveFile, PlayCircleOutline, Restore, Error, Storage } from "@mui/icons-material";
import { CanvasHeaderButton } from "./CanvasStyledComponents";
import { tooltips } from "../../../../utils/CanvasUtil";

export default function MainCanvasButtons(props) {
    const {
        production,
        pauseSave,
        saving,
        isCanvasLocked,
        firePublish,
        openViewDatabasesDialog,
        updateState,
        anchorEl,
        versionAnchor,
        saveDraft,
        saveDraftVersion,
        handleTest,
        publishedVersion,
        handleRenamePlaceholders,
        handleShare,
        aggregate,
        error,
        handleAccess,
        rerenderBadge,
        setLoadingDialog,
        checkUnpublishedChanges
    } = props;

    return (
        <Grid container alignItems={"center"} spacing={1}>
            <Tooltip title={tooltips.publish}>
                <Grid item>
                    <div>
                        <CanvasHeaderButton
                            disabled={production || pauseSave || saving || isCanvasLocked}
                            data-tut="reactour__canvas__publish"
                            variant={"contained"}
                            onClick={firePublish}
                            className={"canvas-button-disabled"}
                            textColor="#FFF"
                            bgColor="rgba(225, 0, 80, 1)"
                            hoverBg="rgba(225, 0, 80, 0.8)"
                        >
                            Publish
                            <div className="atom-dot"></div>
                        </CanvasHeaderButton>
                    </div>
                </Grid>
            </Tooltip>
            <Tooltip title={tooltips.save} placement="top-end">
                <Grid item>
                    <CanvasHeaderButton
                        disabled={production || pauseSave || saving || isCanvasLocked}
                        data-tut="reactour__canvas__save"
                        variant={"contained"}
                        className={"canvas-button-disabled"}
                        onClick={(event) => updateState({ anchorEl: event.target })}
                        textColor="#FFF"
                        bgColor="#2196F3"
                        hoverBg="rgba(33, 159, 243, 0.8)"
                    >
                        Save
                    </CanvasHeaderButton>
                </Grid>
            </Tooltip>
            <Menu id="basic-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => updateState({ anchorEl: null })} MenuListProps={{ "aria-labelledby": "basic-button" }}>
                <MenuItem
                    onClick={() => {
                        saveDraft();
                        updateState({ anchorEl: null });
                    }}
                >
                    💾 Save
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        saveDraftVersion();
                        updateState({ anchorEl: null });
                    }}
                >
                    📍 Save as version
                </MenuItem>
            </Menu>
            <Tooltip title={tooltips.test}>
                <Grid item>
                    <CanvasHeaderButton
                        variant="contained"
                        startIcon={<ScienceIcon />}
                        disabled={saving || isCanvasLocked}
                        onClick={() => {
                            handleTest(true);
                        }}
                        data-tut="reactour__canvas__test"
                        textColor="#FFF"
                        bgColor={!isCanvasLocked && "rgba(255, 88, 0, 1)"}
                        hoverBg="rgba(255, 88, 0, 0.9)"
                    >
                        Test
                    </CanvasHeaderButton>
                </Grid>
            </Tooltip>
            <Tooltip title={tooltips.start}>
                <Grid item>
                    <CanvasHeaderButton
                        disabled={production || isCanvasLocked}
                        data-tut="reactour__canvas__startworkflow"
                        variant={"contained"}
                        color={"primary"}
                        className={publishedVersion === "None" && "disabled-btn"}
                        onClick={() => {
                            setLoadingDialog(true);
                            checkUnpublishedChanges(true);
                        }}
                        startIcon={<PlayCircleOutline />}
                        textColor="#FFF"
                        bgColor={"#55a77a"}
                        hoverBg="rgba(85, 167, 122, 0.9)"
                    >
                        Run
                    </CanvasHeaderButton>
                </Grid>
            </Tooltip>
            <Tooltip title={tooltips.logs}>
                <Grid item>
                    <Link
                        className={"canvasLink"}
                        to={
                            !isCanvasLocked && {
                                pathname: "/project/logs/" + aggregate.project_id,
                                state: {
                                    project_name: aggregate.project_name,
                                },
                            }
                        }
                    >
                        <CanvasHeaderButton
                            disabled={production || isCanvasLocked}
                            data-tut="reactour__canvas__logs"
                            variant={"contained"}
                            startIcon={<CheckCircle />}
                            bgColor={"rgba(204, 229, 216, 1)"}
                            hoverBg="rgba(204, 229, 216, 0.9)"
                        >
                            Sessions
                        </CanvasHeaderButton>
                    </Link>
                </Grid>
            </Tooltip>
            <Grid item>
                {aggregate.project_id && aggregate.components && (
                    <AccessControl
                        handleAccess={handleAccess}
                        components={aggregate.components}
                        projectId={aggregate.project_id}
                        rerenderBadge={rerenderBadge}
                        tooltips={tooltips}
                        isCanvasLocked={isCanvasLocked}
                    />
                )}
            </Grid>
            <Tooltip title={tooltips.version}>
                <Grid item>
                    <CanvasHeaderButton 
                        disabled={production || isCanvasLocked} 
                        data-tut="reactour__canvas__version" 
                        variant={"outlined"} 
                        startIcon={<Restore />}
                        onClick={(event) => updateState({ versionAnchor: event.target })}>
                        Version
                    </CanvasHeaderButton>
                </Grid>
            </Tooltip>
            <Menu id="basic-menu" anchorEl={versionAnchor} open={Boolean(versionAnchor)} onClose={() => updateState({ versionAnchor: null })} MenuListProps={{ "aria-labelledby": "basic-button" }}>
                <Link className={"canvasLink"} to={`/project/workflow_schema/${aggregate.project_id}`}>
                    <MenuItem
                        onClick={() => {
                            updateState({ versionAnchor: null });
                        }}
                    >
                        ↔️ Compare workflow versions
                    </MenuItem>
                </Link>
                <Link className={"canvasLink"} to={`/project/version_history/${aggregate.project_id}`}>
                    <MenuItem
                        onClick={() => {
                            updateState({ versionAnchor: null });
                        }}
                    >
                        ↩️ Restore workflow version
                    </MenuItem>
                </Link>
            </Menu>
            {!isCanvasLocked && (
                <Tooltip title={tooltips.database}>
                    <Grid item>
                        <CanvasHeaderButton disabled={production} onClick={() => updateState({ openViewDatabasesDialog: !openViewDatabasesDialog })} variant={"outlined"} startIcon={<Storage />}>
                            Databases
                        </CanvasHeaderButton>
                    </Grid>
                </Tooltip>
            )}
            <Tooltip title={tooltips.files}>
                <Grid item>
                    <Link className={"canvasLink"} to={`/project/file_library/${aggregate.project_id}`}>
                        <CanvasHeaderButton disabled={production || isCanvasLocked} data-tut="reactour__canvas__files" variant={"outlined"} startIcon={<InsertDriveFile />}>
                            Files
                        </CanvasHeaderButton>
                    </Link>
                </Grid>
            </Tooltip>
            <Grid item>
                <CanvasHeaderButton
                    variant="outlined"
                    startIcon={<ShareIcon />}
                    onClick={() => {
                        handleShare(true);
                    }}
                    disabled={isCanvasLocked}
                >
                    Share
                </CanvasHeaderButton>
            </Grid>
            <Grid item>
                <CanvasHeaderButton
                    disabled={production || isCanvasLocked}
                    variant="outlined"
                    startIcon={<FindReplaceIcon />}
                    onClick={() => {
                        handleRenamePlaceholders(true);
                    }}
                >
                    Rename
                </CanvasHeaderButton>
            </Grid>
        </Grid>
    );
}
