import { Grid, Skeleton } from "@mui/material";
import React from "react";

import { styled } from "@mui/system";

function CardsLoadingSkeleton({ skeletonStyles }) {
    const StyledSkeleton = styled(Skeleton)(({ skeletonStyles }) => ({
        ...skeletonStyles,
    }));

    const getItem = () => {
        return (
            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                <StyledSkeleton variant="rectangular" animation="wave" skeletonStyles={skeletonStyles} />
            </Grid>
        );
    };

    return [...Array(18).keys()].map((a) => {
        return getItem();
    });
}

export default CardsLoadingSkeleton;
