import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { Grid, styled } from "@mui/material";
import { send_request } from "../../utils/Request";
import ErrorExtendingFreeTrialDialog from "../pages/DashboardComponents/ErrorExtendingFreeTrialDialog";

const TopBar = styled(Grid)(({ color, textColor }) => ({
    backgroundColor: `${color} !important`,
    position: "fixed",
    zIndex: "10 !important",
    height: "36px",
    width: "100%",
    color: `${textColor} !important`,
}));

const Text = styled(Grid)({
    textAlign: "center",
});

const Close = styled(Grid)({
    cursor: "pointer",
    textAlign: "center",
});

const ContactUsText = styled("span")({
    fontWeight: "bold",
    "&:hover": {
        cursor: "pointer"
    }
})

function FreeTrialBanner({ classes, closeBanner }) {

    const [trialDaysRemaining, setTrialDaysRemaining] = useState(null);
    const [errorExtendingTrial, setErrorExtendingTrial] = useState(false);

    useEffect(() => {
        // Make API call to get count
        send_request("authz-service/get_free_trial_remaining", "", "", "get")
            .then((res) => {
                setTrialDaysRemaining(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    const handleReactivateTrial = async () => {
        let url = `authz-service/reactivateFreeTrial`;

        const json = await send_request(url, null, null, "POST");

        if (!json || !json.data) {
            // This means they were not eligible
            // Show error dialog
            setErrorExtendingTrial(true);
            return;
        }

        // Else, reload the page
        window.location.reload(false);
    };

    const getBannerColor = (remaining) => {
        if (remaining > 7) return "rgba(85, 167, 122, 1)";
        else if (remaining > 0) return "rgba(255, 234, 203, 1)";
        else return "rgba(176, 0, 32, 1)";
    };

    const getTextColor = (remaining) => {
        if (remaining > 0 && remaining <= 7) return "#000";
        return "#FFF";
    };

    const getCorrectText = (remaining) => {
        if (remaining > 7) {
            return (
                <>
                    You are on a 14-day free trial of Workflow86. Keep using these powerful features by{" "}
                    <Link className={classes.linkToUpgrade} to="/set_plan" target="_blank">
                        upgrading today
                    </Link>
                </>
            );
        } else if (remaining > 0) {
            return (
                <>
                    Your free trial is about to end.{" "}
                    <Link className={classes.linkToUpgrade} to="/set_plan" target="_blank">
                        Upgrade now
                    </Link>{" "}
                    to keep using Workflow86 or <ContactUsText id="chat_with_someone">contact us</ContactUsText> for an extension
                </>
            );
        } else {
            return (
                <>
                    Your 14-day trial has ended.{" "}
                    <Link className={classes.linkToUpgrade} to="/set_plan" target="_blank">
                        Upgrade now
                    </Link>{" "}
                    to keep using Workflow86 {/* <ContactUsText onClick={handleReactivateTrial}>reactivate for another 14 days</ContactUsText> */}
                </>
            );
        }
    };

    return (
        trialDaysRemaining !== null && (
            <TopBar container alignItems={"center"} color={getBannerColor(trialDaysRemaining)} textColor={getTextColor(trialDaysRemaining)}>
                <Text item xs={11}>
                    {getCorrectText(trialDaysRemaining)}
                </Text>
                <Close item xs={1} onClick={closeBanner}>
                    x
                </Close>
                {errorExtendingTrial && <ErrorExtendingFreeTrialDialog errorExtendingTrial={errorExtendingTrial} setErrorExtendingTrial={setErrorExtendingTrial} />}
            </TopBar>
        )
    );
}

export default FreeTrialBanner;
