import React, { Component } from "react";
import TextEditor from "../TextEditor/TextEditor";
import Button from "@mui/material/Button";
import DropZone from "vardogyir-ui/DropZone";
import Chip from "vardogyir-ui/Chip";
import Dropzone from "react-dropzone";
import { send_request, send_external_request, send_component_save_request } from "../../utils/Request";
import Email from "../Component/Email";
import SelectCredential from "../Component/SelectCredential";
import { observer, inject } from "mobx-react";
import { autorun, toJS, reaction, trace } from "mobx";
import * as date from "date-and-time";
import EmailStore from "./EmailStore";
import config from "../../config";
import {
  Input,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from "@mui/material";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { makeStyles } from "@mui/styles";
import { createStyles, withStyles } from "@mui/styles";
import { getCurrentTime } from "../../utils/getCurrentTime";
import { showLoadingScreen } from "../../utils/showLoadingScreen";
import projectStore from "../ProjectCanvas/ProjectStore";
import MentionEditor from "../TextEditor/MentionEditor";
import { IndividualComponentLoader } from "../../utils/ComponentLoadingSkeleton";
const componentPageStyles = makeStyles(() =>
  createStyles({
    root: {
      flexGrow: 1
    }
  })
);

const styles = (theme) => ({});

class EmailWriter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showExtraFields: false,
      focus: null,
      placeholders: [],
      openAttachmentDialog: false,
      attachedFiles: [],
      loading: false,
      description:null,
      status:null
    };
    this._handleClick = this._handleClick.bind(this);
    this.insertPlaceholder = this.insertPlaceholder.bind(this);
    EmailStore.initialiseEmpty();
  }

  componentDidMount() {
    if (this.props.component_id !== undefined) {
      this.setState({ loading: true });
      send_request(
        `project-service/project/component/query/${this.props.component_id}/at-version/${this.props.version}`,
        "",
        {}
      )
        .then((response) => {
          if (
            response.data &&
            response.data.components &&
            response.data.components[0].componentData
          ) {

            EmailStore.setEmailTemplate(response.data.components[0]);

            this.props.onComponentNameChanged(EmailStore.email_name);
            this.props.onComponentDescriptionChanged(response.data.components[0].description);
            this.props.onLastModifiedChanged(EmailStore.lastModified);

            let data = EmailStore.template.componentData;

            if (data.attachedFiles) {
              //there are attached files, let's update the current state
              this.setState({ attachedFiles: data.attachedFiles });
              this.setState({ loading: false });
            }
            this.props.setChildTemplateLoaded(true);
          }
            if(this.props.aiComponentBuilderData!=undefined && this.props.aiComponentBuilderData["data"]!==undefined && this.props.aiComponentBuilderData["data"]!==''  )
            {
                this.props.onComponentNameChanged(this.props.aiComponentBuilderData["name"]);
                this.props.onComponentDescriptionChanged(this.props.aiComponentBuilderData["explanation"]);
                EmailStore.setTemplateData(this.props.aiComponentBuilderData["data"]);
                this.setState({ description: this.props.aiComponentBuilderData["explanation"] });
                this.setState({ status: "AI_BUILD_GENERATED" });

            }
          this.props.setChildTemplateLoaded(true);
          this.setState({ loading: false });
        })
        .catch((err) => {
        
          throw Error(err.message);
        });
    }
  }

  componentWillUnmount() {
    // Clear the mobX reactions
    this.saveEmailTemplate();
    this.reactToPlaceHolder();
  }

  setEmailName = autorun(() => {
    EmailStore.setEmailName(this.props.ComponentName);
  });

  saveEmailTemplate = reaction(
    () => this.props.SaveTrigger.triggerValue,
    () => {
      let cData = EmailStore.template.componentData;

      cData.attachedFiles = [];

      this.state.attachedFiles.forEach((file) =>
        cData.attachedFiles.push({ ...file })
      );

      cData.name = toJS(this.props.ComponentName);

      let lastModified = getCurrentTime();
      let data = {
        componentData: {
          data: cData,
          name: toJS(this.props.ComponentName),
          lastModified: lastModified
        },
        componentId: this.props.component_id,
        type: "email",
        description: this.props.ComponentDescription,
        status: this.state.status
      };

      send_component_save_request("component-service/email/data", data, "", "POST", this.props.SaveTrigger)
        .then((response) => {
          this.props.onLastModifiedChanged(lastModified);
          this.props.showSuccess(data, this.props.SaveTrigger);
          projectStore.savedComponent = true;
        })
        .catch((err) => {
          throw err;
        });
    }
  );

  _handleClick() {
    this.setState({ showExtraFields: !this.state.showExtraFields });
  }

  reactToPlaceHolder = reaction(
    () => toJS(this.props.SelectedPlaceholder),
    (placeholder, reaction) => {
      if (placeholder["selected"] === undefined) {
        return;
      }
      const { style, key, isNotPlaceholder } = placeholder["selected"];
      this.insertPlaceholder(
        style.default,
        key,
        style,
        this.state.focus,
        isNotPlaceholder
      );
    }
  );



  toInput = (label, text, style, focus, isNotPlaceholder) => {
    const { recipients } = toJS(EmailStore.template_data);
    if (isNotPlaceholder !== true) {
      text = "${" + text + "}";
    }

    switch (focus) {
      case "to":
        let to = recipients.to.concat([text]);
        EmailStore.setRecipientsTo(to);
        break;
      case "cc":
        let cc = recipients.cc.concat([text]);
        EmailStore.setRecipientsCc(cc);
        break;
      case "bcc":
        let bcc = recipients.bcc.concat([text]);
        EmailStore.setRecipientsBcc(bcc);
        break;
        case "replyTo":
            let replyTo = recipients.replyTo.concat([text]);
            EmailStore.setReplyTo(replyTo);
            break;
      case "subject":
        let subject = EmailStore.template_data.subject.concat(text);
        EmailStore.setSubject(subject);
        break;
      default:
        break;
    }
  };

  insertPlaceholder = (label, text, style, focus, isNotPlaceholder) => {
    if (focus === "editor" || focus === "subject") {
      this.setState((prevState) => ({
        placeholders: [...prevState.placeholders, { label, text, style, focus }]
      }));
    } else {
      this.toInput(label, text, style, focus, isNotPlaceholder);
    }
  };

  handleAttachmentButton = () => {
    this.setState({ openAttachmentDialog: true });
  };

  addAttachFile = async (files) => {
    let { attachedFiles } = this.state;
    let attachJson = await this.startUploadAttachFile(files[0]);
    attachedFiles.push(attachJson);
    this.setState({ attachedFiles });
  };

  deleteAttachedFile = (fileIndex) => {
    this.state.attachedFiles.splice(fileIndex, 1);
    this.setState({ attachedFiles: this.state.attachedFiles });
  };

  startUploadAttachFile = async (file) => {
    let formData = new FormData();

    formData.append("file", file);
    formData.append("projectId", this.props.projectId);

    const json = await send_request(
      "component-service/email/upload",
      formData,
      "",
      "POST",
      { "Content-Type": "multipart/form-data" }
    ).catch((err) => {
      throw err;
    });
    if (json && json.data) {
      const { id, fileName } = json.data;
      return { id, fileName };
    }
  };

  render() {
    let extraEmailFields;
    const { recipients } = EmailStore.template_data;
    const { attachedFiles } = this.state;
    let subjectArray = [];
    let contentArray = [];

    if (this.state.focus === "subject") {
      subjectArray = this.state.placeholders;
    } else if (this.state.focus === "editor") {
      contentArray = this.state.placeholders;
    }
    extraEmailFields = (
      <div>
        <Email
          items={this.props.availablePlaceholders}
          store={EmailStore.setRecipientsCc}
          defaultValue={toJS(recipients.cc)}
          key={"Cc"}
          onPlaceholderSelected={this.props.onPlaceholderSelected}
          addressType="Cc"
          focus={(e) => {
            this.setState({
              focus: "cc"
            });
          }}
        />
        <Email
          items={this.props.availablePlaceholders}
          store={EmailStore.setRecipientsBcc}
          defaultValue={toJS(recipients.bcc)}
          onPlaceholderSelected={this.props.onPlaceholderSelected}
          key={"Bcc"}
          addressType="Bcc"
          focus={(e) => {
            this.setState({
              focus: "bcc"
            });
          }}
        />
        <Email
            items={this.props.availablePlaceholders}
            store={EmailStore.setReplyTo}
            defaultValue={toJS(recipients.replyTo)}
            onPlaceholderSelected={this.props.onPlaceholderSelected}
            key={"replyTo"}
            addressType="Reply To"
            focus={(e) => {
              this.setState({
                focus: "replyTo"
              });
            }}
        />
      </div>
    );

    if (this.state && !this.state.loading) {
      return (
        <div
          style={{
            width: "100%",
            padding: "24px",
            backgroundColor: "white",
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
            borderRadius: "8px"
          }}
          className={componentPageStyles.root}
        >
          <div>Email account</div>
          <div>
            <SelectCredential
              onChange={(credentialId) => {
                EmailStore.setCredentialId(credentialId);
              }}
              value={EmailStore.getCredentialId()}
            />
          </div>
          <div>
            <Email
              items={this.props.availablePlaceholders}
              key={"To"}
              store={EmailStore.setRecipientsTo}
              defaultValue={toJS(recipients.to)}
              value={recipients.to}
              addressType="To"
              onPlaceholderSelected={this.props.onPlaceholderSelected}
              focus={(e) => {
                this.setState({
                  focus: "to"
                });
              }}
            />
            {extraEmailFields}
          </div>

          <div
            style={{ display: "flex", alignItems: "center", marginTop: "36px" }}
          >
            <div style={{ minWidth: "105px" }}>
              <p
                style={{
                  textAlign: "left",
                  verticalAlign: "middle",
                  padding: "10px",
                  display: "inline"
                }}
              >
                Subject:
              </p>
            </div>
            <div style={{ width: "100%" }}>
              <MentionEditor
                placeholder="Enter the subject line here"
                availablePlaceholders={this.props.availablePlaceholders}
                placeholders={subjectArray}
                text={EmailStore.template_data.subject}
                onChange={(value) => {
                  EmailStore.setSubject(value);
                }}
                onFocus={() => {
                  this.setState({ placeholders: [], focus: "subject" });
                }}
              />
            </div>
          </div>
          <div style={{ marginTop: "16px", marginBottom: "16px" }}>
            <Button
              style={{ paddingLeft: "0px", marginRight: "8px" }}
              color={"primary"}
              variant={"string"}
              onClick={() => {
                this.setState({ openAttachmentDialog: true });
              }}
            >
              <AttachFileIcon
                style={{
                  color: "#313131",
                  marginRight: "8px"
                }}
              />
              Attach File
            </Button>

            {this.state.attachedFiles &&
              this.state.attachedFiles.map((attachedFile, fileIndex) => (
                <Chip
                  label={attachedFile.fileName}
                  onDelete={() => this.deleteAttachedFile(fileIndex)}
                />
              ))}

            <Dialog
              open={this.state.openAttachmentDialog}
              fullWidth={true}
              maxWidth={"sm"}
            >
              <DialogTitle>
                <h5>Attach File</h5>
              </DialogTitle>

              <DialogContent>
                <p>
                  Upload files to be sent as attachments when this email
                  component is executed. The maximum size for all attachment is
                  5 MB
                </p>
                <p>Drop your files into the area below:</p>

                <div style={{ marginBottom: "8px" }}>
                  <Dropzone
                    multiple={false}
                    onDrop={(acceptedFiles) =>
                      this.addAttachFile(acceptedFiles)
                    }
                    maxSize={5000000}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <section>
                        <div {...getRootProps()} style={{}}>
                          <DropZone />
                          <input type={"file"} {...getInputProps()} />
                        </div>
                      </section>
                    )}
                  </Dropzone>
                </div>
                {this.state.attachedFiles &&
                  this.state.attachedFiles.map((attachedFile, fileIndex) => (
                    <Chip
                      label={attachedFile.fileName}
                      onDelete={() => this.deleteAttachedFile(fileIndex)}
                    />
                  ))}
              </DialogContent>

              <DialogActions>
                <Button
                  variant={"outlined"}
                  color={"info"}
                  onClick={() => {
                    this.setState({ openAttachmentDialog: false });
                  }}
                >
                  Cancel
                </Button>

                <Button
                  variant={"contained"}
                  color={"primary"}
                  onClick={() => {
                    this.setState({ openAttachmentDialog: false });
                  }}
                >
                  Attach
                </Button>
              </DialogActions>
            </Dialog>
          </div>
          <TextEditor
            availablePlaceholders={this.props.availablePlaceholders}
            html={EmailStore.template_data.content}
            onChange={(html) => {
              if (html !== EmailStore.template_data.content) {
                EmailStore.setContent(html);
              }
            }}
            placeholder={contentArray}
            editorFocus={() => {
              this.setState({ placeholders: [], focus: "editor" });
            }}
          />
        </div>
      );
    } else return <IndividualComponentLoader />;
  }
}

export default withStyles(styles)(
  inject(
    "SelectedPlaceholder",
    "ComponentName",
    "ComponentDescription",
    "SaveTrigger"
  )(observer(EmailWriter))
);
