const styles = () => ({
  icons: {
    position: "absolute",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems:"center",
    marginTop: "8px",
    top: "100%",
    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
    backgroundColor: "rgba(64, 64, 64, 1)",
    borderRadius: "8px",
    height: "40px",
    padding: "8px",
    gap: "8px",
  },
  icon: {
    color: "#FFFFFF",
    cursor: "pointer",
    "&:hover ": {
      color: "#2196F3",
    },
  },
  nodeWrapper: {
    padding:'10px',
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  editor: {
    cursor: "text!important",
    height: "100%",
    width: "100%"
  },
  colorPicker: {
    margin: "0 8px",
    display: "flex",
    flexDirection: "column",
    alignContent: "flex-end",
    justifyContent: "flex-end"
  },
  resize: {
    margin: "0 8px",
    display: "flex",
    flexDirection: "column",
    alignContent: "flex-end",
    justifyContent: "flex-end",
    backgroundColor: "white!important",
    borderRadius: "8px",
    padding: "16px",
    marginTop: "8px",
    minWidth: "160px"
  },
  handle: {
    //port styling goes here
    zIndex: 1000
  },
  extraSettings: {
    position: "absolute",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    margin: "28px -8px",
    alignContent: "flex-start"
  },
  editorWrapper: {
    width: "100%",
    height: "100%"
  },
  saveEdits: {
    backgroundColor: "#868686",
    "&:hover ": {
      backgroundColor: "#868686"
    },
    "&:active": {
      backgroundColor: "#868686"
    }
  },
  arrowRight: {
    fontSize: "16px",
    marginLeft: "2px",
    "&:hover": {
      color: "#2196f3"
    }
  },
  aiMenuItem: {
    fontSize: "12px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    whiteSpace: "nowrap"
  },
  aiIcon: {
    height: "14px",
    width: "auto",
    paddingRight: "8px"
  },
  aiIconBtn: {
    height: "18px",
    width: "auto",
    paddingRight: "6px"
  },
  useAIToButton: {
    fontSize: "12px",
    display: "flex", 
    justifyContent: "center", 
    alignItems: "center",
    marginLeft: "4px",
    color: "#FFF",
    background: "#944BA8",
    borderRadius: "4px",
    textTransform: "none",
    "&:hover": {
      cursor: "pointer",
      background: "rgba(148, 75, 168, 0.7)",
      color: "rgba(255, 255, 255)"
    }
  },
  aiMenu: {
    padding: "0px"
  }
});
export default styles;
