import { Grid, InputAdornment, TextField, styled } from "@mui/material";
import React, { useState } from "react";

import ExclamationIcon from "../Component/Icons/ExclamationIcon";

const StyledTextField = styled(TextField)(({ customStyleProps }) => ({
    ...customStyleProps,
}));

export const CustomGrid = styled(Grid)(() => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
}));

function W86PlaceholderTextField({ placeholderText, value, defaultValue, handleChange, error, helperText, onBlur, size, className, disabled, customStyleProps ,fullWidth=false, disableAdornment=false}) {
    const [errMessage, setErrMessage] = useState("");

    const hasSpecialCharacters = (value) => {
        return /[^a-zA-Z\d\s:_]/.test(value) !== false;
    };

    return (
        <StyledTextField
            id="w86-placeholder-textfield"
            customStyleProps={customStyleProps}
            className={className ? className : ""}
            disabled={disabled ? disabled : false}
            fullWidth={fullWidth}
            size={size ? size : "small"} // Default to small size if not specified
            variant="outlined"
            defaultValue={defaultValue && defaultValue}
            value={value}
            error={error || errMessage}
            helperText={helperText || errMessage}
            onChange={(e) => {
                // You can add any necessary pre-processing here
                if (hasSpecialCharacters(e.target.value)) {
                    setErrMessage("No special characters or spaces allowed");
                    return;
                }
                setErrMessage("");
                handleChange(e);
            }}
            onBlur={onBlur != null && onBlur}
            placeholder={placeholderText}
            InputProps={{
                startAdornment: !disableAdornment &&  <InputAdornment position="start">{"${"}</InputAdornment>,
                endAdornment: (
                    <InputAdornment position="end">
                        <Grid container horizontal="center" vertical="center">
                            <CustomGrid item>
                            {!disableAdornment && "}"}
                                {(error || errMessage) && <ExclamationIcon style={{ marginLeft: "4px" }} />}
                            </CustomGrid>
                        </Grid>
                    </InputAdornment>
                ),
            }}
        />
    );
}

export default W86PlaceholderTextField;
