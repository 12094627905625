const styles = () => ({
  root: {},
  searchField: {
    backgroundColor: "#FFFFFF",
    maxWidth: "560px",
    margin: "24px"
  },
  addNewImage: {
    height: "56px",
    width: "56px",
    marginLeft: "auto",
    marginRight: "auto"
  },
  icon: {
    marginRight: "8px!important"
  },
  cardsContainer: {
    margin: "0px"
  },
  action: {
    height: "50%"
  },
  name: {
    marginLeft: "16px"
  },
  button: {
    marginLeft: "24px",
    marginRight: "24px"
  },
  moreIcon: {
    position: "absolute",
    top: 135,
    right: 0,
    color: "black"
  },
  doughnut: {
    margin: "24px"
  },
  stackedBar: {
    margin: "24px"
  },
  graph: {
    height: "572px",
    width: "100%"
  },
  icons: {
    marginLeft: "8px",
    cursor: "pointer",
    color: "#868686"
  },
  tick: {
    marginTop: "16px"
  },
  loading: { height: "100%" },
  cardMargin: {
    padding: "8px"
  },
  customTicks: {
    fontSize: "12px!important"
  },
  link: {
    textDecoration: "none",
    color: "#2196F3",
    fontSize: "12px",
    marginTop: "0px"
  }
});

export default styles;
