import React from 'react';
import { SvgIcon } from '@mui/material';

function GoogleIcon(props) {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <svg width="24" height="24" viewBox="-2 -2 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.99922 7.2V9.2H13.7192C13.2392 12 10.9992 14 8.19922 14C4.91922 14 2.19922 11.28 2.19922 8C2.19922 4.72 4.91922 2 8.19922 2C9.87922 2 11.3192 2.72 12.3592 3.84L13.7992 2.4C12.3592 0.96 10.4392 0 8.19922 0C3.79922 0 0.199219 3.6 0.199219 8C0.199219 12.4 3.79922 16 8.19922 16C12.5992 16 15.7992 12.4 15.7992 8V7.2H8.99922Z" fill="white"/>
      </svg>
    </SvgIcon>
  );
}

export default GoogleIcon;