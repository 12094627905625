import React, { useState, useEffect } from "react";

import { CardTitle, DashboardCard, StyledSkeleton } from "./CommonComponents";
import { send_request } from "../../../utils/Request";
import config from "../../../config";

// MUI
import { Autocomplete, Button, Grid, InputAdornment, styled, TextField } from "@mui/material";
import { Search, Slideshow } from "@mui/icons-material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const StyledTextField = styled(TextField)({});

const StartButton = styled(Button)({
    color: "#FFF",
    background: "#000",
    "&:hover": {
        background: "rgba(0, 0, 0, 0.9)",
    },
});

function StartAWorkflowCard() {
    const [portals, setPortals] = useState([]);
    const [currPortal, setCurrPortal] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(async () => {
        setLoading(true);

        let url = "project-service/portal/getProjects";
        const json = await send_request(url, null, null, "GET");

        if (json && json.data) setPortals(json.data);

        setLoading(false);
    }, []);

    useEffect(() => {
        return () => {
            setPortals([]);
            setCurrPortal(null);
        };
    }, []);

    const handleStartWorkflow = (workflow) => {
        if (workflow.entryComponentType == "form") {
            let url = `${config.FORM.FORM_FRONT_END}form/${workflow.entryComponentId}`;
            window.open(url, "_blank");
        } else if (workflow.entryComponentType == "bulk_assessment") {
            let url = `${config.FORM.FORM_FRONT_END}bulk/${workflow.entryComponentId}`;
            window.open(url, "_blank");
        } else {
            let url = `${config.FORM.FORM_FRONT_END}`;
            window.open(url, "_blank");
        }
    };

    const getRenderItem = (opt) => {
        try {
            let renderItem = "";

            if (opt.entryComponentName) renderItem += opt.entryComponentName;
            else renderItem += opt.entryComponentId;

            renderItem += " | "; // Add spacing

            if (opt.projectName) renderItem += opt.projectName;
            else renderItem += opt.projectId;

            return renderItem;
        } catch (e) {
            return opt;
        }
    };

    return (
        <DashboardCard container rowGap={"12px"}>
            <Grid item xs={12}>
                <CardTitle>Start a workflow</CardTitle>
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                        {!loading ? (
                            <Autocomplete
                                disableClearable
                                value={currPortal}
                                options={portals}
                                getOptionLabel={getRenderItem}
                                noOptionsText={"No available portals or forms to start a workflow"}
                                renderInput={(params) => (
                                    <StyledTextField
                                        {...params}
                                        size="small"
                                        fullWidth
                                        variant="outlined"
                                        placeholder="Search for a starting point to run a workflow"
                                        InputProps={{
                                            ...params.InputProps,
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    {" "}
                                                    <Search style={{ color: "#000" }} />
                                                </InputAdornment>
                                            ),
                                            disableUnderline: true,
                                        }}
                                    />
                                )}
                                renderOption={(props, option) => (
                                    <li {...props} key={option.entryComponentId + "_" + option.projectId}>
                                        {getRenderItem(option)}
                                    </li>
                                )}
                                onChange={(event, newValue) => {
                                    setCurrPortal(newValue);
                                }}
                            />
                        ) : (
                            <StyledSkeleton variant="rectangular" height="35px" />
                        )}
                    </Grid>
                    <Grid item>
                        {!loading ? (
                            <StartButton
                                variant="contained"
                                startIcon={<Slideshow />}
                                onClick={() => {
                                    if (!currPortal) return;
                                    handleStartWorkflow(currPortal);
                                }}
                                // disabled={!currPortal}
                            >
                                Start
                            </StartButton>
                        ) : (
                            <StyledSkeleton variant="rectangular" height="35px" width="100px" />
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </DashboardCard>
    );
}

export default StartAWorkflowCard;
