import React, {Component} from 'react';

// MUI
import { Grid } from "@mui/material";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";

class SelectMethod extends Component {

  constructor(props) {
    super(props);
    this.state = {
      method: this.props.componentStore.data.type
    };
  }


  selectMethod = (value) => {
    this.setState({
      method: value
    })
  }

  uploadSample = () => {
    const { method } = this.state

    this.props.componentStore.setField(method, "", "type");

    if (method === "send_a_sample_json"){
      this.props.method("send_a_sample_json")
      this.props.onChangeState(1,1)
    } else if (method === "upload_sample_json"){
      this.props.method("upload_sample_json")
      this.props.onChangeState(1,1)
    } else if (method === "open_endpoint") {
      this.props.method("open_endpoint");
      this.props.onChangeState(7,7)
    }
  }

  // Fetches the correct text to display based on the selected method
  fetchText = (method) => {
    let text = "";

    switch (method) {
      case "send_a_sample_json":
        text = "Send a sample payload to an endpoint and then parse and map to placeholders";
        break;
      case "upload_sample_json":
        text = "Copy and paste a sample JSON of the payload and then parse and map to placeholders";
        break;
      case "open_endpoint":
        text = "Receive a payload and export it as a placeholder without mapping or parsing";
        break;
      default:
        break;
    }

    if (!text) return;
    return <div style={{ marginBottom: 24 }}>{text}</div>
  }

  render() {
    const {method} = this.state
    return (
      <Grid item xs={12} className="textAlignCenter">
        <span>Select a method to set up this webhook import component</span><br/>
        <Select
          name="selectType"
          disableUnderline
          variant="standard"
          labelId="demo-simple-select-outlined-label"
          className={`dropdown-mat selectStandard `}
          style={{margin:" 24px 0"}}
          displayEmpty
          value={method}
          onChange={event => {
            this.selectMethod(event.target.value)
          }}
        >
          <MenuItem value={null} disabled> select a method </MenuItem>
          <MenuItem value={"send_a_sample_json"}>Send sample JSON to endpoint </MenuItem>
          <MenuItem value={"upload_sample_json"}>Copy and paste sample JSON </MenuItem>
          <MenuItem value={"open_endpoint"}>Send to endpoint without parsing</MenuItem>
        </Select>
        <br/>
        {this.fetchText(method)}
        <Grid item xs={12} className="dialogContent" style={{marginTop:0}}>
          <Grid item xs={12} >
            <Button
              onClick={this.uploadSample}
              disabled={method != null ? false : true}
              variant={"contained"} color={"primary"} 
            >
              {" "}
              Next
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default SelectMethod;