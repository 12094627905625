import React, { useState } from "react";

import { Grid, styled, TextField } from "@mui/material";

const StyledTextField = styled(TextField)(({ customStyleProps }) => ({
    ...customStyleProps,
}));

function W86AlphanumericTextfield({ customStyleProps, handleChange, props, value }) {
    const [errMessage, setErrMessage] = useState("");
    const [val, setVal] = useState(value);

    const hasSpecialCharacters = (value) => {
        return /[^a-zA-Z\d\s_]/.test(value) !== false;
    };

    return (
        <StyledTextField
            fullWidth
            variant="outlined"
            type="text"
            value={val}
            error={errMessage}
            helperText={errMessage}
            customStyleProps={customStyleProps}
            onChange={(e) => {
                if (hasSpecialCharacters(e.target.value)) {
                    setErrMessage("No special characters or spaces allowed");
                    return;
                }

                setErrMessage("");

                e.target.value = e.target.value.replace(/ /g, "_").replace(/[^a-zA-Z\d\s:_]/g, "");
                setVal(e.target.value);

                handleChange(e);
            }}
            onBlur={() => {
                setErrMessage("");
            }}
            {...props}
        />
    );
}

export default W86AlphanumericTextfield;
