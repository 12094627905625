import React from 'react';
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { styled } from '@mui/material/styles';

const ModalBox = styled(Box)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  height: '80%',
  backgroundColor: '#fff',
  boxShadow: 24,
  borderRadius: '10px',
  padding: 0,
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
});

const StyledAppBar = styled(AppBar)({
  maxHeight: '40px',
});

const StyledToolbar = styled(Toolbar)({
  minHeight: '40px',
  height: '40px',
});

const FlexGrow = styled(Box)({
  flexGrow: 1,
});

const StyledIframe = styled('iframe')({
  width: '100%',
  height: '100%',
  border: 'none',
  display: 'block',
  flexGrow: 1,
});

const PortalModal = ({ open, onClose, url }) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="form-modal"
      aria-describedby="form-in-modal"
    >
      <ModalBox>
        <StyledAppBar position="static" color="default" elevation={0}>
          <StyledToolbar variant="dense">
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => window.open(url, '_blank')}
              aria-label="open in new tab"
              size="small"
            >
              <OpenInNewIcon fontSize="small" />
            </IconButton>
            <FlexGrow />
            <IconButton
              edge="end"
              color="inherit"
              onClick={onClose}
              aria-label="close"
              size="small"
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </StyledToolbar>
        </StyledAppBar>
        <StyledIframe
          src={url}
          title="Form"
        />
      </ModalBox>
    </Modal>
  );
};

export default PortalModal;