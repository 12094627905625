import React, { useState, useEffect } from "react";

function DaysCountdown({ trialDaysRemaining }) {
    const [time, setTime] = useState(trialDaysRemaining.toFixed(5));

    useEffect(() => {
        const timer = setTimeout(() => {
            let t = calculateTimeLeft(time);
            if (t <= 0) return () => clearTimeout(timer);
            setTime(t);
        }, 1000);

        return () => clearTimeout(timer);
    });

    const calculateTimeLeft = (t) => {
        let newNum = parseFloat(t) - 1 / 86400;
        return newNum.toFixed(5);
    };

    return time;
}

export default DaysCountdown;
