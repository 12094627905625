import React from "react";
//mui
import { Grid, Fade } from "@mui/material";

//styles
import { withStyles } from "@mui/styles";

const styles = () => ({
  instruction: {
    maxWidth: "100%!important",
    background: "#F5F5F5",
    borderRadius: "16px 16px 16px 0px",
    width: "fit-content",
    padding: "0px 16px 0px 16px",
  },
});

const Instruction = ({ classes }) => {
  return (
        <Fade timeout={1000} in={true}>
    <Grid className={classes.instruction}>
      <p>
        <b>Make additional changes or insert and save?</b>
      </p>
      <p>
        If you have any feedback or additional changes, you can send me an additional message and I can edit and adjust this workflow for you.
      </p>
      <p>
        If everything looks correct, I can insert and save this workflow to the canvas.
      </p> 
      <p>
        Please note that once I insert and save this workflow I will not be able to add or remove components or connections for you, but I can help you with configuring and building individual components. 
      </p> 
    </Grid>
    </Fade>
  );
};

export default withStyles(styles)(Instruction);
