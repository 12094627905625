import React from "react";
import { Grid, Card, CardContent, Typography, Fade } from "@mui/material";
import ExploreIcon from "@mui/icons-material/Explore";
import PropTypes from "prop-types";
import projectStore from "../../../../../../ProjectCanvas/ProjectStore";
import { withStyles } from "@mui/styles";
import AIBuilderStyles from "../../../AIBuilderStyles";
const styles = {
  card: {
    border: "1px solid #8000CE",
    borderRadius: "6px",
    paddingBottom: "0px",
    cursor: "pointer",
    background: "#FFF",
    "&:hover": {
      backgroundColor: "#F4EDF6",
      borderRadius: " 6px",
      border: "1px solid #944BA8",
      boxShadow: " 4px 4px 0px 0px rgba(0, 0, 0, 0.25)"
    }
  },
  cardIcon: {
    verticalAlign: "bottom",
    height: "20px",
    width: "20px",
    marginRight: "8px",
    filter: "brightness(0) saturate(100%)"
  },
  ...AIBuilderStyles()
};

const SessionCard = ({ icon, title, description, classes, onClick }) =>
  <Grid item xs={12}>
    <Card className={classes.card} onClick={onClick}>
      <CardContent>
        <Typography>
          {icon}
          <span className={"bold"}>
            {title}
          </span>
        </Typography>
        <p className={"terminate-button"}>
          {description}
        </p>
      </CardContent>
    </Card>
  </Grid>;

SessionCard.propTypes = {
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  onClick: PropTypes.func
};

const InitialMessage = ({ classes, setSidebarState }) => {
  return (
    <Fade in timeout={1000}>
      <div className={classes.gpt}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <p>
              Hi {projectStore.state.userName}, welcome back.
            </p>
            <p className={"m-0"}>How can I help you today?</p>
          </Grid>
          <SessionCard
            classes={classes}
            icon={
              <img
                src="/svgs/family_history.svg"
                className={classes.cardIcon}
              />
            }
            title="Build a new workflow"
            description="Build a workflow from start to finish based on your instructions, a description of a process, or a general requirement."
            onClick={() => setSidebarState("WORKFLOW")}
          />
          <SessionCard
            classes={classes}
            icon={
              <img
                src="/svgs/stylus_note.svg"
                className={classes.cardIcon}
              />
            }
            title="Edit workflow"
            description="Make edits and changes to an existing workflow"
            onClick={() => setSidebarState("WORKFLOW_EDITOR")}
          />
          <SessionCard
            classes={classes}
            icon={
              <img src="/svgs/cognition.svg" className={classes.cardIcon} />
            }
            title="Strategy session"
            description="Brainstorm strategies, ideas and solutions for solving a problem or meeting a goal, objective or requirement."
            onClick={() => setSidebarState("STRATEGY")}
          />
          <SessionCard
            classes={classes}
            icon={<ExploreIcon className={classes.cardIcon} />}
            title="Process discovery session"
            description="Identify, document and map processes and workflows from structured or unstructured information. "
            onClick={() => setSidebarState("DISCOVERY")}
          />
        </Grid>
      </div>
    </Fade>
  );
};

InitialMessage.propTypes = {
  classes: PropTypes.object.isRequired,
  setSidebarState: PropTypes.func.isRequired
};

export default withStyles(styles)(InitialMessage);
